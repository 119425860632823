/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
} from '@material-ui/core';

/* const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions); */

export default function CustomizedDialogs(props) {

    /* 
    PROPS
    title: Dialog title
    content: Dialog content
    */

    const {
        title,
        content,
    } = props;

    const [open, setOpen] = React.useState(localStorage.getItem('calculatorInfo') === null ? true : JSON.parse(localStorage.getItem('calculatorInfo')));

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem('calculatorInfo', false);
    };

    const { t, } = useTranslation();

    return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'black' }} id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('LoginPage.kapat')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
