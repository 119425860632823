import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import axiosInstance from '../../../../components/axios/axiosApi';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import SunSpinner from '../../../../components/UI/SunSpinner/SunSpinner'; 
import SolarPackageComponent from '../../../../components/SolarPackage/SolarPackage';
import UserInfoAccordion from '../../../../components/SolarPackage/UserInfoAccordion';
import ProjectOverview from '../../../../components/SolarPackage/ProjectOverview';
import BootstrapSelect from '../../../../components/BootstrapSelect/BootstrapSelect';

import { changeSelectedPackageId } from '../../../../services/products/product/solarPackageReducer';

import {
  Container,
  Grid,
  Button,
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'var(--secondary-color)',
    color: 'white'
  }
})(Button);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 4,
    fontFamily: 'Poppins',
    borderBottom: 'none',
    fontSize: '1.2rem',
    width: '30%'
  },
}))(TableCell);

const StyledTableCellTitle = withStyles(() => ({
  root: {
    padding: 4,
    fontFamily: 'Poppins',
    borderBottom: 'none',
    fontSize: '1rem',
    width: '70%'
  },
}))(TableCell);

const BeforePaymentSolarPackages = ({
  userInfo,
  imagePreview,
  expandUserInfo,
  setExpandUserInfo
}) => {

  const { t, } = useTranslation();

  const dispatch = useDispatch();

  const {
    staticMapURL
  } = useSelector(state => state.location);

  const {
    module,
  } = useSelector(state => state.module);

  const {
    mail
  } = useSelector(state => state.summary);

  const {
    selectedPackageId
  } = useSelector(state => state.solarPackage);

  // Responsive Design Values
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  // const [selectedPackageId, setSelectedPackageId] = useState();

  // Package Related States
  const [ selectedPackageInfo, setSelectedPackageInfo ] = useState({
    roi: '',
    yearlySavings: '',
    price_TL: '',
    kdv: '',
    price_TL_kdv: '',
    promotion_amount: 0,
    price_TL_kdv_with_promotion: '',
    price_EUR_kdv_with_promotion: '',
    is_promotion_available: false,
    upfront_cost: '',
    monthlyConsumption: '',
    yearlyProduction: '',
    yearlyConsumption: '',
    savedTrees: '',
    savedCO2: ''
  });
  const [ filterOrder, setFilterOrder ] = useState('roi');
  const [ gridType, setGridType ] = useState('ON');

  const [ allPackages, setAllPackages ] = useState([]);
  const [ sortFeatures, setSortFeatures ] = useState({});

  const [ packageNotFound, setPackageNotFound ] = useState(false);

  const [ bestRoiPackageId, setBestRoiPackageId ] = useState();
  const [ cheapestPackageId, setCheapestPackageId ] = useState();

  const changeSelectedPackage = (packageId) => {
    dispatch(changeSelectedPackageId(packageId));
  };

  const sortPackages = (allPackages, sortList) => {
    let tempSortedPackages = [];
    sortList.map((packageId) => {
      allPackages.map((solarPackage) => {
        if (Number(solarPackage.id) === Number(packageId)) {
          tempSortedPackages.push(solarPackage)
        }
      });
    });
    return tempSortedPackages;
  };

  useEffect(() => {
    if (allPackages.length !== 0 && Object.keys(sortFeatures).length !== 0) {
      const sortedPackageList = sortPackages(allPackages, sortFeatures[filterOrder]);
      setAllPackages(sortedPackageList);
    }
  }, [filterOrder]);

  useEffect(() => {
    const fetchSuitablePackages = async () => {
      if (allPackages.length !== 0) {
        if (selectedPackageId) {
          let saveRequestURL = "musteri/save"
          if (module === "german_solarvis") {
            saveRequestURL = "deutsche/musteri/save"
          }
          await axiosInstance.post(saveRequestURL, {
            language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase(),
            selected_package_id: Number(selectedPackageId),
            mail: mail,
          })
            .then((response) => {
              // console.log(response.data);
            })
            // .catch((error) => console.log(error))
    
          await axiosInstance.post(`home/single_package_feasibility?format=json`, {
            package_id: Number(selectedPackageId)
          })
            .then((response) => {
              const data = response.data.data.feasibility_results;
              const packageInfo = allPackages.filter((pkg) => pkg.id === Number(selectedPackageId))[0];
              setSelectedPackageInfo({
                roi: data["roi"],
                yearlySavings: module !== "german_solarvis" ? data["yillik_ort_tl_tasrrf"] : data["yillik_ort_euro_tasrrf"],
                monthlyConsumption: data["aylik_tuketim"],
                price_TL: module !== "german_solarvis" ? packageInfo.price_TL : packageInfo.price_USD_kdv_with_promotion,
                kdv: packageInfo.kdv,
                price_TL_kdv: module !== "german_solarvis" ? packageInfo.price_TL_kdv : packageInfo.price_USD_kdv_with_promotion,
                upfront_cost: packageInfo.upfront_cost,
                promotion_amount: packageInfo.promotion_amount,
                price_TL_kdv_with_promotion: module !== "german_solarvis" ? packageInfo.price_TL_kdv_with_promotion : packageInfo.price_USD_kdv_with_promotion,
                is_promotion_available: packageInfo.is_promotion_available,
                yearlyProduction: data["yillik_uretim"],
                yearlyConsumption: data["yillik_tuketim"],
                savedTrees: data["agac"],
                savedCO2: data["co2"]
              });
            })
            // .catch((error) => console.log(error))
        }
      } else {

        let tempPackagePreviews;
        await axiosInstance.get(`solar_package/fetch_solar_packages?solar_package_ids=&grid_type=&language=${(localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase()}`)
          .then((response) => {
            tempPackagePreviews = response.data.data;
            if (tempPackagePreviews.length === 0) {
              setPackageNotFound(true);
            }
          })
          // .catch((error) => console.log(error))
    
        let tempSortFeatures;
        await axiosInstance.get(`home/fetch_suitable_packages?grid_type=${gridType}&format=json`)
          .then(response => {
            const data = response.data.data;
            setSortFeatures(data.sorted_list);
            tempSortFeatures = data.sorted_list;
            setBestRoiPackageId(data.sorted_list.roi[0]);
            setCheapestPackageId(data.sorted_list.kdvli_usd_maliyet[0]);
            if (!selectedPackageId) {
              dispatch(changeSelectedPackageId(Number(data.sorted_list.roi[0])));
              // setSelectedPackageId(Number(data.sorted_list.roi[0]));
            }
          })
          .catch(error => {
            // console.log(error);
        });
    
        const sortedPackageList = sortPackages(tempPackagePreviews, tempSortFeatures[filterOrder]);
        setAllPackages(sortedPackageList);

        if (selectedPackageId) {
          let saveRequestURL = "musteri/save"
          if (module === "german_solarvis") {
            saveRequestURL = "deutsche/musteri/save"
          }
          await axiosInstance.post(saveRequestURL, {
            language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase(),
            selected_package_id: Number(selectedPackageId),
            mail: mail,
          })
            .then((response) => {
              // console.log(response.data);
            })
            // .catch((error) => console.log(error))
    
          await axiosInstance.post(`home/single_package_feasibility?format=json`, {
            package_id: Number(selectedPackageId)
          })
            .then((response) => {
              const data = response.data.data.feasibility_results;
              const packageInfo = sortedPackageList.filter((pkg) => pkg.id === Number(selectedPackageId))[0];
              setSelectedPackageInfo({
                roi: data["roi"],
                yearlySavings: module !== "german_solarvis" ? data["yillik_ort_tl_tasrrf"] : data["yillik_ort_euro_tasrrf"],
                monthlyConsumption: data["aylik_tuketim"],
                price_TL: module !== "german_solarvis" ? packageInfo.price_TL : packageInfo.price_USD_kdv_with_promotion,
                kdv: packageInfo.kdv,
                price_TL_kdv: module !== "german_solarvis" ? packageInfo.price_TL_kdv : packageInfo.price_USD_kdv_with_promotion,
                upfront_cost: packageInfo.upfront_cost,
                promotion_amount: packageInfo.promotion_amount,
                price_TL_kdv_with_promotion: module !== "german_solarvis" ? packageInfo.price_TL_kdv_with_promotion : packageInfo.price_USD_kdv_with_promotion,
                is_promotion_available: packageInfo.is_promotion_available,
                yearlyProduction: data["yillik_uretim"],
                yearlyConsumption: data["yillik_tuketim"],
                savedTrees: data["agac"],
                savedCO2: data["co2"]
              });
            })
            // .catch((error) => console.log(error))
        }

      }
  
    }

    fetchSuitablePackages();
  }, [selectedPackageId, gridType]);

  return (
    <>
      {packageNotFound &&
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyItems: 'center', backgroundColor:'#F4F6F8' }}>
        <h3>Uygun paket bulunamamıştır. Lütfen bizimle iletişime geçin.</h3>
      </div>}
      {(selectedPackageId && selectedPackageInfo.roi)
      ?
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', backgroundColor:'#F4F6F8' }}>
        <div style={{ flex: 5 }}>
          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', margin: isTabletOrMobile ? '0px' : '20px', marginTop: '0px' }}>
            <Container maxWidth={false}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <UserInfoAccordion
                    userInfo={userInfo}
                    imagePreview={imagePreview}
                    expandUserInfo={expandUserInfo}
                    setExpandUserInfo={setExpandUserInfo}
                    isAccordion={true}
                  />
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <h2 style={{ marginTop: 30, fontSize: isTabletOrMobile ? '1.4rem' : '1.8rem', fontWeight: 'normal' }}>{t('BeforePayment.suitableSolarPackages')}</h2>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: isTabletOrMobile ? 'column' : 'row', alignItems: 'center' }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span style={{ fontSize: isTabletOrMobile ? '0.85rem' : '1rem' }}>{t('BeforePayment.offGridPackages')}</span>
                      <Switch
                        checked={gridType === 'ON' ? true : false}
                        onChange={handleChangeGridType}
                        color="primary"
                        name="gridType"
                        inputProps={{ 'aria-label': 'gridType checkbox' }}
                      />
                      <span style={{ fontSize: isTabletOrMobile ? '0.85rem' : '1rem' }}>{t('BeforePayment.onGridPackages')}</span>
                      <a href="https://solarvis.co" target="_blank">
                        <IconButton aria-label="question grid type">
                          <HelpIcon/>
                        </IconButton>
                      </a>
                    </div> */}
                    <div>
                      <span>{t('BeforePayment.sort')}:</span>
                      <BootstrapSelect
                        label="filter"
                        name="packageListOrder"
                        id="packageListOrder"
                        value={filterOrder}
                        changeFuction={setFilterOrder}
                        selectItemValues={
                          ['roi', 'kdvli_usd_maliyet', 'ac_guc', 'guc', 'panel_sayisi', 'yillik_uretim']
                        }
                        selectItemLabels={
                          [t('BeforePayment.roiLabel'), t('BeforePayment.priceLabel'), t('BeforePayment.acPowerLabel'), t('BeforePayment.dcPowerLabel'), t('BeforePayment.panelLabel'), t('BeforePayment.productionLabel')]
                        }
                        width={isTabletOrMobile ? 220 : 300}
                      />
                    </div>
                  </div>
                </Grid>
                {!(allPackages.length === 0) && allPackages.map((solarPackage) => (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xl={4}
                    xs={12}
                    key={solarPackage.id}
                  >
                    <SolarPackageComponent
                      bestRoiPackageId={bestRoiPackageId}
                      cheapestPackageId={cheapestPackageId}
                      data={solarPackage}
                      selectFunction={changeSelectedPackage}
                      packageSelected={solarPackage.id === selectedPackageId}
                      primaryColor={getComputedStyle(document.documentElement).getPropertyValue('--primary-color')}
                      secondaryColor={getComputedStyle(document.documentElement).getPropertyValue('--secondary-color')}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
        </div>
        {!isTabletOrMobile &&
        <div style={{ flex: 2 }}>
          {(selectedPackageId && selectedPackageInfo.roi)
          ?
          <ProjectOverview
            pkgOverview={selectedPackageInfo}
          />
          :
          <SunSpinner text=""/>
          }
        </div>}
      </div>
      :
      <SunSpinner text=""/>
      }
      {(isTabletOrMobile && selectedPackageId && selectedPackageInfo.roi) &&
      <div style={{
          margin: '0px',
          minWidth: '0px',
          gridColumn: '1 / -1',
          width: '100%',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px 4px',
          bottom: '0rem',
          marginTop: 'auto',
          position: 'sticky',
          backgroundColor: 'white',
          padding: '2rem',
          paddingTop: '0.5rem'
      }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Table style={{margin: '10px 0px' }}>
            <TableBody>
              <TableRow
                key="yearlySavings"
              >
                <StyledTableCellTitle>
                  <strong>{t('ProjectOverview.yearlySavings')}</strong>
                </StyledTableCellTitle>
                <StyledTableCell>
                  {module !== "german_solarvis" ? `${selectedPackageInfo.yearlySavings} TL` : `€ ${selectedPackageInfo.yearlySavings}`}
                </StyledTableCell>
              </TableRow>
              <TableRow
                key="roi"
              >
                <StyledTableCellTitle>
                  <strong>{t('ProjectOverview.roi')}</strong>
                </StyledTableCellTitle>
                <StyledTableCell>
                  {selectedPackageInfo.roi} {t('ProjectOverview.years')}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Link
            to="/login/package-payment"
            state={{ pkgOverview: selectedPackageInfo }}
            style={{ textDecoration: 'none' }}
          >
            <StyledButton
                size="large"
                type="submit"
                variant="contained"
                fullWidth
            >
              {t('ProjectOverview.nextToCheckout')}
            </StyledButton>
          </Link>
        </div>
      </div>
      }
    </>
  );
}

export default BeforePaymentSolarPackages;
