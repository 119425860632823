import React from 'react';
import classes from './SunSpinner.module.css';
import {
    Dialog,
    DialogContent,
  } from '@material-ui/core';

const spinner = (props) => {

    const { isDialog } = props;

    const normalSpinner = (
        <>
            {/* <div className={classes.bar}></div> */}
            <div className={classes.circle}>
                <div className={classes.circle}></div>
                <div className={classes.innerCircle}></div>
            </div>
            <div className={classes.frame}>
                <div className={classes.bar}></div>
                <div className={classes.bar}></div>
                <div className={classes.bar}></div>
                <div className={classes.bar}></div>
                <div className={classes.bar}></div>
                <div className={classes.bar}></div>
                <div className={classes.bar}></div>    
                <div className={classes.bar}></div>    
                <div className={classes.bar}></div>    
            </div>
        </>
    );

    const dialogSpinner = (
        <>
            <Dialog aria-labelledby="customized-dialog-title" open={true} style={{ backgroundColor: 'transparent' }}>
                <DialogContent style={{ width: 300, height: 300, backgroundColor: 'transparent' }}>
                    <div className={classes.circle}>
                        <div className={classes.circle}></div>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <div className={classes.frame}>
                        <div className={classes.bar}></div>
                        <div className={classes.bar}></div>
                        <div className={classes.bar}></div>
                        <div className={classes.bar}></div>
                        <div className={classes.bar}></div>
                        <div className={classes.bar}></div>
                        <div className={classes.bar}></div>    
                        <div className={classes.bar}></div>    
                        <div className={classes.bar}></div>    
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );

    return (
        isDialog ? dialogSpinner : normalSpinner
    );
};

export default spinner;
