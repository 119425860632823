import React, { useState, useRef, useEffect } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../components/axios/axiosApi";
import classes from "./SummaryPage.module.css";
import { IconContext } from "react-icons/lib";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { changeStaticMapURL } from "../../services/products/product/reducer";
import {
  changeUserId,
  changeSubconProjectId,
  changeFirstSession,
  changeUserAPIStatus,
} from "../../services/users/userReducer";
import { changeSolarvisBannerState } from "../../services/products/product/moduleReducer";
import {
  changeSelectedDCPower,
  changeOptimalDCPower,
  changeOptimalACPower,
  changeYaklasikAmortisman,
  changeAmortismanYilAy,
  changeYillikKazanc,
  changeYillikKazancEUR,
  changeYaklasikMaliyet,
  changeYaklasikMaliyetEUR,
  changeYillikTasarruf,
  changeYillikTasarrufEUR,
} from "../../services/products/product/summaryReducer";
import { changeStep } from "../../services/products/product/stepReducer";
import { FaSolarPanel, FaReceipt } from "react-icons/fa";
import { RiBuilding2Fill, RiBattery2Fill } from "react-icons/ri";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsLightningFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import RechartComposedLine from "./RechartComposedLine";
import i18n from "../../i18n";
import {
  makeStyles,
  Dialog,
  DialogContent,
  withStyles,
  Slider,
  Button,
  Tooltip,
} from "@material-ui/core";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import InfoIcon from "@material-ui/icons/Info";
import MuiAlert from "@material-ui/lab/Alert";
import SpinnerDialog from "../../components/UI/SpinnerDialog/Spinner";
import SunSpinner from "../../components/UI/SunSpinner/SunSpinner";
import download from "downloadjs";

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 600,
  },
})(Dialog);

const StyledSlider = withStyles({
  root: {
    color: "var(--secondary-color)",
    height: 8,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginLeft: -8,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  markLabel: {
    transform: "rotate(45deg)",
  },
  mark: {
    backgroundColor: "#fff",
    height: 8,
    width: 8,
    border: "1px solid currentColor",
    opacity: 1,
    borderRadius: "50%",
    marginLeft: "-4px",
  },
  markActive: {
    opacity: 1,
    height: 8,
    width: 8,
    backgroundColor: "#fff",
    borderRadius: "50%",
  },
})(Slider);

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: 14,
    backgroundColor: "var(--secondary-color)",
  },
  arrow: {
    color: "var(--secondary-color)",
  },
})(Tooltip);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <StyledTooltip
      arrow
      open={open}
      interactive
      enterTouchDelay={0}
      title={value}
    >
      {children}
    </StyledTooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  myAlert: {
    color: "#000000",
    backgroundColor: "#ffffff",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

function SummaryPage(props) {
  const { usedFor } = props;

  const MUIClasses = useStyles();
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [feasibilityLoading, setFeasibilityLoading] = useState(false);
  const { userId, firstSession } = useSelector((state) => state.users);
  const {
    roofArea,
    cityName,
    ilce,
    tempLatitude,
    tempLongitude,
    drawingPointsNew,
    alanTercihi,
  } = useSelector((state) => state.location);
  const {
    fatura,
    batarya,
    sarj,
    tesis,
    meskenTipi,
    roofTilt,
    sGucu,
    trafoGucu,
    mail,
    cephe,
    consumptionProfile,
    selectedDCPower,
    optimalDCPower,
    optimalACPower,
    dailyConsumptionProfile,
    monthlyConsumptionProfile,
    monthlyConsumptionInput,
  } = useSelector((state) => state.summary);
  const { module, availableModules } = useSelector((state) => state.module);
  const { firstScreenAtEnd } = useSelector((state) => state.companyInfo);

  const [feasibilityInfoBoxOpen, setFeasibilityInfoBoxOpen] = useState(false);

  const handleCloseFeasbilityInfoBox = () => {
    setFeasibilityInfoBoxOpen(false);
  };

  const [analysisData, setAnalysisData] = useState({
    panel: null,
    guc: null,
    acGuc: null,
    tlMaliyet: null,
    dolarMaliyet: null,
    eurMaliyet: null,
    uretim: null,
    amortisman: null,
    amortismanGonder: null,
    agac: null,
    co2: null,
    yillikTasarruf: null,
    yillikTasarrufEUR: null,
    dolar: null,
    aylikTuketim: null,
    yillikTuketim: null,
    yillikTasarrufUSD: null,
    amortismanYaklasik: null,
    amortismanYilAy: ["-", "-"],
    tlMaliyetYaklasik: null,
    dolarMaliyetYaklasik: null,
    eurMaliyetYaklasik: null,
    kredilendirilebilirMaliyetTL: null,
    kredilendirilebilirMaliyetUSD: null,
  });

  const [powerList, setPowerList] = useState([]);
  const [powerListMarks, setPowerListMarks] = useState([]);

  const [graphData, setGraphData] = useState({
    firstGraphDataNormalFatura: [],
    firstGraphDataGesFatura: [],
    barGraphData: [],
  });

  const [mixedGraphData, setMixedGraphData] = useState([]);
  const [arcelikOzelContent, setArcelikOzelContent] = useState({
    toplamNormalFatura: "",
    sistemeSatis: "",
    toplamTasarruf: "",
    toplamKazanc: "",
  });

  let firstGraphDataNormalFaturaTemp = [];
  let firstGraphDataGesFaturaTemp = [];
  let graphDataTemp = [];
  let mixedGraphDataTemp = [];

  let usedAxiosInstance = axiosInstance;

  const handleChangeStep = (newValue) => {
    dispatch(changeStep({ stepNumber: newValue }));
  };

  const handleChangeDragEnd = (event, newValue) => {
    dispatch(
      changeSelectedDCPower({
        selectedDCPower: newValue || parseInt(event.target.value),
      })
    );
  };

  const translateTesisGerman = () => {
    if (tesis === "Mesken") {
      return "Wohn";
    } else {
      return "Kommerziell";
    }
  };

  const findMinMaxLatLngOfDrawings = () => {
    let maxLat;
    let minLat;
    let maxLng;
    let minLng;
    drawingPointsNew.forEach((value) => {
      value.points.forEach((point) => {
        if (!maxLat || point.lat > maxLat) {
          maxLat = point.lat;
        }
        if (!minLat || point.lat < minLat) {
          minLat = point.lat;
        }
        if (!maxLng || point.lng > maxLng) {
          maxLng = point.lng;
        }
        if (!minLng || point.lng < minLng) {
          minLng = point.lng;
        }
      });
    });
    return {
      maxLat: maxLat,
      minLat: minLat,
      maxLng: maxLng,
      minLng: minLng,
    };
  };

  const getDistance = (p1, p2) => {
    var rad = function (x) {
      return x * Math.PI / 180;
    };

    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat() - p1.lat());
    var dLong = rad(p2.lng() - p1.lng());
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  };

  const calculateMaxRealDistanceFromLatLng = () => {
    const { maxLat, minLat, maxLng, minLng } = findMinMaxLatLngOfDrawings();
    const maxLatlng = new window.google.maps.LatLng(maxLat, maxLng);
    const minLatlng = new window.google.maps.LatLng(minLat, minLng);
    const maxDistance = getDistance(maxLatlng, minLatlng);
    return maxDistance;
  };

  useEffect(() => {
    if (analysisData.guc) {
      let totalArea = 0;
      if (alanTercihi === "cizim" && drawingPointsNew.length > 0) {
        drawingPointsNew.forEach((value) => (totalArea += value.area));
      } else {
        totalArea = roofArea;
      }

      let staticMapURLString = `https://maps.googleapis.com/maps/api/staticmap?size=400x400&maptype=satellite`;

      if (drawingPointsNew.length > 0 && alanTercihi === "cizim") {
        const maxRealDistance = Math.round(calculateMaxRealDistanceFromLatLng());

        // Decide Static Map zoom level according to total roof area
        let tempZoom = 18;
        if (maxRealDistance <= 10) {
          tempZoom = 21;
        } else if (maxRealDistance > 10 && maxRealDistance <= 40) {
          tempZoom = 20;
        } else if (maxRealDistance > 40 && maxRealDistance <= 80) {
          tempZoom = 19;
        } else if (maxRealDistance > 80 && maxRealDistance <= 160) {
          tempZoom = 18;
        } else if (maxRealDistance > 160 && maxRealDistance <= 320) {
          tempZoom = 17;
        } else if (maxRealDistance > 320) {
          tempZoom = 16;
        } else {
          tempZoom = 18;
        }

        staticMapURLString += `&zoom=${tempZoom}`;

        const colorNameListofGoogle = [
          "0xAD1717",
          "0xFB8B24",
          "0xE36414",
          "0x0F4C5C",
        ];
        drawingPointsNew.forEach((tempRoofAreaObject, idxx) => {
          let drawPoints = `&path=color:${colorNameListofGoogle[idxx]}|weight:5|fillcolor:${colorNameListofGoogle[idxx]}|`;
          tempRoofAreaObject.points.forEach((point) => {
            drawPoints += point.lat + "," + point.lng + "|";
          });
          drawPoints = drawPoints.slice(0, -1);
          staticMapURLString += drawPoints;
        });
      } else {
        // Decide Static Map zoom level according to total roof area
        let tempZoom = 18;
        if (totalArea <= 75) {
          tempZoom = 21;
        } else if (totalArea > 75 && totalArea <= 400) {
          tempZoom = 20;
        } else if (totalArea > 400 && totalArea <= 1500) {
          tempZoom = 19;
        } else if (totalArea > 1500 && totalArea <= 4000) {
          tempZoom = 18;
        } else if (totalArea > 4000 && totalArea <= 10000) {
          tempZoom = 17;
        } else if (totalArea > 10000) {
          tempZoom = 16;
        } else {
          tempZoom = 18;
        }
        staticMapURLString = `https://maps.googleapis.com/maps/api/staticmap?center=${tempLatitude},${tempLongitude}&zoom=${tempZoom}&size=400x400&maptype=satellite&markers=color:red%7C${tempLatitude},${tempLongitude}`;
      }

      dispatch(changeStaticMapURL({ staticMapURL: staticMapURLString }));

      axiosGetUserId(staticMapURLString);
    }
  }, [analysisData]); // her analysisData değiştiğinde çalışması aynı anda birden fazla istek atmasına sebep oluyor


  let lineGraphTicksTemp = [];
  const [lineGraphTicks, setLineGraphTicks] = React.useState(null);

  async function axiosGet() {
    setFeasibilityLoading(true);

    let sozlesmeGucuTemp;
    if (tesis === "Mesken") {
      sozlesmeGucuTemp = "10";
    } else {
      sozlesmeGucuTemp = sGucu;
    }

    let requestURL = `fizibilite?tutar=${fatura}`;
    if (module === "german_solarvis") {
      requestURL = `deutsche/feasibility?&tuketim=${monthlyConsumptionInput}`;
    }
    if (selectedDCPower === 0) {
      requestURL += `&enlem=${String(tempLatitude.toFixed(4))}&boylam=${String(
        tempLongitude.toFixed(4)
      )}&tesis=${tesis}&sgucu=${sozlesmeGucuTemp}&consumption_profile=${consumptionProfile}&format=json`;
    } else {
      requestURL += `&enlem=${String(tempLatitude.toFixed(4))}&boylam=${String(
        tempLongitude.toFixed(4)
      )}&tesis=${tesis}&sgucu=${sozlesmeGucuTemp}&consumption_profile=${consumptionProfile}&input_dc_guc=${selectedDCPower}&format=json`;
    }

    if (
      !monthlyConsumptionProfile.every((value) => value.consumption === 0) &&
      monthlyConsumptionProfile.length > 0
    ) {
      // Make this list of objects to string with splitted comma that only includes consumption values
      let tempMonthlyConsumptionProfile = "";
      monthlyConsumptionProfile.map(
        (value) =>
          (tempMonthlyConsumptionProfile += `${String(value.consumption)},`)
      );
      tempMonthlyConsumptionProfile = tempMonthlyConsumptionProfile.slice(
        0,
        -1
      );
      requestURL += `&monthly_manual_consumptions=${tempMonthlyConsumptionProfile}`;
    }
    if (
      dailyConsumptionProfile.every((value) => value.consumption !== 0) &&
      dailyConsumptionProfile.length > 0
    ) {
      // Make this list of objects to string with splitted comma that only includes consumption values
      let tempDailyConsumptionProfile = "";
      dailyConsumptionProfile.map(
        (value) =>
          (tempDailyConsumptionProfile += `${String(value.consumption)},`)
      );
      tempDailyConsumptionProfile = tempDailyConsumptionProfile.slice(0, -1);
      requestURL += `&daily_consumption_percentages=${tempDailyConsumptionProfile}`;
    }

    if (drawingPointsNew.length > 0 && alanTercihi === "cizim") {
      let tempDrawingPoints = "";
      drawingPointsNew.forEach(
        (value) =>
          (tempDrawingPoints += `${value.area},${value.slope},${value.aspect}|`)
      );
      tempDrawingPoints = tempDrawingPoints.slice(0, -1);
      requestURL += `&drawing_areas=${tempDrawingPoints}`;
    } else {
      requestURL += `&drawing_areas=${roofArea},${roofTilt},${cephe}`;
    }

    let tempOptimalDCPower = optimalDCPower;
    let tempOptimalACPower = optimalACPower;

    await usedAxiosInstance
      .get(requestURL)
      .then((response) => {
        const data = response.data.data;
        setAnalysisData({
          panel: data["panel_sayisi"],
          guc: data["guc"],
          acGuc: data["ac_guc"],
          tlMaliyet: data["kdvli_tl_maliyet"],
          dolarMaliyet: data["kdvli_usd_maliyet"],
          eurMaliyet: data["kdvli_euro_maliyet"],
          uretim: data["yillik_uretim"],
          amortisman: data["roi"].slice(0, 4),
          amortismanGonder: data["roi"],
          agac: data["agac"],
          co2: data["co2"],
          yillikTasarruf: data["yillik_ort_tl_tasrrf"],
          dolar: data["dolar"],
          aylikTuketim: data["aylik_tuketim"], // String((Number(response.data['tuketim'])*0.012).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
          yillikTuketim: data["yillik_tuketim"],
          yillikTasarrufUSD: data["yillik_ort_usd_tasrrf"],
          yillikTasarrufEUR: data["yillik_ort_euro_tasrrf"],
          amortismanYaklasik:
            data["roi"] === "20+" ? "20+" : data["approx_roi"],
          amortismanYilAy:
            data["roi"] === "20+" ? ["20+", ""] : data["roi_as_years_months"],
          tlMaliyetYaklasik: data["approx_tl_maliyet"],
          dolarMaliyetYaklasik: data["approx_usd_maliyet"],
          eurMaliyetYaklasik: data["approx_euro_maliyet"],
          kredilendirilebilirMaliyetTL:
            module === "akbank"
              ? data["akbank_ozel_content"]["creditable_amount_tl"]
              : null,
          kredilendirilebilirMaliyetUSD:
            module === "akbank"
              ? data["akbank_ozel_content"]["creditable_amount_usd"]
              : null,
        });

        // Yıllık tasarrufu, amortisman süresini ve proje maliyetini Redux'ta da güncelle
        dispatch(
          changeYillikTasarruf({ yillikTasarruf: data["yillik_ort_tl_tasrrf"] })
        );
        dispatch(
          changeYillikTasarrufEUR({
            yillikTasarrufEUR: data["yillik_ort_euro_tasrrf"],
          })
        );

        if (data["roi"] === "20+") {
          dispatch(changeAmortismanYilAy({ amortismanYilAy: ["20+", ""] }));
          dispatch(changeYaklasikAmortisman({ yaklasikAmortisman: "20+" }));
        } else {
          dispatch(
            changeAmortismanYilAy({
              amortismanYilAy: data["roi_as_years_months"],
            })
          );
          dispatch(
            changeYaklasikAmortisman({ yaklasikAmortisman: data["approx_roi"] })
          );
        }
        dispatch(
          changeYaklasikMaliyet({ yaklasikMaliyet: data["approx_tl_maliyet"] })
        );
        dispatch(
          changeYaklasikMaliyetEUR({
            yaklasikMaliyetEUR: data["approx_euro_maliyet"],
          })
        );

        dispatch(
          changeYillikKazanc({
            yillikKazanc: data["arcelik_ozel_content"]["toplam_kazanc"],
          })
        );
        dispatch(
          changeYillikKazancEUR({
            yillikKazancEUR: data["arcelik_ozel_content"]["toplam_kazanc"],
          })
        );

        if (data.hasOwnProperty("optimal_dc_guc")) {
          dispatch(
            changeOptimalDCPower({ optimalDCPower: data["optimal_dc_guc"] })
          );
          dispatch(
            changeOptimalACPower({ optimalACPower: data["optimal_ac_guc"] })
          );
          tempOptimalDCPower = data["optimal_dc_guc"];
          tempOptimalACPower = data["optimal_ac_guc"];
          if (selectedDCPower === 0 && tesis !== "Mesken" && module !== "ibtenerji" && usedFor === "endUser") {
            dispatch(
              changeSelectedDCPower({ selectedDCPower: data["optimal_dc_guc"] })
            );
          }
        }

        // Yeni grafik için data
        let onlyMonths = data["aylik_zaman"]
          .slice(0, 12)
          .map((month) => month.split(" ")[0].slice(0, 3) + ".");
        if (
          (
            localStorage.getItem("dil") || String(i18n.language).split("-")[0]
          ).toUpperCase() === "EN"
        ) {
          // English month abbreviations list
          onlyMonths = onlyMonths.map((month) => {
            switch (month) {
              case "Oca.":
                return (month = "Jan.");
              case "Şub.":
                return (month = "Feb.");
              case "Mar.":
                return (month = "Mar.");
              case "Nis.":
                return (month = "Apr.");
              case "May.":
                return (month = "May.");
              case "Haz.":
                return (month = "Jun.");
              case "Tem.":
                return (month = "Jul.");
              case "Ağu.":
                return (month = "Aug.");
              case "Eyl.":
                return (month = "Sep.");
              case "Eki.":
                return (month = "Oct.");
              case "Kas.":
                return (month = "Nov.");
              case "Ara.":
                return (month = "Dec.");
              default:
                return month;
            }
          });
          // console.log(onlyMonths);
        }

        if (
          (
            localStorage.getItem("dil") || String(i18n.language).split("-")[0]
          ).toUpperCase() === "DE"
        ) {
          // English month abbreviations list
          onlyMonths = onlyMonths.map((month) => {
            switch (month) {
              case "Oca.":
                return (month = "Jan.");
              case "Şub.":
                return (month = "Feb.");
              case "Mar.":
                return (month = "März.");
              case "Nis.":
                return (month = "Apr.");
              case "May.":
                return (month = "Mai.");
              case "Haz.":
                return (month = "Juni.");
              case "Tem.":
                return (month = "Juli.");
              case "Ağu.":
                return (month = "Aug.");
              case "Eyl.":
                return (month = "Sept.");
              case "Eki.":
                return (month = "Okt.");
              case "Kas.":
                return (month = "Nov.");
              case "Ara.":
                return (month = "Dez.");
              default:
                return month;
            }
          });
          // console.log(onlyMonths);
        }

        onlyMonths.forEach((date, index) => {
          const normalFatura = Number(
            data["arcelik_ozel_content"]["normal_fatura_yillik_tl"][index]
          );
          const gesFatura = Number(
            data["arcelik_ozel_content"]["ges_fatura_yillik_tl"][index]
          );
          const month = date;

          const obje = {
            month: month,
            normalFatura: normalFatura,
            gesFatura: gesFatura,
            faturaFarki: [normalFatura, gesFatura],
            satis: gesFatura < 0 ? gesFatura : 0,
          };
          mixedGraphDataTemp.push(obje);
        });

        setMixedGraphData(mixedGraphDataTemp);

        setArcelikOzelContent({
          toplamNormalFatura:
            data["arcelik_ozel_content"]["toplam_normal_fatura"],
          sistemeSatis: data["arcelik_ozel_content"]["sisteme_satis"],
          toplamTasarruf: data["arcelik_ozel_content"]["toplam_tasarruf"],
          toplamKazanc: data["arcelik_ozel_content"]["toplam_kazanc"],
        });

        data["aylik_zaman"].slice(0, 12).forEach((date, index) => {
          const veri = data["aylik_normal_fatura"][index];
          const updatedData = veri.replace(".", "");

          const obje = {
            x: date,
            y: Number(updatedData),
          };
          firstGraphDataNormalFaturaTemp.push(obje);

          lineGraphTicksTemp.push(date);
        });

        data["aylik_zaman"].slice(0, 12).forEach((date, index) => {
          const veri = data["aylik_ges_fatura"][index];
          const updatedData = veri.replace(".", "");
          const obje = {
            x: date,
            y: Number(updatedData),
          };
          firstGraphDataGesFaturaTemp.push(obje);
        });

        data["yillik_zaman"].forEach((date, index) => {
          const veri = data["yillik_roi"][index];
          const updatedData = Number(veri.replaceAll(".", ""));
          let obje;
          if (updatedData < 0) {
            obje = {
              year: date,
              "Yatırımınız cebinize dönüyor": Number(updatedData),
              kebabColor: "hsl(4, 61%, 59%)",
            };
          } else {
            obje = {
              year: date,
              "Muhteşem! Artık kara geçiyorsunuz": Number(updatedData),
              kebabColor: "hsl(4, 61%, 60%)",
            };
          }
          graphDataTemp.push(obje);
        });
        setGraphData({
          firstGraphDataNormalFatura: firstGraphDataNormalFaturaTemp,
          firstGraphDataGesFatura: firstGraphDataGesFaturaTemp,
          barGraphData: graphDataTemp,
        });
        setLineGraphTicks(lineGraphTicksTemp);
        setFeasibilityLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setFeasibilityLoading(false);
      });

    if (
      userId &&
      tesis !== "Mesken" &&
      module !== "ibtenerji" && usedFor === "endUser"
    ) {
      let totalArea = 0;
      if (alanTercihi === "cizim" && drawingPointsNew.length > 0) {
        drawingPointsNew.forEach((value) => (totalArea += value.area));
      } else {
        totalArea = roofArea;
      }

      await usedAxiosInstance
        .get(
          `manuel_guc?musteri_id=${userId}&alan=${totalArea}&optimal_dc_guc=${tempOptimalDCPower}&optimal_ac_guc=${tempOptimalACPower}`
        )
        .then((response) => {
          // console.log(response);
          const { data, status } = response.data;
          if (status) {
            setPowerList(data.guc_listesi);
            let tempPowerList = [];
            data.guc_listesi.map((item, index) => {
              tempPowerList.push({
                value: item,
                /* label: item + " kWp" */
                label: "",
              });
            });
            setPowerListMarks(tempPowerList);
          }
        })
      // .catch((error) => console.log(error));
    }
  }

  async function axiosGetUserId() {
    let totalArea = 0;
    if (alanTercihi === "cizim" && drawingPointsNew.length > 0) {
      drawingPointsNew.forEach((value) => (totalArea += value.area));
    } else {
      totalArea = roofArea;
    }

    let staticMapURLString = `https://maps.googleapis.com/maps/api/staticmap?size=400x400&maptype=satellite`;

    if (drawingPointsNew.length > 0 && alanTercihi === "cizim") {
      const maxRealDistance = Math.round(calculateMaxRealDistanceFromLatLng());

      // Decide Static Map zoom level according to total roof area
      let tempZoom = 18;
      if (maxRealDistance <= 10) {
        tempZoom = 21;
      } else if (maxRealDistance > 10 && maxRealDistance <= 40) {
        tempZoom = 20;
      } else if (maxRealDistance > 40 && maxRealDistance <= 80) {
        tempZoom = 19;
      } else if (maxRealDistance > 80 && maxRealDistance <= 160) {
        tempZoom = 18;
      } else if (maxRealDistance > 160 && maxRealDistance <= 320) {
        tempZoom = 17;
      } else if (maxRealDistance > 320) {
        tempZoom = 16;
      } else {
        tempZoom = 18;
      }

      staticMapURLString += `&zoom=${tempZoom}`;

      // HEX codes of colors
      // this.colorList = ["#ad1717", "#FB8B24", "#E36414", "#0F4C5C"];

      const colorNameListofGoogle = [
        "0xAD1717",
        "0xFB8B24",
        "0xE36414",
        "0x0F4C5C",
      ];
      drawingPointsNew.forEach((tempRoofAreaObject, idxx) => {
        let drawPoints = `&path=color:${colorNameListofGoogle[idxx]}|weight:5|fillcolor:${colorNameListofGoogle[idxx]}|`;
        tempRoofAreaObject.points.forEach((point) => {
          drawPoints += point.lat + "," + point.lng + "|";
        });
        drawPoints = drawPoints.slice(0, -1);
        staticMapURLString += drawPoints;
      });
    } else {
      // Decide Static Map zoom level according to total roof area
      let tempZoom = 18;
      if (totalArea <= 75) {
        tempZoom = 21;
      } else if (totalArea > 75 && totalArea <= 400) {
        tempZoom = 20;
      } else if (totalArea > 400 && totalArea <= 1500) {
        tempZoom = 19;
      } else if (totalArea > 1500 && totalArea <= 4000) {
        tempZoom = 18;
      } else if (totalArea > 4000 && totalArea <= 10000) {
        tempZoom = 17;
      } else if (totalArea > 10000) {
        tempZoom = 16;
      } else {
        tempZoom = 18;
      }
      staticMapURLString = `https://maps.googleapis.com/maps/api/staticmap?center=${tempLatitude},${tempLongitude}&zoom=${tempZoom}&size=400x400&maptype=satellite&markers=color:red%7C${tempLatitude},${tempLongitude}`;
    }

    dispatch(changeStaticMapURL({ staticMapURL: staticMapURLString }));

    let sessionCounter;
    if (firstSession === 1) {
      sessionCounter = true;
      dispatch(changeFirstSession({ firstSession: 0 }));
    } else {
      sessionCounter = false;
    }

    let userIdSend;
    let moduleStatusSend;
    if (userId) {
      userIdSend = userId;
      moduleStatusSend = 2;
    } else {
      userIdSend = "00";
      moduleStatusSend = 1;
    }

    if (analysisData.dolar) {
      let saveRequestURL = "musteri/save";
      if (usedFor === "admin") {
        saveRequestURL = "admin/leadgen/musteri_save";
      }
      if (module === "german_solarvis") {
        saveRequestURL = "deutsche/musteri/save";
        if (usedFor === "admin") {
          saveRequestURL = "deutsche/admin/musteri/save";
        }
      }
      await usedAxiosInstance
        .post(saveRequestURL, {
          musteri_id: userIdSend,
          is_session: sessionCounter,
          png: staticMapURLString,
          il: cityName,
          ilce: ilce,
          enlem: tempLatitude.toFixed(4),
          boylam: tempLongitude.toFixed(4),
          fatura_tutari: fatura,
          monthly_consumption: monthlyConsumptionInput,
          tesis_tipi: tesis,
          mesken_tipi: meskenTipi,
          sgucu: sGucu,
          trafoGucu: module === "aksasolar" ? trafoGucu : 0,
          is_battery: Boolean(batarya),
          is_charger: Boolean(sarj),
          language: (
            localStorage.getItem("dil") || String(i18n.language).split("-")[0]
          ).toUpperCase(),
          status: moduleStatusSend,
          drawing_areas:
            alanTercihi === "cizim" && drawingPointsNew.length > 0
              ? drawingPointsNew
              : [
                {
                  area: Number(roofArea),
                  slope: Number(roofTilt),
                  aspect: Number(cephe),
                  points: [],
                },
              ],
          consumption_profile: consumptionProfile,
          input_dc_guc: selectedDCPower ? Number(selectedDCPower) : null,
          monthly_manual_consumptions: monthlyConsumptionProfile,
          daily_consumption_percentages: dailyConsumptionProfile,
        })
        .then((response) => {
          const data = response.data.data;

          if (data["musteri_id"]) {
            dispatch(changeUserId({ userId: data["musteri_id"] }));
          }

          if (data.hasOwnProperty("subcon_project_id")) {
            dispatch(
              changeSubconProjectId({
                subconProjectId: data["subcon_project_id"],
              })
            );
          }

          dispatch(changeUserAPIStatus({ userStatus: moduleStatusSend }));

          let totalArea = 0;
          if (alanTercihi === "cizim" && drawingPointsNew.length > 0) {
            drawingPointsNew.forEach((value) => (totalArea += value.area));
          } else {
            totalArea = roofArea;
          }

          if (
            userIdSend === "00" &&
            tesis !== "Mesken" &&
            module !== "ibtenerji" && usedFor === "endUser"
          ) {
            usedAxiosInstance
              .get(
                `manuel_guc?musteri_id=${data["musteri_id"]}&alan=${totalArea}`
              )
              .then((response) => {
                // console.log(response);
                const { data, status } = response.data;
                if (status) {
                  setPowerList(data.guc_listesi);
                  let tempPowerList = [];
                  data.guc_listesi.map((item, index) => {
                    tempPowerList.push({
                      value: item,
                      /* label: item + " kWp" */
                      label: "",
                    });
                  });
                  setPowerListMarks(tempPowerList);
                }
              })
            // .catch((error) => console.log(error));
          }

          return;
        })
      // .catch((error) => console.log(error));
    }
  }

  async function getReport(id) {
    // Define report type
    let fileURL = "";
    let reportType = "default_tr";
    let reportName = "GES Ön Fizibilite Raporu.pdf";
    if (
      localStorage.getItem("dil") === "en" ||
      String(i18n.language).split("-")[0] === "en" ||
      localStorage.getItem("dil") === "de" ||
      String(i18n.language).split("-")[0] === "de" ||
      localStorage.getItem("dil") === "el" ||
      String(i18n.language).split("-")[0] === "el"
    ) {
      reportType = "default_en";
      reportName = "SPP Pre-Feasibility Report.pdf";
    }
    setLoading(true);
    await usedAxiosInstance
      .get(
        `report/generate?musteri_id=${id}&format=json&is_admin_page=0&report_type=${reportType}` /* , {
        responseType: 'blob'
      } */
      )
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        // Build a URL from the file
        fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        setLoading(false);
        if (isDesktopOrLaptop) {
          download(file, reportName);
        }
      })
      .then(() => {
        window.location.href =
          "https://www.akbank.com/basvuru/cati-ges-fizibilite/";
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        window.location.href =
          "https://www.akbank.com/basvuru/cati-ges-fizibilite/";
        setLoading(false);
      });
  }

  useEffect(() => {
    const dataGet = axiosGet();
  }, [selectedDCPower]);

  const submitHandler = async (event) => {
    event.preventDefault();

    /*
      Akbank'a özel condition !!
      Fizibilite raporu oluşturalım. Rapor oluştururken de raporunuz oluşturuluyor ve
      kredi formuna yönlendiriliyorsunuz yazısı gösteririz.
      Ardından form sayfasına yönlendirelim.
      */
    if (module === "akbank") {
      let saveRequestURL = "musteri/save";
      if (usedFor === "admin") {
        saveRequestURL = "admin/leadgen/musteri_save";
      }
      if (module === "german_solarvis") {
        saveRequestURL = "deutsche/musteri/save";
      }
      await usedAxiosInstance
        .post(saveRequestURL, {
          musteri_id: userId,
          status: 3,
          mail: "",
          language: (
            localStorage.getItem("dil") || String(i18n.language).split("-")[0]
          ).toUpperCase(),
        })
        .then(() => {
          // Send pageview with a custom path
          ReactGA.send({
            hitType: "pageview",
            page: "/kullaniciya_ozel_ekran",
          });
        })
      // .catch((error) => console.log(error));
      getReport(userId);
      return;
    }

    if (firstScreenAtEnd === "contact" && usedFor === "endUser") {
      dispatch(changeUserAPIStatus({ userStatus: 3 }));

      let saveRequestURL = "musteri/save";

      await usedAxiosInstance
        .post(saveRequestURL, {
          musteri_id: userId,
          status: 3,
          mail: mail,
          language: (
            localStorage.getItem("dil") || String(i18n.language).split("-")[0]
          ).toUpperCase(),
        })
        .then((response) => {

          if (response.data.case_code === "saved_different_company") {
            alert(response.data.message)
          }

          const data = response.data.data;
          dispatch(changeUserAPIStatus({ userStatus: 3 }));
          // Send pageview with a custom path
          ReactGA.send({
            hitType: "pageview",
            page: "/kullaniciya_ozel_ekran",
          });

          if (data.hasOwnProperty("subcon_project_id")) {
            dispatch(
              changeSubconProjectId({
                subconProjectId: data["subcon_project_id"],
              })
            );
          }

          window.location.href = "/login";
          //console.log(response.data['musteri_id']);
          return data["musteri_id"];
        })
      // .catch((error) => console.log(error));
    } else {
      handleChangeStep(5);
    }
  };

  const willMount = useRef(true);
  if (willMount.current) {
    dispatch(changeSolarvisBannerState(true));
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/ozet_ekrani" });
    willMount.current = false;
  }

  if (loading) {
    if (
      localStorage.getItem("dil") === "tr" ||
      String(i18n.language).split("-")[0] === "tr"
    ) {
      if (isDesktopOrLaptop) {
        return (
          <>
            <SpinnerDialog text="Ön fizibilite raporunuz oluşturuluyor ve kredi talep formuna yönlendiriliyorsunuz..." />
          </>
        );
      } else {
        return (
          <>
            <SpinnerDialog text="Kredi talep formuna yönlendiriliyorsunuz..." />
          </>
        );
      }
    } else {
      return (
        <>
          <SpinnerDialog text="Your pre-feasibility report is created and you are directed to the loan request form..." />
        </>
      );
    }
  }

  return (
    <>
      {feasibilityLoading && <SunSpinner isDialog={true} />}
      <div
        className={
          module === "istanbulges"
            ? classes.leftWrapperIstanbulGES
            : classes.leftWrapper
        }
      >
        <div className={classes.projectInfoWrapper}>
          <div className={classes.projectInfo}>
            <span className={classes.littleIcon}>
              <div style={{ marginRight: 8 }}>
                <IconContext.Provider value={{ color: "#ffffff", size: 13 }}>
                  <MdLocationOn />
                </IconContext.Provider>
              </div>
              {cityName} - {ilce}
            </span>
            <span className={classes.littleIcon}>
              <div style={{ marginRight: 8 }}>
                <IconContext.Provider value={{ color: "#ffffff", size: 13 }}>
                  <FaSolarPanel />
                </IconContext.Provider>
              </div>
              {analysisData.panel} {t("SummaryPage.gunesPaneli")}
            </span>
            {module !== "german_solarvis" && (
              <span className={classes.littleIcon}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: "#ffffff", size: 13 }}>
                    <FaReceipt />
                  </IconContext.Provider>
                </div>
                {fatura} ₺
              </span>
            )}
            {module === "german_solarvis" && (
              <span className={classes.littleIcon}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: "#ffffff", size: 13 }}>
                    <FaReceipt />
                  </IconContext.Provider>
                </div>
                {monthlyConsumptionInput} kWh
              </span>
            )}
            <span className={classes.littleIcon}>
              <div style={{ marginRight: 8 }}>
                <IconContext.Provider value={{ color: "#ffffff", size: 13 }}>
                  <RiBuilding2Fill />
                </IconContext.Provider>
              </div>
              {t("SummaryPage.tesis")}:{module !== "german_solarvis" && tesis}
              {module === "german_solarvis" && translateTesisGerman()}
            </span>
            <span className={classes.littleIcon}>
              <div style={{ marginRight: 8 }}>
                <IconContext.Provider value={{ color: "#ffffff", size: 13 }}>
                  <RiBattery2Fill />
                </IconContext.Provider>
              </div>
              {t("SummaryPage.batarya")}:{" "}
              {batarya ? t("SummaryPage.secildi") : t("SummaryPage.secilmedi")}
            </span>
            <span className={classes.littleIcon}>
              <div style={{ marginRight: 8 }}>
                <IconContext.Provider value={{ color: "#ffffff", size: 13 }}>
                  <BsLightningFill />
                </IconContext.Provider>
              </div>
              {t("SummaryPage.sarj")}:{" "}
              {sarj ? t("SummaryPage.secildi") : t("SummaryPage.secilmedi")}
            </span>
          </div>
        </div>
        {tesis === "Sanayi" && (
          <Alert
            severity="warning"
            icon={<WbSunnyIcon fontSize="inherit" />}
            className={MUIClasses.myAlert}
          >
            {t("SummaryPage.governmentIncentiveInfo1")}
            <a
              href="https://www.gensed.org/basin/lisanssiz-yatirimlar-icin-dorduncu-bolge-destekleri-yururluge-girdi"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("SummaryPage.governmentIncentiveInfo2")}
            </a>
            {t("SummaryPage.governmentIncentiveInfo3")}
          </Alert>
        )}
        <div className={classes.summaryWrapper}>
          <div className={classes.summary1}>
            <div className={classes.summaryComp2}>
              {module !== "german_solarvis" && (
                <div className={classes.summaryComp1}>
                  <span className={classes.priceValue}>
                    {t("SummaryPage.maliyet")}
                  </span>
                  <span className={classes.outputPrice}>
                    {analysisData.tlMaliyetYaklasik} ₺ /{" "}
                    {analysisData.dolarMaliyetYaklasik} $
                  </span>
                </div>
              )}
              {module === "german_solarvis" && (
                <div className={classes.summaryComp1}>
                  <span className={classes.priceValue}>
                    {t("SummaryPage.maliyet")}
                  </span>
                  <span className={classes.outputPrice}>
                    {analysisData.eurMaliyetYaklasik} €
                  </span>
                </div>
              )}
              {module === "akbank" && (
                <div className={classes.summaryComp1}>
                  <span className={classes.priceValue}>
                    {t("SummaryPage.kredilendirilebilirMaliyet")}
                  </span>
                  <span className={classes.outputPrice}>
                    {analysisData.kredilendirilebilirMaliyetTL} ₺ /{" "}
                    {analysisData.kredilendirilebilirMaliyetUSD} $
                  </span>
                </div>
              )}
              <div className={classes.summaryComp1}>
                <span className={classes.priceValue}>
                  {t("SummaryPage.roi")}
                </span>
                {module === "arcelikkurumsalcozumler" && (
                  <span className={classes.outputPrice}>
                    {analysisData.amortismanYaklasik} {t("SummaryPage.yil")}
                  </span>
                )}
                {module !== "arcelikkurumsalcozumler" && (
                  <span className={classes.outputPrice}>
                    {analysisData.amortismanYilAy[1] === 0
                      ? `${analysisData.amortismanYilAy[0]} ${t(
                        "SummaryPage.yil"
                      )}`
                      : `${analysisData.amortismanYilAy[0]} ${t(
                        "SummaryPage.yil"
                      )} ${analysisData.amortismanYilAy[1]} ${t(
                        "SummaryPage.ay"
                      )}`}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.summary2}>
          <div className={classes.summary2Comp1}>
            {powerListMarks.length > 0 &&
              tesis !== "Mesken" &&
              module !== "ibtenerji" && usedFor === "endUser" && (
                <div
                  className={classes.summaryComp1}
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: 10,
                    flexDirection: "column",
                    marginBottom: 10,
                    paddingBottom: 40,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => {
                        dispatch(
                          changeSelectedDCPower({
                            selectedDCPower: optimalDCPower,
                          })
                        );
                      }}
                      style={
                        selectedDCPower === optimalDCPower
                          ? {
                            textTransform: "none",
                            backgroundColor: "var(--secondary-color-light)",
                            border: "2px solid var(--secondary-color)",
                            width: "40%",
                          }
                          : {
                            textTransform: "none",
                            backgroundColor: "white",
                            width: "40%",
                          }
                      }
                    >
                      {t("SummaryPage.optimalPower")}
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => {
                        dispatch(
                          changeSelectedDCPower({
                            selectedDCPower: powerList[powerList.length - 1],
                          })
                        );
                      }}
                      style={
                        selectedDCPower === powerList[powerList.length - 1]
                          ? {
                            textTransform: "none",
                            backgroundColor: "var(--secondary-color-light)",
                            border: "2px solid var(--secondary-color)",
                            width: "40%",
                          }
                          : {
                            textTransform: "none",
                            backgroundColor: "white",
                            width: "40%",
                          }
                      }
                    >
                      {t("SummaryPage.maximumPower")}
                    </Button>
                  </div>
                  <StyledSlider
                    value={selectedDCPower}
                    onChangeCommitted={handleChangeDragEnd}
                    aria-labelledby="discrete-slider-restrict"
                    step={null}
                    marks={powerListMarks}
                    valueLabelDisplay="on"
                    min={powerListMarks[0].value}
                    max={powerListMarks[powerListMarks.length - 1].value}
                    ValueLabelComponent={ValueLabelComponent}
                    valueLabelFormat={(value) => {
                      return (
                        <div style={{ textAlign: "center" }}>{value} kWp</div>
                      );
                    }}
                  />
                </div>
              )}
            <div className={classes.summaryComp1}>
              <span className={classes.priceValue}>{t("SummaryPage.guc")}</span>
              <span className={classes.outputPrice}>
                {analysisData.guc} kWp / {analysisData.acGuc} kWe
              </span>
            </div>
            <div className={classes.summaryComp1}>
              <span className={classes.priceValue}>
                {t("SummaryPage.uretim")}
              </span>
              <span className={classes.outputPrice}>
                {analysisData.uretim} MWh
              </span>
            </div>
            <div
              className={classes.summaryComp1}
              style={{ paddingBottom: "0.75rem" }}
            >
              <span className={classes.priceValue}>
                {t("SummaryPage.tuketim")}
              </span>
              <span className={classes.outputPrice}>
                {analysisData.yillikTuketim} MWh
              </span>
            </div>
            <div
              className={classes.summaryComp1}
              style={{ paddingTop: "0.75rem", borderTop: "1px solid #fff" }}
            >
              <span className={classes.priceValue}>
                {t("SummaryPage.agac")}
              </span>
              <span className={classes.outputPrice}>
                {analysisData.agac} {t("SummaryPage.adet")}
              </span>
            </div>
            <div className={classes.summaryComp1}>
              <span className={classes.priceValue}>
                {t("SummaryPage.co2")}
                <sub>2</sub>
              </span>
              <span className={classes.outputPrice}>{analysisData.co2} kg</span>
            </div>
            {module === "akbank" && (
              <div
                className={classes.summaryCompSerh}
                style={{ marginTop: "1rem" }}
              >
                <span className={classes.priceSerh}>
                  {t("SummaryPage.kredilendirilebilirMaliyetInfo")}
                </span>
              </div>
            )}
            <div
              style={{ cursor: "pointer", marginTop: 20 }}
              onClick={() => setFeasibilityInfoBoxOpen(!feasibilityInfoBoxOpen)}
            >
              <Alert
                severity="info"
                icon={<InfoIcon fontSize="inherit" />}
                className={MUIClasses.myAlert}
              >
                {t("SummaryPage.howFeasibilityCalculationsInfo")}
              </Alert>
            </div>
            <StyledDialog
              aria-labelledby="customized-dialog-title"
              open={feasibilityInfoBoxOpen}
              onClose={handleCloseFeasbilityInfoBox}
            >
              <DialogContent dividers>
                <ul style={{ marginLeft: "10px" }}>
                  {module !== "isbank" && (
                    <li style={{ margin: "10px 0px" }}>
                      {module === "arcelikkurumsalcozumler"
                        ? t("InvoiceInfoPage.tahminiMetinArcelik")
                        : t("InvoiceInfoPage.tahminiMetinDefault")}
                    </li>
                  )}
                  {module === "isbank" && (
                    <li style={{ margin: "10px 0px" }}>
                      {t("InvoiceInfoPage.tahminiMetinIsbank")}
                    </li>
                  )}
                  <li style={{ margin: "10px 0px" }}>
                    {t("SummaryPage.bilgi")}
                  </li>
                  <li style={{ margin: "10px 0px" }}>
                    {t("SummaryPage.powerCalculationInfo")}
                  </li>
                  <li style={{ margin: "10px 0px" }}>
                    {t("SummaryPage.feasibilityInfoUSD")}
                  </li>
                  <li style={{ margin: "10px 0px" }}>
                    {t("InvoiceInfoPage.bataryaNot")}
                  </li>
                </ul>
              </DialogContent>
            </StyledDialog>
          </div>
        </div>
      </div>
      <div className={classes.rightWrapper}>
        <div className={classes.rightWrapperlv1}>
          <div
            className={classes.rightWrapperlv2}
            style={{ padding: 0, paddingTop: "1rem" }}
          >
            <div className={classes.graphWrapper}>
              <p
                style={{
                  alignSelf: "center",
                  width: "auto",
                  fontWeight: "bold",
                }}
              >
                {t("SummaryPage.grafikTitle")}
              </p>
              <RechartComposedLine graphData={mixedGraphData} module={module} />
              {module !== "german_solarvis" && (
                <ul style={{ marginLeft: "20px", width: "80%", height: '100%' }}>
                  <li style={{ fontSize: "1rem" }}>
                    {t("SummaryPage.currentBillInfo1")}
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {arcelikOzelContent.toplamNormalFatura} ₺
                    </span>
                    {t("SummaryPage.currentBillInfo2")}
                  </li>
                  <li style={{ fontSize: "1rem" }}>
                    {t("SummaryPage.afterSPPBillInfo1")}
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {arcelikOzelContent.toplamTasarruf} ₺
                    </span>
                    {t("SummaryPage.afterSPPBillInfo2")}
                  </li>
                  {!(
                    Number(arcelikOzelContent.sistemeSatis) === 0 ||
                    arcelikOzelContent.sistemeSatis === "0"
                  ) && (
                      <li style={{ fontSize: "1rem" }}>
                        {t("SummaryPage.salesGridInfo1")}
                        <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                          {arcelikOzelContent.sistemeSatis} ₺
                        </span>
                        {t("SummaryPage.salesGridInfo2")}
                      </li>
                    )}
                  <li
                    style={{
                      fontSize: "1.2rem",
                      borderTop: "1px solid black",
                      marginTop: "5px",
                      paddingTop: "5px",
                    }}
                  >
                    {t("SummaryPage.totalProfitInfo1")}
                    <span style={{ fontWeight: "bold", fontSize: "1.4rem" }}>
                      {arcelikOzelContent.toplamKazanc} ₺
                    </span>
                    {t("SummaryPage.totalProfitInfo2")}
                  </li>
                </ul>
              )}
              {module === "german_solarvis" && (
                <ul style={{ marginLeft: "20px", width: "80%" }}>
                  <li style={{ fontSize: "1rem" }}>
                    {t("SummaryPage.currentBillInfo1")}
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {arcelikOzelContent.toplamNormalFatura} €
                    </span>
                    {t("SummaryPage.currentBillInfo2")}
                  </li>
                  <li style={{ fontSize: "1rem" }}>
                    {t("SummaryPage.afterSPPBillInfo1")}
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {arcelikOzelContent.toplamTasarruf} €
                    </span>
                    {t("SummaryPage.afterSPPBillInfo2")}
                  </li>
                  {!(
                    Number(arcelikOzelContent.sistemeSatis) === 0 ||
                    arcelikOzelContent.sistemeSatis === "0"
                  ) && (
                      <li style={{ fontSize: "1rem" }}>
                        {t("SummaryPage.salesGridInfo1")}
                        <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                          {arcelikOzelContent.sistemeSatis} €
                        </span>
                        {t("SummaryPage.salesGridInfo2")}
                      </li>
                    )}
                  <li
                    style={{
                      fontSize: "1.2rem",
                      borderTop: "1px solid black",
                      marginTop: "5px",
                      paddingTop: "5px",
                    }}
                  >
                    {t("SummaryPage.totalProfitInfo1")}
                    <span style={{ fontWeight: "bold", fontSize: "1.4rem" }}>
                      {arcelikOzelContent.toplamKazanc} €
                    </span>
                    {t("SummaryPage.totalProfitInfo2")}
                  </li>
                </ul>
              )}
            </div>
            <div className={classes.formwizard}>
              <div className={classes.formwizardlv1}>
                <div className={classes.buttonContainer}>
                  <button
                    onClick={() => handleChangeStep(3)}
                    className={classes.backButton}
                  >
                    <div className={classes.backButtonIcon}>
                      <IconContext.Provider
                        value={{ color: "#ffffff", size: 16 }}
                      >
                        <AiOutlineArrowRight />
                      </IconContext.Provider>
                    </div>
                    <span className={classes.startText}>{t("Buton.geri")}</span>
                  </button>
                  <button
                    onClick={submitHandler}
                    className={classes.nextButton}
                  >
                    {usedFor === "admin" && (
                      <span className={classes.finishText}>
                        {t("Buton.ileri")}
                      </span>
                    )}
                    {availableModules.includes("home") &&
                      tesis === "Mesken" &&
                      usedFor !== "admin" && (
                        <span className={classes.finishText}>
                          {t("Buton.kesifTalebi")}
                        </span>
                      )}
                    {!(
                      availableModules.includes("home") && tesis === "Mesken"
                    ) &&
                      usedFor !== "admin" && (
                        <span className={classes.finishText}>
                          {t("Buton.ileri")}
                        </span>
                      )}
                    <div className={classes.buttonIcon}>
                      <IconContext.Provider
                        value={{ color: "#ffffff", size: 16 }}
                      >
                        <AiOutlineArrowRight />
                      </IconContext.Provider>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SummaryPage;