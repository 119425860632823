import React from "react";

const KVKKMetni1 = () => {
    return (
        <div>
            <p><strong>TÜRKİYE İŞ BANKASI A.Ş. GÜNEŞ ENERJİ SANTRALİ ÖN FİZİBİLİTE HAZIRLAMA BAŞVURUSU KİŞİSEL VERİLER HAKKINDA AYDINLATMA METNİ</strong></p>
            <br />
            <p>
                TÜRKİYE İŞ BANKASI ANONİM ŞİRKETİ (“Türkiye İş Bankası ”), sahibi olduğu, <a href="https://www.isbank.com.tr/kampanyalar/isteguneskampanyasi" rel="noopener noreferrer">https://www.isbank.com.tr/kampanyalar/isteguneskampanyasi</a> adresli web sayfası üzerinden “Ön Fizibilite Raporunu Oluştur” butonu aracılığıyla <a href="https://gunes.isbank.com.tr" rel="noopener roreferrer">Güneş Enerjisi Santrali Fizibilitesi (isbank.com.tr)</a> alanına yönlendirme yaptığı, hizmet sağlayıcı Electravis Teknoloji Anonim Şirketi (Solarvis) tarafından sunulan Güneş Enerji Santrali Ön Fizibilite Hazırlama Paneli hizmeti üzerinden, tarafınıza ait kişisel verileri toplamakta ve işlemektedir.
            </p>
            <br />
            <p>
                6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") hükümleri gereği, Solarvis Ön Fizibilite Hazırlama Paneli hizmeti kullanımı ile birlikte Türkiye İş Bankası A.Ş.’ye (“Veri Sorumlusu” ve/veya “Banka” ya da “Türkiye İş Bankası”) ilettiğiniz kimliğinizi belirli veya belirlenebilir kılan her türlü bilginiz, Kişisel Veri niteliğindedir. Bankamız nezdindeki Kişisel Verilerin güvenliği Kişisel Verilerin Korunması Kanunu ve Bankacılık Kanunu başta olmak üzere yasal düzenlemeler çerçevesinde sağlanmaktadır.
            </p>
            <br />
            <p>
                Türkiye İş Bankası, Solarvis Ön Fizibilite Hazırlama Paneli kullanımı kapsamında aşağıdaki kişisel verileri toplamaktadır;
            </p>
            <br />
            <ul>
                <li>Başvuran Kişinin Adı ve Soyadı</li>
                <li>Eposta Adresi</li>
                <li>Telefonu Numarası</li>
            </ul>
            <br />
            <p>
                Türkiye İş Bankası, Solarvis Ön Fizibilite Hazırlama Paneli hizmeti ile temin edilen kişisel verileri aşağıdaki yöntemlerle toplamaktadır;
            </p>
            <ul>
                <li>Bankamızca tarafınıza sunulmasına onay verdiğiniz ürün/hizmetlerle ilgili olarak daha önce Şubelerimiz ve dijital kanallarımız üzerinden verdiğiniz bilgiler, temin edilen bilgilerde yer alan kişisel veriler otomatik yöntemlerle toplanmaktadır.</li>
            </ul>
            <br />
            <p>
                Toplanan kişisel veriler fiziksel dosyalarda, Electravis Mühendislik Danışmanlık Elektrik Elektronik İç ve Dış Ticaret Limited Şirketi (Solarvis ) sunucularında ve yedeklerinde tutulmaktadır.
                Türkiye İş Bankası, Solarvis Ön Fizibilite Hazırlama Paneli hizmeti ile temin edilen kişisel verileri aşağıdaki amaçlarla işlemektedir;
            </p>
            <ul>
                <li>İletişim faaliyetlerinin yürütülmesi,</li>
                <li>İş faaliyetlerinin yürütülmesi/denetimi,</li>
                <li>Mal / hizmet satış süreçlerinin yürütülmesi,</li>
                <li>Mal / hizmet satış sonrası destek hizmetlerinin yürütülmesi,</li>
                <li>Müşteri ilişkileri yönetimi süreçlerinin yürütülmesi,</li>
                <li>Müşteri memnuniyetine yönelik aktivitelerin yürütülmesi,</li>
                <li>Reklam / kampanya / promosyon süreçlerinin yürütülmesi,</li>
                <li>Ürün / hizmetlerin pazarlama süreçlerinin yürütülmesi.</li>
            </ul>
            <br />
            <p>
                İşletmenizin bankacılık dışı ihtiyaçları için seçerek başvurusunda bulunduğunuz “Ön Fizibilite Raporunu Oluştur” hizmetlerine erişiminizi temin edebilmek, Bankamız ile müşteri ilişkisi kurmanız halinde size bankacılık hizmetlerini temin edebilmek ve daha iyi hizmet sunabilmek amaçları başta olmak üzere, www.isbank.com.tr/kampanyalar/isteguneskampanyasi web sayfası üzerinden paylaştığınız yahut internet sitesi kullanımınız nedeni ile çerezler*(çerezlere ilişkin detaylı bilgi için https://www.isbank.com.tr/gizlilik-politikamiz sayfasını inceleyebilirsiniz) aracılığı ile toplanan
                bilgileriniz Türkiye İş Bankası tarafından güvenle saklanır ve yasal çerçeve dışındaki 3. kişilere açıklanmaz. Bankamız ile paylaştığınız her türlü veriniz sır niteliğindendir ve ifşa edilmez.
                Türkiye İş Bankası olarak kişisel verilerinizin güvenliğine en üst düzeyde önem vererek, sizlere sunduğumuz tüm ürün ve hizmetlerimizde kişisel verilerinizin güvenliğinin ön planda olduğu bilinciyle faaliyetlerimize devam ettiğimizi, verilerinizin bütünlüğü ve güncelliğinin korunması için en üst düzeyde önlemler aldığımızı ve destek ya da dış hizmet aldığımız ve/veya iş ortaklığı yaptığımız tüm kuruluşların, bilgi güvenliği ve gizlilik standartlarımıza uygun hareket etmelerini sağladığımızı belirtmek isteriz.
                Kişisel verilerinizin bankacılık işlemleriniz için kullanılması, özel hayatınızın gizliliği ve temel hak ve özgürlüklerinizin korunması temel prensibimizdir.
            </p>
            <br />
            <p>
                Başvurusunda bulunduğunuz www.isbank.com.tr/kampanyalar/isteguneskampanyasi web sayfası üzerinden elektronik yollarla gerçekleştirdiğiniz başvuru taleplerinizin alınması esnasında iletilen ve bu yollarla Türkiye İş Bankası tarafından elde edilen tüm kişisel verileriniz:
            </p>
            <br />
            <ul>
                <li>Türkiye İş Bankası’na konuya ilişkin olarak ileteceğiniz, Kullanım Koşulları’na dayalı olarak; talepte bulunduğunuz Güneş Enerji Santrali Ön Fizibilite Hazırlama Raporu uygulama/ürünlerine yönelik tüm hizmetlerinin en iyi şekilde sunulabilmesi ve müşteri memnuniyetinin sağlanabilmesi için genel veya size özel kişiselleştirilmiş kampanya, ürün ve hizmetlere ilişkin teklifler hazırlanması, bu hizmet ve ürünlere yönelik tanıtım, pazarlama, promosyon, reklam ve kampanya faaliyetlerinin yapılması, bunların dönemsel olarak size sunulması amaçları ile,</li>
                <li>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması hukuki sebebine dayalı olarak; başvurusunda bulunduğunuz Güneş Enerji Santrali Ön Fizibilite Hazırlama Raporu uygulama/ürünlerinize ilişkin olarak, işletmenize bu kapsamda ilgili tüm ürün ve hizmetlerin sunulabilmesi, işletmenizin muhasebe ve vergi faaliyetlerinin yürütülmesi için elektronik çözümler sunan sistem entegrasyonlarının sağlanması amaçları ile,</li>
                <li>Türkiye İş Bankası’nın tabi olduğu ilgili mevzuatlardan doğan hukuki yükümlülüklerini yerine getirebilmesi için zorunlu olması ve kanunlarda açıkça öngörülmesi hukuki sebeplerine dayalı olarak; Bankamız ile müşteri ilişkisi kurulması sürecinde kişilere kimlik tespit ve teyit işlemlerinin yapılabilmesi, risk izleme ve düzenleyici-denetleyici otoritelere karşı raporlama yükümlülüğünün yerine getirilmesi, 5411 sayılı Bankacılık Kanunu başta olmak üzere, bankacılık faaliyeti kapsamında Türkiye İş Bankası’nın tabi olduğu tüm mevzuatlar gereği yükümlülüklerin yerine getirilmesi amaçları ile,</li>
                <li>İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Türkiye İş Bankası’nın meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebine dayalı olarak; risk yönetimi faaliyetlerinin yürütülmesi ve sahtecilik faaliyetlerinin önlenmesi amaçları ile,</li>
                <li>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayalı olarak; şikayet yönetimi ile tüm yasal takip ve dava süreçleri başta olmak üzere, adlî ve/veya idari otoriteler nezdindeki süreçlerin yönetilmesi amaçları ile işlenebilecek ve yine yukarıda belirtilen hukuki sebep ve amaçlara dayanılarak Solarvis Ön Fizibilite Hazırlama Paneli kapsamında yer alan hizmet hizmet sağlayıcı firmalar ile paylaşılacaktır.</li>
            </ul>
            <br />
            <p>
                Solarvis Ön Fizibilite Hazırlama Paneli’ne başvurmanız durumunda Bankamızca tutulan verilerinizden başvuran kişinin ve varsa adına başvurduğu firmanın kimlik tespitinin yapılmasını ve başvuran kişi/firma ile iletişime geçilmesini sağlayacak (Ad ve Soyad, Eposta Adresi, Telefonu Numarası,) verileriniz; bahse konu hizmetin size sağlanabilmesi için yalnızca başvurduğunuz hizmet sağlayıcı/sağlayıcılara aktarılacaktır.
            </p>
            <br />
            <p>
                Yine yukarıda belirtilen hukuki sebep ve amaçlara dayanılarak; 5411 sayılı Bankacılık Kanunu ile diğer Kanun ve sair mevzuat hükümlerinin zorunlu kıldığı/izin verdiği kişi, kurum ve/veya kuruluşlara, 5411 sayılı Bankacılık Kanunu 73. maddesinin 4. fıkrasında sayılan finansal kuruluşlar ile 3. kişilere, BDDK, SPK, TCMB, GİB, SGK, MASAK, FİNDEKS, Hazine ve Maliye Bakanlığı, Kredi Kayıt Bürosu, Merkezi Kayıt
                Kuruluşu A.Ş. gibi kişisel verileri alma yetkisi bulunan kamu tüzel, tüzel kişi ve otoritelere, Türkiye İş Bankası’nın ana hissedarına ve iştiraklerimize hukuki zorunluluklar nedeniyle ancak yasal sınırlamalar çerçevesinde aktarılabilecektir.
                Kanun’un 11. Maddesi uyarınca veri sahibi olarak, aşağıda yer alan haklarınız ile ilgili, veri sorumlusu sıfatı ile Türkiye İş Bankası’na başvurma hakkınız bulunmaktadır:
            </p>
            <ul>
                <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
                <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                <li>Kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
                <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                <li>Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                <li>İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
                <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
            </ul>
            <p>
                Yukarıda belirtilen haklarınızı kullanmak için;
            </p>
            <ul>
                <li>Kimlik tespiti yapılarak Şubelerimiz aracılığıyla,</li>
                <li>Kimlik doğrulaması gerçekleştirilerek Dijital Kanallarımız ya da Çağrı Merkezimiz üzerinden,</li>
                <li>“İş Kuleleri 34330 Levent Beşiktaş-İstanbul” adresine noter kanalıyla,</li>
                <li>isbankasi@hs02.kep.tr adresine güvenli elektronik imzalı olarak,</li>
                <li>Bankamız sisteminde kayıtlı bulunan elektronik posta adresinizi kullanarak</li>
            </ul>
            <p>
                taleplerinizi Türkiye İş Bankası A.Ş’ye iletebilirsiniz.
            </p>
            <br />
            <p>
                Başvurularda;
            </p>
            <ul>
                <li>Kişisel Veri Sahibinin/İlgili Kişinin adı, soyadı ve başvuru yazılı ise imzası,</li>
                <li>Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numarası, yabancılar için uyruğu, pasaport numarası veya varsa kimlik numarası,</li>
                <li>Tebligata esas yerleşim yeri veya iş yeri adresi,</li>
                <li>Varsa bildirime esas elektronik posta adresi, telefon ve faks numarası,</li>
                <li>Talep konusu,</li>
            </ul>
            <br />
            <p>
                belirtilmeli, konuya ilişkin bilgi ve belgeler başvuruya eklenmelidir. Başvurular Türkiye İş Bankası tarafından yapılacak olan kimlik doğrulamasını takiben kabul edilecek olup, yasal süreler içerisinde yazılı olarak veya elektronik ortamda cevap verilecektir.
                Yazılı başvurularda, evrakın Türkiye İş Bankası ’na tebliğ edildiği tarih, başvuru tarihidir.
                Diğer yöntemlerle yapılan başvurularda; başvurunun Türkiye İş Bankası ’na ulaştığı tarih, başvuru tarihidir.
                Kullanıcılar ile çözüm sağlayıcılar arasında ortaya çıkabilecek uyuşmazlıklara Türkiye İş Bankası hiçbir şekilde taraf değildir ve Türkiye İş Bankası müşterilerle çözüm sağlayıcılar arasında kurulan ticari ilişkiyle ilgili herhangi bir ticari sorumluluğu bulunmamaktadır.
            </p>
            <br />
            <p>
                Bununla birlikte kullanıcıların panel üzerinden hesapladıkları Ön Fizibilite Raporundan dolayı Türkiye İş Bankası A.Ş.’nin herhangi bir taahhüttü ve sorumluluğu bulunmamaktadır.
            </p>
        </div>
    );
};

export default KVKKMetni1;