import React from "react";
import ReactDOM from "react-dom";
import store from "./store/store";
import { Provider } from "react-redux";
import App from "./App";
import "./i18n";
import TagManager from "react-gtm-module";
import AuthProvider from "./provider/auth-provider";

if (window.location.host === "hesaplama.volthaus.co") {
  const tagManagerArgs = {
    gtmId: "GTM-T6ZFTK2",
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.Suspense fallback="">
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </React.Suspense>,
  document.getElementById("root")
);
