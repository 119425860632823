import React from "react";
import classes from "./RadioButton.module.css";
import { IconContext } from "react-icons/lib";

/* 
props:
- eğim: 0,15,30,45
- aktiflik: aktif, deaktif
bunları da useSelector'la çek.
*/

const RadioButton = (props) => {
  const {
    value,
    text,
    active,
    imgSrc,
    icon,
    changeFunction,
    onClickButtonFunc,
  } = props;

  return (
    <label
      className={active ? classes.rooflabelActive : classes.roofLabel}
      onClick={() => onClickButtonFunc()}
    >
      <div className={classes.extraIcon}>
        {!imgSrc && (
          <IconContext.Provider
            value={{
              color: active
                ? "#ffffff"
                : getComputedStyle(document.documentElement).getPropertyValue(
                    "--primary-color"
                  ),
              size: 10,
            }}
          >
            {icon}
          </IconContext.Provider>
        )}
        {imgSrc && (
          <img
            src={imgSrc}
            alt={text}
            style={
              active
                ? { filter: "brightness(0) invert(1)", width: "10%" }
                : { width: "10%" }
            }
          />
        )}
        <span style={{ marginLeft: 10 }} className={classes.battery}>
          {text}
        </span>
      </div>
      <input
        type="radio"
        id={value}
        name={value}
        value={value}
        checked={active}
        onChange={() => changeFunction(value)}
        className={classes.checkbox}
      />
    </label>
  );
};

export default RadioButton;
