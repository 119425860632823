import { configureStore } from '@reduxjs/toolkit';
import locationReducer from '../../services/products/product/reducer';
import stepReducer from '../../services/products/product/stepReducer';
import userReducer from '../../services/users/userReducer';
import moduleReducer from '../../services/products/product/moduleReducer';
import summaryReducer from '../../services/products/product/summaryReducer';
import companyInfoReducer from '../../services/products/product/companyInfoReducer';
import constantsReducer from '../../services/products/product/constants';
import solarPackageReducer from '../../services/products/product/solarPackageReducer';
import adminReducer from '../../services/users/adminReducer';


// console.log(process.env.NODE_ENV)
export default configureStore({
  reducer: {
      location: locationReducer,
      steps: stepReducer,
      users: userReducer,
      module: moduleReducer,
      summary: summaryReducer,
      companyInfo: companyInfoReducer,
      constants: constantsReducer,
      solarPackage: solarPackageReducer,
      admin: adminReducer
  },
  devTools: process.env.NODE_ENV === "development" ? true : false
})