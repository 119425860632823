import React, { useRef } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import classes from './RoofSlopePage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight } from 'react-icons/ai';
import GoogleMap from '../GoogleMapViewer/Map';
import { useSelector, useDispatch } from 'react-redux';
import {
    InputBase,
    FormControl,
    withStyles,
} from '@material-ui/core';
import { changeStep } from '../../services/products/product/stepReducer';
import { changeEgim, changeCephe } from '../../services/products/product/summaryReducer';
import StepProgress from '../../components/StepProgress';
import { useMediaQuery } from 'react-responsive';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        width: '60%'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: '8px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

function RoofSlopePage(props) {
    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const { roofTilt, cephe, baseType } = useSelector(state => state.summary);
    const { module } = useSelector(state => state.module);
    const {
        tempLatitude,
        tempLongitude,
        currentZoom,
        drawingPointsNew
    } = useSelector(state => state.location);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const roofLabelMinWidth = isTabletOrMobile ? 100 : 120;

    const handleChange = (event) => {
        const newValue = event.target.value;
        const reg = new RegExp('^[0-9]*$');
        if (newValue.length < 3 && reg.test(newValue)) {
            dispatch(changeEgim({ roofTilt: event.target.value }));
        }
        if (newValue == '') {
            dispatch(changeEgim({ roofTilt: '0' }));
        }
    };

    const handleChangeSelect = (event) => {
        dispatch(changeCephe({ cephe: event.target.value }));
    };

    const handleChangeStep = (newValue) => {
        dispatch(changeStep({ stepNumber: newValue }));
    };

    const willMount = useRef(true);
    if (willMount.current) {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/cati_egimi" });
        willMount.current = false;
    }

    return (
        <>
            <div className={classes.leftWrapper}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`https://maps.googleapis.com/maps/api/staticmap?center=${tempLatitude},${tempLongitude}&zoom=${currentZoom - 1}&size=400x400&maptype=satellite&markers=color:red%7C${tempLatitude},${tempLongitude}&key=AIzaSyC8haRE39XSEpuxc8tR7s90FVhD3NxKUzg`} />
                <>
                    <div className={classes.directionTextRegion} style={isTabletOrMobile ? { top: '1rem', width: '100%' } : { top: '7rem' }}>
                        <span className={classes.directionText}>{t('RoofSlopePage.kuzey')}</span>
                    </div>
                    <div className={classes.directionTextRegion} style={isTabletOrMobile ? { top: '35%', width: '100%' } : { bottom: '2rem' }}>
                        <span className={classes.directionText}>{t('RoofSlopePage.guney')}</span>
                    </div>
                    <div className={classes.directionTextRegion} style={isTabletOrMobile ? { top: '20%', width: '100%', height: 'fit-content', textAlign: 'end' } : { top: '55%', height: 'fit-content', textAlign: 'end' }}>
                        <span className={classes.directionText}>{t('RoofSlopePage.dogu')}</span>
                    </div>
                    <div className={classes.directionTextRegion} style={isTabletOrMobile ? { top: '20%', width: '100%', height: 'fit-content', textAlign: 'start' } : { top: '55%', height: 'fit-content', textAlign: 'start' }}>
                        <span className={classes.directionText}>{t('RoofSlopePage.bati')}</span>
                    </div>
                </>
                {/* <GoogleMap /> */}
            </div>
            <div className={classes.rightWrapper}>
                <div className={classes.rightWrapperlv1}>
                    <div className={classes.rightWrapperlv2}>
                        <div className={classes.title}>
                            <h2 className={classes.titleh2}>{baseType === "roof" ? t('RoofSlopePage.egimSec') : t('RoofSlopePage.egimSecArazi')}</h2>
                            <span className={classes.spanText}>{baseType === "roof" ? t('RoofSlopePage.egimSecMetin') : t('RoofSlopePage.egimSecMetinArazi')}</span>
                        </div>
                        <div className={classes.radioWrapper}>
                            <label className={roofTilt === "0" ? classes.rooflabelActive : classes.roofLabel}>
                                {t('RoofSlopePage.duz')}
                                <div className={classes.roofDiv}>
                                    <span className={classes.spanRoofLabel}>0-5&#176;</span>
                                </div>
                                <input
                                    type="radio"
                                    id="roof_slope1"
                                    name="roof_slope"
                                    value="0"
                                    className={classes.radioRoof}
                                    checked={roofTilt === "0"}
                                    onChange={handleChange}
                                ></input>
                            </label>
                            <label className={roofTilt === "5" ? classes.rooflabelActive : classes.roofLabel}>
                                {t('RoofSlopePage.azegim')}
                                <div className={classes.roofDiv}>
                                    <span className={classes.spanRoofLabel}>5-10&#176;</span>
                                </div>
                                <input
                                    type="radio"
                                    id="roof_slope2"
                                    name="roof_slope"
                                    value="5"
                                    className={classes.radioRoof}
                                    checked={roofTilt === "5"}
                                    onChange={handleChange}
                                ></input>
                            </label>
                            <label className={roofTilt === "15" ? classes.rooflabelActive : classes.roofLabel}>
                                {t('RoofSlopePage.egimli')}
                                <div className={classes.roofDiv}>
                                    <span className={classes.spanRoofLabel}>10-20&#176;</span>
                                </div>
                                <input
                                    type="radio"
                                    id="roof_slope3"
                                    name="roof_slope"
                                    value="15"
                                    className={classes.radioRoof}
                                    checked={roofTilt === "15"}
                                    onChange={handleChange}
                                ></input>
                            </label>
                            <label className={roofTilt === "25" ? classes.rooflabelActive : classes.roofLabel}>
                                {t('RoofSlopePage.cokegim')}
                                <div className={classes.roofDiv}>
                                    <span className={classes.spanRoofLabel}>&#62;20&#176;</span>
                                </div>
                                <input
                                    type="radio"
                                    id="roof_slope4"
                                    name="roof_slope"
                                    value="25"
                                    className={classes.radioRoof}
                                    checked={roofTilt === "25"}
                                    onChange={handleChange}
                                ></input>
                            </label>
                            <div>
                                <span>{baseType === "roof" ? t('RoofSlopePage.knownSlope') : t('RoofSlopePage.knownSlopeArazi')}</span>
                                <BootstrapInput
                                    name="roofTilt"
                                    type="number"
                                    value={roofTilt}
                                    style={{ width: '80px', marginLeft: '10px' }}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 2 }}
                                />
                            </div>
                        </div>
                        {Boolean(Number(roofTilt)) &&
                            <div className={classes.title} style={{ marginTop: '2rem' }}>
                                <span className={classes.spanText}>{baseType === "roof" ? t('RoofSlopePage.yonSec') : t('RoofSlopePage.yonSecArazi')}</span>
                            </div>}
                        {Boolean(Number(roofTilt)) &&
                            <div className={classes.radioWrapper}>
                                <div className={classes.radioDivider}>
                                    <label className={cephe === "-45" ? classes.rooflabelActive : classes.roofLabel} style={{ minWidth: roofLabelMinWidth, width: 'fit-content', justifyContent: 'center' }}>
                                        {t('RoofSlopePage.kuzeybati')}
                                        <input
                                            type="radio"
                                            id="cephe1"
                                            name="cephe"
                                            value="-45"
                                            className={classes.radioRoof}
                                            checked={cephe === "-45"}
                                            onChange={handleChangeSelect}
                                        ></input>
                                    </label>
                                    <label className={cephe === "-180" ? classes.rooflabelActive : classes.roofLabel} style={{ minWidth: roofLabelMinWidth, width: 'fit-content', margin: isTabletOrMobile ? '0px 10px' : '0px 30px', justifyContent: 'center' }}>
                                        {t('RoofSlopePage.kuzey')}
                                        <input
                                            type="radio"
                                            id="cephe1"
                                            name="cephe"
                                            value="-180"
                                            className={classes.radioRoof}
                                            checked={cephe === "-180"}
                                            onChange={handleChangeSelect}
                                        ></input>
                                    </label>
                                    <label className={cephe === "135" ? classes.rooflabelActive : classes.roofLabel} style={{ minWidth: roofLabelMinWidth, width: 'fit-content', justifyContent: 'center' }}>
                                        {t('RoofSlopePage.kuzeydogu')}
                                        <input
                                            type="radio"
                                            id="cephe1"
                                            name="cephe"
                                            value="135"
                                            className={classes.radioRoof}
                                            checked={cephe === "135"}
                                            onChange={handleChangeSelect}
                                        ></input>
                                    </label>
                                </div>
                                <div className={classes.radioDivider} style={{ alignItems: 'center' }}>
                                    <label className={cephe === "90" ? classes.rooflabelActive : classes.roofLabel} style={{ minWidth: roofLabelMinWidth, width: 'fit-content', justifyContent: 'center' }}>
                                        {t('RoofSlopePage.bati')}
                                        <input
                                            type="radio"
                                            id="cephe1"
                                            name="cephe"
                                            value="90"
                                            className={classes.radioRoof}
                                            checked={cephe === "90"}
                                            onChange={handleChangeSelect}
                                        ></input>
                                    </label>
                                    <div style={{ margin: isTabletOrMobile ? '0px 10px' : '0px 30px' }}>
                                        <img
                                            style={isTabletOrMobile ? { width: 100, height: 100, objectFit: 'cover' } : { width: 120, height: 120, objectFit: 'cover' }}
                                            src={require('../../assets/resources/pusula.png')}
                                        />
                                    </div>
                                    <label className={cephe === "-90" ? classes.rooflabelActive : classes.roofLabel} style={{ minWidth: roofLabelMinWidth, width: 'fit-content', justifyContent: 'center' }}>
                                        {t('RoofSlopePage.dogu')}
                                        <input
                                            type="radio"
                                            id="cephe1"
                                            name="cephe"
                                            value="-90"
                                            className={classes.radioRoof}
                                            checked={cephe === "-90"}
                                            onChange={handleChangeSelect}
                                        ></input>
                                    </label>
                                </div>
                                <div className={classes.radioDivider}>
                                    <label className={cephe === "45" ? classes.rooflabelActive : classes.roofLabel} style={{ minWidth: roofLabelMinWidth, width: 'fit-content', justifyContent: 'center' }}>
                                        {t('RoofSlopePage.guneybati')}
                                        <input
                                            type="radio"
                                            id="cephe1"
                                            name="cephe"
                                            value="45"
                                            className={classes.radioRoof}
                                            checked={cephe === "45"}
                                            onChange={handleChangeSelect}
                                        ></input>
                                    </label>
                                    <label className={cephe === "0" ? classes.rooflabelActive : classes.roofLabel} style={{ minWidth: roofLabelMinWidth, width: 'fit-content', margin: isTabletOrMobile ? '0px 10px' : '0px 30px', justifyContent: 'center' }}>
                                        {t('RoofSlopePage.guney')}
                                        <input
                                            type="radio"
                                            id="cephe1"
                                            name="cephe"
                                            value="0"
                                            className={classes.radioRoof}
                                            checked={cephe === "0"}
                                            onChange={handleChangeSelect}
                                        ></input>
                                    </label>
                                    <label className={cephe === "-135" ? classes.rooflabelActive : classes.roofLabel} style={{ minWidth: roofLabelMinWidth, width: 'fit-content', justifyContent: 'center' }}>
                                        {t('RoofSlopePage.guneydogu')}
                                        <input
                                            type="radio"
                                            id="cephe1"
                                            name="cephe"
                                            value="-135"
                                            className={classes.radioRoof}
                                            checked={cephe === "-135"}
                                            onChange={handleChangeSelect}
                                        ></input>
                                    </label>
                                </div>
                            </div>}
                    </div>
                    <div className={classes.formwizard}>
                        {isTabletOrMobile &&
                            <div style={{
                                padding: '0.5rem 50px',
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr auto',
                                alignItems: 'center',
                                gridGap: '1rem',
                            }}>
                                <img src="/images/sun.png" style={{ width: '20px' }} />
                                <StepProgress />
                                <img src="/images/solar-panel-home.png" style={{ width: '20px' }} />
                            </div>}
                        <div className={classes.formwizardlv1}>
                            <div className={classes.buttonContainer}>
                                <button onClick={() => handleChangeStep(1)} className={classes.backButton}>
                                    <div className={classes.backButtonIcon}>
                                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                            <AiOutlineArrowRight />
                                        </IconContext.Provider>
                                    </div>
                                    <span className={classes.startText}>{t('Buton.geri')}</span>
                                </button>
                                <button onClick={() => handleChangeStep(3)} className={classes.nextButton}>
                                    <span className={classes.startText}>{t('Buton.ileri')}</span>
                                    <div className={classes.buttonIcon}>
                                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                            <AiOutlineArrowRight />
                                        </IconContext.Provider>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RoofSlopePage;