/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
import axios from 'axios';
import i18next from 'i18next';
import demoCred from '../../constants/demo-user-constants';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const sessionExpired = (isAdmin) => {
  if (localStorage.getItem('dil') == "tr" || String(i18next.language) == "tr-TR") {
    alert('Oturumunuz sona erdi. Lütfen tekrar giriş yapın.');
  } else {
    alert('Your session has expired. Please login again.');
  }

  localStorage.clear();
  localStorage.setItem("cacheCleaned", "yes");
  if (isAdmin) {
    window.location.href = '/admin/login';
  } else {
    window.location.href = '/login';
  }
};

const sendTokenRequestWithDemoAccount = (originalRequest, error) => {
  let accountInfo = {
    username: null,
    password: null
  }
  accountInfo = demoCred[localStorage.getItem('modül')];

  let domainSend;
  if (localStorage.getItem('modül') === "solarvis" && !(window.location.host === "calculator.solarvis.co" || window.location.host === "calculator-test.solarvis.co")) {
    domainSend = 'demotest.solarvis.co';
  }
  else if (window.location.host === "localhost:3002") {
    domainSend = 'isb-test.solarvis.co';
  }
  else if (window.location.host === "localhost:3001") {
    domainSend = 'arc-test.solarvis.co';
  }
  else {
    domainSend = window.location.host
  }

  return axiosInstance
    .post(`/token/?domain=${domainSend}`, { ...accountInfo })
    .then((response) => {
      localStorage.setItem('access', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
      originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
      return axiosInstance.get(localStorage.getItem('tempRequest'));
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 40000,
  headers: {
    // Authorization: localStorage.getItem('access') ? `Bearer ${localStorage.getItem('access')}` : null,
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access');

    axiosInstance.defaults.headers.common['Authorization'] = accessToken ? `Bearer ${accessToken}` : '';
    config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : '';

    if (config.url.includes('get_cust_file') || config.url.includes('generate')) {
      config.responseType = 'blob';
    }

    return config;
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refresh');
    if (originalRequest.url !== '/token/refresh/') {
      localStorage.setItem('tempRequest', originalRequest.url);
    }

    // Prevent infinite loops
    if ((error.response && error.response.status === 401) && originalRequest.url === `/token/refresh/`) {
      const tokenParts = parseJwt(refreshToken);
      if (!tokenParts.is_demo) {
        sessionExpired(tokenParts.is_admin);
        return
      } else {
        return sendTokenRequestWithDemoAccount(originalRequest, error);
      }

    }

    if (error.response.status == 429) {
      if (localStorage.getItem('dil') == "tr" || String(i18next.language) === "tr-TR") {
        alert(error.response.data.message);
      } else {
        alert("You have sent too many requests. Please try again in 1 minute.");
      }
      return Promise.reject(error.response);
    }

    if (error.response.status === 401 && originalRequest.url !== '/token/refresh/') {

      if (refreshToken) {
        const tokenParts = parseJwt(refreshToken);

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access', response.data.access);
              axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
              originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              // console.log(err);
            });
        } else {
          if (!tokenParts.is_demo) {
            sessionExpired(tokenParts.is_admin);
            return
          } else {
            return sendTokenRequestWithDemoAccount(originalRequest, error);
          }
        }
      } else {
        sessionExpired(false);
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error.response);
  }
);

export default axiosInstance;
