import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  makeStyles,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Container,
  Table,
  TableCell,
  TableRow,
  TableBody,
  withStyles
} from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none',
    fontSize: '1rem',
    width: '50%'
  },
}))(TableCell);

export default function SimpleAccordion(props) {
  const {
    userInfo,
    imagePreview,
    expandUserInfo,
    setExpandUserInfo,
    isAccordion
  } = props;
  const classes = useStyles();

  const { t, } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

  const {
    module,
  } = useSelector(state => state.module);

  const convertCephe = (cepheRakam) => {
    const cepheRakamStr = String(cepheRakam);
    if (cepheRakamStr === "0") {
      return t('UserInfoAccordion.south')
    } else if (cepheRakamStr === "-180") {
      return t('UserInfoAccordion.north')
    } else if (cepheRakamStr === "-90") {
      return t('UserInfoAccordion.east')
    } else if (cepheRakamStr === "90") {
      return t('UserInfoAccordion.west')
    } else {
      return t('UserInfoAccordion.south')
    }
  }

  const commonPart = (
    <Container maxWidth={false}>
      <Grid
        container
        style={{ alignItems: 'center' }}
      >
        <Grid
          item
          lg={5}
          md={12}
          xl={5}
          xs={12}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={imagePreview} alt={userInfo.id} style={{ height: '200px', width: '200px' }} />
          </div>
        </Grid>
        <Grid
          item
          lg={7}
          md={12}
          xl={7}
          xs={12}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Table style={{ marginTop: 10 }}>
              <TableBody>
                <TableRow
                  key="location"
                >
                  <StyledTableCell>
                    <strong>{t('UserInfoAccordion.location')}</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    {userInfo.il} - {userInfo.ilce}
                  </StyledTableCell>
                </TableRow>
                {module === "german_solarvis" &&
                <TableRow
                  key="fatura"
                >
                  <StyledTableCell>
                    <strong>{t('InvoiceInfoPage.monthlyAvarageConsumption')}</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    {userInfo.aylik_tuketim} kWh
                  </StyledTableCell>
                </TableRow>}
                {module !== "german_solarvis" &&
                <TableRow
                  key="fatura"
                >
                  <StyledTableCell>
                    <strong>{t('UserInfoAccordion.monthlyBill')}</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    {userInfo.fatura_tutari} TL
                  </StyledTableCell>
                </TableRow>}
                {module !== "german_solarvis" &&
                <TableRow
                  key="sgucu"
                >
                  <StyledTableCell>
                    <strong>{t('UserInfoAccordion.contractPower')}</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    {userInfo.sgucu} kW
                  </StyledTableCell>
                </TableRow>}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <div className={classes.root}>
      {isAccordion
      &&
      <Accordion expanded={expandUserInfo} onChange={() => setExpandUserInfo(!expandUserInfo)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h2 style={{ fontSize: isTabletOrMobile ? '1.2rem' : '1.4rem', fontWeight: 'normal', marginBottom: 10 }}>{t('UserInfoAccordion.yourInfo')}</h2>
        </AccordionSummary>
        <AccordionDetails>
          {commonPart}
        </AccordionDetails>
      </Accordion>
      }
      {!isAccordion
      &&
      (
        <div style={{
          backgroundColor: '#ffffff',
          padding: '40px',
          borderRadius: '10px'
        }}
        >
          <h2 style={{ fontSize: isTabletOrMobile ? '1.2rem' : '1.4rem', fontWeight: 'normal', marginBottom: 10 }}>{t('UserInfoAccordion.yourInfo')}</h2>
          {commonPart}
        </div>
      )}
    </div>
  );
}
