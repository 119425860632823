import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classes from './PaymentPage.module.css';
import NavbarMain from '../../../../containers/NavbarMainPage/NavbarMain';
import Footer from '../../../../containers/AlternativeFooter/Footer';
import Spinner from '../../../../components/UI/Spinner/Spinner'; 
import axios from 'axios';

import { MdReport } from 'react-icons/md';
import { IconContext } from 'react-icons/lib';

import {
  withStyles,
  Button
} from '@material-ui/core';

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'var(--secondary-color)',
    color: 'white',
    marginTop: 20
  }
})(Button);

const PaymentResponse = (props) => {
  const { success } = props;
  const navigate = useNavigate();

  const { t, } = useTranslation();

  useEffect(() => {
    if (success) {
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/kredi_kullandirimi_yonlendirme_basarili" });
    } else {
      ReactGA.send({ hitType: "pageview", page: "/kredi_kullandirimi_yonlendirme_basarisiz" });
    }
    let URLString = window.location.href;
    let url = new URL(URLString);

    if (success) {
      let transactionId = url.searchParams.get("transactionId");

      axios.get(`https://${process.env.REACT_APP_PECA_ENDPOINT}/transaction/inquiry/${transactionId}`)
        .then((response) => {
          if (response.data.status === 'DONE') {
            navigate('/login/successful-payment', { replace: true });
          } else {
            navigate('/login/failed-payment', { replace: true });
          }
        })
        .catch((error) => {
          // console.log(error);
          navigate('/login/failed-payment', { replace: true });
        });
    } else {
      navigate('/login/failed-payment', { replace: true });
    }
  }, []);

  return (
    <div className={classes.outerWrapper}>
      <NavbarMain />
      <div 
        style={{
          height: '80vh', 
          width:'100%', 
          backgroundColor: 'whitesmoke',
          display:'flex',
          flexDirection: 'column',
          textAlign:'center',
          placeContent: 'center',
          placeItems: 'center'
        }}>
        {!success &&
        <>
          <div className={classes.circleDiv}>
            <IconContext.Provider value={{ color: '#228f26', size: 150 }}>
              <MdReport />
            </IconContext.Provider>
          </div>
          <div style={{ maxWidth: '600px' }}>
            <p style={{fontSize: '20px'}}>Güneş Paketi için kredi başvurunuz başarısız olmuştur.</p>
            <div>
              <Link
                to="/login"
                style={{ textDecoration: 'none' }}
              >
                <StyledButton
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t('PaymentResponse.backToHome')}
                </StyledButton>
              </Link>
            </div>
          </div>
        </>
        }
        {success && <Spinner text="Kredi kullandırım işleminiz devam ediyor.."/>}
      </div>
      <Footer />
    </div>
  );
}

export default PaymentResponse;