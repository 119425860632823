import React, { useRef } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import classes from './AddressPage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import SearchBox from '../GoogleMapViewer/UsePlacesAutoCompleteTest';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import i18n from '../../i18n';
import axios from 'axios';
import {
    changeIlce,
    changeCityName,
    changePlaka
} from '../../services/products/product/reducer';
import UseMyLocation from '../GoogleMapViewer/UseMyLocation';

function AddressPage(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const { stepNumber } = useSelector(state => state.steps);
    const { module } = useSelector(state => state.module);
    const { validAdress, detailedAdress, latitude, longitude, cityName, ilce } = useSelector(state => state.location);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isTabletLandscape = useMediaQuery({ minWidth: 600, maxWidth: 1100, orientation: 'landscape' });

    const handleChange = (newValue) => {
        dispatch(changeStep({ stepNumber: newValue }));
    };

    const getCityDistrict = () => {
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyC8haRE39XSEpuxc8tR7s90FVhD3NxKUzg`)
            .then((response) => {
                let countryCode = "TR";
                response.data.results[0].address_components.forEach(addressObject => {
                    // Decide component names accorfing to the country
                    // Türkiye olduğu durum
                    if (addressObject.types[0] === "country" && addressObject.short_name !== "TR") {
                        countryCode = addressObject.short_name;
                    }
                });
                response.data.results[0].address_components.forEach(addressObject => {
                    // Decide component names accorfing to the country
                    // Türkiye olduğu durum
                    if (countryCode === "TR") {
                        if (addressObject.types[0] === "postal_code") {
                            dispatch(changePlaka({ plaka: addressObject.short_name.slice(0, 2) }));
                        } else if (addressObject.types[0] === "administrative_area_level_2") {
                            if (String(addressObject.short_name) === "null" || String(addressObject.short_name) === "undefined") {
                                dispatch(changeIlce({ ilce: 'Merkez' }));
                            } else {
                                dispatch(changeIlce({ ilce: String(addressObject.short_name) }));
                            }
                        } else if (addressObject.types[0] === "administrative_area_level_1") {
                            dispatch(changeCityName({ cityName: String(addressObject.short_name) }));
                        }
                    } else {
                        // Türkiye harici ülke olduğu durum
                        // Şehir ismi
                        if (addressObject.types[0] === "locality") {
                            dispatch(changeCityName({ cityName: String(addressObject.short_name) }));
                        } else {
                            if (addressObject.types[0] === "administrative_area_level_2") {
                                dispatch(changeIlce({ ilce: String(addressObject.short_name) }));
                            } else {
                                dispatch(changeIlce({ ilce: '-' }));
                            }
                        }
                    }
                });
            })
        // .catch((error) => console.log(error));
    };

    const willMount = useRef(true);
    if (willMount.current) {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/adres_secimi" });

        if ((latitude !== 38 && longitude !== 35) && (String(cityName) === "null" || String(ilce) === "null")) {
            getCityDistrict();
        };

        willMount.current = false;
    }


    const generateImage = () => {
        // İngilizce seçilmişse
        if (localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en') {
            // Arçelik modülüyse
            if (module === 'arcelikkurumsalcozumler') {
                return (
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/ev-gorseli.jpeg" alt="solar savings panels" />
                );
            } else if (module === 'isbank') {
                return (
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/isbank/isb-home-image.jpeg" alt="solar savings panels" />
                );
            } else if (module === 'aydemplus') {
                if (isTabletLandscape) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_giris_desktop.png" alt="solar savings panels" />
                    );
                } else if (isTabletOrMobile) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_giris_mobile.png" alt="solar savings panels" />
                    );
                } else {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_giris_desktop.png" alt="solar savings panels" />
                    );
                }
            } else if (module === 'aksasolar') {
                if (isTabletLandscape) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aksa/AKSA_giris_desktop.png" alt="solar savings panels" />
                    );
                } else if (isTabletOrMobile) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aksa/AKSA_giris_mobile.png" alt="solar savings panels" />
                    );
                } else {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aksa/AKSA_giris_desktop.png" alt="solar savings panels" />
                    );
                }
            } else {
                if (isTabletOrMobile) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/gunes-enerjisi-tasarruf-mobil-en.png" alt="solar savings panels" />
                    );
                } else {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/gunes-enerjisi-tasarruf-2-en.png" alt="solar savings panels" />
                    );
                }
            }
        } else if (['el', 'ro', 'de'].includes(localStorage.getItem('dil')) || ['el', 'ro', 'de'].includes(String(i18n.language).split('-')[0])) { // Yunanca veya Romence seçilmişse
            if (isTabletLandscape) {
                return (
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/solar-energy-savings-desktop-standart.png" alt="solar savings panels" />
                );
            } else if (isTabletOrMobile) {
                return (
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/solar-energy-savings-mobile-standart.png" alt="solar savings panels" />
                );
            } else {
                return (
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/solar-energy-savings-desktop-standart.png" alt="solar savings panels" />
                );
            }
        } else { // Türkçe seçilmişse
            if (module === 'arcelikkurumsalcozumler') {
                return (
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/ev-gorseli.jpeg" alt="Güneş panelleri ile tasarruf" />
                );
            } else if (module === 'isbank') {
                return (
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/isbank/isb-home-image.jpeg" alt="Güneş panelleri ile tasarruf" />
                );
            } else if (module === 'aydemplus') {
                if (isTabletLandscape) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_giris_desktop.png" alt="Güneş panelleri ile tasarruf" />
                    );
                } else if (isTabletOrMobile) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_giris_mobile.png" alt="Güneş panelleri ile tasarruf" />
                    );
                } else {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_giris_desktop.png" alt="Güneş panelleri ile tasarruf" />
                    );
                }
            } else if (module === 'aksasolar') {
                if (isTabletLandscape) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aksa/AKSA_giris_desktop.png" alt="solar savings panels" />
                    );
                } else if (isTabletOrMobile) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aksa/AKSA_giris_mobile.png" alt="solar savings panels" />
                    );
                } else {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aksa/AKSA_giris_desktop.png" alt="solar savings panels" />
                    );
                }
            } else {
                if (isTabletLandscape) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/gunes-enerjisi-tasarruf-2.png" alt="Güneş panelleri ile tasarruf" />
                    );
                } else if (isTabletOrMobile) {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/gunes-enerjisi-tasarruf-mobil.png" alt="Güneş panelleri ile tasarruf" />
                    );
                } else {
                    return (
                        <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/gunes-enerjisi-tasarruf-2.png" alt="Güneş panelleri ile tasarruf" />
                    );
                }
            }
        }
    }

    return (
        <>
            <div className={classes.leftWrapper}>
                {generateImage()}
            </div>
            <div className={classes.rightWrapper}>
                <div className={classes.rightWrapperlv1}>
                    <div className={classes.rightWrapperlv2}>
                        <div className={classes.title}>
                            <h2 className={classes.titleh2}>{t('AdressPage.adresSec')}</h2>
                            {module === "isbank" && <span className={classes.adresGir}>{t('AdressPage.adresMetinTicari')}</span>}
                            {module !== "isbank" && <span className={classes.adresGir}>{t('AdressPage.adresMetin')}</span>}
                        </div>
                        <div className={classes.inputAdressWrapper}>
                            <div>
                                <SearchBox />
                            </div>
                            <button disabled={!(validAdress || Boolean(detailedAdress))} onClick={() => handleChange(1)} className={(validAdress || Boolean(detailedAdress)) ? classes.nextButtonActive : classes.nextButtonDeactive}>
                                <span className={classes.startText}>{t('AdressPage.basla')}</span>
                                <div className={classes.buttonIcon}>
                                    <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                    </IconContext.Provider>
                                </div>
                            </button>
                        </div>
                        <span className={classes.adresGir} style={{ margin: '1.125rem 0', marginLeft: '0.5rem' }}>{t('AdressPage.veya')}</span>
                        <UseMyLocation />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddressPage;