import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  latitude: Number(localStorage.getItem('enlem')) || 38,
  longitude: Number(localStorage.getItem('boylam')) || 35,
  zoomLevel: 19,
  markerOn: JSON.parse(localStorage.getItem('markerOn')) || false,
  roofArea: JSON.parse(localStorage.getItem('roofArea')) || null,
  panelAmount: localStorage.getItem('panelAmount') || 0,
  mapStep: 1,
  tempLatitude: JSON.parse(localStorage.getItem('tempLatitude')) || 38, //marker center sorununu çözmek için tanımladım.
  tempLongitude: JSON.parse(localStorage.getItem('tempLongitude')) || 38,
  infoWindow: false,
  staticMapURL: localStorage.getItem('staticMapURL') || null,
  drawingPointsNew: JSON.parse(localStorage.getItem('drawingPointsNew')) || [],
  cityName: localStorage.getItem('sehirIsmi') || null,
  plaka: localStorage.getItem('plaka') || null,
  ilce: localStorage.getItem('ilce') || null,
  validAdress: false,
  detailedAdress: localStorage.getItem('detailedAddress') || '',
  alanTercihi: localStorage.getItem('alanTercihi') || 'cizim',
  tabButonlarAktif: false,
  currentZoom: JSON.parse(localStorage.getItem('currentZoom')) || 19,
  tempMapZoom: JSON.parse(localStorage.getItem('tempMapZoom')) || 19,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    changeLocation: (state, action) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
      localStorage.setItem('enlem', action.payload.latitude);
      localStorage.setItem('boylam', action.payload.longitude);
    },
    changeMapStep: (state, action) => {
      state.mapStep = action.payload.mapStep;
    },
    changeTempLocation: (state, action) => {
      state.tempLatitude = action.payload.tempLatitude;
      state.tempLongitude = action.payload.tempLongitude;
      localStorage.setItem('tempLatitude', JSON.stringify(action.payload.tempLatitude));
      localStorage.setItem('tempLongitude', JSON.stringify(action.payload.tempLongitude));
    },
    changeZoom: (state, action) => {
      state.zoomLevel = action.payload.zoomLevel;
    },
    changeArea: (state, action) => {
      state.roofArea = action.payload.roofArea;
      localStorage.setItem('roofArea', JSON.stringify(action.payload.roofArea));
    },
    changeMarker: (state, action) => {
      state.markerOn = action.payload.markerOn;
      localStorage.setItem('markerOn', JSON.stringify(action.payload.markerOn));
    },
    changeInfoWindow: (state, action) => {
      state.infoWindow = action.payload.infoWindow;
    },
    changeStaticMapURL: (state, action) => {
      state.staticMapURL = action.payload.staticMapURL;
      localStorage.setItem('staticMapURL', action.payload.staticMapURL);
    },
    changeCityName: (state, action) => {
      state.cityName = action.payload.cityName;
      localStorage.setItem('sehirIsmi', action.payload.cityName);
    },
    changePlaka: (state, action) => {
      state.plaka = action.payload.plaka;
      localStorage.setItem('plaka', action.payload.plaka);
    },
    changeIlce: (state, action) => {
      state.ilce = action.payload.ilce;
      localStorage.setItem('ilce', action.payload.ilce);
    },
    changeAdressValidity: (state, action) => {
      state.validAdress = action.payload.validAdress;
    },
    changeDetailedAdress: (state, action) => {
      state.detailedAdress = action.payload.detailedAdress;
      localStorage.setItem('detailedAddress', action.payload.detailedAdress);
    },
    changePanelAmount: (state, action) => {
      state.panelAmount = action.payload.panelAmount;
      localStorage.setItem('panelAmount', action.payload.panelAmount)
    },
    changeAlanTercihi: (state, action) => {
      state.alanTercihi = action.payload.alanTercihi;
      localStorage.setItem('alanTercihi', action.payload.alanTercihi)
    },
    changeTabButonlarAktif: (state, action) => {
      state.tabButonlarAktif = action.payload.tabButonlarAktif;
    },
    changeCurrentZoom: (state, action) => {
      state.currentZoom = action.payload.currentZoom;
      localStorage.setItem('currentZoom', JSON.stringify(action.payload.currentZoom));
    },
    changeTempMapZoom: (state, action) => {
      state.tempMapZoom = action.payload.tempMapZoom;
      localStorage.setItem('tempMapZoom', JSON.stringify(action.payload.tempMapZoom));
    },
    changeDrawingPointsNew: (state, action) => {
      state.drawingPointsNew = action.payload.drawingPointsNew;
      localStorage.setItem('drawingPointsNew', JSON.stringify(action.payload.drawingPointsNew));
    },
    resetState: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const {
  changeLocation,
  changeMapStep,
  resetState,
  changeTempLocation,
  changeZoom,
  changeArea,
  changeMarker,
  changeInfoWindow,
  changeStaticMapURL,
  changeCityName,
  changePlaka,
  changeIlce,
  changeAdressValidity,
  changeDetailedAdress,
  changePanelAmount,
  changeAlanTercihi,
  changeTabButonlarAktif,
  changeCurrentZoom,
  changeTempMapZoom,
  changeDrawingPointsNew
} = locationSlice.actions

export default locationSlice.reducer