import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import i18n from "../../i18n";

function ReCaptcha({ captchaRef }) {
  const [lang, setLang] = useState(String(i18n.language).split("-")[0]);
  const [reload,setReload] = useState(false)
  window.recaptchaOptions = {
    useRecaptchaNet: true,
  };
  useEffect(() => {
    setReload(true);
    setLang(String(i18n.language).split("-")[0]);
    setTimeout(() => {
      setReload(false);
    }, 500);
  }, [i18n.language]);

  return (
    <>
      {
        !reload && <ReCAPTCHA
        onloadCallback={() => {}}
        hl={lang}
        ref={captchaRef}
        sitekey="6LfoasImAAAAAO5H1KWu9jOCIOuvW95BGXnYQvSJ"
      />
      }
    </>
  );
}

export default ReCaptcha;
