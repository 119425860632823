import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../components/axios/axiosApi";
import { Navigate, useNavigate } from "react-router-dom";
import classes from "./QuoteSent.module.css";
import { Button, withStyles, Snackbar } from "@material-ui/core";
import ReactCodeInput from "react-code-input";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
// import MailingDialog from '../../components/Dialog/MailingDialog';
import SMSDialog from "../../components/Dialog/SMSDialog";
import { changeStep } from "../../services/products/product/stepReducer";
import { changeLoggedIn } from "../../services/users/userReducer";
import {
  changeAdminUserId,
  changeIsAdminState,
  changeAdminPassword,
  changeAdminMail,
  changeAdminLoggedIn,
  changeAdminName,
  changeAdminSurname,
  resetAdminInfo,
} from "../../services/users/adminReducer";
import { loginReducer } from "../../utils/func/AuthLogin";
import { numberVerificationSend } from "../../utils/func/NumberVerificationSend";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export default function QuoteSent(props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const { firstScreenAtEnd } = useSelector((state) => state.companyInfo);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState("warning");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { loggedIn } = useSelector((state) => state.users);
  const [second, setSecond] = useState(0);
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [counterActive, setCountActive] = useState(false); // counter show or hidden control
  // React.useEffect(() => {
  //   setVerificationCode("")
  //   setSecond(60)
  // },[])

  React.useEffect(() => {
    const timer = setInterval(() => {
      setSecond((prevCount) => prevCount - 1);
    }, 1000);

    if (second === 0) {
      clearInterval(timer);
      setIsDisableButton(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [second]);

  const [verificationCode, setVerificationCode] = useState("");
  /* const [ unreceivedMail, setUnreceivedMail ] = useState(false); */
  const [unreceivedSMS, setUnreceivedSMS] = useState(false);
  const navigate = useNavigate();
  const { tesis } = useSelector((state) => state.summary);
  const { module, availableModules } = useSelector((state) => state.module);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState(false);
  };

  const handleOnChange = (event) => {
    setVerificationCode(event);
  };

  const checkAuthenticationCode = async () => {
    let body = {};

    const splitProps = String(props.usedFor).split("|");

    // if props.usedFor === 'login' then send to login request and set body as {username: email, verification_code: verificationCode}

    if (splitProps.includes("login")) {
      body.email = props.formValues.email;
      body.verification_code = Number(verificationCode);
    }

    // else send to register request and set body as form values
    else {
      body = { ...body, ...props.formValues };
      body.verification_code = Number(verificationCode);
      body.musteri_id = localStorage.getItem("userId");
    }

    await axiosInstance
      .post(props.url, body)
      .then(async (response) => {
        const data = response.data.data;
        if (response.data.status) {
          // await props.musteriSave(); // if response.data.status is true, send to musterisave request
          // await props.login(); // if musteriSave request is done, send to login request
          localStorage.setItem("access", data.access);
          localStorage.setItem("refresh", data.refresh);

          setSnackbarMessage(response.data.message);
          setSnackbarType("success");
          setSnackbarState(true);

          setTimeout(() => {
            const parsedInfo = parseJwt(data.access);
            if (splitProps.includes("admin")) {
              const isAdmin = parsedInfo.is_admin;
              if (!isAdmin) {
                alert("Bu işlemi yapmaya yetkiniz yoktur!");
                return;
              }
              dispatch(changeAdminMail({ adminMail: props.formValues.email }));
              dispatch(changeAdminLoggedIn({ adminLoggedIn: true }));
              if (parsedInfo.isim) {
                dispatch(changeAdminName({ adminName: parsedInfo.isim }));
              }
              if (parsedInfo.soy_isim) {
                dispatch(
                  changeAdminSurname({
                    adminSurname: parsedInfo.soy_isim,
                  })
                );
              }
              dispatch(changeIsAdminState({ isAdmin: true }));
              // show message set snackbar message and type

              setTimeout(() => {
                navigate("/admin");
              }, 1250);
            } else {

              if (parsedInfo.is_admin) {
                dispatch(changeAdminLoggedIn({ adminLoggedIn: true }));
              }
              else {
                dispatch(changeLoggedIn({ loggedIn: true }));
              }
              // show message set snackbar message and type

              if (firstScreenAtEnd !== "contact" || parsedInfo.is_admin) {
                setTimeout(() => {
                  if (parsedInfo.is_admin) {
                    navigate("/admin");
                  }
                  else {
                    navigate("/login");
                  }
                }, 1250);
              } else {
                dispatch(changeStep({ stepNumber: 5 }));
              }
            }
          }, 1250);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType("warning");
          setSnackbarState(true);

          if (String(response.data.message).startsWith("3")) {
            setSecond(60);
            setCountActive(true);
            setIsDisableButton(true);
          } else {
            setIsDisableButton(false);
          }
        }
      })
      .catch((error) => {
        if (typeof error.status !== "undefined" && error.status === 429) {
          setSecond(60);
          setCountActive(true);
          setIsDisableButton(true);
        }
        else {
          setSnackbarMessage(
            "Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz."
          );
          setSnackbarType("warning");
          setSnackbarState(true);
        }
        // console.log(error);
      });
  };

  useEffect(() => {
    let URLString = window.location.href;
    let url = new URL(URLString);
    if (url.searchParams.get("email")) {
      const email = url.searchParams.get("email");
      setEmail(email);
    } else {
      // navigate("/login")
    }
  }, []);

  const resendSmsFunction = () => {
    numberVerificationSend({
      musteriId: localStorage.getItem("userId"),
      phoneNumber: props.formValues.phoneNumber,
      email: props.formValues.email,
    })
      .then((response) => {
        if (response) {
          setSnackbarMessage("SMS gönderildi");
          setSnackbarType("success");
          setSnackbarState(true);
        } else {
          setSnackbarMessage("SMS gönderilemedi");
          setSnackbarType("warning");
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage("SMS gönderilemedi");
        setSnackbarType("warning");
        setSnackbarState(true);
      });
  };

  // if (!loggedIn) {
  //   return <Navigate to="/login" />;
  // }

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        placeContent: "center",
        placeItems: "center",
      }}
    >
      <div>
        <img
          src={require("../../assets/resources/sms-verification-icon.png")}
          style={{ width: "200px", height: "200px" }}
          alt="sms-verification-icon"
        />
      </div>
      <div style={{ maxWidth: "500px" }}>
        <p style={{ fontSize: "18px", padding: "0px 20px" }}>
          {t("QuoteSendPage.infoText1")}
          {t("QuoteSendPage.infoText2")}
        </p>
        {!(availableModules.includes("home") && tesis === "Mesken") && (
          <p
            style={{ fontSize: "15px", padding: "20px", paddingBottom: "0px" }}
          >
            {t("QuoteSendPage.infoText3")}
          </p>
        )}
      </div>
      <div style={{ padding: "20px 0px" }}>
        <ReactCodeInput
          type="number"
          fields={6}
          value={verificationCode}
          onChange={handleOnChange}
          autoFocus
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <StyledButton
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          style={{ fontSize: "15px" }}
          onClick={() => {
            // dispatch(changeStep({stepNumber: 4}))
            props.onCancel();
          }}
        >
          {t("QuoteSendPage.goBack")}
        </StyledButton>
        <StyledButton
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={isDisableButton ? null : checkAuthenticationCode}
          disabled={isDisableButton}
        >
          {t("QuoteSendPage.verify")}
        </StyledButton>
      </div>
      {/* {second === 0 && counterActive && (
        <div
          style={{
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={resendSmsFunction}
          >
            {t("QuoteSendPage.notReceivedSMS")}
          </span>
        </div>
      )} */}
      {second > 0 && (
        <div style={{ padding: "30px" }}>
          <span>{`${second} ${t("QuoteSendPage.afterSecondSendAgain")}`}</span>
        </div>
      )}
      {/* <MailingDialog
          unreceivedMail={unreceivedMail}
          changeFunc={setUnreceivedMail}
          changeSnackbarMessage={setSnackbarMessage}
          changeSnackbarType={setSnackbarType}
          changeSnackbarState={setSnackbarState}
        /> */}
      <SMSDialog
        unreceivedSMS={unreceivedSMS}
        changeFunc={setUnreceivedSMS}
        changeSnackbarMessage={setSnackbarMessage}
        changeSnackbarType={setSnackbarType}
        changeSnackbarState={setSnackbarState}
        setSecond={setSecond}
        // onClose={props.onCancel}
        setCode={setVerificationCode}
        setActiveCounter={setCountActive}
        phoneNumber={props.formValues.phoneNumber}
      />
      <Snackbar
        open={snackbarState}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
