/* eslint-disable prefer-template */
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./UserLoginPage.module.css";
import { Form, Modal } from "antd";
import de from "react-phone-input-2/lang/de.json";
import tr from "react-phone-input-2/lang/tr.json";
import {
  changeIsAdminState,
  changeAdminMail,
  changeAdminLoggedIn,
  changeAdminName,
  changeAdminSurname,
} from "../../services/users/adminReducer";
import {
  Box,
  Button,
  Typography,
  withStyles,
  Snackbar,
  OutlinedInput,
} from "@material-ui/core";
// import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
// import { changeAccessToken, changeRefreshToken } from 'src/services/auth/authReducer';
import { changeLoggedIn } from "../../services/users/userReducer";
import MuiAlert from "@material-ui/lab/Alert";
import axiosInstance from "../../components/axios/axiosApi";
import Navbar from "../../containers/LoginNavbar/Navbar";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import i18n from "../../i18n";
import ReCaptcha from "../../components/Captcha/ReCaptcha";
import QuoteSent from "../QuoteSentPage/QuoteSent";
import { loginReducer } from "../../utils/func/AuthLogin";
import ReactCodeInput from "react-code-input";
import PhoneInput from "react-phone-input-2";
import { recaptchaValidation } from "../../utils/func/recaptcha-validation";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

const LoginView = (props) => {
  const [smsPopupShow, setSmsPopupShow] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { module } = useSelector((state) => state.module);
  const { firmaKodu } = useSelector((state) => state.companyInfo);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState("warning");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [updatePhoneNumberModalOpen, setUpdatePhoneNumberModalOpen] =
    useState(false);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 991px)" });

  const [formValues, setFormValues] = React.useState(null);

  const [loginForm] = Form.useForm();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState(false);
  };

  const decideHelmet = () => {
    if (
      localStorage.getItem("dil") === "tr" ||
      String(i18n.language).split("-")[0] === "tr"
    ) {
      if (module === "solarvis") {
        return (
          <Helmet>
            <title>{`solarVis | Kullanıcı Girişi`}</title>
            <meta
              name="description"
              content="Fizibilite raporunu gör ve fotoğraflarını yükle."
            />
          </Helmet>
        );
      } else {
        return (
          <Helmet>
            <title>{`${firmaKodu} | Kullanıcı Girişi`}</title>
            <meta
              name="description"
              content="Fizibilite raporunu gör ve fotoğraflarını yükle."
            />
          </Helmet>
        );
      }
    } else {
      return (
        <Helmet>
          <title>{`${firmaKodu} | User Login`}</title>
          <meta
            name="description"
            content="View the feasibility report and upload your photos."
          />
        </Helmet>
      );
    }
  };

  const recaptchaRefForLogin = React.createRef();

  // i'd like to get email adress in url query params
  const location = useLocation();
  const email = location.state?.email;

  const login = async (values) => {
    if (recaptchaRefForLogin.current.getValue()?.length === 0) {
      alert(t("LoginPage.captchaError"));
      return;
    }
    const validationRecaptcha = await recaptchaValidation(recaptchaRefForLogin);
    if (!validationRecaptcha) {
      recaptchaRefForLogin.current.reset();
      alert(t("LoginPage.captchaError"));
      return;
    }
    loginReducer({
      values,
    })
      .then((result) => {
        const resp = result.data;
        // if resp.status is true, open the verification modal
        if (resp.status) {
          if (!resp.foreigner) {
            setSmsPopupShow(true);
          } else {
            axiosInstance
              .post("verify_single_sign_on", {
                email: loginForm.getFieldValue("email"),
                verification_code: 111111,
              })
              .then((response) => {

                if (response.data.status) {
                  const { data } = response.data;
                const parsedInfo = parseJwt(data.access);

                  // await props.musteriSave(); // if response.data.status is true, send to musterisave request
                  // await props.login(); // if musteriSave request is done, send to login request
                  localStorage.setItem("access", data.access);
                  localStorage.setItem("refresh", data.refresh);
                  
                  setSnackbarMessage(response.data.message);
                  setSnackbarType("success");
                  setSnackbarState(true);

                  if(parsedInfo.is_admin) {
                    window.location.href = "/admin";
                  }
                  else {
                    window.location.href = "/login";
                  }
                }
              });
          }
        } else if (
          resp.status === false &&
          String(resp.message).includes(
            "lütfen telefon numaranızı güncelleyin."
          )
        ) {
          setUpdatePhoneNumberModalOpen(true);
        } else {
          alert(resp.message);
        }
        if (
          typeof resp.case_code !== "undefined" &&
          resp.case_code === "saved_different_company"
        ) {
          recaptchaRefForLogin.current.reset();
          alert(resp.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response.status === 401 &&
          error.response.data.detail ===
            "No active account found with the given credentials"
        ) {
          alert(t("LoginPage.hataliGiris"));
        } else {
          alert(t("LoginPage.hataliGiris"));
        }
        recaptchaRefForLogin.current.reset();
      });
  };

  const updatePhoneNumber = (values) => {
    axiosInstance
      .post("update_phone_number", {
        email: loginForm.getFieldValue("email"),
        verification_code: values.verification_code,
        updated_phone: values.updated_phone_number,
      })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          alert(message);
          setUpdatePhoneNumberModalOpen(false);
          const { access, refresh } = res.data.data;
          localStorage.setItem("access", access);
          localStorage.setItem("refresh", refresh);
          const parsedJwt = parseJwt(access);
          if (parsedJwt.is_admin) {
            dispatch(changeIsAdminState({ isAdmin: true }));
            dispatch(changeAdminLoggedIn({ adminLoggedIn: true }));
            dispatch(changeAdminMail({ adminMail: values.email }));
            if (parsedJwt.isim) {
              dispatch(changeAdminName({ adminName: parsedJwt.isim }));
            }
            if (parsedJwt.soy_isim) {
              dispatch(
                changeAdminSurname({
                  adminSurname: parsedJwt.soy_isim,
                })
              );
            }
            navigate("/admin", { replace: true });
          } else {
            dispatch(changeLoggedIn({ loggedIn: true }));
            navigate("/login", { replace: true });
          }
        } else {
          alert(message);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Telefon numarası güncellenirken bir problem oluştu");
      });
  };

  return (
    <>
      {decideHelmet()}
      <Modal
        open={smsPopupShow}
        onCancel={() => setSmsPopupShow(false)}
        footer={null}
      >
        <QuoteSent
          usedFor="login"
          formValues={loginForm.getFieldsValue()}
          url="verify_single_sign_on"
          login={() => login(formValues)}
          onCancel={() => setSmsPopupShow(false)}
        />
      </Modal>
      <Modal
        open={updatePhoneNumberModalOpen}
        onCancel={() => setUpdatePhoneNumberModalOpen(false)}
        title="Telefon Numarnızı Güncelleyin"
        footer={null}
      >
        <p style={{ marginBottom: "16px" }}>
          Doğrulama kodu hesabınıza kayıtlı e-posta adresinize gönderildi
        </p>
        <Form
          initialValues={{
            updated_phone_number: "",
            verification_code: "",
          }}
          layout="vertical"
          onFinish={updatePhoneNumber}
        >
          <Form.Item
            label="Doğrulama Kodu"
            required
            name={"verification_code"}
            rules={[
              { required: true, message: "Alan zorunludur" },
              {
                len: 6,
                message: "Doğrulama kodu 6 haneli olmalıdır.",
              },
            ]}
          >
            <ReactCodeInput fields={6} autoFocus type="number" />
          </Form.Item>
          <Form.Item
            name="updated_phone_number"
            required
            rules={[{ required: true, message: "Alan zorunludur" }]}
            label="Yeni Telefon Numaranız"
          >
            <PhoneInput
              country={module === "german_solarvis" ? "de" : "tr"}
              onlyCountries={module !== "german_solarvis" ? ["tr"] : []}
              placeholder={
                module === "german_solarvis"
                  ? t("ProfilePage.phoneLabel")
                  : "(5xx) xxx xx xx"
              }
              masks={{ tr: "(...) ... .. ..", de: "... ........" }}
              areaCodes={false}
              prefix=""
              autoFormat
              disableCountryCode
              // countryCodeEditable={false}
              containerStyle={{ outline: "none", border: "none" }}
              localization={module === "german_solarvis" ? de : tr} // for input lang
              inputProps={{ autoComplete: "tel-national" }}
              inputStyle={{
                width: "100%",
                paddingTop: "12px",
                paddingBottom: "12px",
                outline: "none",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                borderRadius: "0px",
                borderColor: "darkgray",
              }}
            />
          </Form.Item>
          <Form.Item
            style={{ margin: 12, display: "flex", justifyContent: "end" }}
          >
            <Button variant="contained" type="submit" color="primary">
              {t("LoginPage.send")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className={classes.pageWrapper}>
        <div className={classes.container}>
          <div className={classes.loginWrapper}>
            <Navbar />
            <div
              style={{
                marginTop: "12vh",
              }}
            >
              
              <Form
                form={loginForm}
                initialValues={{
                  email: email || "",
                  password: "",
                }}
                onFinish={login}
                className={classes.form}
              >
             <Box mb={3}>
                <Typography color="textPrimary" variant="h4">
                  {t("LoginPage.giris")}
                </Typography>
                <Typography color="textSecondary" variant="subtitle2">
                  {t("LoginPage.subtitle")}
                </Typography>
              </Box>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: t("ErrorMessages.fieldRequired"),
                    },
                    {
                      type: "email",
                      message: t("ErrorMessages.emailError"),
                    },
                  ]}
                >
                  <OutlinedInput
                    fullWidth
                    autoComplete="email"
                    name="email"
                    type="email"
                    placeholder={t("LoginPage.email")}
                    variant="outlined"
                  />
                </Form.Item>
                {/* <Form.Item
                    name={"password"}
                    rules={[{ required: true, message: "Şifre zorunludur" }]}
                  >
                    <OutlinedInput
                      fullWidth
                      name="password"
                      autoComplete="current-password"
                      variant="outlined"
                      autoFocus={email.length > 0}
                      placeholder={t("LoginPage.sifre")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      type={showPassword ? "text" : "password"}
                    />
                  </Form.Item> */}
                <ReCaptcha captchaRef={recaptchaRefForLogin} />
                <Box my={2}>
                  <StyledButton
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("LoginPage.giris")}
                  </StyledButton>
                </Box>
              </Form>
            </div>
          </div>
          {isDesktopOrLaptop && (
            <div className={classes.iconWrapper}>
              <img
                src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/solarpanel.png"
                alt="solar_panel"
                className={classes.solarImage}
              />
            </div>
          )}
        </div>

        {/* i'd like to update phone number of the user for i want to open a modal */}

        <Snackbar
          open={snackbarState}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default LoginView;
