import React from "react";
import "./ResetPasswordPage.css";
import { Button, Form, Input } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import axios from "../../components/axios/axiosApi";

function ResetPasswordPage(props) {
  const [form] = Form.useForm();
  const [showError, setShowError] = React.useState(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const navigate = useNavigate();
  React.useEffect(() => {
    const uid = params.get("uid");
    const token = params.get("token");
    if (!uid || !token) {
      setShowError(true);
    }
  }, [params]);

  const onResetPasswordHandle = (values) => {

    axios
      .post("password_reset_confirm", {
        uid: params.get("uid"),
        token: params.get("token"),
        new_password: values.password,
        new_password_confirm: values.confirmPassword,
      })
      .then((res) => {
        const { message, status } = res.data;
        if (message) {
          alert(message);

          if (status) {
            navigate("/login", { replace: true });
          }
        } else {
          throw new Error();
        }
        console.log(res);
      })
      .catch((err) => {
        alert("Şifre sıfırlanırken bir problem oluştu");
      });

    form.resetFields();
  };

  return (
    <div className="page">
      {showError && (
        <div
          style={{
            padding: "8px",
            fontSize: 14,
            background: "#E74C3C",
            borderRadius: "8px",
            color: "white",
            margin: "4px",
          }}
        >
          Şifre sıfırlamak için bir anahtarın yok!
        </div>
      )}
      <Link to={"/"} style={{ padding: 0, margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src="/solarvis_logo.svg"
          alt="Solarvis Logo"
          style={{ width: 96, height: 96, position: "absolute", top: 64 }}
        />
      </Link>
      <div className="reset-password-form">
        <h3 className="title">Şifrenizi Sıfırlayın</h3>
        <p className="description">Yeni şifrenizi 1 saat içinde belirleyin</p>
        <Form
          style={{ marginTop: "12px" }}
          form={form}
          initialValues={{ password: "", confirmPassword: "" }}
          onFinish={showError ? null : onResetPasswordHandle}
        >
          <Form.Item
            name={"password"}
            rules={[
              {
                required: true,
                message: "Yeni Şifre zorunludur",
              },
              {
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                message:
                  "Yeni şifreniz 1 küçük, 1 büyük karakter ve sayı içermelidir",
              },
              {
                min: 8,
                message: "Yeni şifre en az 8 karakter içermelidir",
              },
            ]}
          >
            <Input.Password placeholder="Yeni Şifre" size="large" />
          </Form.Item>
          <Form.Item
            name={"confirmPassword"}
            rules={[
              {
                required: true,
                message: "Yeni Şifre (Tekrar) zorunludur",
              },

              {
                validator: (_, value) => {
                  if (form.getFieldValue("password") === value) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Şifreler uyuşmuyor");
                  }
                },
              },
            ]}
          >
            <Input.Password placeholder="Yeni Şifre (Tekrar)" size="large" />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {/* <Link to={-1} style={{color:'black',textDecoration:'underline'}}>
              Geri Dön
            </Link> */}
            <Button disabled={showError} type="primary" htmlType="submit">
              Sıfırla
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
