import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import "./App.css";
import i18n from "./i18n";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import psl from "psl";
import axiosInstance from "./components/axios/axiosApi";
//import createHistory from 'history/createBrowserHistory';
import PageWrapper from "./pages/PageWrapper/PageWrapper";
import QuoteSentPage from "./pages/QuoteSentPage/QuoteSent";
import MainPageWrapper from "./pages/UserMainPage/Wrapper";
import UserDocumentUploadPage from "./pages/UserDocumentUploadPage/UserDocumentUploadPage";
import UserAccountPage2 from "./pages/UserAccountPage/UserAccountPage2";
import { useDispatch, useSelector } from "react-redux";
import {
  changeModule,
  changeAvailableModules,
} from "./services/products/product/moduleReducer";
import { changeQuote } from "./services/products/product/stepReducer";
import {
  changeCompanyInfo,
  changeLogoPreviewURL,
} from "./services/products/product/companyInfoReducer";
import {
  changeMaxFatura,
  changeAvgFatura,
  changeFaturaSgucuOrani,
} from "./services/products/product/constants";
import { changeLoggedIn, changeUserId } from "./services/users/userReducer";

import AydinlatmaMetni from "./pages/UserMainPage/Home/KVKKPages/AydinlatmaMetni";
import CerezPolitikasi from "./pages/UserMainPage/Home/KVKKPages/CerezPolitikasi";
import KVKKPolitikasi from "./pages/UserMainPage/Home/KVKKPages/KVKKPolitikasi";
import SatisSozlesmesi from "./pages/UserMainPage/Home/KVKKPages/SatisSozlesmesi";

import PaymentResponse from "./pages/UserMainPage/Home/PaymentPages/PaymentResponse";
import CreditResponse from "./pages/UserMainPage/Home/PaymentPages/CreditResponse";
import PaymentPage from "./pages/UserMainPage/Home/PaymentPages/PaymentPage";
import PaytrRedirect from "./pages/RedirectPages/PaytrRedirect";

import PageWrapperAdmin from "./pages/AdminPages/PageWrapper/PageWrapper";
import AdminLoginPage from "./pages/AdminPages/Account/AdminLogin";

import SunSpinner from "./components/UI/SunSpinner/SunSpinner";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  changeTesis,
  changeFatura,
  changeSozlesmeGucu,
  changeConsumptionProfile,
  changeCurrency,
} from "./services/products/product/summaryReducer";

import { changeDrawingPointsNew } from "./services/products/product/reducer";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import { Button } from "@material-ui/core";
import {
  changeAdminLoggedIn,
  changeIsAdminState,
} from "./services/users/adminReducer";

// Define Working Environment Variables -> development or production
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

// ------- SENTRY - ERROR TRACING APPLICATION --------- //
// Run sentry only in production mode
if (NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://43d862d756074ab9a32301a98016fd9e@o1341554.ingest.sentry.io/6661861",
    integrations: [new BrowserTracing()],

    environment: "production",
    ignoreErrors: ["ResizeObserver loop limit exceeded"],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// ------------------------------ //

// ------- GOOGLE ANALYTICS --------- //

if (window.location.host === "hesaplama.volthaus.co") {
  ReactGA.initialize("G-BPCMRX9J1M", {
    debug: false,
  });
} else {
  ReactGA.initialize("G-RLR6M2VL22", {
    debug: false,
  });
}

// ------------------------------ //

function App() {
  const dispatch = useDispatch();
  const { mail, roofArea, roofTilt, cephe, consumptionProfile } = useSelector(
    (state) => state.summary
  );

  const { avgFatura, faturaSgucuOrani } = useSelector(
    (state) => state.constants
  );

  const [loading, setLoading] = React.useState(true);

  const fetchCompanyInfo = () => {
    let usedAxiosInstance = axiosInstance;
    usedAxiosInstance
      .get(`settings/company/end_user/fetch?format=json`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            const data = response.data.data;

            if (!data) return;

            if (typeof data.firma_kodu !== "undefined") {
              let firmaKoduStrip = data.firma_kodu.replaceAll(" ", "");
              if (firmaKoduStrip === "ArcelikSolar") {
                dispatch(
                  changeLogoPreviewURL({
                    logoPreviewURL: `https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/logos/${firmaKoduStrip}_website_logo.png`,
                  })
                );
              } else {
                dispatch(
                  changeLogoPreviewURL({
                    logoPreviewURL: `https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/logos/${firmaKoduStrip}_logo.png`,
                  })
                );
              }
            }

            dispatch(
              changeCompanyInfo({
                firmaKodu: data.firma_kodu,
                companyName: data.company,
                companyPhone: data.phone,
                companyMail: data.mail,
                companyAddress: data.adress,
                companyPrimaryColor: data.primary_color,
                companySecondaryColor: data.secondary_color,
                vergiDairesi: data.vergi_dairesi,
                vergiNo: data.vergi_no,
                mersisNo: data.mersis_no,
                companyContactPerson: data.contact_person,
                kvkkLink: data.kvkk_link,
                cookiesLink: data.cookies_link,
                firstScreenAtEnd: data.first_screen_at_end,
              })
            );

            const availableModules = data.available_modules;
            dispatch(changeAvailableModules(availableModules));

            document.documentElement.style.setProperty(
              "--primary-color",
              data.primary_color
            );
            document.documentElement.style.setProperty(
              "--secondary-color",
              data.secondary_color
            );
            document.documentElement.style.setProperty(
              "--secondary-color-light",
              `${data.secondary_color}17`
            );
            if ("font_family" in data && data.font_family !== null) {
              document.documentElement.style.setProperty(
                "--font-fam",
                data.font_family
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    usedAxiosInstance
      .get("fatura_orani?format=json")
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data;
          dispatch(
            changeMaxFatura({
              Mesken: data.maks_fatura["Mesken"],
              Ticarethane: data.maks_fatura["Ticarethane"],
              Sanayi: data.maks_fatura["Sanayi"],
              TarımsalSulama: data.maks_fatura["Tarımsal Sulama"],
            })
          );
          dispatch(
            changeAvgFatura({
              Mesken: data.ort_fatura["Mesken"],
              Ticarethane: data.ort_fatura["Ticarethane"],
              Sanayi: data.ort_fatura["Sanayi"],
              TarımsalSulama: data.ort_fatura["Tarımsal Sulama"],
            })
          );
          dispatch(changeFaturaSgucuOrani(data.fatura_sgcucu_oran));
        }
      })
      .catch((error) => {});

    setLoading(false);
  };

  useEffect(() => {
    let didCancel = false;
    const parsed = psl.parse(window.location.host);

    if (
      !localStorage.getItem("cacheCleaned") ||
      localStorage.getItem("cacheCleaned") !== "yes"
    ) {
      localStorage.clear();
      localStorage.setItem("cacheCleaned", "yes");
      window.location.reload(true);
    }

    if (
      parsed.sld === "solarvis" ||
      window.location.host.includes("localhost") ||
      window.location.host === "172.17.15.120:3000"
    ) {
      if (
        window.location.host === "isb-prod.solarvis.co" ||
        window.location.host === "isb-staging.solarvis.co" ||
        parsed.subdomain === "isb-prod" ||
        parsed.subdomain === "isb-staging" ||
        window.location.host === "isb-test.solarvis.co" ||
        parsed.subdomain === "isb-test" ||
        window.location.host === "localhost:3002"
      ) {
        dispatch(changeTesis({ tesis: "Ticarethane" }));
        dispatch(
          changeSozlesmeGucu({
            sGucu: parseInt(avgFatura.Ticarethane / faturaSgucuOrani),
          })
        );
        dispatch(changeFatura({ fatura: parseInt(avgFatura.Ticarethane) }));
        if (consumptionProfile !== "custom") {
          dispatch(
            changeConsumptionProfile({ consumptionProfile: "constant" })
          );
        }
        dispatch(changeModule({ module: "isbank" }));
        localStorage.setItem("modül", "isbank");
      } else if (
        window.location.host === "arc-test.solarvis.co" ||
        window.location.host === "arc-staging.solarvis.co" ||
        parsed.subdomain === "arc-test" ||
        parsed.subdomain === "arcelik" ||
        parsed.subdomain === "arc-staging" ||
        window.location.host === "localhost:3001"
      ) {
        dispatch(changeModule({ module: "arcelikkurumsalcozumler" }));
        localStorage.setItem("modül", "arcelikkurumsalcozumler");
      } else if (
        window.location.host === "akb-test.solarvis.co" ||
        window.location.host === "akb-staging.solarvis.co" ||
        parsed.subdomain === "akb-test" ||
        parsed.subdomain === "akb-staging" ||
        window.location.host === "fizibilite.solarvis.co" ||
        parsed.subdomain === "fizibilite"
      ) {
        dispatch(changeTesis({ tesis: "Ticarethane" }));
        dispatch(
          changeSozlesmeGucu({
            sGucu: parseInt(avgFatura.Ticarethane / faturaSgucuOrani),
          })
        );
        dispatch(changeFatura({ fatura: parseInt(avgFatura.Ticarethane) }));
        if (consumptionProfile !== "custom") {
          dispatch(
            changeConsumptionProfile({ consumptionProfile: "constant" })
          );
        }
        dispatch(changeModule({ module: "akbank" }));
        localStorage.setItem("modül", "akbank");
      } else if (
        window.location.host === "aks-test.solarvis.co" ||
        parsed.subdomain === "aks-test"
      ) {
        dispatch(changeModule({ module: "aksasolar" }));
        localStorage.setItem("modül", "aksasolar");
      } else if (
        window.location.host === "volthaus-test.solarvis.co" ||
        parsed.subdomain === "volthaus-test"
      ) {
        dispatch(changeModule({ module: "volthaus" }));
        localStorage.setItem("modül", "volthaus");
      } else if (
        window.location.host === "greeneffect.solarvis.co" ||
        parsed.subdomain === "greeneffect"
      ) {
        dispatch(changeModule({ module: "greeneffect" }));
        localStorage.setItem("modül", "greeneffect");
      } else if (
        window.location.host === "kiracsolar.solarvis.co" ||
        parsed.subdomain === "kiracsolar"
      ) {
        dispatch(changeModule({ module: "kiracsolar" }));
        localStorage.setItem("modül", "kiracsolar");
      } else if (
        window.location.host === "ibt-test.solarvis.co" ||
        window.location.host === "ibt-staging.solarvis.co" ||
        window.location.host === "ibt-prod.solarvis.co" ||
        window.location.host === "teklif.ibtenerji.com" ||
        parsed.subdomain === "ibt-test" ||
        parsed.subdomain === "ibt-prod" ||
        parsed.subdomain === "ibt-staging" ||
        parsed.subdomain === "ibtenerji"
      ) {
        dispatch(changeModule({ module: "ibtenerji" }));
        localStorage.setItem("modül", "ibtenerji");
        localStorage.setItem("dil", "tr");
        i18n.changeLanguage("tr");
      } else if (
        window.location.host === "german.solarvis.co" ||
        parsed.subdomain === "german" ||
        window.location.host === "german.solarvis.co"
      ) {
        dispatch(changeModule({ module: "german_solarvis" }));
        dispatch(changeCurrency({ currency: "€" }));
        localStorage.setItem("modül", "german_solarvis");
        localStorage.setItem("dil", "de");
        i18n.changeLanguage("de");
      } else if (
        window.location.host === "calculator.solarvis.co" ||
        parsed.subdomain === "calculator" ||
        window.location.host === "calculator-test.solarvis.co" ||
        parsed.subdomain === "calculator-test" ||
        window.location.host === "localhost:3003"
      ) {
        dispatch(changeModule({ module: "electraVis Global" }));
        dispatch(changeCurrency({ currency: "$" }));
        localStorage.setItem("modül", "electraVis Global");
        localStorage.setItem("dil", "en");
        i18n.changeLanguage("en");
      } else if (
        window.location.host === "projexsolar.solarvis.co" ||
        parsed.subdomain === "projexsolar"
      ) {
        dispatch(changeModule({ module: "projexsolar" }));
        localStorage.setItem("modül", "projexsolar");
      } else if (
        window.location.host === "b2benergy.solarvis.co" ||
        parsed.subdomain === "b2benergy"
      ) {
        dispatch(changeModule({ module: "b2benergy" }));
        localStorage.setItem("modül", "b2benergy");
      } else if (
        window.location.host === "kslcleantech.solarvis.co" ||
        parsed.subdomain === "kslcleantech"
      ) {
        dispatch(changeModule({ module: "kslcleantech" }));
        localStorage.setItem("modül", "kslcleantech");
      } else if (
        window.location.host === "aydemplus.solarvis.co" ||
        parsed.subdomain === "aydemplus"
      ) {
        dispatch(changeModule({ module: "aydemplus" }));
        localStorage.setItem("modül", "aydemplus");
      } else if (
        window.location.host === "grnt.solarvis.co" ||
        parsed.subdomain === "grnt"
      ) {
        dispatch(changeModule({ module: "garantibbva" }));
        localStorage.setItem("modül", "garantibbva");
      } else if (
        window.location.host === "kontekenerji.solarvis.co" ||
        parsed.subdomain === "kontekenerji"
      ) {
        dispatch(changeModule({ module: "kontekenerji" }));
        localStorage.setItem("modül", "kontekenerji");
      } else if (
        window.location.host === "guneslicati.solarvis.co" ||
        parsed.subdomain === "guneslicati"
      ) {
        dispatch(changeModule({ module: "guneslicati" }));
        localStorage.setItem("modül", "guneslicati");
      } else if (
        window.location.host === "eksperenerji.solarvis.co" ||
        parsed.subdomain === "eksperenerji"
      ) {
        dispatch(changeModule({ module: "eksperenerji" }));
        localStorage.setItem("modül", "eksperenerji");
      } else {
        dispatch(changeModule({ module: "solarvis" }));
        localStorage.setItem("modül", "solarvis");
      }
    } else {
      dispatch(changeModule({ module: parsed.sld }));
      localStorage.setItem("modül", parsed.sld);
      if (parsed.sld === "isbank" || parsed.sld === "aksasolar") {
        localStorage.setItem("dil", "tr");
        i18n.changeLanguage("tr");
        dispatch(changeTesis({ tesis: "Ticarethane" }));
        dispatch(
          changeSozlesmeGucu({
            sGucu: parseInt(avgFatura.Ticarethane / faturaSgucuOrani),
          })
        );
        dispatch(changeFatura({ fatura: parseInt(avgFatura.Ticarethane) }));
        dispatch(changeConsumptionProfile({ consumptionProfile: "constant" }));
      }
      if (parsed.sld === "ibtenerji" || parsed.subdomain.includes("ibt")) {
        localStorage.setItem("dil", "tr");
        i18n.changeLanguage("tr");
      }
    }

    if (Boolean(mail)) {
      dispatch(changeQuote({ quoteSend: true }));
    }

    function axiosGet() {
      let username, password;
      let URLString = window.location.href;
      let url = new URL(URLString);

      if (
        !Boolean(localStorage.getItem("refresh")) ||
        localStorage.getItem("refresh") === "undefined"
      ) {
        if (
          parsed.sld === "solarvis" ||
          window.location.host.includes("localhost") ||
          window.location.host === "172.17.15.120:3000"
        ) {
          if (
            window.location.host === "isb-prod.solarvis.co" ||
            window.location.host === "isb-test.solarvis.co" ||
            window.location.host === "isb-staging.solarvis.co" ||
            parsed.subdomain === "isb-staging" ||
            parsed.subdomain === "isb-prod" ||
            parsed.subdomain === "isb-test" ||
            window.location.host === "localhost:3002"
          ) {
            username = process.env.REACT_APP_ISBANK_UI;
            password = process.env.REACT_APP_ISBANK_PW;
          } else if (
            window.location.host === "akb-test.solarvis.co" ||
            window.location.host === "akb-staging.solarvis.co" ||
            window.location.host === "akb-prod.solarvis.co" ||
            parsed.subdomain === "akb-staging" ||
            parsed.subdomain === "akb-test" ||
            window.location.host === "fizibilite.solarvis.co" ||
            parsed.subdomain === "fizibilite"
          ) {
            username = process.env.REACT_APP_AKBANK_UI;
            password = process.env.REACT_APP_AKBANK_PW;
          } else if (
            window.location.host === "aks-test.solarvis.co" ||
            parsed.subdomain === "aks-test"
          ) {
            username = process.env.REACT_APP_AKSA_UI;
            password = process.env.REACT_APP_AKSA_PW;
          } else if (
            window.location.host === "arc-test.solarvis.co" ||
            parsed.subdomain === "arc-test" ||
            window.location.host === "arc-staging.solarvis.co" ||
            parsed.subdomain === "arc-staging" ||
            window.location.host === "localhost:3001"
          ) {
            username = process.env.REACT_APP_ARCELIK_UI;
            password = process.env.REACT_APP_ARCELIK_PW;
          } else if (
            window.location.host === "calculator.solarvis.co" ||
            parsed.subdomain === "calculator" ||
            window.location.host === "calculator-test.solarvis.co" ||
            parsed.subdomain === "calculator-test" ||
            window.location.host === "localhost:3003"
          ) {
            username = process.env.REACT_APP_SOLARVISGLOBAL_UI;
            password = process.env.REACT_APP_SOLARVISGLOBAL_PW;
          } else if (
            window.location.host === "greeneffect.solarvis.co" ||
            parsed.subdomain === "greeneffect"
          ) {
            username = process.env.REACT_APP_GREENEFFECT_UI;
            password = process.env.REACT_APP_GREENEFFECT_PW;
          } else if (
            window.location.host === "volthaus-test.solarvis.co" ||
            parsed.subdomain === "volthaus-test"
          ) {
            username = process.env.REACT_APP_VOLTHAUS_UI;
            password = process.env.REACT_APP_VOLTHAUS_PW;
          } else if (
            window.location.host === "kiracsolar.solarvis.co" ||
            parsed.subdomain === "kiracolsar"
          ) {
            username = process.env.REACT_APP_KIRACSOLAR_UI;
            password = process.env.REACT_APP_KIRACSOLAR_PW;
          } else if (
            window.location.host === "ibt-test.solarvis.co" ||
            window.location.host === "ibt-prod.solarvis.co" ||
            window.location.host === "ibt-staging.solarvis.co" ||
            window.location.host === "teklif.ibtenerji.com" ||
            parsed.subdomain === "ibt-test" ||
            parsed.subdomain === "ibtenerji" ||
            parsed.subdomain === "ibt-staging" ||
            parsed.subdomain === "ibt-prod"
          ) {
            username = process.env.REACT_APP_IBTSOLAR_UI;
            password = process.env.REACT_APP_IBTSOLAR_PW;
          } else if (
            window.location.host === "german.solarvis.co" ||
            parsed.subdomain === "german"
          ) {
            username = process.env.REACT_APP_GERMANSOLARVIS_UI;
            password = process.env.REACT_APP_GERMANSOLARVIS_PW;
          } else if (
            window.location.host === "projexsolar.solarvis.co" ||
            parsed.subdomain === "projexsolar"
          ) {
            username = process.env.REACT_APP_PROJEXSOLAR_UI;
            password = process.env.REACT_APP_PROJEXSOLAR_PW;
          } else if (
            window.location.host === "b2benergy.solarvis.co" ||
            parsed.subdomain === "b2benergy"
          ) {
            username = process.env.REACT_APP_B2BENERGY_UI;
            password = process.env.REACT_APP_B2BENERGY_PW;
          } else if (
            window.location.host === "kslcleantech.solarvis.co" ||
            parsed.subdomain === "kslcleantech"
          ) {
            username = process.env.REACT_APP_KSLCLEANTECH_UI;
            password = process.env.REACT_APP_KSLCLEANTECH_PW;
          } else if (
            window.location.host === "aydemplus.solarvis.co" ||
            parsed.subdomain === "aydemplus"
          ) {
            username = process.env.REACT_APP_AYDEMPLUS_UI;
            password = process.env.REACT_APP_AYDEMPLUS_PW;
          } else if (
            window.location.host === "grnt.solarvis.co" ||
            parsed.subdomain === "grnt"
          ) {
            username = process.env.REACT_APP_GARANTIBBVA_UI;
            password = process.env.REACT_APP_GARANTIBBVA_PW;
          } else if (
            window.location.host === "kontekenerji.solarvis.co" ||
            parsed.subdomain === "kontekenerji"
          ) {
            username = process.env.REACT_APP_KONTEKENERJI_UI;
            password = process.env.REACT_APP_KONTEKENERJI_PW;
          } else if (
            window.location.host === "guneslicati.solarvis.co" ||
            parsed.subdomain === "guneslicati"
          ) {
            username = process.env.REACT_APP_GUNESLICATI_UI;
            password = process.env.REACT_APP_GUNESLICATI_PW;
          } else if (
            window.location.host === "eksperenerji.solarvis.co" ||
            parsed.subdomain === "eksperenerji"
          ) {
            username = process.env.REACT_APP_EKSPERENERJI_UI;
            password = process.env.REACT_APP_EKSPERENERJI_PW;
          } else {
            username = process.env.REACT_APP_SOLARVISDEFAULT_UI;
            password = process.env.REACT_APP_SOLARVISDEFAULT_PW;
          }
        } else if (parsed.sld === "istanbulges") {
          username = process.env.REACT_APP_ISTANBULGES_UI;
          password = process.env.REACT_APP_ISTANBULGES_PW;
        } else if (parsed.sld === "elfsolar") {
          username = process.env.REACT_APP_ELFSOLAR_UI;
          password = process.env.REACT_APP_ELFSOLAR_PW;
        } else if (parsed.sld === "enisolar") {
          username = process.env.REACT_APP_ENISOLAR_UI;
          password = process.env.REACT_APP_ENISOLAR_PW;
        } else if (parsed.sld === "cn") {
          username = process.env.REACT_APP_CN_UI;
          password = process.env.REACT_APP_CN_PW;
        } else if (parsed.sld === "aksasolar") {
          username = process.env.REACT_APP_AKSA_UI;
          password = process.env.REACT_APP_AKSA_PW;
        } else if (parsed.sld === "e-prosumer") {
          username = process.env.REACT_APP_EPROSUMER_UI;
          password = process.env.REACT_APP_EPROSUMER_PW;
        } else if (parsed.sld === "istrich") {
          username = process.env.REACT_APP_ISTRICH_UI;
          password = process.env.REACT_APP_ISTRICH_PW;
        } else if (parsed.sld === "arcelikkurumsalcozumler") {
          username = process.env.REACT_APP_ARCELIK_UI;
          password = process.env.REACT_APP_ARCELIK_PW;
        } else if (parsed.sld === "solarvil") {
          username = process.env.REACT_APP_SOLARVIL_UI;
          password = process.env.REACT_APP_SOLARVIL_PW;
        } else if (parsed.sld === "solarmesken") {
          username = process.env.REACT_APP_SOLARMESKEN_UI;
          password = process.env.REACT_APP_SOLARMESKEN_PW;
        } else if (parsed.sld === "volthaus") {
          username = process.env.REACT_APP_VOLTHAUS_UI;
          password = process.env.REACT_APP_VOLTHAUS_PW;
        } else if (parsed.sld === "ibtenerji") {
          username = process.env.REACT_APP_IBTSOLAR_UI;
          password = process.env.REACT_APP_IBTSOLAR_PW;
        } else if (parsed.sld === "isbank") {
          username = process.env.REACT_APP_ISBANK_UI;
          password = process.env.REACT_APP_ISBANK_PW;
        } else if (parsed.sld === "aydemplus") {
          username = process.env.REACT_APP_AYDEMPLUS_UI;
          password = process.env.REACT_APP_AYDEMPLUS_PW;
        }

        let domainSend;
        if (String(window.location.host) === "localhost:3000") {
          domainSend = "demotest.solarvis.co";
        } else if (String(window.location.host) === "localhost:3002") {
          domainSend = "isb-test.solarvis.co";
        } else {
          domainSend = window.location.host;
        }

        axiosInstance
          .post(`/token/?domain=${domainSend}`, {
            username: username,
            password: password,
          })
          .then((response) => {
            if (response.data["access"]) {
              localStorage.setItem("access", response.data.access);
              localStorage.setItem("refresh", response.data.refresh);
            }

            fetchCompanyInfo();

            return response.data["access"];
          })
          .catch();
      } else {
        fetchCompanyInfo();
      }

      if (!didCancel) {
        // Ignore if we started fetching something else
      }
    }

    axiosGet();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, []);

  if (loading) {
    return (
      <>
        <SunSpinner />
      </>
    );
  }

  return (
    <Router>
      {String(window.location.host).includes("localhost") && (
        <Button
          type="button"
          style={{
            marginRight: "6px",
            position: "fixed",
            left: 24,
            bottom: 24,
            zIndex: 9999,
          }}
          onClick={() => {
            localStorage.clear();
            localStorage.setItem("cacheCleaned", "yes");
            window.location.reload();
          }}
          size="small"
          variant="contained"
          color="primary"
        >
          Verileri Temizle
        </Button>
      )}

      <Routes>
        <Route path="/" exact element={<PageWrapper usedFor="endUser" />} />
        <Route
          path="admin"
          exact
          element={<PageWrapperAdmin usedFor="admin" />}
        />
        <Route
          path="admin/login"
          exact
          element={<AdminLoginPage usedFor="admin" />}
        />
        <Route path="login" element={<MainPageWrapper />} />
        <Route path="login/photos" element={<UserDocumentUploadPage />} />
        <Route
          path="login/account"
          element={<UserAccountPage2 usedFor="endUser" />}
        />
        <Route path="login/package-payment" element={<PaymentPage />} />
        <Route
          path="login/successfull-payment"
          element={<PaymentResponse success={true} />}
        />
        <Route
          path="login/failed-payment"
          element={<PaymentResponse success={false} />}
        />
        <Route
          path="login/successful-credit-response"
          element={<CreditResponse success={true} />}
        />
        <Route
          path="login/failed-credit-response"
          element={<CreditResponse success={false} />}
        />
        <Route path="kvkk-aydinlatma-metni" element={<AydinlatmaMetni />} />
        <Route path="kvkk-politikasi" element={<KVKKPolitikasi />} />
        <Route
          path="cerez-kullanim-izinleri-bildirimi"
          element={<CerezPolitikasi />}
        />
        <Route path="mesafeli-satis-sozlesmesi" element={<SatisSozlesmesi />} />
        <Route path="paytr-redirect" element={<PaytrRedirect />} />
        <Route path="password_reset_confirm" element={<ResetPasswordPage />} />
        <Route path="sms_verification" element={<QuoteSentPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
