/* eslint-disable prefer-template */
import React from 'react';
import classes from './UserPageFooter.module.css';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const UserPageFooter = () => {
    const { module } = useSelector(state => state.module);
    const { stepNumber } = useSelector(state => state.steps);
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

    return (
        <>
            {module !== "akbank" && <div className={classes.copyrightWrapper} style={(isMobile && stepNumber === 3) ? {position: "fixed", bottom: 0, width: '100%' } : {}}>
                <span> </span>
                <span style={{ color: 'white' }}>Powered by <a href="https://solarvis.co" target='_blank' rel="noopener noreferrer" style={{ color: 'white'}}>solarVis</a></span>
            </div> }
            {module === "akbank" && <div className={classes.copyrightWrapperAkbank}>
                <span style={{ color: 'white', maxWidth: 800 }}>Güneş enerjisi ön fizibilite hizmeti Şirketimiz Electravis Teknoloji Anonim Şirketi tarafından verilmektedir. Bu internet sitesinden alacağınız hizmetle/vereceğimiz bilgilerle ilgili olarak Akbank T.A.Ş’nin herhangi bir sorumluluğu bulunmamaktadır.</span>
                <span style={{ color: 'white' }}>Powered by <a href="https://solarvis.co" target='_blank' rel="noopener noreferrer" style={{ color: 'white'}}>solarVis</a></span>
            </div> }
        </>
    );
};

export default UserPageFooter;
