import React from 'react';
import Map from './ReactGoogleMapExampleTest';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeMarker } from '../../services/products/product/reducer';

export default function GoogleMapExample(props) {

  const { usedFor } = props;
  
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const drawingOnMedia = useMediaQuery({ query: '(min-width: 1224px)' });
  // const isTabletLandscape = useMediaQuery({ query: '(min-width : 714px) and (max-width : 990px)' });

  const { t, } = useTranslation();
  const dispatch = useDispatch();

  // Eğer kullanıcı mobilden giriş yaptıysa, imleci direkt haritaya koysun diye
  if (isTabletOrMobile) {
    dispatch(changeMarker({markerOn: true}));
  }

  const fetchMapHeight = () => {
    if (isTabletOrMobile) {
      return '38vh'
    }
    return '70vh'
  };
  
  return (
    <>
      <Map
        usedFor={usedFor}
        desktop={isDesktopOrLaptop}
        mobile={isTabletOrMobile}
        drawingOnMedia={drawingOnMedia}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: fetchMapHeight() }} />}
        mapElement={<div style={{ height: `100%` }} />}
        drawAgainButtonText={t('DrawingPage.tekrarCiz')}
        panelText={t('DrawingPage.panel')}
        sifirlaText={t('DrawingPage.sifirla')}
        smallAreaAlertText={t('DrawingPage.smallAreaAlert')}
        translationObject={t}
      />
  </>
  )
}