import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import de from "react-phone-input-2/lang/de.json";
import tr from "react-phone-input-2/lang/tr.json";

export default function UniversalPhoneNumber({ value, setValue }) {
  const [phoneNumberValue, setPhoneNumberValue] = useState(value);
  const { module } = useSelector((state) => state.module);

  const { t } = useTranslation();

  useEffect(() => {
    if (String(value).startsWith("0")) {
      setPhoneNumberValue(String(value).replace("0", ""));
    }
  }, [value]);

  const commonConfig = {
    value: phoneNumberValue,
    autoFormat: true,
    containerStyle: { outline: "none", border: "none" },
    inputProps: { autoComplete: "tel-national" },
    disableCountryCode: true,
    inputStyle: {
      width: "100%",
      paddingTop: "0px",
      paddingBottom: "7px",
      outline: "none",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderRadius: "0px",
      borderColor: "darkgray",
    },
    onChange: (phone) => setValue(phone),
  };

  const trConfig = {
    ...commonConfig,
    country: "tr",
    onlyCountries: ["tr"],
    placeholder: "5xx xxx xx xx",
    localization: tr,
  };

  const internationalConfig = {
    ...commonConfig,
    country: "us",
    // onlyCountries: [],
    placeholder: t("ProfilePage.phoneLabel"),
    // masks: { de: "... ........" },
  };

  if (module === "german_solarvis" || module === "electraVis Global")
    return <PhoneInput {...internationalConfig} />;
  else return <PhoneInput {...trConfig} />;
}
