import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import classes from "./UserAccountPage.module.css";
import NavbarMain from "../../containers/NavbarMainPage/NavbarMain";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Snackbar,
  withStyles,
  Button as MaterialButton,
} from "@material-ui/core";
import de from "react-phone-input-2/lang/de.json";
import tr from "react-phone-input-2/lang/tr.json";
import { Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../components/axios/axiosApi";
import Spinner from "../../components/UI/Spinner/Spinner";
import i18n from "../../i18n";
import MaskedInput from "antd-mask-input";
import { replace } from "formik";
import Alert from "@material-ui/lab/Alert/Alert";
import PhoneInput from "react-phone-input-2";
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      mask={
        //  https://imask.js.org/guide.html#masked-pattern
        "0(000) 000 00 00"
      }
      {...props}
      maskOptions={{
        lazy: true,
        placeholderChar: "_",
      }}
    />
  );
}

const StyledDialogTitle = withStyles({
  root: {
    fontSize: "1rem",
    fontWeight: "bold",
    paddingBottom: 0,
  },
})(DialogTitle);

function UserAccountPage() {
  const { loggedIn } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState("warning");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [updateProfilePending, setUpdateProfilePending] = useState(false);
  const [loading, setLoading] = useState(true); // for control the requests
  const [passwordInfos, setPasswordInfos] = useState(null);
  const [inputs, setInputs] = useState({
    activeInputs: [],
    passiveInputs: [],
    status: false,
  });
  const mapContactInfo = (value) => {
    switch (value) {
      case "name":
        return "isim";
      case "surname":
        return "soy_isim";
      case "phoneNumber":
        return "telefon";
      case "email":
        return "username";
      case "vkn":
        return "vkn";
      case "companyName":
        return "customer_firm";
      case "referenceCode":
        return "subcon_reference_code";
      default:
        return value;
    }
  };
  const commonRule = [{ required: true, message: t("ErrorMessages.fieldRequired") }];
  const mapRules = (type) => {
    switch (type) {
      case "name":
        return commonRule;

      case "surname":
        return commonRule;

      case "phoneNumber":
        return commonRule.concat([
          {
            validator: (_, value) => {
              if (
                !String(value).startsWith("5") ||
                String(value).length !== 10
              ) {
                return Promise.reject(t("ErrorMessages.phoneError"));
              }
              return Promise.resolve();
            },
          },
        ]);

      case "email":
        return commonRule.concat([
          {
            type: "email",
            message: t("ErrorMessages.emailError"),
          },
        ]);
      case "vkn":
        return;
      case "companyName":
        return;
      case "referenceCode":
        return;

      default:
        return commonRule;
    }
  };

  const getMustInputs = () => {
    return axiosInstance
      .get("input/get_company/?is_must_inputs=1")
      .then((res) => {
        const { status } = res.data;
        if (status) {
          const { active_inputs, passive_inputs } = res.data;
          setInputs({
            activeInputs: active_inputs,
            passiveInputs: passive_inputs,
            status: true,
          });
          return res.data;
        } else {
          throw new Error("Bir hata oluştu");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getUserProfile = () => {
    return axiosInstance
      .get("/customer/fetch_profile_info/?format=json")
      .then((res) => {
        const { status } = res.data;
        if (status) {
          const { data } = res.data;
          return data;
        } else {
          throw new Error("Profil bilgileri getirilirken bir problem oluştu");
        }
      })
      .catch((Err) => {
        console.error(Err);
      });
  };

  const handleChangePw = () => {
    const values = passwordInfos;
    // http://api.localhost/settings/save?domain=localhost&variables=[mail_gonder|maliyet_x|maliyet_y|maliyet_z|mw_alan|panel_gucu|kayip_yuzdesi|co2_katsayi|agac_katsayi|komisyon_orani]
    axiosInstance
      .post("change_pw?format=json", {
        new_password: values.newPassword,
        new_password_confirm: values.newPasswordConfirm,
        current_password: values.currentPassword,
      })
      .then((response) => {
        setOpenDialog(false);
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType("success");
          setSnackbarState(true);
          passwordForm.resetFields();
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType("warning");
          setSnackbarState(true);
        }
      })
      .catch(() => {
        // setOpen(false);
        setSnackbarMessage(
          "Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz."
        );
        setSnackbarType("warning");
        setSnackbarState(true);
      });
  };

  React.useEffect(() => {
    Promise.all([getMustInputs(), getUserProfile()])
      .then((res) => {
        const responseOfInputRequest = res[0]; // why index 0? Because it first called in Promise.all array parameter
        const responseOfUserProfile = res[1];
        responseOfInputRequest.active_inputs.forEach((input) => {
          const title = input.title;

          if (
            title === "phoneNumber" &&
            String(responseOfUserProfile[mapContactInfo(title)]).startsWith("0")
          ) {
            const splitterPhone = String(
              responseOfUserProfile[mapContactInfo(title)]
            ).substring(1);

            // while save to from, split phoneNumber if starts with 0
            profileForm.setFieldValue(
              input.title,
              splitterPhone
            );
          } else {
            profileForm.setFieldValue(
              input.title,
              responseOfUserProfile[mapContactInfo(title)]
            );
          }
        });
      })

      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState(false);
  };

  const initialValues = {};
  const [profileForm] = Form.useForm();
  const [passwordForm] = Form.useForm();

  const renderFields = "name surname email phoneNumber".split(" ");

  const onHandleUpdateProfile = (values) => {
    setUpdateProfilePending(true);

    const body = {
      username: values.email,
      isim: values.name,
      soy_isim: values.surname,
      telefon: values.phoneNumber,
    };

    axiosInstance
      .post("/customer/save_profile_info/?format=json", body)
      .then((res) => {
        const { status } = res.data;
        if (!status) {
          throw new Error(res);
        }
        setSnackbarMessage(res.data.message);
        setSnackbarType("success");
        setSnackbarState(true);
      })
      .catch((err) => {
        setSnackbarMessage("Bir problem oluştu");
        setSnackbarType("error");
        setSnackbarState(true);
      })
      .finally(() => {
        setUpdateProfilePending(false);
      });
  };

  if (!loggedIn) {
    return <Navigate to={"/login"} replace />;
  } else if (loading) {
    return <Spinner />;
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("ProfilePage.cardTitle")}</title>
      </Helmet>
      <div className={classes.outerWrapper}>
        <NavbarMain />
        <Container
          maxWidth={"xl"}
          style={{
            height: "100%",
            backgroundColor: "#f4f6f8",
            padding: "12px",
          }}
        >
          <Row align={"stretch"} gutter={16}>
            <Col lg={24} xs={24}>
              <Card>
                <CardHeader
                  subheader={t("ProfilePage.cardSubHeader")}
                  title={t("ProfilePage.cardTitle")}
                />
                <Divider />
                <Form
                  form={profileForm}
                  initialValues={initialValues}
                  onFinish={onHandleUpdateProfile}
                  layout="vertical"
                  style={{
                    padding: "8px",
                  }}
                >
                  <Row gutter={8}>
                    {true &&
                      inputs.activeInputs.map((input, index) => {
                        if (renderFields.includes(input.title)) {
                          return (
                            <Col sm={12} xs={24} key={index}>
                              <Form.Item
                                required={input.is_must}
                                rules={mapRules(input.title)}
                                name={input.title}
                                label={
                                  localStorage.getItem("dil") === "en" ||
                                  String(i18n.language).split("-")[0] === "en"
                                    ? input.name_en
                                    : input.name_tr
                                }
                              >
                                {input.title === "phoneNumber" && (
                                  <PhoneInput
                                    country={
                                      module === "german_solarvis" ? "de" : "tr"
                                    }
                                    onlyCountries={
                                      module !== "german_solarvis" ? ["tr"] : []
                                    }
                                    placeholder={
                                      module === "german_solarvis"
                                        ? t("ProfilePage.phoneLabel")
                                        : "(5xx) xxx xx xx"
                                    }
                                    value={String(profileForm.getFieldValue("phoneNumber"))}
                                    masks={{
                                      tr: "(...) ... .. ..",
                                      de: "... ........",
                                    }}
                                    areaCodes={false}
                                    prefix=""
                                    autoFormat
                                    disableCountryCode
                                    // countryCodeEditable={false}
                                    containerStyle={{
                                      outline: "none",
                                      border: "none",
                                    }}
                                    localization={
                                      module === "german_solarvis" ? de : tr
                                    } // for input lang
                                    inputStyle={{
                                      width: "100%",
                                      paddingTop: "7px",
                                      paddingBottom: "7px",
                                      outline: "none",
                                      borderColor: "lightgray",
                                    }}
                                  />
                                )}
                                {input.title !== "phoneNumber" && (
                                  <Input
                                    autoComplete={input.html_autocomplete}
                                    type={input.type}
                                    size="large"
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </Row>
                  <Form.Item>
                    <MaterialButton
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={updateProfilePending}
                    >
                      {t("ProfilePage.save")}
                    </MaterialButton>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>
          {t("ProfilePage.updatePasswordQuestion")}
        </StyledDialogTitle>
        <DialogContent>
          <p style={{ padding: "8px 0" }}>
            {t("ProfilePage.updatePasswordText")}
          </p>
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={() => setOpenDialog(false)} color="primary">
            {t("ProfilePage.back")}
          </MaterialButton>
          <MaterialButton
            onClick={handleChangePw}
            color="primary"
            variant="contained"
          >
            {t("ProfilePage.update")}
          </MaterialButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarState}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default UserAccountPage;