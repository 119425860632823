/* eslint-disable prefer-template */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Button, withStyles } from '@material-ui/core';
import { Navigate } from "react-router-dom";
import classes from '../UserDocumentUploadPage.module.css';
import UserLoginPage from '../../UserLoginPage/UserLoginPage';
import axiosInstance from '../../../components/axios/axiosApi';
import { changeUploadStepNumber } from '../../../services/products/product/stepReducer';

const StyledButton = withStyles({
    root: {
      textTransform: 'none',
      backgroundColor: 'var(--secondary-color)',
      color: 'white'
    }
  })(Button);

const Start = () => {
    const { t, } = useTranslation();
    const dispatch = useDispatch();

    const { loggedIn } = useSelector(state => state.users);
    const { availableModules } = useSelector(state => state.module);

    const[documentCategoryList, setDocumentCategoryList] = React.useState([]);

    const fetchDocumentList = () => {
        axiosInstance.get('subcon/document/document_category_APIs/end_user_get_document_categories')
          .then((response) => {
            const { status } = response.data;
            if (status) {
              const { data, message } = response.data;
              setDocumentCategoryList(data);
            }
          })
        //   .catch((error) => console.log(error));
      };

    useEffect(() => {
        fetchDocumentList();
    }, [availableModules]);

    if (!loggedIn) {
        return <Navigate to="/login" />;
    }
        

    return (
        <>
            <Grid
                item
                lg={8}
                md={8}
                xl={8}
                xs={12}
            >
                <div className={classes.card}>
                    <div>
                        <h2 style={{fontWeight:'normal', fontSize: '1.5rem'}}>{t('StartUpload.uploadDocumentsTitle')}</h2>
                        <p style={{ margin: '10px 0' }}>{t('StartUpload.uploadDocumentsDescription')}</p>
                        <Box my={2}>
                            <StyledButton
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={() => dispatch(changeUploadStepNumber({uploadStepNumber: 1}))}
                            >
                                {t('StartUpload.startButton')}
                            </StyledButton>
                        </Box>
                    </div>
                </div>
            </Grid>
            <Grid
                item
                lg={4}
                md={4}
                xl={4}
                xs={12}
            >
                <div className={classes.card}>
                    <div>
                        <h4 style={{ marginBottom: '10px' }}>{t('StartUpload.askDocuments')}</h4>
                        <ul style={{ marginLeft: '15px' }}>
                            {documentCategoryList.map((category, index) => (
                                <li key={index}>{category.document_category_name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Grid>
        </>        
  );
};

export default Start;
