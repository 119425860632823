import axiosInstance from "../../components/axios/axiosApi"


// this function for send number verification to backend
// this method will return a verification send promise
export const numberVerificationSend = (values) => {

    const {musteriId,phoneNumber,email} = values;


    // if musteriId is null or phoneNumber is null, it does not send to backend
    if(!musteriId || !phoneNumber) return;

    // if musteriId is not null and phoneNumber is not null, it sends to backend
    return axiosInstance.post(`/initialize_verification`, {
        telefon: phoneNumber,
        musteri_id: musteriId,
        email: email || localStorage.getItem("mail"),
    });
}