/* eslint-disable prefer-template */
import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../i18n';
import NavbarMain from '../../containers/NavbarMainPage/NavbarMain';
import classes from './Arcelik/UserMainPage.module.css';
import UserLoginPage from '../UserLoginPage/UserLoginPage';
import { Helmet } from "react-helmet";
import UserPageFooter from '../../containers/UserPageFooter/UserPageFooter';
import HomeModuleFooter from '../../containers/AlternativeFooter/Footer';
import ArcelikMainPage from './Arcelik/UserMainPage';
import DefaultMainPage from './Default/UserMainPage';
import HomeMainPage from './Home/UserMainPage';

const Wrapper = () => {
    const { module, availableModules } = useSelector(state => state.module);
    const { firmaKodu } = useSelector(state => state.companyInfo);
    const { tesis } = useSelector(state => state.summary);
    const { loggedIn } = useSelector(state => state.users);

    if (!loggedIn) {
        return <UserLoginPage />
    }

    const decideComponent = () => {
        if (module === "arcelikkurumsalcozumler") {
            if (tesis === "Mesken" && availableModules.includes("home")) {
                return <HomeMainPage />
            }
            return <ArcelikMainPage />
        }
        else if (tesis === "Mesken" && availableModules.includes("home")) {
            return <HomeMainPage />
        }
        return <DefaultMainPage />
    }

    const decideFooter = () => {
        if (tesis === "Mesken" && availableModules.includes("home")) {
            return <HomeModuleFooter />
        }
        return <UserPageFooter />
    }

    const decideHelmet = () => {
        if (localStorage.getItem('dil') === 'tr' || String(i18n.language).split('-')[0] === 'tr') {
          if (module === "solarvis") {
              return <Helmet>
              <title>{`solarVis | Güneş Enerjisi Proje Teklifi`}</title>
              </Helmet>
          } else {
            return <Helmet>
            <title>{`${firmaKodu} | Güneş Enerjisi Proje Teklifi`}</title>
            </Helmet>
          }
        } else {
          return <Helmet>
            <title>{`${firmaKodu} | Calculate Your Solar Potential`}</title>
            </Helmet>
        }
      };

    return (
        <>
            {decideHelmet()}
            <NavbarMain />
            <div className={classes.outerWrapper}>
                {decideComponent()}
            </div>
            {decideFooter()}
        </>
    );
};

export default Wrapper;
