/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { ResponsiveBar } from '@nivo/bar';
import classes from './NivoBar.module.css';

function BarGraph(props) {
  const { barData, mB, mT } = props;
  const labelBottom = '';
  const labelLeft = 'Tüketim (kWh)';
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  let generalGraphSettings;
  if (isTabletOrMobile) {
    generalGraphSettings = {
      widthLineGraph: '90%',
      widthBarGraph: '100%',
      tickRotation: -90,
      marginRight: 20,
      marginLeft: 20,
      legendOffsetBottom: 60,
      legendOffsetLeft: -70
    };
  } else {
    generalGraphSettings = {
      widthLineGraph: '70%',
      widthBarGraph: '90%',
      tickRotation: -60,
      marginRight: 50,
      marginLeft: 110,
      legendOffsetBottom: 50,
      legendOffsetLeft: -95
    };
  }

  const lineGraphSettings = {
    theme: {
      background: 'transparent',
      fontFamily: 'Poppins',
      textColor: '#000000',
      color: '#000000',
      legends: {
        text: {
          fontSize: '16px',
          fill: '#000000'
        }
      },
      axis: {
        legend: {
          text: {
            fontSize: '16px',
            color: 'white'
          }
        },
        ticks: {
          text: {
            fontSize: '12px'
          }
        }
      },
    }
  };

  return (
    <div className={classes.ChartContainer} style={{ marginTop: mT, marginBottom: mB }}>
      <div className={classes.ChartShow} style={{ width: generalGraphSettings.widthBarGraph }}>
        <ResponsiveBar
          data={barData}
          keys={['consumption']}
          indexBy="month"
          enableLabel={false}
          margin={{
            top: 10,
            right: generalGraphSettings.marginRight,
            bottom: 80,
            left: 110
          }}
          padding={0.45}
          tooltip={({ value }) => (
            <strong style={{ color: '#000000', fontSize: '14px' }}>
              {`Tüketim: ${value} kWh`}
            </strong>
          )}
          // groupMode="grouped"
          colors={['#ff884b', '#085b06']}
          // borderWidth={3}
          borderColor={{ from: 'color', modifiers: [['darker', '1.7']] }}
          axisTop={null}
          borderRadius={2}
          axisRight={null}
          axisBottom={{
            tickSize: 3,
            tickPadding: 9,
            tickRotation: generalGraphSettings.tickRotation,
            legend: labelBottom,
            legendPosition: 'middle',
            legendOffset: generalGraphSettings.legendOffsetBottom
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: labelLeft,
            legendPosition: 'middle',
            legendOffset: generalGraphSettings.legendOffsetLeft
          }}
          labelSkipWidth={15}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', '1.6']] }}
          /* legends={[
              {
                  dataFrom: 'keys',
                  anchor: 'right',
                  direction: 'column',
                  justify: false,
                  translateX: 135,
                  translateY: 0,
                  itemsSpacing: 11,
                  itemWidth: 103,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemOpacity: 1
                          }
                      }
                  ]
              }
          ]} */
          animate={true}
          theme={lineGraphSettings.theme}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </div>
  );
}

/* const dataXX = [
  {"year": "2021", "Yatırım cebinize dönüyor": -40000},
  {"year": "2022", "Yatırım cebinize dönüyor": -20000},
  {"year": "2023", "Yatırım cebinize dönüyor": 0},
  {"year": "2024", "Muhteşem! Artık kara geçiyorsunuz": 20000},
  {"year": "2025", "Muhteşem! Artık kara geçiyorsunuz": 40000},
  {"year": "2026", "Muhteşem! Artık kara geçiyorsunuz": 60000},
  {"year": "2027", "Yatırım cebinize dönüyor": -40000},
  {"year": "2028", "Yatırım cebinize dönüyor": -20000},
  {"year": "2029", "Yatırım cebinize dönüyor": 0},
  {"year": "2030", "Muhteşem! Artık kara geçiyorsunuz": 20000},
  {"year": "2031", "Muhteşem! Artık kara geçiyorsunuz": 40000},
  {"year": "2032", "Muhteşem! Artık kara geçiyorsunuz": 60000},
  {"year": "2033", "Yatırım cebinize dönüyor": -40000},
  {"year": "2034", "Yatırım cebinize dönüyor": -20000},
  {"year": "2035", "Yatırım cebinize dönüyor": 0},
  {"year": "2036", "Muhteşem! Artık kara geçiyorsunuz": 20000},
  {"year": "2037", "Muhteşem! Artık kara geçiyorsunuz": 40000},
  {"year": "2038", "Muhteşem! Artık kara geçiyorsunuz": 60000},
] */

BarGraph.propTypes = {
  barData: PropTypes.array,
  mB: PropTypes.number,
  mT: PropTypes.number
};

export default BarGraph;
