import React, { useState, useEffect, useRef } from "react";
import ReactGA, { set } from "react-ga";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../components/axios/axiosApi";
import axiosInstanceAdmin from "../../components/axios/axiosApiAdmin";
import classes from "./InvoiceInfoPage.module.css";
import { IconContext } from "react-icons/lib";
import { AiOutlineArrowRight, AiFillHome } from "react-icons/ai";
import { RiBattery2ChargeLine } from "react-icons/ri";
import { MdEvStation, MdApartment } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import {
  changeStep,
  changeQuote,
} from "../../services/products/product/stepReducer";
import RadioButton from "../../components/UI/RadioButton/RadioButton";
import GeneralDialog from "../../components/Dialog/GeneralDialog";
import SunSpinner from "../../components/UI/SunSpinner/SunSpinner";
import {
  changeYillikTasarruf,
  changeFatura,
  changeBatarya,
  changeSarj,
  changeTesis,
  changeMeskenTipi,
  changeSozlesmeGucu,
  changeTrafoGucu,
  changeYaklasikAmortisman,
  changeAmortismanYilAy,
  changeYillikKazanc,
  changeYaklasikMaliyet,
  changeConsumptionProfile,
  changeSelectedDCPower,
  changeOptimalDCPower,
  changeOptimalACPower,
  changeMonthlyConsumptionProfile,
  changeDailyConsumptionProfile,
  changeMonthlyConsumptionInput,
  changeYillikTasarrufEUR,
  changeYillikKazancEUR,
  changeYaklasikMaliyetEUR,
} from "../../services/products/product/summaryReducer";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useMediaQuery } from "react-responsive";
import StepProgress from "../../components/StepProgress";
import MuiAlert from "@material-ui/lab/Alert";
import ConsumptionProfileDialog from "../../components/Dialog/consumptionProfileDialog";

import { useDebounce, useDebouncedCallback } from "use-debounce";

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 600,
  },
})(Dialog);

const useStyles = makeStyles({
  root: {
    width: 250,
  },
  input: {
    width: 95,
    fontFamily: "var(--font-fam)",
  },
  item: {
    width: 200,
  },
  myAlert: {
    color: "#000000",
    backgroundColor: "#ffffff",
  },
});

const ColoredSlider = withStyles({
  root: {
    color: "var(--primary-color)",
    height: 5,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -5,
    marginLeft: -7,
  },
  active: {},
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
  },
})(Slider);

const ColoredCheckbox = withStyles({
  root: {
    color: "var(--primary-color)",
    "&$checked": {
      color: "var(--primary-color)",
    },
  },
  checked: {},
})(Checkbox);

function InvoiceInfoPage(props) {
  const { t } = useTranslation();
  const { usedFor } = props;

  let timeoutId;

  const debounceInput = (sendRequest) => {
    // Önceki zamanlayıcıyı temizle (eğer varsa)
    clearTimeout(timeoutId);

    // Yeni bir zamanlayıcı başlat
    timeoutId = setTimeout(() => {
      sendRequest();
    }, 1500); // 1.5 saniyelik gecikme süresi
  };

  const MUIClasses = useStyles();

  // ------------ DETERMINE MOBILE OR DESKTOP ---------- //

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletLandscape = useMediaQuery({
    minWidth: 600,
    maxWidth: 1100,
    orientation: "landscape",
  });

  // --------------------------------------------------- //

  const dispatch = useDispatch();
  const { userId, userStatus } = useSelector((state) => state.users);
  const {
    roofArea,
    tempLatitude,
    tempLongitude,
    drawingPointsNew,
    alanTercihi,
  } = useSelector((state) => state.location);
  const {
    roofTilt,
    batarya,
    sarj,
    tesis,
    meskenTipi,
    fatura,
    sGucu,
    trafoGucu,
    yillikTasarruf,
    yillikTasarrufEUR,
    mail,
    yaklasikAmortisman,
    cephe,
    consumptionProfile,
    selectedDCPower,
    monthlyConsumptionProfile,
    dailyConsumptionProfile,
    monthlyConsumptionInput,
  } = useSelector((state) => state.summary);

  const { module, availableModules } = useSelector((state) => state.module);

  const { maxFatura, avgFatura, faturaSgucuOrani } = useSelector(
    (state) => state.constants
  );

  const [feasibilityInfoBoxOpen, setFeasibilityInfoBoxOpen] = useState(false);

  const handleCloseFeasbilityInfoBox = () => {
    setFeasibilityInfoBoxOpen(false);
  };

  const [loading, setLoading] = useState(false);

  const [tempFatura, setTempFatura] = useState(fatura);
  const [tempMonthlyConsumptionInput, setTempMonthlyConsumptionInput] =
    useState(monthlyConsumptionInput);

  //const [tempTesis, setTempTesis] = useState(tesis);
  const [tempSgucu, setTempSgucu] = useState(sGucu);
  const [tempTrafoGucu, setTempTrafoGucu] = useState(trafoGucu);
  const [isSummaryDisabled, setIsSummaryDisabled] = useState(false);

  const [faturaValue] = useDebounce(tempFatura, 750);
  const [sGucuValue] = useDebounce(tempSgucu, 750);

  const checkApartmentRef = useRef(null);
  const prevTesis = useRef(tesis);
  const prevFatura = useRef(fatura);
  const prevProfile = useRef(consumptionProfile);

  const [customProfileDialogOpen, setCustomProfileDialogOpen] = useState(false);

  let usedAxiosInstance = axiosInstance;
  if (usedFor === "admin") {
    usedAxiosInstance = axiosInstanceAdmin;
  }

  const willMount = useRef(true);
  if (willMount.current) {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/fatura_bilgileri" });
    willMount.current = false;
  }

  const [apartmanChecked, setApartmanChecked] = useState(
    JSON.parse(localStorage.getItem("apartmanChecked")) || false
  );

  const [apartmanShowError, setApartmanShowError] = useState(false);

  const handleChangeApartmanCheck = (event) => {
    if (event.target.checked === true) {
      setApartmanShowError(false);
    }
    setApartmanChecked(event.target.checked);
    localStorage.setItem(
      "apartmanChecked",
      JSON.stringify(event.target.checked)
    );
  };

  useEffect(() => {
    let didCancel = false;

    async function axiosGet() {
      setLoading(true);
      let sozlesmeGucuTemp;
      if (tesis === "Mesken") {
        sozlesmeGucuTemp = "10";
      } else {
        sozlesmeGucuTemp = sGucu;
      }

      let requestURL = `fizibilite?tutar=${fatura}`;
      if (module === "german_solarvis") {
        requestURL = `deutsche/feasibility?&tuketim=${monthlyConsumptionInput}`;
      }
      if (Number(selectedDCPower) === 0 || prevTesis.current !== tesis) {
        requestURL += `&enlem=${String(
          tempLatitude.toFixed(4)
        )}&boylam=${String(
          tempLongitude.toFixed(4)
        )}&tesis=${tesis}&sgucu=${sozlesmeGucuTemp}&consumption_profile=${consumptionProfile}&format=json`;
      } else {
        requestURL += `&enlem=${String(
          tempLatitude.toFixed(4)
        )}&boylam=${String(
          tempLongitude.toFixed(4)
        )}&tesis=${tesis}&sgucu=${sozlesmeGucuTemp}&consumption_profile=${consumptionProfile}&input_dc_guc=${selectedDCPower}&format=json`;
      }

      if (
        prevTesis.current === tesis &&
        prevFatura.current === fatura &&
        consumptionProfile === "custom"
      ) {
        // console.log('monthlyConsumptionProfile:', monthlyConsumptionProfile);
        if (
          !monthlyConsumptionProfile.every(
            (value) => value.consumption === 0
          ) &&
          monthlyConsumptionProfile.length > 0
        ) {
          // Make this list of objects to string with splitted comma that only includes consumption values
          let tempMonthlyConsumptionProfile = "";
          monthlyConsumptionProfile.map(
            (value) =>
              (tempMonthlyConsumptionProfile += `${String(value.consumption)},`)
          );
          tempMonthlyConsumptionProfile = tempMonthlyConsumptionProfile.slice(
            0,
            -1
          );
          requestURL += `&monthly_manual_consumptions=${tempMonthlyConsumptionProfile}`;
        }
        if (
          !dailyConsumptionProfile.every((value) => value.consumption === 0) &&
          dailyConsumptionProfile.length > 0
        ) {
          // Make this list of objects to string with splitted comma that only includes consumption values
          let tempDailyConsumptionProfile = "";
          dailyConsumptionProfile.map(
            (value) =>
              (tempDailyConsumptionProfile += `${String(value.consumption)},`)
          );
          tempDailyConsumptionProfile = tempDailyConsumptionProfile.slice(
            0,
            -1
          );
          requestURL += `&daily_consumption_percentages=${tempDailyConsumptionProfile}`;
        }
      }

      if (drawingPointsNew.length > 0 && alanTercihi === "cizim") {
        let tempDrawingPoints = "";
        drawingPointsNew.map(
          (value) =>
            (tempDrawingPoints += `${value.area},${value.slope},${value.aspect}|`)
        );
        tempDrawingPoints = tempDrawingPoints.slice(0, -1);
        requestURL += `&drawing_areas=${tempDrawingPoints}`;
      } else {
        requestURL += `&drawing_areas=${roofArea},${roofTilt},${cephe}`;
      }

      usedAxiosInstance
        .get(requestURL)
        .then((response) => {
          const data = response.data.data;
          if (data["aylik_tasarruf"]) {
            dispatch(
              changeYillikTasarruf({
                yillikTasarruf: data["yillik_ort_tl_tasrrf"],
              })
            );
            dispatch(
              changeYillikTasarrufEUR({
                yillikTasarrufEUR: data["yillik_ort_euro_tasrrf"],
              })
            );
            if (data["roi"] === "20+") {
              dispatch(changeAmortismanYilAy({ amortismanYilAy: ["20+", ""] }));
              dispatch(changeYaklasikAmortisman({ yaklasikAmortisman: "20+" }));
            } else {
              dispatch(
                changeAmortismanYilAy({
                  amortismanYilAy: data["roi_as_years_months"],
                })
              );
              dispatch(
                changeYaklasikAmortisman({
                  yaklasikAmortisman: data["approx_roi"],
                })
              );
            }
            dispatch(
              changeYaklasikMaliyet({
                yaklasikMaliyet: data["approx_tl_maliyet"],
              })
            );
            dispatch(
              changeYaklasikMaliyetEUR({
                yaklasikMaliyetEUR: data["approx_euro_maliyet"],
              })
            );
            dispatch(
              changeYillikKazanc({
                yillikKazanc: data["arcelik_ozel_content"]["toplam_kazanc"],
              })
            );
            dispatch(
              changeYillikKazancEUR({
                yillikKazancEUR: data["arcelik_ozel_content"]["toplam_kazanc"],
              })
            );

            if (data.hasOwnProperty("optimal_dc_guc")) {
              dispatch(
                changeOptimalDCPower({ optimalDCPower: data["optimal_dc_guc"] })
              );
              dispatch(
                changeOptimalACPower({ optimalACPower: data["optimal_ac_guc"] })
              );

              if (prevTesis.current !== tesis) {
                dispatch(
                  changeSelectedDCPower({
                    selectedDCPower: data["optimal_dc_guc"],
                  })
                );
              }
            }

            if (data.hasOwnProperty("monthly_consumptipon_with_mon_names")) {
              dispatch(
                changeMonthlyConsumptionProfile({
                  monthlyConsumptionProfile:
                    data["monthly_consumptipon_with_mon_names"],
                })
              );
            }

            if (data.hasOwnProperty("percentage_consumption_in_one_day")) {
              dispatch(
                changeDailyConsumptionProfile({
                  dailyConsumptionProfile:
                    data["percentage_consumption_in_one_day"],
                })
              );
            }
          }
          prevTesis.current = tesis;
          prevFatura.current = fatura;
          setLoading(false);
          return data["kdvli_tl_maliyet"];
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
        });

      if (!didCancel) {
        // Ignore if we started fetching something else
        //console.log(response);
      }
    }

    axiosGet();

    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, [
    tesis,
    faturaValue, // fatura debounce değeri
    sGucuValue, // sGucu debounce değeri
    consumptionProfile,
    customProfileDialogOpen,
    tempMonthlyConsumptionInput,
  ]);

  const handleChangeStep = (newValue) => {
    if (newValue === 4) {
      if (
        tesis === "Mesken" &&
        meskenTipi === "Apartman" &&
        apartmanChecked === false
      ) {
        checkApartmentRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setApartmanShowError(true);
        return;
      }
    }

    if (newValue === 2) {
      if (isTabletOrMobile || alanTercihi === "manuel") {
        dispatch(changeStep({ stepNumber: newValue }));
      } else {
        dispatch(changeStep({ stepNumber: 1 }));
      }
    } else {
      dispatch(changeStep({ stepNumber: newValue }));
    }

    if (Boolean(userId) && Boolean(mail)) {
      dispatch(changeQuote({ quoteSend: true }));
    }
  };

  const handleChangeConsumptionProfile = (newValue) => {
    if (newValue === "custom") {
      setCustomProfileDialogOpen(true);
    } else {
      dispatch(changeConsumptionProfile({ consumptionProfile: newValue }));
    }
  };

  const selectionChange = (event) => {
    dispatch(changeTesis({ tesis: event.target.value }));
    if (event.target.value === "Mesken") {
      dispatch(changeSozlesmeGucu({ sGucu: 10 }));
      dispatch(changeFatura({ fatura: parseInt(avgFatura.Mesken) }));
      dispatch(changeConsumptionProfile({ consumptionProfile: "family" }));
      if (module === "german_solarvis") {
        dispatch(
          changeMonthlyConsumptionInput({ monthlyConsumptionInput: 250 })
        );
      }
    } else if (event.target.value === "Ticarethane") {
      dispatch(
        changeSozlesmeGucu({
          sGucu: parseInt(avgFatura.Ticarethane / faturaSgucuOrani),
        })
      );
      dispatch(changeFatura({ fatura: parseInt(avgFatura.Ticarethane) }));
      dispatch(changeConsumptionProfile({ consumptionProfile: "constant" }));
      dispatch(
        changeTrafoGucu({
          trafoGucu: Math.round(
            parseInt(avgFatura.Ticarethane / faturaSgucuOrani) * (5 / 3)
          ),
        })
      );
      if (module === "german_solarvis") {
        dispatch(
          changeMonthlyConsumptionInput({ monthlyConsumptionInput: 1200 })
        );
      }
    } else if (event.target.value === "Sanayi") {
      dispatch(
        changeSozlesmeGucu({
          sGucu: parseInt(avgFatura.Sanayi / faturaSgucuOrani),
        })
      );
      dispatch(changeFatura({ fatura: parseInt(avgFatura.Sanayi) }));
      dispatch(changeConsumptionProfile({ consumptionProfile: "constant" }));
      dispatch(
        changeTrafoGucu({
          trafoGucu: Math.round(
            parseInt(avgFatura.Sanayi / faturaSgucuOrani) * (5 / 3)
          ),
        })
      );
    } else if (event.target.value === "Tarımsal Sulama") {
      dispatch(
        changeSozlesmeGucu({
          sGucu: parseInt(avgFatura.TarımsalSulama / faturaSgucuOrani),
        })
      );
      dispatch(
        changeTrafoGucu({
          trafoGucu: Math.round(
            parseInt(avgFatura.TarımsalSulama / faturaSgucuOrani) * (5 / 3)
          ),
        })
      );
      dispatch(changeFatura({ fatura: parseInt(avgFatura.TarımsalSulama) }));
      dispatch(changeConsumptionProfile({ consumptionProfile: "constant" }));
    }
  };

  const handleChange = (event, newValue) => {
    const value = event.target.value || newValue;
    dispatch(changeFatura({ fatura: newValue || parseInt(value) }));

    if (!value || parseInt(value) < 100) {
      setIsSummaryDisabled(true);
      return;
    }
    setIsSummaryDisabled(false);

    // manuel input değiştiğinde tekrar istek atması için ekledim bu kısmı
    if (
      (typeof value !== "undefined" || !isNaN(value)) &&
      parseInt(value) &&
      value >= 100
    ) {
      setTempFatura(newValue || parseInt(value));
    }

    if (["Ticarethane", "Sanayi", "Tarımsal Sulama"].includes(tesis)) {
      dispatch(
        changeSozlesmeGucu({
          sGucu: parseInt(
            (newValue || parseInt(event.target.value)) / faturaSgucuOrani
          ),
        })
      );
      dispatch(
        changeTrafoGucu({
          trafoGucu: Math.round(
            parseInt(
              (newValue || parseInt(event.target.value)) / faturaSgucuOrani
            ) *
            (5 / 3)
          ),
        })
      );
    }
  };
  const handleChangeMonthlyConsumption = (event, newValue) => {
    dispatch(
      changeMonthlyConsumptionInput({
        monthlyConsumptionInput: newValue || parseInt(event.target.value),
      })
    );

    // manuel input değiştiğinde tekrar istek atması için ekledim bu kısmı
    if (parseInt(event.target.value)) {
      setTempMonthlyConsumptionInput(newValue || parseInt(event.target.value));
    }
  };

  const handleChangeDragEndMonthlyConsumption = (event, newValue) => {
    setTempMonthlyConsumptionInput(newValue || parseInt(event.target.value));
  };

  const handleChangeInputSozlesmeGucu = (event, newValue) => {
    if (String(event.target.value).length === 0 || isNaN(event.target.value)) {
      setIsSummaryDisabled(true);
      // return;
    } else {
      setIsSummaryDisabled(false);
    }
    dispatch(
      changeSozlesmeGucu({ sGucu: newValue || parseInt(event.target.value) })
    );
    dispatch(
      changeTrafoGucu({
        trafoGucu: Math.round(
          (newValue || parseInt(event.target.value)) * (5 / 3)
        ),
      })
    );

    // manuel input değiştiğinde tekrar istek atması için ekledim bu kısmı
    if (parseInt(event.target.value)) {
      setTempSgucu(newValue || parseInt(event.target.value));
    }
  };

  const handleChangeInputSozlesmeGucuDrag = (event, newValue) => {
    if (String(newValue).length === 0 || isNaN(newValue)) {
      setIsSummaryDisabled(true);
    } else {
      setIsSummaryDisabled(false);
    }
    setTempSgucu(newValue || parseInt(newValue));
  };

  const handleChangeInputTrafoGucu = (event, newValue) => {
    dispatch(
      changeTrafoGucu({ trafoGucu: newValue || parseInt(event.target.value) })
    );

    // manuel input değiştiğinde tekrar istek atması için ekledim bu kısmı
    if (parseInt(event.target.value)) {
      setTempTrafoGucu(newValue || parseInt(event.target.value));
    }
  };

  const handleChangeInputTrafoGucuDrag = (event, newValue) => {
    setTempTrafoGucu(newValue || parseInt(event.target.value));
  };

  const handleBatteryChange = (event) => {
    dispatch(changeBatarya({ batarya: event.target.checked }));
  };

  const handleChargerChange = (event) => {
    dispatch(changeSarj({ sarj: event.target.checked }));
  };

  const handleMeskenTipiChange = (event) => {
    dispatch(changeMeskenTipi({ meskenTipi: event.target.value }));
  };

  let optionsFatura;
  let optionsSozlesme;
  let optionsTrafoGucu;

  if (tesis === "Mesken") {
    optionsFatura = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={fatura}
            onChange={handleChange}
            aria-labelledby="input-slider"
            step={20}
            min={100}
            max={maxFatura.Mesken}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={fatura}
            margin="dense"
            onChange={handleChange}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">₺</InputAdornment>}
            error={fatura < 100}
            inputProps={{
              step: 20,
              min: 100,
              max: maxFatura.Mesken,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
        {(fatura < 100 || !fatura) && (
          <Alert severity="error" className={MUIClasses.myAlert}>
            {t("InvoiceInfoPage.minFaturaError")}
          </Alert>
        )}
      </>
    );
    // German solarvis gelirse fatura değil tüketim inputu girilen alan gelmesi gerekiyor.
    if (module === "german_solarvis") {
      optionsFatura = (
        <>
          <Grid item xs>
            <ColoredSlider
              value={monthlyConsumptionInput}
              onChange={handleChangeMonthlyConsumption}
              onChangeCommitted={handleChangeDragEndMonthlyConsumption}
              aria-labelledby="input-slider"
              step={20}
              min={100}
              max={1500}
            />
          </Grid>
          <Grid item>
            <Input
              className={MUIClasses.input}
              value={monthlyConsumptionInput}
              margin="dense"
              onChange={handleChangeMonthlyConsumption}
              //onBlur={handleBlur}
              endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
              inputProps={{
                step: 20,
                min: 100,
                max: 1500,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid>
        </>
      );
    }
  } else if (tesis === "Ticarethane") {
    optionsFatura = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={fatura}
            onChange={handleChange}
            aria-labelledby="input-slider"
            step={1000}
            min={100}
            max={maxFatura.Ticarethane}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={fatura}
            margin="dense"
            onChange={handleChange}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">₺</InputAdornment>}
            error={fatura < 100}
            inputProps={{
              step: 500,
              min: 100,
              max: maxFatura.Ticarethane,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
        {(fatura < 100 || !fatura) && (
          <Alert severity="error" className={MUIClasses.myAlert}>
            {t("InvoiceInfoPage.minFaturaError")}
          </Alert>
        )}
      </>
    );

    // German solarvis gelirse fatura değil tüketim inputu girilen alan gelmesi gerekiyor.
    if (module === "german_solarvis") {
      optionsFatura = (
        <>
          <Grid item xs>
            <ColoredSlider
              value={monthlyConsumptionInput}
              onChange={handleChangeMonthlyConsumption}
              onChangeCommitted={handleChangeDragEndMonthlyConsumption}
              aria-labelledby="input-slider"
              step={100}
              min={50}
              max={10000}
            />
          </Grid>
          <Grid item>
            <Input
              className={MUIClasses.input}
              value={monthlyConsumptionInput}
              margin="dense"
              onChange={handleChangeMonthlyConsumption}
              //onBlur={handleBlur}
              endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
              inputProps={{
                step: 100,
                min: 50,
                max: 10000,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid>
        </>
      );
    }

    optionsSozlesme = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={sGucu}
            onChange={handleChangeInputSozlesmeGucu}
            onChangeCommitted={handleChangeInputSozlesmeGucuDrag}
            aria-labelledby="input-slider"
            step={50}
            min={50}
            max={parseInt(maxFatura.Ticarethane / faturaSgucuOrani)}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={sGucu}
            margin="dense"
            onChange={handleChangeInputSozlesmeGucu}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">kWe</InputAdornment>}
            inputProps={{
              step: 50,
              min: 10,
              max: parseInt(maxFatura.Ticarethane / faturaSgucuOrani),
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </>
    );

    optionsTrafoGucu = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={trafoGucu}
            onChange={handleChangeInputTrafoGucu}
            onChangeCommitted={handleChangeInputTrafoGucuDrag}
            aria-labelledby="input-slider"
            step={50}
            min={50}
            max={Math.round(
              parseInt(maxFatura.Ticarethane / faturaSgucuOrani) * (5 / 3)
            )}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={trafoGucu}
            margin="dense"
            onChange={handleChangeInputTrafoGucu}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">kVA</InputAdornment>}
            inputProps={{
              step: 50,
              min: 50,
              max: Math.round(
                parseInt(maxFatura.Ticarethane / faturaSgucuOrani) * (5 / 3)
              ),
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </>
    );
  } else if (tesis === "Sanayi") {
    optionsFatura = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={fatura}
            onChange={handleChange}
            aria-labelledby="input-slider"
            step={5000}
            min={100}
            max={maxFatura.Sanayi}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={fatura}
            margin="dense"
            onChange={handleChange}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">₺</InputAdornment>}
            error={fatura < 100}
            inputProps={{
              step: 1000,
              min: 100,
              max: maxFatura.Sanayi,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
        {(fatura < 100 || !fatura) && (
          <Alert severity="error" className={MUIClasses.myAlert}>
            {t("InvoiceInfoPage.minFaturaError")}
          </Alert>
        )}
      </>
    );

    optionsSozlesme = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={sGucu}
            onChange={handleChangeInputSozlesmeGucu}
            onChangeCommitted={handleChangeInputSozlesmeGucuDrag}
            aria-labelledby="input-slider"
            step={100}
            min={100}
            max={parseInt(maxFatura.Sanayi / faturaSgucuOrani)}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={sGucu}
            margin="dense"
            onChange={handleChangeInputSozlesmeGucu}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">kWe</InputAdornment>}
            inputProps={{
              step: 50,
              min: 0,
              max: parseInt(maxFatura.Sanayi / faturaSgucuOrani),
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </>
    );

    optionsTrafoGucu = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={trafoGucu}
            onChange={handleChangeInputTrafoGucu}
            onChangeCommitted={handleChangeInputTrafoGucuDrag}
            aria-labelledby="input-slider"
            step={50}
            min={50}
            max={Math.round(
              parseInt(maxFatura.Sanayi / faturaSgucuOrani) * (5 / 3)
            )}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={trafoGucu}
            margin="dense"
            onChange={handleChangeInputTrafoGucu}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">kVA</InputAdornment>}
            inputProps={{
              step: 50,
              min: 50,
              max: Math.round(
                parseInt(maxFatura.Sanayi / faturaSgucuOrani) * (5 / 3)
              ),
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </>
    );
  } else if (tesis === "Tarımsal Sulama") {
    optionsFatura = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={fatura}
            onChange={handleChange}
            aria-labelledby="input-slider"
            step={500}
            min={100}
            max={maxFatura.TarımsalSulama}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={fatura}
            margin="dense"
            onChange={handleChange}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">₺</InputAdornment>}
            error={fatura < 100}
            inputProps={{
              step: 500,
              min: 100,
              max: maxFatura.TarımsalSulama,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
        {(fatura < 100 || !fatura) && (
          <Alert severity="error" className={MUIClasses.myAlert}>
            {t("InvoiceInfoPage.minFaturaError")}
          </Alert>
        )}
      </>
    );

    optionsSozlesme = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={sGucu}
            onChange={handleChangeInputSozlesmeGucu}
            onChangeCommitted={handleChangeInputSozlesmeGucuDrag}
            aria-labelledby="input-slider"
            step={25}
            min={25}
            max={parseInt(maxFatura.TarımsalSulama / faturaSgucuOrani)}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={sGucu}
            margin="dense"
            onChange={handleChangeInputSozlesmeGucu}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">kWe</InputAdornment>}
            inputProps={{
              step: 25,
              min: 0,
              max: parseInt(maxFatura.TarımsalSulama / faturaSgucuOrani),
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </>
    );

    optionsTrafoGucu = (
      <>
        <Grid item xs>
          <ColoredSlider
            value={trafoGucu}
            onChange={handleChangeInputTrafoGucu}
            onChangeCommitted={handleChangeInputTrafoGucuDrag}
            aria-labelledby="input-slider"
            step={50}
            min={50}
            max={Math.round(
              parseInt(maxFatura.TarımsalSulama / faturaSgucuOrani) * (5 / 3)
            )}
          />
        </Grid>
        <Grid item>
          <Input
            className={MUIClasses.input}
            value={trafoGucu}
            margin="dense"
            onChange={handleChangeInputTrafoGucu}
            //onBlur={handleBlur}
            endAdornment={<InputAdornment position="end">kVA</InputAdornment>}
            inputProps={{
              step: 50,
              min: 50,
              max: Math.round(
                parseInt(maxFatura.TarımsalSulama / faturaSgucuOrani) * (5 / 3)
              ),
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </>
    );
  }

  const generateImageArcelik = () => {
    if (isTabletOrMobile) {
      return (
        <>
          {tesis === "Mesken" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/arcelik-ev-mobile.jpeg"
              alt="arcelik ev mobile"
            />
          )}
          {tesis === "Sanayi" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/arcelik-sanayi-mobile.jpeg"
              alt="arcelik sanayi mobile"
            />
          )}
          {tesis === "Ticarethane" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/arcelik-ticarethane-mobile.jpeg"
              alt="arcelik ticarethane mobile"
            />
          )}
          {tesis === "Tarımsal Sulama" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/arcelik-tarimsal-mobile.jpeg"
              alt="arcelik tarimsal mobile"
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {tesis === "Mesken" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/arcelik-ev-desktop.jpeg"
              alt="arcelik ev desktop"
            />
          )}
          {tesis === "Sanayi" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/arcelik-sanayi-desktop.jpeg"
              alt="arcelik sanayi desktop"
            />
          )}
          {tesis === "Ticarethane" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/arcelik-ticarethane-desktop.jpeg"
              alt="arcelik ticarethane desktop"
            />
          )}
          {tesis === "Tarımsal Sulama" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/arcelik/arcelik-tarimsal-desktop.jpeg"
              alt="arcelik tarimsal desktop"
            />
          )}
        </>
      );
    }
  };

  const generateImageAydemPlus = () => {
    if (isTabletOrMobile) {
      return (
        <>
          {tesis === "Mesken" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_Mesken_mobile.png"
              alt="aydemplus mesken mobile"
            />
          )}
          {tesis === "Sanayi" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_Sanayi_mobile.png"
              alt="aydemplus sanayi mobile"
            />
          )}
          {tesis === "Ticarethane" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_Ticarethane_mobile.png"
              alt="aydemplus ticarethane mobile"
            />
          )}
          {tesis === "Tarımsal Sulama" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_TarimsalSulama_mobile.png"
              alt="aydemplus tarimsal mobile"
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {tesis === "Mesken" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_Mesken_desktop.png"
              alt="aydemplus mesken desktop"
            />
          )}
          {tesis === "Sanayi" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_Sanayi_desktop.png"
              alt="aydemplus sanayi desktop"
            />
          )}
          {tesis === "Ticarethane" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_Ticarethane_desktop.png"
              alt="aydemplus ticarethane desktop"
            />
          )}
          {tesis === "Tarımsal Sulama" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/aydem/aydemplus_TarimsalSulama_desktop.png"
              alt="aydemplus tarimsal desktop"
            />
          )}
        </>
      );
    }
  };

  const generateImageIstanbulGES = () => {
    if (roofTilt === "0") {
      return (
        <img
          style={{ width: "100%", objectFit: "cover" }}
          src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/istanbulges/istanbulges-duz.jpeg"
          alt="duz cati"
        />
      );
    } else if (Number(roofTilt) < 15) {
      return (
        <img
          style={{ width: "100%", objectFit: "cover" }}
          src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/istanbulges/istanbulges-azegim.jpeg"
          alt="az egimli cati"
        />
      );
    } else if (Number(roofTilt) >= 15) {
      return (
        <img
          style={{ width: "100%", objectFit: "cover" }}
          src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/istanbulges/istanbulges-egimli.jpeg"
          alt="egimli cati"
        />
      );
    }
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "popover1" : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "popover2" : undefined;

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? "popover3" : undefined;

  const tesisTipiAciklama = (
    <>
      <IconButton
        aria-describedby={id1}
        aria-label={id1}
        onClick={handleClick1}
        style={{ marginLeft: "10px" }}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <div
          style={{
            padding: "10px",
            maxWidth: "300px",
          }}
        >
          <span style={{ fontSize: "0.8rem" }}>
            {t("InvoiceInfoPage.tesisTipiDesc")}
          </span>
        </div>
      </Popover>
    </>
  );

  const consumptionProfileDescription = (
    <>
      <IconButton
        aria-describedby={id2}
        aria-label={id2}
        onClick={handleClick2}
        style={{ marginLeft: "10px" }}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <div
          style={{
            padding: "10px",
            maxWidth: "300px",
          }}
        >
          <span style={{ fontSize: "0.8rem" }}>
            {t("InvoiceInfoPage.consProfileDesc")}
            <br />

            <strong>{t("InvoiceInfoPage.constant")}: </strong>
            {t("InvoiceInfoPage.constantDesc")}
            <br />
            <strong>{t("InvoiceInfoPage.weekdays")}: </strong>
            {t("InvoiceInfoPage.weekdaysDesc")}
            <br />
            <strong>{t("InvoiceInfoPage.shoppingHours")}: </strong>
            {t("InvoiceInfoPage.shoppingHoursDesc")}
            <br />
            <strong>{t("InvoiceInfoPage.night")}: </strong>
            {t("InvoiceInfoPage.nightDesc")}
          </span>
        </div>
      </Popover>
    </>
  );

  return (
    <>
      {loading && <SunSpinner isDialog={true} />}
      {module === "arcelikkurumsalcozumler" && (
        <div className={classes.leftWrapper}>{generateImageArcelik()}</div>
      )}
      {module === "istanbulges" && (
        <div className={classes.leftWrapper}>
          {tesis === "Mesken" && generateImageIstanbulGES()}
          {tesis === "Sanayi" && (
            <img
              style={{ width: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/istanbulges/istanbulges-sanayi.jpeg"
              alt="sanayi görseli"
            />
          )}
          {tesis === "Ticarethane" && (
            <img
              style={{ width: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/istanbulges/istanbulges-ticarethane.jpeg"
              alt="ticarethane görseli"
            />
          )}
          {tesis === "Tarımsal Sulama" && (
            <img
              style={{ width: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/istanbulges/istanbulges-tarimsal.jpeg"
              alt="tarımsal sulama görseli"
            />
          )}
        </div>
      )}
      {module === "isbank" && (
        <div className={classes.leftWrapper}>
          {tesis === "Mesken" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/istanbulges/istanbulges-ev.jpeg"
              alt="isbank ev görseli"
            />
          )}
          {tesis === "Sanayi" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/isbank/isb-sanayi.jpeg"
              alt="isbank sanayi görseli"
            />
          )}
          {tesis === "Ticarethane" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/isbank/isb-ticarethane.jpeg"
              alt="isbank ticarethane görseli"
            />
          )}
          {tesis === "Tarımsal Sulama" && (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/isbank/isb-tarimsal.jpeg"
              alt="isbank tarımsal sulama görseli"
            />
          )}
        </div>
      )}
      {module === "aydemplus" && (
        <div className={classes.leftWrapper}>{generateImageAydemPlus()}</div>
      )}
      {module !== "istanbulges" &&
        module !== "arcelikkurumsalcozumler" &&
        module !== "isbank" &&
        module !== "aydemplus" && (
          <div className={classes.leftWrapper}>
            {tesis === "Mesken" && (
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/istanbulges/istanbulges-ev.jpeg"
                alt="ev gorseli"
              />
            )}
            {tesis === "Sanayi" && (
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/solarroof_sanayi.png"
                alt="sanayi gorseli"
              />
            )}
            {tesis === "Ticarethane" && (
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/solarroof_ticarethane.png"
                alt="ticarethane gorseli"
              />
            )}
            {tesis === "Tarımsal Sulama" && (
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/general/tarimsal_sulama.jpeg"
                alt="tarımsal sulama gorseli"
              />
            )}
          </div>
        )}
      <div className={classes.invoicePage}>
        <div className={classes.rightWrapper}>
          <div className={classes.rightWrapperlv1}>
            <div className={classes.rightWrapperlv2}>
              <div className={classes.title}>
                <h2 className={classes.titleh2}>
                  {t("InvoiceInfoPage.title")}
                </h2>
                {Number(userStatus) < 4 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span className={classes.adresGir}>
                      {t("InvoiceInfoPage.tesisSoru")}
                    </span>
                    {tesisTipiAciklama}
                  </div>
                )}
              </div>
              {!(
                (availableModules.length === 2 &&
                  availableModules.includes("home") &&
                  availableModules.includes("subcon")) ||
                Number(userStatus) > 3
              ) && (
                  <div className={classes.radioWrapper}>
                    <div className={classes.facilityChoice}>
                      {!["akbank", "isbank", "aksasolar"].includes(
                        module
                      ) && (
                          <label
                            className={
                              tesis === "Mesken"
                                ? classes.rooflabelActive
                                : classes.roofLabel
                            }
                          >
                            <span className={classes.spanRoofLabel}>
                              {t("InvoiceInfoPage.mesken")}
                            </span>
                            <input
                              type="radio"
                              id="facility_type1"
                              name="facility_type"
                              value="Mesken"
                              className={classes.radioRoof}
                              checked={tesis === "Mesken"}
                              onChange={selectionChange}
                              disabled={Number(userStatus) > 3}
                            ></input>
                          </label>
                        )}
                      <label
                        className={
                          tesis === "Ticarethane"
                            ? classes.rooflabelActive
                            : classes.roofLabel
                        }
                      >
                        <span className={classes.spanRoofLabel}>
                          {t("InvoiceInfoPage.ticarethane")}
                        </span>
                        <input
                          type="radio"
                          id="facility_type2"
                          name="facility_type"
                          value="Ticarethane"
                          className={classes.radioRoof}
                          checked={tesis === "Ticarethane"}
                          onChange={selectionChange}
                          disabled={Number(userStatus) > 3}
                        ></input>
                      </label>
                      {module !== "german_solarvis" && (
                        <label
                          className={
                            tesis === "Sanayi"
                              ? classes.rooflabelActive
                              : classes.roofLabel
                          }
                        >
                          <span className={classes.spanRoofLabel}>
                            {t("InvoiceInfoPage.sanayi")}
                          </span>
                          <input
                            type="radio"
                            id="facility_type3"
                            name="facility_type"
                            value="Sanayi"
                            className={classes.radioRoof}
                            checked={tesis === "Sanayi"}
                            onChange={selectionChange}
                            disabled={Number(userStatus) > 3}
                          ></input>
                        </label>
                      )}
                      {module !== "german_solarvis" && (
                        <label
                          className={
                            tesis === "Tarımsal Sulama"
                              ? classes.rooflabelActive
                              : classes.roofLabel
                          }
                        >
                          <span className={classes.spanRoofLabel}>
                            {t("InvoiceInfoPage.tarimsal")}
                          </span>
                          <input
                            type="radio"
                            id="facility_type4"
                            name="facility_type"
                            value="Tarımsal Sulama"
                            className={classes.radioRoof}
                            checked={tesis === "Tarımsal Sulama"}
                            onChange={selectionChange}
                            disabled={Number(userStatus) > 3}
                          ></input>
                        </label>
                      )}
                    </div>
                  </div>
                )}
              {!(
                (availableModules.length === 2 &&
                  availableModules.includes("home") &&
                  availableModules.includes("subcon")) ||
                Number(userStatus) > 3
              ) && <Divider variant="middle" />}
              {tesis === "Mesken" && Number(userStatus) < 4 && (
                <div
                  className={classes.optionsWrapper}
                  style={{
                    marginBottom: meskenTipi === "Müstakil" ? "1rem" : "0rem",
                    marginTop: "1rem",
                  }}
                >
                  <div className={classes.checkWrapper}>
                    <label
                      className={
                        meskenTipi === "Müstakil"
                          ? classes.rooflabelActive
                          : classes.roofLabel
                      }
                    >
                      <div className={classes.extraIcon}>
                        <IconContext.Provider
                          value={{
                            color:
                              meskenTipi === "Müstakil"
                                ? "#ffffff"
                                : getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue("--primary-color"),
                            size: 20,
                          }}
                        >
                          <AiFillHome />
                        </IconContext.Provider>
                        <span
                          style={{ marginLeft: 5 }}
                          className={classes.battery}
                        >
                          {t("InvoiceInfoPage.mustakil")}
                        </span>
                      </div>
                      <input
                        type="radio"
                        id="Müstakil"
                        name="meskenTipi"
                        value="Müstakil"
                        checked={meskenTipi === "Müstakil"}
                        onChange={handleMeskenTipiChange}
                        className={classes.checkbox}
                      />
                    </label>
                    <label
                      className={
                        meskenTipi === "Apartman"
                          ? classes.rooflabelActive
                          : classes.roofLabel
                      }
                      style={{ marginLeft: "20px" }}
                      ref={checkApartmentRef}
                    >
                      <div className={classes.extraIcon}>
                        <IconContext.Provider
                          value={{
                            color:
                              meskenTipi === "Apartman"
                                ? "#ffffff"
                                : getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue("--primary-color"),
                            size: 20,
                          }}
                        >
                          <MdApartment />
                        </IconContext.Provider>
                        <span
                          style={{ marginLeft: 5 }}
                          className={classes.battery}
                        >
                          {t("InvoiceInfoPage.apartman")}
                        </span>
                      </div>
                      <input
                        type="radio"
                        id="Apartman"
                        name="meskenTipi"
                        value="Apartman"
                        checked={meskenTipi === "Apartman"}
                        onChange={handleMeskenTipiChange}
                        className={classes.checkbox}
                      />
                    </label>
                  </div>
                </div>
              )}
              {tesis === "Mesken" &&
                Number(userStatus) < 4 &&
                meskenTipi === "Apartman" && (
                  <div
                    style={{
                      margin: "1rem 0rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <ColoredCheckbox
                        checked={apartmanChecked}
                        onChange={handleChangeApartmanCheck}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <span
                        style={{
                          fontSize: isTabletOrMobile ? "0.8rem" : "1rem",
                        }}
                      >
                        {t("InvoiceInfoPage.apartmanKarar")}
                      </span>
                    </div>
                    {apartmanShowError && (
                      <span style={{ color: "red" }}>
                        {t("InvoiceInfoPage.apartmanRequired")}
                      </span>
                    )}
                  </div>
                )}
              {tesis === "Mesken" && Number(userStatus) < 4 && (
                <Divider variant="middle" />
              )}
              {Number(userStatus) > 3 && (
                <div className={classes.title2}>
                  <span className={classes.adresGir}>
                    {t("InvoiceInfoPage.cannotChangeFacilityType")}
                  </span>
                </div>
              )}

              <div className={classes.title2}>
                <span className={classes.adresGir}>
                  {t("InvoiceInfoPage.faturaBilgi")}
                </span>
                <div style={{ marginTop: 12, width: "95%" }}>
                  {isTabletOrMobile && module !== "german_solarvis" && (
                    <span className={classes.adresGir}>
                      {t("InvoiceInfoPage.sonFatura")}
                    </span>
                  )}
                  {isTabletOrMobile && module === "german_solarvis" && (
                    <span className={classes.adresGir}>
                      {t("InvoiceInfoPage.monthlyAvarageConsumption")}
                    </span>
                  )}
                  <Grid container spacing={3} alignItems="center">
                    {isDesktopOrLaptop && module !== "german_solarvis" && (
                      <Grid className={MUIClasses.item} item>
                        {t("InvoiceInfoPage.sonFatura")}
                      </Grid>
                    )}
                    {isDesktopOrLaptop && module === "german_solarvis" && (
                      <Grid className={MUIClasses.item} item>
                        {t("InvoiceInfoPage.monthlyAvarageConsumption")}
                      </Grid>
                    )}
                    {optionsFatura}
                  </Grid>
                </div>
                {tesis !== "Mesken" && module !== "german_solarvis" && (
                  <>
                    <div style={{ marginTop: 5, width: "95%" }}>
                      {isTabletOrMobile && (
                        <span className={classes.adresGir}>
                          {t("InvoiceInfoPage.sgucu")}
                        </span>
                      )}
                      <Grid container spacing={3} alignItems="center">
                        {isDesktopOrLaptop && (
                          <Grid className={MUIClasses.item} item>
                            {t("InvoiceInfoPage.sgucu")}
                          </Grid>
                        )}
                        {optionsSozlesme}
                      </Grid>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      <span
                        className={classes.adresGir}
                        style={{ fontSize: "0.8rem" }}
                      >
                        * {t("InvoiceInfoPage.sgucuMetin")}
                      </span>
                    </div>
                  </>
                )}
                {tesis === "Mesken" && (
                  <div className={classes.title2}>
                    <span className={classes.adresGir}>
                      {t("InvoiceInfoPage.consumptionProfileInfo")}
                    </span>
                    <div
                      className={classes.optionsWrapper}
                      style={
                        isTabletOrMobile
                          ? { marginBottom: 0, marginTop: 20 }
                          : {}
                      }
                    >
                      <div
                        className={classes.checkWrapper}
                        style={{ flexWrap: "wrap" }}
                      >
                        <RadioButton
                          value={"family"}
                          active={consumptionProfile === "family"}
                          changeFunction={(value) =>
                            handleChangeConsumptionProfile(value)
                          }
                          text={t("InvoiceInfoPage.familyWithKids")}
                          imgSrc={require("../../assets/resources/consumptionProfile/family.png")}
                          onClickButtonFunc={() => { }}
                        />
                        <RadioButton
                          value={"onetwoperson"}
                          active={consumptionProfile === "onetwoperson"}
                          changeFunction={(value) =>
                            handleChangeConsumptionProfile(value)
                          }
                          text={t("InvoiceInfoPage.oneOrTwoPerson")}
                          imgSrc={require("../../assets/resources/consumptionProfile/oneortwoperson.png")}
                          onClickButtonFunc={() => { }}
                        />
                        <RadioButton
                          value={"homeoffice"}
                          active={consumptionProfile === "homeoffice"}
                          changeFunction={(value) =>
                            handleChangeConsumptionProfile(value)
                          }
                          text={t("InvoiceInfoPage.homeOffice")}
                          imgSrc={require("../../assets/resources/consumptionProfile/homeoffice.png")}
                          onClickButtonFunc={() => { }}
                        />
                        <RadioButton
                          value={"nighthome"}
                          active={consumptionProfile === "nighthome"}
                          changeFunction={(value) =>
                            handleChangeConsumptionProfile(value)
                          }
                          text={t("InvoiceInfoPage.night")}
                          imgSrc={require("../../assets/resources/consumptionProfile/night.png")}
                          onClickButtonFunc={() => { }}
                        />
                        <RadioButton
                          value={"custom"}
                          active={consumptionProfile === "custom"}
                          changeFunction={(value) =>
                            handleChangeConsumptionProfile(value)
                          }
                          text={t("InvoiceInfoPage.detailedConsumption")}
                          imgSrc={require("../../assets/resources/consumptionProfile/custom.png")}
                          onClickButtonFunc={() =>
                            setCustomProfileDialogOpen(true)
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                {module === "aksasolar" && tesis !== "Mesken" && (
                  <>
                    <div style={{ marginTop: 5, width: "95%" }}>
                      {isTabletOrMobile && (
                        <span className={classes.adresGir}>
                          {t("InvoiceInfoPage.trafoGucu")}
                        </span>
                      )}
                      <Grid container spacing={3} alignItems="center">
                        {isDesktopOrLaptop && (
                          <Grid className={MUIClasses.item} item>
                            {t("InvoiceInfoPage.trafoGucu")}
                          </Grid>
                        )}
                        {optionsTrafoGucu}
                      </Grid>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      <span
                        className={classes.adresGir}
                        style={{ fontSize: "0.8rem" }}
                      >
                        Not: {t("InvoiceInfoPage.trafoGucuMetin")}
                      </span>
                    </div>
                  </>
                )}
              </div>
              {tesis !== "Mesken" && (
                <div className={classes.title2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span className={classes.adresGir}>
                      {t("InvoiceInfoPage.consumptionProfileInfo")}
                    </span>
                    {consumptionProfileDescription}
                  </div>
                  <div
                    className={classes.optionsWrapper}
                    style={
                      isTabletOrMobile ? { marginBottom: 0, marginTop: 20 } : {}
                    }
                  >
                    <div
                      className={classes.checkWrapper}
                      style={{ flexWrap: "wrap" }}
                    >
                      <RadioButton
                        value={"constant"}
                        active={consumptionProfile === "constant"}
                        changeFunction={(value) =>
                          handleChangeConsumptionProfile(value)
                        }
                        text={t("InvoiceInfoPage.constant")}
                        imgSrc={require("../../assets/resources/consumptionProfile/constant.png")}
                        onClickButtonFunc={() => { }}
                      />
                      <RadioButton
                        value={"weekdays"}
                        active={consumptionProfile === "weekdays"}
                        changeFunction={(value) =>
                          handleChangeConsumptionProfile(value)
                        }
                        text={t("InvoiceInfoPage.weekdays")}
                        imgSrc={require("../../assets/resources/consumptionProfile/weekdays.png")}
                        onClickButtonFunc={() => { }}
                      />
                      <RadioButton
                        value={"shoppinghours"}
                        active={consumptionProfile === "shoppinghours"}
                        changeFunction={(value) =>
                          handleChangeConsumptionProfile(value)
                        }
                        text={t("InvoiceInfoPage.shoppingHours")}
                        imgSrc={require("../../assets/resources/consumptionProfile/shoppinghours.png")}
                        onClickButtonFunc={() => { }}
                      />
                      <RadioButton
                        value={"nightindustrial"}
                        active={consumptionProfile === "nightindustrial"}
                        changeFunction={(value) =>
                          handleChangeConsumptionProfile(value)
                        }
                        text={t("InvoiceInfoPage.night")}
                        imgSrc={require("../../assets/resources/consumptionProfile/night.png")}
                        onClickButtonFunc={() => { }}
                      />
                      <RadioButton
                        value={"custom"}
                        active={consumptionProfile === "custom"}
                        changeFunction={(value) =>
                          handleChangeConsumptionProfile(value)
                        }
                        text={t("InvoiceInfoPage.detailedConsumption")}
                        imgSrc={require("../../assets/resources/consumptionProfile/custom.png")}
                        onClickButtonFunc={() =>
                          setCustomProfileDialogOpen(true)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {!monthlyConsumptionProfile.every(
                (value) => value.consumption === 0
              ) &&
                monthlyConsumptionProfile.length > 0 && (
                  <div className={classes.title2}>
                    <ConsumptionProfileDialog
                      usedFor={usedFor}
                      dialogOpenState={customProfileDialogOpen}
                      setDialogOpenState={setCustomProfileDialogOpen}
                      prevProfile={prevProfile}
                    />
                  </div>
                )}
              <Divider variant="middle" />

              <>
                <Divider variant="middle" />
                <div
                  className={classes.optionsWrapper}
                  style={isTabletOrMobile ? { marginBottom: "0rem" } : {}}
                >
                  <h3 className={classes.extraTexth3}>
                    {t("InvoiceInfoPage.ekstra")}
                  </h3>
                  <div className={classes.checkWrapper}>
                    {module !== "akbank" && (
                      <label
                        className={
                          batarya
                            ? classes.extraLabelActive
                            : classes.extraLabelDeactive
                        }
                      >
                        <div className={classes.extraIcon}>
                          <IconContext.Provider
                            value={{
                              color: batarya
                                ? "#ffffff"
                                : getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue("--primary-color"),
                              size: 20,
                            }}
                          >
                            <RiBattery2ChargeLine />
                          </IconContext.Provider>
                          <span
                            style={{ marginLeft: 5 }}
                            className={classes.battery}
                          >
                            {t("InvoiceInfoPage.batarya")}
                          </span>
                        </div>
                        <input
                          type="checkbox"
                          id="battery"
                          name="battery"
                          checked={batarya}
                          onChange={handleBatteryChange}
                          className={classes.checkbox}
                        />
                      </label>
                    )}
                    {
                      /* tesis === "Mesken" &&  */ <label
                        className={
                          sarj
                            ? classes.extraLabelActive
                            : classes.extraLabelDeactive
                        }
                      >
                        <div className={classes.extraIcon}>
                          <IconContext.Provider
                            value={{
                              color: sarj
                                ? "#ffffff"
                                : getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue("--primary-color"),
                              size: 20,
                            }}
                          >
                            <MdEvStation />
                          </IconContext.Provider>
                          <span
                            style={{ marginLeft: 5 }}
                            className={classes.battery}
                          >
                            {t("InvoiceInfoPage.sarj")}
                          </span>
                        </div>
                        <input
                          type="checkbox"
                          id="charger"
                          name="charger"
                          checked={sarj}
                          onChange={handleChargerChange}
                          className={classes.checkbox}
                        />
                      </label>
                    }
                  </div>
                </div>
              </>
              <div
                style={{
                  cursor: "pointer",
                  marginTop: 20,
                  marginBottom: isTabletOrMobile ? "6rem" : 0,
                }}
                onClick={() =>
                  setFeasibilityInfoBoxOpen(!feasibilityInfoBoxOpen)
                }
              >
                <Alert
                  severity="info"
                  icon={<InfoIcon fontSize="inherit" />}
                  className={MUIClasses.myAlert}
                >
                  {t("SummaryPage.howFeasibilityCalculationsInfo")}
                </Alert>
              </div>
              <StyledDialog
                aria-labelledby="customized-dialog-title"
                open={feasibilityInfoBoxOpen}
                onClose={handleCloseFeasbilityInfoBox}
              >
                <DialogContent dividers>
                  <ul style={{ marginLeft: "10px" }}>
                    {module !== "isbank" && (
                      <li style={{ margin: "10px 0px" }}>
                        {module === "arcelikkurumsalcozumler"
                          ? t("InvoiceInfoPage.tahminiMetinArcelik")
                          : t("InvoiceInfoPage.tahminiMetinDefault")}
                      </li>
                    )}
                    {module === "isbank" && (
                      <li style={{ margin: "10px 0px" }}>
                        {t("InvoiceInfoPage.tahminiMetinIsbank")}
                      </li>
                    )}
                    {module !== "ibtenerji" && (
                      <li style={{ margin: "10px 0px" }}>
                        {t("SummaryPage.bilgi")}
                      </li>
                    )}
                    {module === "ibtenerji" && (
                      <li style={{ margin: "10px 0px" }}>
                        {t("SummaryPage.bilgiKDVHaric")}
                      </li>
                    )}
                    <li style={{ margin: "10px 0px" }}>
                      {t("SummaryPage.powerCalculationInfo")}
                    </li>
                    {module !== "ibtenerji" && (
                      <li style={{ margin: "10px 0px" }}>
                        {t("SummaryPage.feasibilityInfoUSD")}
                      </li>
                    )}
                    {module === "ibtenerji" && (
                      <li style={{ margin: "10px 0px" }}>
                        {t("SummaryPage.feasibilityInfoElecPrices")}
                      </li>
                    )}
                    <li style={{ margin: "10px 0px" }}>
                      {t("InvoiceInfoPage.bataryaNot")}
                    </li>
                  </ul>
                </DialogContent>
              </StyledDialog>
            </div>
            {(isDesktopOrLaptop || isTabletLandscape) && (
              <div className={classes.formwizard}>
                <div className={classes.formwizardlv1}>
                  <div className={classes.buttonContainer}>
                    <button
                      onClick={() => handleChangeStep(2)}
                      className={classes.backButton}
                    >
                      <div className={classes.backButtonIcon}>
                        <IconContext.Provider
                          value={{ color: "#ffffff", size: 16 }}
                        >
                          <AiOutlineArrowRight />
                        </IconContext.Provider>
                      </div>
                      <span className={classes.startText}>
                        {t("Buton.geri")}
                      </span>
                    </button>
                    <button
                      onClick={() => handleChangeStep(4)}
                      className={classes.nextButton}
                      disabled={isSummaryDisabled}
                    >
                      <span className={classes.startText}>
                        {t("Buton.ozet")}
                      </span>
                      <div className={classes.buttonIcon}>
                        <IconContext.Provider
                          value={{ color: "#ffffff", size: 16 }}
                        >
                          <AiOutlineArrowRight />
                        </IconContext.Provider>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isTabletOrMobile && !isTabletLandscape && (
              <div className={classes.formwizard}>
                <div
                  style={{
                    padding: "0.5rem 50px",
                    display: "grid",
                    gridTemplateColumns: "auto 1fr auto",
                    alignItems: "center",
                    gridGap: "1rem",
                  }}
                >
                  <img
                    src="/images/sun.png"
                    alt="sun icon"
                    style={{ width: "20px" }}
                  />
                  <StepProgress />
                  <img
                    src="/images/solar-panel-home.png"
                    alt="solar panel icon"
                    style={{ width: "20px" }}
                  />
                </div>
                <div className={classes.formwizardlv1}>
                  <div className={classes.buttonContainer}>
                    {module !== "isbank" && (
                      <div className={classes.costSavingWrapper}>
                        {/* <IconContext.Provider value={{ color: '#7d7d7d', size: 30 }}>
                                  <GiReceiveMoney />
                                </IconContext.Provider> */}
                        <img
                          src="/images/moneybank.png"
                          alt="kumbara"
                          style={{ width: "30px", filter: "opacity(0.4)" }}
                        />
                        <div className={classes.costSaving}>
                          {module !== "german_solarvis" && (
                            <span className={classes.costSavingText}>
                              {yillikTasarruf} ₺
                            </span>
                          )}
                          {module === "german_solarvis" && (
                            <span className={classes.costSavingText}>
                              {yillikTasarrufEUR} €
                            </span>
                          )}
                          <span className={classes.costSavingText2}>
                            {t("ProgressBar.tasarruf")}
                          </span>
                        </div>
                      </div>
                    )}
                    {module !== "isbank" && (
                      <div className={classes.costSavingWrapper}>
                        {/* <IconContext.Provider value={{ color: '#7d7d7d', size: 30 }}>
                                  <GiReceiveMoney />
                                </IconContext.Provider> */}
                        <img
                          src="/images/payback-period-money.png"
                          alt="payback-period-solar-money"
                          style={{ width: "30px", filter: "opacity(0.4)" }}
                        />
                        <div className={classes.costSaving}>
                          <span className={classes.costSavingText}>
                            {yaklasikAmortisman} {t("SummaryPage.yil")}
                          </span>
                          <span className={classes.costSavingText2}>
                            {t("ProgressBar.amortisman")}
                          </span>
                        </div>
                      </div>
                    )}
                    <button
                      onClick={() => handleChangeStep(2)}
                      className={classes.backButton}
                    >
                      <div className={classes.backButtonIcon}>
                        <IconContext.Provider
                          value={{ color: "#ffffff", size: 16 }}
                        >
                          <AiOutlineArrowRight />
                        </IconContext.Provider>
                      </div>
                      <span className={classes.startText}>
                        {t("Buton.geri")}
                      </span>
                    </button>
                    <button
                      onClick={() => handleChangeStep(4)}
                      className={classes.nextButton}
                      disabled={isSummaryDisabled}
                    >
                      <span className={classes.startText}>
                        {t("Buton.ozet")}
                      </span>
                      <div className={classes.buttonIcon}>
                        <IconContext.Provider
                          value={{ color: "#ffffff", size: 16 }}
                        >
                          <AiOutlineArrowRight />
                        </IconContext.Provider>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {(window.location.host === "calculator.solarvis.co" ||
        window.location.host === "calculator-test.solarvis.co") && (
          <GeneralDialog title="" content={t("InvoiceInfoPage.calculatorInfo")} />
        )}
    </>
  );
}

export default InvoiceInfoPage;
