import React,{useState} from "react";
import "react-dropzone-uploader/dist/styles.css";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone-uploader";
import {Snackbar} from '@material-ui/core'
import { getDroppedOrSelectedFiles } from "html5-file-selector";
//import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useMediaQuery } from "react-responsive";
import axiosInstance from "../../../components/axios/axiosApi";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { Form, Upload } from "antd";
import { IoCloudUploadSharp } from "react-icons/io5";
import MuiAlert from '@material-ui/lab/Alert';
import {useNavigate} from 'react-router-dom'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FileUploadComponent = (props) => {
  const { t } = useTranslation();
  const { Dragger } = Upload;
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState("warning");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };
  const navigate = useNavigate();
  const onFileChange = ({ file, files, event }) => {
    let body = new FormData();
    // const key = {filename:`${props.photoCategory}-${fileCounter}.${extension}`,category_id: 3,subcon_project_id:1,is_admin: false};
    const jsonData = {
      file_document_category_id: props.documentCategory.id,
      user_comment: "",
    };
    body.append("json_data_str", JSON.stringify(jsonData));
    body.append("file", file);

    axiosInstance
      .post(
        "subcon/document/project_APIs/end_user_upload_single_project_document",
        body,
        {
          Headers: { contentType: "multipart/form-data" },
        }
      )
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { message } = response.data;

          setSnackbarMessage(message);
          setSnackbarType("success");
          setSnackbarState(true);

          setTimeout(() => {
            navigate("/login")
          }, 2000);
        }
      });
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const beforeUpload = (file, files) => {
    return false;
  };

  return (
    <Form initialValues={{ files: [] }} style={{ width: "100%" }}>
      <Form.Item name={"files"}>
        <Dragger
          maxCount={1}
          listType="picture-card"
          beforeUpload={beforeUpload}
          multiple={false}
          onChange={onFileChange}
          height={268}
          onDrop={(e) => {
            console.log("Dropped files", e.dataTransfer.files);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <IoCloudUploadSharp size={36} style={{ opacity: 0.5 }} />
            <h5></h5>
            <p style={{ textAlign: "center" }}>
              <span
                style={{
                  color: "#a6a6a5",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                {t("UploadComponent.fileExtensions")}
              </span>
            </p>
          </div>
        </Dragger>
      </Form.Item>
      <Snackbar
        open={snackbarState}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Form>
  );
};

export default FileUploadComponent;
