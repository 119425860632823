import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  panel: null,
  fatura: JSON.parse(localStorage.getItem('faturaTutarı')) || 2000,
  batarya: JSON.parse(localStorage.getItem('bataryaSecenegi')) || false,
  sarj: JSON.parse(localStorage.getItem('sarjSecenegi')) || false,
  tesis: localStorage.getItem('tesisTipi') || 'Mesken',
  meskenTipi: localStorage.getItem('meskenTipi') || 'Müstakil',
  roofTilt: localStorage.getItem('roofTilt') || '0',
  cephe: localStorage.getItem('cephe') || '0',
  baseType: localStorage.getItem('baseType') || 'roof',
  tlMaliyet: '-',
  eurMaliyet: '-',
  yillikUretim: null,
  usdMaliyet: null,
  amortisman: null,
  yaklasikAmortisman: '-',
  amortismanYilAy: ['-', '-'],
  yaklasikMaliyet: '-',
  yaklasikMaliyetEUR: '-',
  agac: null,
  co2: null,
  yillikTasarruf: '-',
  yillikTasarrufEUR: '-',
  yillikKazanc: '-',
  yillikKazancEUR: '-',
  sGucu: JSON.parse(localStorage.getItem('sozlesmeGucu')) || 5,
  trafoGucu: JSON.parse(localStorage.getItem('trafoGucu')) || 10,
  isim: localStorage.getItem('isim') || '',
  soyisim: localStorage.getItem('soyisim') || '',
  telefon: localStorage.getItem('telefon') || '',
  mail: localStorage.getItem('mail') || '',
  endUserCompanyName: localStorage.getItem('endUserCompanyName') || '',
  tckn: localStorage.getItem('tckn') || '',
  vkn: localStorage.getItem('vkn') || '',
  consumptionProfile: localStorage.getItem('consumptionProfile') || "family",
  selectedDCPower: localStorage.getItem('selectedDCPower') || 0,
  optimalDCPower: Number(localStorage.getItem('optimalDCPower')) || 0,
  optimalACPower: Number(localStorage.getItem('optimalACPower')) || 0,
  dailyConsumptionProfile: JSON.parse(localStorage.getItem('dailyConsumptionProfile')) || [],
  monthlyConsumptionProfile: JSON.parse(localStorage.getItem('monthlyConsumptionProfile')) || [],
  currency: '₺',
  monthlyConsumptionInput: 250 // kWh
};

export const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    changePanel: (state, action) => {
      state.panel = action.payload.panel;
    },
    changeFatura: (state, action) => {
        state.fatura = action.payload.fatura;
        localStorage.setItem('faturaTutarı', JSON.stringify(action.payload.fatura));
    },
    changeBatarya: (state, action) => {
        state.batarya = action.payload.batarya;
        localStorage.setItem('bataryaSecenegi', JSON.stringify(action.payload.batarya));
    },
    changeSarj: (state, action) => {
        state.sarj = action.payload.sarj;
        localStorage.setItem('sarjSecenegi', JSON.stringify(action.payload.sarj));
    },
    changeTesis: (state, action) => {
        state.tesis = action.payload.tesis;
        localStorage.setItem('tesisTipi', action.payload.tesis);
    },
    changeMeskenTipi: (state, action) => {
      state.meskenTipi = action.payload.meskenTipi;
      localStorage.setItem('meskenTipi', action.payload.meskenTipi);
    },
    changeEgim: (state, action) => {
        state.roofTilt = action.payload.roofTilt;
        localStorage.setItem('roofTilt', action.payload.roofTilt);
    },
    changeCephe: (state, action) => {
      state.cephe = action.payload.cephe;
      localStorage.setItem('cephe', action.payload.cephe);
    },
    changeBaseType: (state, action) => {
      state.baseType = action.payload.baseType;
      localStorage.setItem('baseType', action.payload.baseType);
    },
    changeConsumptionProfile: (state, action) => {
      state.consumptionProfile = action.payload.consumptionProfile;
      localStorage.setItem('consumptionProfile', action.payload.consumptionProfile);
    },
    changeTLMaliyet: (state, action) => {
        state.tlMaliyet = action.payload.tlMaliyet;
    },
    changeUSDMaliyet: (state, action) => {
        state.usdMaliyet = action.payload.usdMaliyet;
      },
    changeEURMaliyet: (state, action) => {
        state.eurMaliyet = action.payload.eurMaliyet;
      },
    changeUretim: (state, action) => {
        state.yillikUretim = action.payload.yillikUretim;
      },
    changeAmortisman: (state, action) => {
        state.amortisman = action.payload.amortisman;
      },
    changeYaklasikAmortisman: (state, action) => {
        state.yaklasikAmortisman = action.payload.yaklasikAmortisman;
      },
    changeAmortismanYilAy: (state, action) => {
        state.amortismanYilAy = action.payload.amortismanYilAy;
      },
    changeYaklasikMaliyet: (state, action) => {
        state.yaklasikMaliyet = action.payload.yaklasikMaliyet;
      },
    changeYaklasikMaliyetEUR: (state, action) => {
        state.yaklasikMaliyetEUR = action.payload.yaklasikMaliyetEUR;
      },
    changeAgac: (state, action) => {
        state.agac = action.payload.agac;
      },
    changeCo2: (state, action) => {
        state.co2 = action.payload.co2;
      },
    changeYillikTasarruf: (state, action) => {
        state.yillikTasarruf = action.payload.yillikTasarruf;
      },
    changeYillikTasarrufEUR: (state, action) => {
        state.yillikTasarrufEUR = action.payload.yillikTasarrufEUR;
      },
    changeYillikKazanc: (state, action) => {
        state.yillikKazanc = action.payload.yillikKazanc;
      },
    changeYillikKazancEUR: (state, action) => {
        state.yillikKazancEUR = action.payload.yillikKazancEUR;
      },
    changeSozlesmeGucu: (state, action) => {
        state.sGucu = action.payload.sGucu;
        localStorage.setItem('sozlesmeGucu', JSON.stringify(action.payload.sGucu));
      },
    changeTrafoGucu: (state, action) => {
        state.trafoGucu = action.payload.trafoGucu;
        localStorage.setItem('trafoGucu', JSON.stringify(action.payload.trafoGucu));
      },
    changeIsim: (state, action) => {
      state.isim = action.payload.isim;
      localStorage.setItem('isim', action.payload.isim);
    },
    changeSoyisim: (state, action) => {
      state.soyisim = action.payload.soyisim;
      localStorage.setItem('soyisim', action.payload.soyisim);
    },
    changeTelefon: (state, action) => {
      state.telefon = action.payload.telefon;
      localStorage.setItem('telefon', action.payload.telefon);
    },
    changeEndUserCompanyName: (state, action) => {
      state.endUserCompanyName = action.payload.endUserCompanyName;
      localStorage.setItem('endUserCompanyName', action.payload.endUserCompanyName);
    },
    changeVKN: (state, action) => {
      state.vkn = action.payload.vkn;
      localStorage.setItem('vkn', action.payload.vkn);
    },
    changeTCKN: (state, action) => {
      state.tckn = action.payload.tckn;
      localStorage.setItem('tckn', action.payload.tckn);
    },
    changeMail: (state, action) => {
      state.mail = action.payload.mail;
      localStorage.setItem('mail', action.payload.mail);
    },
    changeSelectedDCPower: (state, action) => {
      state.selectedDCPower = action.payload.selectedDCPower;
      localStorage.setItem('selectedDCPower', action.payload.selectedDCPower);
    },
    changeOptimalDCPower: (state, action) => {
      state.optimalDCPower = action.payload.optimalDCPower;
      localStorage.setItem('optimalDCPower', action.payload.optimalDCPower);
    },
    changeOptimalACPower: (state, action) => {
      state.optimalACPower = action.payload.optimalACPower;
      localStorage.setItem('optimalACPower', action.payload.optimalACPower);
    },
    changeDailyConsumptionProfile: (state, action) => {
      state.dailyConsumptionProfile = action.payload.dailyConsumptionProfile;
      localStorage.setItem('dailyConsumptionProfile', JSON.stringify(action.payload.dailyConsumptionProfile));
    },
    changeMonthlyConsumptionProfile: (state, action) => {
      state.monthlyConsumptionProfile = action.payload.monthlyConsumptionProfile;
      localStorage.setItem('monthlyConsumptionProfile', JSON.stringify(action.payload.monthlyConsumptionProfile));
    },
    changeCurrency: (state, action) => {
      state.currency = action.payload.currency;
      localStorage.setItem('currency', JSON.stringify(action.payload.currency));
    },
    changeMonthlyConsumptionInput: (state, action) => {
      state.monthlyConsumptionInput = action.payload.monthlyConsumptionInput;
      localStorage.setItem('monthlyConsumptionInput', JSON.stringify(action.payload.monthlyConsumptionInput));
    },
    resetSummary: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const {
  changePanel,
  changeFatura,
  changeBatarya,
  changeSarj,
  changeTesis,
  changeMeskenTipi,
  changeEgim,
  changeCephe,
  changeBaseType,
  changeConsumptionProfile,
  changeTLMaliyet,
  changeYillikKazanc,
  changeUSDMaliyet,
  changeUretim,
  changeAmortisman,
  changeYaklasikAmortisman,
  changeAmortismanYilAy,
  changeYaklasikMaliyet,
  changeAgac,
  changeCo2,
  changeYillikTasarruf,
  changeSozlesmeGucu,
  changeTrafoGucu,
  changeIsim,
  changeSoyisim,
  changeMail,
  changeTelefon,
  changeEndUserCompanyName,
  changeTCKN,
  changeVKN,
  changeSelectedDCPower,
  changeOptimalDCPower,
  changeOptimalACPower,
  changeDailyConsumptionProfile,
  changeMonthlyConsumptionProfile,
  changeCurrency,
  changeMonthlyConsumptionInput,
  changeYillikTasarrufEUR,
  changeYillikKazancEUR,
  changeEURMaliyet,
  changeYaklasikMaliyetEUR,
  resetSummary
} = summarySlice.actions

export default summarySlice.reducer