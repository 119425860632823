import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Button,
  withStyles,
  makeStyles,
  Divider,
  Typography,
  CardContent,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

const StyledCardContent = withStyles({
  root: {
    position: 'relative'
  }
})(CardContent);


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tabRoot: {
    width: 'fit-content'
  },
  list: {
    padding: '5px',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#f7e2e2',
    color: '#ad3737'
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row'
  },
  card: {
    width: '100%',
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const SolarMaterial = (props) => {
  const { material } = props;
  const MUIClasses = useStyles();

  const { t, } = useTranslation();

  return (
    <>
      <Card className={MUIClasses.card}>
        <CardMedia
          component="img"
          alt={material.human_readable_material_category}
          height="200"
          image={material.package_material_image_presigned_url}
          title={material.human_readable_material_category}
          style={{ objectFit: 'contain' }}
        />
        <Divider variant="middle" />
        <StyledCardContent>
          <div className={MUIClasses.list}>
            {['solar_panel', 'inverter'].includes(material.material_category) &&
            <h3 style={{ marginLeft: '5px', marginBottom: '10px' }}>
              {material.human_readable_material_category} - {material.material_amount_value} {t('SummaryPage.adet')} {material.power_value} {material.power_unit}
            </h3>
            }
            {!['solar_panel', 'inverter'].includes(material.material_category) &&
            <h3 style={{ marginLeft: '5px', marginBottom: '10px' }}>
              {material.human_readable_material_category}
            </h3>
            }
            <Table>
              <TableBody>
                {material.product_name && <TableRow
                  key="product_name"
                >
                  <StyledTableCell>
                    <span>{t('SolarPackageCard.productModel')}</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span>{material.product_name}</span>
                  </StyledTableCell>
                </TableRow>}
                {material.specs.length > 0 && material.specs.map((materialSpec) => (
                  <>
                    <TableRow
                      key={materialSpec.human_readable_spec_key}
                    >
                      <StyledTableCell>
                        <span>{materialSpec.human_readable_spec_key}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        {materialSpec.value.includes('http') && <a href={materialSpec.value} target="_blank" rel="noopener noreferrer">{materialSpec.value}</a>}
                        {!materialSpec.value.includes('http') && <span>{materialSpec.value}</span>}
                      </StyledTableCell>
                    </TableRow>
                  </>
                ))}
                {material.datasheet_presigned_url && <TableRow
                  key="datasheet_presigned_url"
                >
                  <StyledTableCell>
                    <span>{t('SolarPackageCard.tecnicalDocument')}</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <a href={material.datasheet_presigned_url} target="_blank" rel="noopener noreferrer">{t('SolarPackageCard.download')}</a>
                  </StyledTableCell>
                </TableRow>}
              </TableBody>
            </Table>
          </div>
        </StyledCardContent>
      </Card>
    </>
  );
}

export default SolarMaterial;