const requestedContactDataForCredit = [
    {
        name: 'vkn',
        title: 'Vergi Numarası',
        type: 'number',
    },
    {
        name: 'vd',
        title: 'Vergi Dairesi',
        type: 'text',
    },
    {
        name: 'name',
        title: 'Yetkili Kişi Adı',
        type: 'text',
    },
    {
        name: 'surname',
        title: 'Yetkili Kişi Soy adı',
        type: 'text',
    },
    {
        name: 'phoneNumber',
        title: 'Yetkili Kişi Telefonu',
        type: 'text',
    }
];

const bankInfo = [
    {
        name: 'AKBANK',
        logoURL: 'https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/logos/Akbank_logo.png',
        checked: false
    },
    {
        name: 'İŞ BANKASI',
        logoURL: 'https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/logos/IsBankasi_logo.png',
        checked: false
    },
    {
        name: 'HAYAT FİNANS',
        logoURL: 'https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/logos/HayatFinans_logo.png',
        checked: false
    },
];

const exportedData = {
    requestedContactDataForCredit,
    bankInfo,
};

export default exportedData;