/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../../components/axios/axiosApi';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import {
  changeUserAPIStatus,
  changeUserId
} from '../../../services/users/userReducer';
import {
  changeDiscoveryRequest
} from '../../../services/products/product/moduleReducer';
import {
  changeLocation,
  changeTempLocation,
  changeCityName,
  changeIlce,
  changeDrawingPointsNew,
  changeMarker,
  changeStaticMapURL,
  changeDetailedAdress
} from '../../../services/products/product/reducer';
import {
  Container,
  Grid,
  Box,
  Button,
  withStyles,
  TextField,
  Checkbox,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Snackbar
} from '@material-ui/core';
import classes from './UserMainPage.module.css';
import ProcessTracker from '../../../components/ProcessTracker';
import {
  changeIsim,
  changeMail,
  changeSoyisim,
  changeTelefon,
  changeTesis,
  changeFatura,
  changeSozlesmeGucu,
  changeSarj,
  changeBatarya,
  changeEndUserCompanyName,
  changeVKN,
  changeTCKN,
  changeDailyConsumptionProfile,
  changeMonthlyConsumptionProfile,
  changeConsumptionProfile,
} from '../../../services/products/product/summaryReducer';
import { useMediaQuery } from 'react-responsive';
import MaskedInput from 'react-text-mask';
import Spinner from '../../../components/UI/Spinner/Spinner';
import download from 'downloadjs';
import exportedTestData from '../testData';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['0', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'var(--secondary-color)',
    color: 'white'
  }
})(Button);

const StyledCheckbox = withStyles({
  root: {
    color: 'var(--secondary-color)',
    '&$checked': {
      color: 'var(--secondary-color)',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const StyledTableCellHead = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    borderBottom: 'none'
  },
}))(TableCell);

const MainPage = () => {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const {
    module,
    discoveryRequest,
    availableModules
  } = useSelector(state => state.module);
  const { firstScreenAtEnd } = useSelector(state => state.companyInfo);
  const { userId, userStatus, loggedIn } = useSelector((state) => state.users);
  const { mail } = useSelector((state) => state.summary);
  const { detailedAdress } = useSelector(state => state.location);

  const [loading, setLoading] = useState(false);
  const [shouldReloadPage, setShouldReloadPage] = useState(true);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  /* const [contactInfo, setContactInfo] = useState(
    {
      name: '',
      surname: '',
      phoneNumber: '0(   )    -    ',
    }
  );

  const [validity, setValidity] = useState(
    {
      nameValid: true,
      surnameValid: true,
      phoneNumberValid: true,
    }
  ); */

  /* 
  Avaliable contact keys for now:
  address
  companyName
  vkn
  phoneNumber
  surname
  tckn
  name
  */
  const [contactInfo, setContactInfo] = useState({});
  const [contactInfoValues, setContactInfoValues] = useState({});

  const [contactInfoForCredit, setContactInfoForCredit] = useState(exportedTestData.requestedContactDataForCredit);
  const [contactInfoValuesForCredit, setContactInfoValuesForCredit] = useState({
    vkn: '',
    vd: '',
    name: '',
    surname: '',
    phoneNumber: '',
  });
  const [bankInfo, setBankInfo] = useState([]);

  const [creditApplicationInfo, setCreditApplicationInfo] = useState({
    applicationExists: false,
  });

  const mapContactInfo = (value) => {
    switch (value) {
      case 'name':
        return 'isim';
      case 'surname':
        return 'soy_isim';
      case 'phoneNumber':
        return 'telefon';
      case 'companyName':
        return 'customer_firm';
      case 'referenceCode':
        return 'subcon_reference_code';
      default:
        return value;
    }
  };

  const inputChangeHandler = (event) => {
    const value = event.target.value;
    // checkValidity(value, event.target.name);
    setContactInfoValues({
      ...contactInfoValues,
      [event.target.name]: value
    });
  }

  const inputChangeHandlerForCredit = (event) => {
    const value = event.target.value;
    // checkValidity(value, event.target.name);
    setContactInfoValuesForCredit({
      ...contactInfoValuesForCredit,
      [event.target.name]: value
    });
  }

  const handleChangeBankChecked = (name) => {
    // Prevents React from resetting its properties:
    const newData = bankInfo.map((bankItem) => {
      if (bankItem.name === name) {
        return {
          ...bankItem,
          checked: !bankItem.checked
        };
      }
      return bankItem;
    });
    setBankInfo(newData);
  };

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

  async function getReport(id) {
    // Define report type
    let reportType = 'default_tr';
    let reportName = 'GES Ön Fizibilite Raporu.pdf';
    if (localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en') {
      reportType = 'default_en';
      reportName = 'SPP Pre-Feasibility Report.pdf';
    }
    if (localStorage.getItem('dil') === 'de' || String(i18n.language).split('-')[0] === 'de') {
      reportType = 'default_de';
      reportName = 'SPP-Vormachbarkeitsbericht.pdf';
    }
    setLoading(true);
    await axiosInstance.get(`report/generate?musteri_id=${id}&format=json&is_admin_page=0&report_type=${reportType}`/* , {
      responseType: 'blob'
    } */)
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' }
        );
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        setLoading(false);
        download(file, reportName,);
      })
      .catch();
  }

  const fetchUserFeasibilityInfo = async (url) => {
    let roofArea;
    let staticMapURLTemp;
    let userIdTemp;
    let conctInfoTemp = {};

    await axiosInstance.get(`input/get_company/`)
      .then((response) => {
        if (response.data.status) {
          setContactInfo(response.data.active_inputs);
          conctInfoTemp = response.data.active_inputs;
        }
      })
      .catch();

    await axiosInstance.get('/customer/fetch_profile_info/?format=json')
      .then(response => {
        const data = response.data.data;
        let contactInfoValuesTemp = {};

        conctInfoTemp.map(tempContact => {
          contactInfoValuesTemp[tempContact.title] = data[mapContactInfo(tempContact.title)];
        });

        setContactInfoValues(contactInfoValuesTemp);

        if (data.hasOwnProperty('isim') && data.isim) dispatch(changeIsim({ isim: data.isim }));
        if (data.hasOwnProperty('soy_isim') && data.soy_isim) dispatch(changeSoyisim({ soyisim: data.soy_isim }));
        if (data.hasOwnProperty('telefon') && data.telefon) dispatch(changeTelefon({ telefon: data.telefon }));
        if (data.hasOwnProperty('tckn') && data.tckn) dispatch(changeTCKN({ tckn: data.tckn }));
        if (data.hasOwnProperty('vkn') && data.vkn) dispatch(changeVKN({ vkn: data.vkn }));
        if (data.hasOwnProperty('address') && data.address) dispatch(changeDetailedAdress({ detailedAdress: data.address }));
        if (data.hasOwnProperty('username') && data.username) dispatch(changeMail({ mail: data.username }));

        setContactInfoValuesForCredit({
          ...contactInfoValuesForCredit,
          vkn: data.hasOwnProperty('vkn') ? data.vkn : '',
          name: data.hasOwnProperty('isim') ? data.isim : '',
          surname: data.hasOwnProperty('soy_isim') ? data.soy_isim : '',
          phoneNumber: data.hasOwnProperty('telefon') ? data.telefon : '',
        });

      })
      .catch(error => {
        // console.log(error);
      })

    await axiosInstance.get('get_user_feasibility_info/?format=json')
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data;
          roofArea = data.alan;
          staticMapURLTemp = data.gmaps_link;
          userIdTemp = data.id;
          dispatch(changeUserId({ userId: data.id }));
          dispatch(changeLocation({ latitude: data.enlem, longitude: data.boylam }));
          dispatch(changeTempLocation({ tempLatitude: data.enlem, tempLongitude: data.boylam }));
          dispatch(changeCityName({ cityName: data.il }));
          dispatch(changeIlce({ ilce: data.ilce }));
          dispatch(changeDrawingPointsNew({ drawingPointsNew: data.drawing_points }));
          dispatch(changeTesis({ tesis: data.tesis_tipi }));
          dispatch(changeFatura({ fatura: data.fatura_tutari }));
          dispatch(changeSozlesmeGucu({ sGucu: data.sgucu }));
          dispatch(changeBatarya({ batarya: data.is_battery }));
          dispatch(changeSarj({ sarj: data.is_charger }));
          dispatch(changeUserAPIStatus({ userStatus: data.status }));
          if (data.status === 4 || data.status === 5) {
            dispatch(changeDiscoveryRequest(true));
          }
          if (!detailedAdress) {
            dispatch(changeDetailedAdress({ detailedAdress: `${data.il}, ${data.ilce}` }))
          }
          dispatch(changeMarker({ markerOn: true }));
          dispatch(changeStaticMapURL({ staticMapURL: data.gmaps_link }));
          if (data.monthly_manual_consumptions) {
            dispatch(changeMonthlyConsumptionProfile({ monthlyConsumptionProfile: data.monthly_manual_consumptions }));
          }
          if (data.daily_consumption_percentages) {
            dispatch(changeDailyConsumptionProfile({ dailyConsumptionProfile: data.daily_consumption_percentages }));
          }
          dispatch(changeConsumptionProfile({ consumptionProfile: data.consumption_profile }));
        }
      })
      .catch(error => {
        // console.log(error);
      });

    if ((firstScreenAtEnd === "summary")) {
      let saveRequestURL = "musteri/save"
      if (module === "german_solarvis") {
        saveRequestURL = "deutsche/musteri/save"
      }
      await axiosInstance.post(saveRequestURL, {
        musteri_id: userIdTemp,
        status: 3,
        mail: mail,
        language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase(),
      })
        .then(response => {
          const data = response.data.data;
          dispatch(changeUserAPIStatus({ userStatus: 3 }));
          // Send pageview with a custom path
          ReactGA.send({ hitType: "pageview", page: "/kullaniciya_ozel_ekran" });
          return data['musteri_id'];
        })
      // .catch(error => console.log(error));
    }
  }

  const fetchCreditApplicationRelatedData = async () => {
    await axiosInstance.get(`bankloan/users/get-own-applications/?lang=${(localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase()}`)
      .then((response) => {
        const { data, status } = response.data;
        if (!status) {
          setCreditApplicationInfo({
            applicationExists: false,
          })
        } else {
          setCreditApplicationInfo({
            ...data,
            applicationExists: true,
          });
        }
      })
      .catch();
  }

  const fetchAvailableBanksForCredit = async () => {
    await axiosInstance.get(`bankloan/users/fetch-banks/?lang=${(localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase()}`)
      .then((response) => {
        const { data } = response.data;
        const updatedData = data.map((bankItem) => {
          return {
            ...bankItem,
            checked: true
          };
        });
        setBankInfo(updatedData);
      })
      .catch();
  }

  const applyCreditApplication = (event) => {
    event.preventDefault();

    axiosInstance.put('bankloan/users/apply-for-credit/', {
      credit_request_amount: creditApplicationInfo.credit_request_amount,
      user_vkn: contactInfoValuesForCredit.vkn,
      user_vd: contactInfoValuesForCredit.vd,
      applied_banks: bankInfo.filter(bankItem => bankItem.checked).map(bankItem => bankItem.name),
      lang: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase()
    })
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
          setShouldReloadPage(!shouldReloadPage);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
          // alert('Böyle bir kayıt bulunamadı.');
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        // console.log(error);
      });
  }

  const confirmCreditProposal = (event, bankName) => {
    event.preventDefault();

    axiosInstance.get(`bankloan/users/confirm-credit-proposal?bank_name=${bankName}&lang=${(localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase()}`)
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
          setShouldReloadPage(!shouldReloadPage);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
          // alert('Böyle bir kayıt bulunamadı.');
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        // console.log(error);
      });
  }

  const explorationRequest = async (event) => {
    event.preventDefault();

    const contactInfoValuesKeys = Object.keys(contactInfoValues);
    const sendData = {};
    contactInfoValuesKeys.map(key => {
      sendData[mapContactInfo(key)] = contactInfoValues[key];
    });

    await axiosInstance.post('/customer/save_profile_info/?format=json', sendData)
      .then((response) => {
        if (response.data.status) {
          /* 
          Avaliable contact keys for now:
          name
          surname
          phoneNumber
          address
          companyName
          vkn
          tckn
          */
          if (contactInfoValues.hasOwnProperty("name") && contactInfoValues.name) dispatch(changeIsim({ isim: contactInfoValues.name }));
          if (contactInfoValues.hasOwnProperty("surname") && contactInfoValues.surname) dispatch(changeSoyisim({ soyisim: contactInfoValues.surname }));
          if (contactInfoValues.hasOwnProperty("phoneNumber") && contactInfoValues.phoneNumber) dispatch(changeTelefon({ telefon: contactInfoValues.phoneNumber }));
          if (contactInfoValues.hasOwnProperty("address") && contactInfoValues.address) dispatch(changeDetailedAdress({ detailedAdress: contactInfoValues.address }));
          if (contactInfoValues.hasOwnProperty("companyName") && contactInfoValues.companyName) dispatch(changeEndUserCompanyName({ endUserCompanyName: contactInfoValues.companyName }));
          if (contactInfoValues.hasOwnProperty("vkn") && contactInfoValues.vkn) dispatch(changeVKN({ vkn: contactInfoValues.vkn }));
          if (contactInfoValues.hasOwnProperty("tckn") && contactInfoValues.tckn) dispatch(changeTCKN({ tckn: contactInfoValues.tckn }));
        }
      })
      .catch(error => {
        // console.log(error);
      })

    let saveRequestURL = "musteri/save"
    if (module === "german_solarvis") {
      saveRequestURL = "deutsche/musteri/save"
    }
    await axiosInstance.post(saveRequestURL, {
      musteri_id: userId,
      status: 4,
      language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase(),
      mail: mail,
      ...sendData,
    })
      .then((response) => {
        if (response.data.status) {
          dispatch(changeUserAPIStatus({ userStatus: 4 }));
          dispatch(changeDiscoveryRequest(true));
          // Send pageview with a custom path
          ReactGA.send({ hitType: "pageview", page: "/kesif_talebi" });
        }
      })
      .then(() => {
        if (module === "isbank") {
          window.location.href = "https://anindaislem.isbank.com.tr/Home?id=CompanyCreditCardApply&SiteUrl=SolarVis";
        }
      })
      .catch((error) => {
        // console.log(error);
        if (module === "isbank") {
          window.location.href = "https://anindaislem.isbank.com.tr/Home?id=CompanyCreditCardApply&SiteUrl=SolarVis";
        }
      });
  }

  const decideExplanations = () => {
    if (module === "aksasolar") {
      return {
        "title": t('UserMainPage.teklifHizmeti'),
        "buttonText": t('UserMainPage.teklifButon'),
        "afterRequestText": t('UserMainPage.teklifHizmetiAlindi'),
        "beforeRequestText": t('UserMainPage.teklifMetin'),
      }
    } else if (availableModules.includes("bank")) {
      if (module === "isbank") {
        return {
          "title": t('UserMainPage.kesifHizmetiIsbank'),
          "buttonText": t('UserMainPage.kesifButonIsbank'),
          "afterRequestText": t('UserMainPage.kesifHizmetiAlindiIsbank'),
          "beforeRequestText": `${t('UserMainPage.kesifMetinIsbank1')} ${t('UserMainPage.kesifMetinIsbank2')} ${t('UserMainPage.kesifMetinIsbank3')}`,
        }
      }
      return {
        "title": t('UserMainPage.kesifHizmetiBank'),
        "buttonText": t('UserMainPage.kesifButonBank'),
        "afterRequestText": t('UserMainPage.kesifHizmetiAlindiBank'),
        "beforeRequestText": t('UserMainPage.kesifMetinBank'),
      }
    } else if (availableModules.includes("subcon_detailed")) {
      return {
        "title": t('UserMainPage.kesifHizmetiSubcon'),
        "buttonText": t('UserMainPage.kesifButonSubcon'),
        "afterRequestText": t('UserMainPage.kesifHizmetiAlindiSubcon'),
        "beforeRequestText": t('UserMainPage.kesifMetinSubcon'),
      }
    } else {
      return {
        "title": t('UserMainPage.kesifHizmeti'),
        "buttonText": t('UserMainPage.kesifButon'),
        "afterRequestText": t('UserMainPage.kesifHizmetiAlindi'),
        "beforeRequestText": t('UserMainPage.kesifMetin'),
      }
    }
  };

  const contactItemTranslator = {
    tr: {
      "name": "İsim",
      "surname": "Soyisim",
      "phoneNumber": "Telefon Numarası",
      "address": "Adres",
      "companyName": "Şirket Adı",
      "vkn": "VKN",
      "tckn": "TCKN",
      "referenceCode": "Referans Kodu",
      "branchSelection": "Şube Seçimi",
    },
    en: {
      "name": "Name",
      "surname": "Surname",
      "phoneNumber": "Phone Number",
      "address": "Address",
      "companyName": "Company Name",
      "vkn": "VKN",
      "tckn": "TCKN",
      "referenceCode": "Reference Code",
      "branchSelection": "Branch Selection",
    },
    el: {
      "name": "Όνομα",
      "surname": "Επώνυμο",
      "phoneNumber": "Τηλέφωνο",
      "address": "Διεύθυνση",
      "companyName": "Όνομα Εταιρείας",
      "vkn": "VKN",
      "tckn": "TCKN",
      "referenceCode": "Κωδικός Αναφοράς",
      "branchSelection": "Επιλογή Υποκαταστήματος",
    }
  };


  useEffect(() => {
    fetchUserFeasibilityInfo("");
  }, [loggedIn, userStatus]);

  useEffect(() => {
    if (availableModules.includes('credit_application') && userId) {
      fetchCreditApplicationRelatedData();
    }
  }, [userId, shouldReloadPage]);

  useEffect(() => {
    fetchAvailableBanksForCredit();
  }, [creditApplicationInfo]);

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  if (loading) {
    if (localStorage.getItem('dil') === 'tr' || String(i18n.language).split('-')[0] === 'tr') {
      return (
        <div style={{ minHeight: "90vh", height: "100%" }}>
          <Spinner text="Raporunuz oluşturuluyor.." />
        </div>
      )
    } else if (localStorage.getItem('dil') === 'de' || String(i18n.language).split('-')[0] === 'de') {
      return (
        <div style={{ minHeight: "90vh", height: "100%" }}>
          <Spinner text="Ihr Bericht wird erstellt.." />
        </div>
      )
    } else {
      return (
        <div style={{ minHeight: "90vh", height: "100%" }}>
          <Spinner text="Your report is being created.." />
        </div>
      )
    }
  }

  return (
    <Container
      maxWidth={"xl"}
      style={{ height: '100%', backgroundColor: '#F4F6F8', minHeight: '90vh' }}
    >
      <Grid
        container
        spacing={3}
        style={{ justifyContent: 'center' }}
      >
        {/* BANKA SEÇİP BAŞVURU YAP */}
        {!(module === "isbank" || module === "akbank" || module === "garantibbva") && availableModules.includes('credit_application') && creditApplicationInfo.applicationExists && creditApplicationInfo.status === 'INIT' &&
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-between' }} >
              <div>
                <h2 className={classes.allh2}>Hemen Güneş Kredisine Başvurun!</h2>
                <p>Kredi teklifi almak istediğiniz bankaları seçin ve bilgilerinizi doldurun.</p>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {bankInfo.map((bankItem, index) => (
                    <div
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
                      className={bankItem.checked ? classes.bankItemChecked : classes.bankItemUnchecked}
                    >
                      <StyledCheckbox
                        id={bankItem.name}
                        checked={bankItem.checked}
                        onChange={() => handleChangeBankChecked(bankItem.name)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <img src={bankItem.logo_url} alt={bankItem.name} style={{ width: '200px', height: 'auto' }} />
                    </div>
                  ))}
                </div>
                <form autoComplete='on' style={{ padding: '20px 0px' }}>
                  <Container maxWidth={"xl"} style={{ padding: '0px' }}>
                    <Grid
                      container
                      spacing={2}
                    >
                      {!(_.isEmpty(contactInfoForCredit)) && contactInfoForCredit.map((contactItem, index) => (
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xl={6}
                          xs={12}
                        >
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>{contactItem.title}</span>
                            {contactItem.type !== "select" &&
                              <TextField
                                fullWidth
                                name={contactItem.name}
                                type={contactItem.type}
                                id={contactItem.name}
                                onChange={(event) => inputChangeHandlerForCredit(event)}
                                value={contactInfoValuesForCredit[contactItem.name]}
                                /* helperText={!validity[contactItem.title] ? contactItem.placeholder_tr : ""}
                                error={!validity[contactItem.title]} */
                                variant="outlined"
                                size="small"
                                InputProps={contactItem.name === "phoneNumber" ? { inputComponent: TextMaskCustom } : {}}
                                inputComponent={contactItem.name === "phoneNumber" ? TextMaskCustom : {}}
                              />
                            }
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Container>
                </form>
                <Box my={2}>
                  <div style={{ textDecoration: 'none' }}>
                    <StyledButton
                      onClick={(e) => applyCreditApplication(e)}
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={bankInfo.filter(bankItem => bankItem.checked).length === 0 || !Object.keys(contactInfoValuesForCredit).every((contactItem) => contactInfoValuesForCredit[contactItem] !== "")}
                    >
                      Başvuru Yap
                    </StyledButton>
                  </div>
                </Box>
              </div>
            </div>
          </Grid>}
        {/* GELEN TEKLİFLERİ LİSTELE */}
        {!(module === "isbank" || module === "akbank" || module === "garantibbva") && availableModules.includes('credit_application') && creditApplicationInfo.hasOwnProperty('application_status') && creditApplicationInfo.status !== 'DONE' && creditApplicationInfo.application_status.length > 0 &&
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-between', display: 'block' }} >
              <div>
                <h2 className={classes.allh2}>Kredi Teklifleriniz</h2>
                <p style={{ margin: '20px 0px' }}>Başvurduğunuz bankalardan gelen güneş kredisi teklifleri aşağıda listelenmiştir.</p>
                <TableContainer>
                  <Table aria-label="credit-proposals">
                    <TableHead>
                      <TableRow>
                        <StyledTableCellHead align="left">
                          Banka
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          Teklif Edilen Kredi Tutarı
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          Faiz Oranı
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          Vade Süresi
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          Ödeme Planı Dokümanı
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left"> </StyledTableCellHead>
                        <StyledTableCellHead align="left"> </StyledTableCellHead>
                        {/* <StyledTableCellHeadWithBorder align="left"> </StyledTableCellHeadWithBorder> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {creditApplicationInfo.application_status.map((proposal) => (
                        proposal.hasOwnProperty('bank_proposal')
                          ?
                          <TableRow key={proposal.id}>
                            <StyledTableCell align="left">
                              <img src={proposal.bank_logo_url} alt={proposal.bank} style={{ width: '100px', height: 'auto' }} />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {proposal.bank_proposal.creditable_amount} ₺
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              % {proposal.bank_proposal.interest_rate}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {proposal.bank_proposal.credit_term} ay
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {proposal.bank_proposal.hasOwnProperty('payment_plan_url')
                                ?
                                <a href={proposal.bank_proposal.payment_plan_url} target="_blank" rel="noopener noreferrer">İndir</a>
                                :
                                <span>-</span>}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <StyledButton
                                size="medium"
                                variant="contained"
                                onClick={(event) => confirmCreditProposal(event, proposal.bank)}
                              >
                                Onayla
                              </StyledButton>
                            </StyledTableCell>
                          </TableRow>
                          :
                          <TableRow key={proposal.id}>
                            <StyledTableCell align="left">
                              <img src={proposal.bank_logo_url} alt={proposal.bank} style={{ width: '100px', height: 'auto' }} />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              -
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              -
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              -
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              -
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Teklif Bekleniyor
                            </StyledTableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Grid>}
        {/* ONAYLANAN TEKLİF DETAYLARINI GÖSTER */}
        {!(module === "isbank" || module === "akbank" || module === "garantibbva") && availableModules.includes('credit_application') && creditApplicationInfo.hasOwnProperty('application_status') && creditApplicationInfo.status === 'DONE' && creditApplicationInfo.application_status.length > 0 &&
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-between', display: 'block', minHeight: 'unset' }} >
              <div>
                <h2 className={classes.allh2}>Onaylanan Kredi Teklifiniz</h2>
                <p style={{ margin: '20px 0px' }}>Onayladığınız kredi teklifinizin detayları aşağıda belirtilmiştir.</p>
                <TableContainer>
                  <Table aria-label="credit-proposals">
                    <TableHead>
                      <TableRow>
                        <StyledTableCellHead align="left">
                          Banka
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          Anlaşılan Kredi Tutarı
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          Faiz Oranı
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          Vade Süresi
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          Ödeme Planı Dokümanı
                        </StyledTableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {creditApplicationInfo.application_status.filter(proposal => proposal.status === 'APPROVED').map((proposal) => (
                        <TableRow key={proposal.id}>
                          <StyledTableCell align="left">
                            <img src={proposal.bank_logo_url} alt={proposal.bank} style={{ width: '100px', height: 'auto' }} />
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {proposal.bank_proposal.creditable_amount} ₺
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            % {proposal.bank_proposal.interest_rate}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {proposal.bank_proposal.credit_term} ay
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {proposal.bank_proposal.hasOwnProperty('payment_plan_url')
                              ?
                              <a href={proposal.bank_proposal.payment_plan_url} target="_blank" rel="noopener noreferrer">İndir</a>
                              :
                              <span>-</span>}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Grid>}
        {/* RAPOR OLUŞTUR */}
        {!creditApplicationInfo.applicationExists &&
          <Grid
            item
            lg={5}
            md={5}
            xl={5}
            xs={12}
          >
            <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px' }} >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <h2 className={classes.allh2}>{t('UserMainPage.reportReady')}</h2>
                <p>{t('UserMainPage.reportText')}</p>
                <Box my={2}>
                  <div style={{ textDecoration: 'none' }} onClick={() => getReport(userId)}>
                    <StyledButton
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {t('UserMainPage.reportButton')}
                    </StyledButton>
                  </div>
                </Box>
              </div>
              {isDesktopOrLaptop &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {module !== "isbank" && <img src="./images/report.png" alt="GES Fizibilite Raporu" className={classes.icon} />}
                  {module === "isbank" && <img src="./images/report-icon-alternative.png" alt="GES Fizibilite Raporu" className={classes.icon} />}
                </div>}
            </div>
          </Grid>}
        {!(module === "isbank" || module === "akbank" || module === "garantibbva") &&
          <Grid
            item
            lg={7}
            md={7}
            xl={7}
            xs={12}
          >
            <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-between' }} >
              {!discoveryRequest &&
                <>
                  <div>
                    <h2 className={classes.allh2}>{decideExplanations().title}</h2>
                    <p>{decideExplanations().beforeRequestText}</p>
                    <form autoComplete='on' style={{ padding: '20px 0px' }}>
                      <Container maxWidth={"xl"} style={{ padding: '0px' }}>
                        <Grid
                          container
                          spacing={2}
                        >
                          {!(_.isEmpty(contactInfo)) && contactInfo.sort((a, b) => a.id - b.id).map((contactItem, index) => (
                            <Grid
                              key={index}
                              item
                              lg={6}
                              md={6}
                              xl={6}
                              xs={12}
                            >
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>{contactItemTranslator[(localStorage.getItem('dil') || String(i18n.language).split('-')[0])][contactItem.title]}</span>
                                {contactItem.type !== "select" &&
                                  <TextField
                                    fullWidth
                                    name={contactItem.title}
                                    type={contactItem.type}
                                    id={contactItem.autocomplete}
                                    autoComplete={contactItem.autocomplete}
                                    onChange={(event) => inputChangeHandler(event)}
                                    value={contactInfoValues[contactItem.title]}
                                    /* helperText={!validity[contactItem.title] ? contactItem.placeholder_tr : ""}
                                    error={!validity[contactItem.title]} */
                                    variant="outlined"
                                    size="small"
                                    InputProps={contactItem.title === "phoneNumber" ? { inputComponent: TextMaskCustom } : {}}
                                    inputComponent={contactItem.title === "phoneNumber" ? TextMaskCustom : {}}
                                  />
                                }
                                {contactItem.type === "select" &&
                                  <Autocomplete
                                    value={contactInfoValues[contactItem.title]}
                                    onChange={(event, newValue) => setContactInfoValues(prevState => ({
                                      ...prevState,
                                      [contactItem.title]: newValue
                                    }))}
                                    id={contactItem.title}
                                    options={contactItem.selection_choices}
                                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                                  />
                                }
                                {(contactItem.title === "referenceCode" || contactItem.title === "branchSelection") &&
                                  <p style={{ fontSize: '0.8rem' }}>{t('UserMainPage.optionalInput')}</p>
                                }
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </Container>
                    </form>
                    <Box my={2}>
                      <div style={{ textDecoration: 'none' }}>
                        <StyledButton
                          size="large"
                          type="submit"
                          variant="contained"
                          onClick={(e) => explorationRequest(e)}
                          disabled={!Object.keys(contactInfoValues).filter(key => key !== 'branchSelection').filter(key => key !== 'referenceCode').map(key => contactInfoValues[key]).every(value => value)}
                        >
                          {decideExplanations().buttonText}
                        </StyledButton>
                      </div>
                    </Box>
                  </div>
                </>}
              {discoveryRequest &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h2 className={classes.allh2} style={{ fontSize: '1.3rem' }}>{decideExplanations().afterRequestText}</h2>
                </div>
              }
              {isDesktopOrLaptop &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src="./images/solar-cati-kesif.png" alt="Solar Keşif Hizmeti" className={classes.icon} style={{ width: 300 }} />
                </div>}
            </div>
          </Grid>}
        {/* AKBANK ÖZEL COMPONENT */}
        {module === "akbank" &&
          <Grid
            item
            lg={7}
            md={7}
            xl={7}
            xs={12}
          >
            <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-between' }} >
              <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
                <h2 className={classes.allh2} style={{ fontSize: '1.0rem' }}>
                  Hemen kredi talebinde bulunmak için formu doldurun!
                </h2>
                <a href="https://www.akbank.com/basvuru/cati-ges-fizibilite/">
                  <StyledButton
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Kredi Formuna Git!
                  </StyledButton>
                </a>
              </div>
            </div>
          </Grid>}
        {/* İŞ BANKASI ÖZEL COMPONENT */}
        {module === "isbank" &&
          <Grid
            item
            lg={7}
            md={7}
            xl={7}
            xs={12}
          >
            <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-between' }} >
              <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column', justifyContent: 'space-around' }}>
                <h2 className={classes.allh2}>
                  {t('UserMainPage.kesifHizmetiIsbank')}
                </h2>
                <p>{t('UserMainPage.kesifMetinIsbank1')}</p>
                <p>{t('UserMainPage.kesifMetinIsbank2')}</p>
                <p>{t('UserMainPage.kesifMetinIsbank3')}</p>
                <Box my={2}>
                  <div style={{ textDecoration: 'none' }}>
                    <StyledButton
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={(e) => explorationRequest(e)}
                    >
                      {t('UserMainPage.kesifButonIsbank')}
                    </StyledButton>
                  </div>
                </Box>
              </div>
            </div>
          </Grid>}
        {/* DOKÜMAN YÜKLEME */}
        {/* Doküman yükleme component'i keşif talebinden sonra render'lansın */}
        {userStatus > 3 && !(module === "isbank" || module === "akbank" || module === "garantibbva") && !creditApplicationInfo.applicationExists &&
          <Grid
            item
            lg={5}
            md={5}
            xl={5}
            xs={12}
          >
            <div className={classes.card} style={{ marginBottom: isDesktopOrLaptop ? '40px' : '0px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <h2 className={classes.allh2}>{t('UserMainPage.uploadNow')}</h2>
                <p>{t('UserMainPage.uploadNowText')}</p>
                <Box my={2}>
                  <Link style={{ textDecoration: 'none' }} to="photos">
                    <StyledButton
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {t('UserMainPage.uploadButton')}
                    </StyledButton>
                  </Link>
                </Box>
              </div>
              {isDesktopOrLaptop &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src="./images/upload.png" alt="GES Fizibilite Dosyaları" className={classes.icon} />
                </div>}
            </div>
          </Grid>}
        {/* SÜREÇ NASIL İLERLİYOR */}
        {!(module === "isbank" || module === "akbank" || module === "garantibbva") && !creditApplicationInfo.applicationExists &&
          <Grid
            item
            lg={7}
            md={7}
            xl={7}
            xs={12}
          >
            <div className={classes.cardColumn}>
              <div>
                <h2 className={classes.allh2} style={{ textAlign: 'center' }}>{t('UserMainPage.progress')}</h2>
              </div>
              <div>
                <ProcessTracker moduleType="industrial" />
              </div>
            </div>
          </Grid>}
      </Grid>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MainPage;
