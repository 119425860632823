import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from "react-router-dom";
import Navbar from '../../containers/AlternativeNavbar/Navbar';
import { AiOutlineArrowRight } from 'react-icons/ai';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { IconContext } from 'react-icons/lib';
import { GiReceiveMoney } from 'react-icons/gi';
import { ImPriceTag } from 'react-icons/im';
import { CgTimelapse } from 'react-icons/cg';
import { withStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { changeCookieShow } from '../../services/users/userReducer';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import classes from './PageWrapper.module.css';
import AddressPage from '../AddressPage/AddressPage';
import DrawingPage from '../DrawingPage/DrawingPage';
import RoofSlopePage from '../RoofSlopePage/RoofSlopePage';
import { Helmet } from "react-helmet";
import InvoiceInfoPage from '../InvoiceInfoPage/InvoiceInfoPageRedux';
import SummaryInfoComp from '../SummaryPage/SummaryInfoComp';
import ContactInfoComp from '../SummaryPage/ContactInfoComp';
import Footer from '../../containers/UserPageFooter/UserPageFooter';
import StepProgress from '../../components/StepProgress';
import i18n from '../../i18n';

import {
  changeLocation,
  changeZoom,
  changeAdressValidity,
  changeDetailedAdress,
  changeTempLocation,
  changeTabButonlarAktif,
  changeTempMapZoom
} from '../../services/products/product/reducer';

const StyledAlert = withStyles({
  root: {
    backgroundColor: 'var(--primary-color)'
  }
})(MuiAlert);

function Alert(props) {
    return <StyledAlert elevation={6} variant="filled" {...props} />;
  }

  export default function PageWrapper(props) {
    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const { cookieShow, loggedIn } = useSelector(state => state.users);
    const { firmaKodu, cookiesLink, firstScreenAtEnd } = useSelector(state => state.companyInfo);
    const { stepNumber } = useSelector(state => state.steps);
    const { adminLoggedIn } = useSelector(state => state.admin);
    const {
      yillikKazanc,
      yillikKazancEUR,
      yaklasikMaliyet,
      yaklasikMaliyetEUR,
      yaklasikAmortisman,
      amortismanYilAy
    } = useSelector(state => state.summary);
    const { module } = useSelector(state => state.module);
    const [cookieShowState, setCookieShow] = React.useState(!cookieShow);
    const [solarvisBannerStateLocal, setSolarvisBannerStateLocal] = React.useState(false);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });
  
    const willMount = useRef(true);
    if (willMount.current) {
      willMount.current = false;
      if (loggedIn) {
        dispatch(changeStep({stepNumber: 1}));
      } else {
        // VoltHaus'tan gelirse
        let URLString = window.location.href;
        let url = new URL(URLString);
        if (url.searchParams.get("redirectFrom")) {
          const redirectedLat = Number(url.searchParams.get("latitude"));
          const redirectedLng = Number(url.searchParams.get("longitude"));
          const detailedAddress = url.searchParams.get("address");

          dispatch(changeLocation({latitude: redirectedLat, longitude: redirectedLng}));
          dispatch(changeTempLocation({tempLatitude: redirectedLat, tempLongitude: redirectedLng}));
          dispatch(changeZoom({zoomLevel: 18}));
          dispatch(changeTempMapZoom({tempMapZoom: 18}));
          dispatch(changeAdressValidity({validAdress: true}));
          dispatch(changeTabButonlarAktif({tabButonlarAktif: true}));
          dispatch(changeStep({stepNumber: 1}));
          dispatch(changeDetailedAdress({detailedAdress: detailedAddress}));
        } else {
          dispatch(changeStep({stepNumber: 0}));
        }
      }
    }
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setCookieShow(false);
      dispatch(changeCookieShow({ cookieShow: true }))
    };

    const stepSettings = {
      "firstContactThenSummary": {
        "first": 4,
        "second": 6
      },
      "firstSummaryThenContact": {
        "first": 6,
        "second": 6
      }
    }

    const stepSettingsCompany = () => {
      if (firstScreenAtEnd === "contact") {
        return stepSettings.firstContactThenSummary
      } else {
        return stepSettings.firstSummaryThenContact
      }
    }

    const stepperClasses = () => {
      if (firstScreenAtEnd === "contact") {
        return classes.stepWrapperLast
      } else {
        return classes.stepWrapperLastQuoteSent
      }
    }

    const heightController = () => {
      if (stepNumber === 3) {
        return classes.stepWrapperUnsetHeight
      } else {
        return classes.stepWrapper
      }
    }

    const decideHelmet = () => {
      if (localStorage.getItem('dil') === 'tr' || String(i18n.language).split('-')[0] === 'tr') {
        if (module === "solarvis") {
            return <Helmet>
            <title>{`solarVis | Güneş Enerjisi Santrali Fizibilitesi`}</title>
            <meta
              name="description"
              content="Güneş Enerjisi Santrali Fizibilitesi - Çatınızın güneşlenme potansiyelini keşfedin ve ön fizibilite raporunuzu alın. "
            />
            </Helmet>
        } else {
          return <Helmet>
          <title>{`${firmaKodu} | Güneş Enerjisi Santrali Fizibilitesi`}</title>
          <meta
            name="description"
            content="Güneş Enerjisi Santrali Fizibilitesi - Çatınızın güneşlenme potansiyelini keşfedin ve ön fizibilite raporunuzu alın. "
          />
          </Helmet>
        }
      } else {
        return <Helmet>
          <title>{`${firmaKodu} | Calculate Your Solar Potential`}</title>
          <meta
            name="description"
            content="Get Solar Quote - Discover your roof's solar potential for free and get a solar quote."
          />
          </Helmet>
      }
    };

    if (module === "ibtenerji" || adminLoggedIn) {
      return <Navigate to="/admin"/>
    }
    
    return (
      <>
        {decideHelmet()}
        <main className={classes.mainWrapper}>
          <Navbar/>
          <h1 className={classes.headerOne}>{t('ProgressBar.header')}</h1>
          <div className={classes.outerWrapper}>
              <div className={classes.wrapper}>
                  <div className={classes.solarCalculator}>
                    <div className={classes.anotherWrapper}>
                      <div className={classes.motionDiv}>
                        <div
                          className={
                            stepNumber !== stepSettingsCompany().first
                            ? heightController()
                            : stepperClasses()
                            }
                          style={(stepNumber === 1 && isDesktopOrLaptop)
                            ? { gridTemplateColumns: '2fr 1fr' }
                            : {}
                          }
                        >
                          {stepNumber !== stepSettingsCompany().second &&
                          <div className={classes.stepper}>
                            <div style={{
                              margin: 0,
                              minWidth: 0,
                              height: '100%',
                              width: '100%',
                              backgroundColor: 'white'
                            }}>
                              <div className={classes.leftStepper}>
                                <img src="/images/sun.png" alt="sun icon" style={{width: '40px' }} />
                                <StepProgress />
                                <img src="/images/solar-panel-home.png" alt="solar panel icon" style={{width: '40px' }} />
                              </div>
                            </div>
                            {module !== "isbank" &&
                            <div className={stepNumber === 3 ? classes.rightStepperAnimated : classes.rightStepper}>
                              <div className={classes.costSavingWrapper}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 30 }}>
                                  <GiReceiveMoney />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  {module !== "german_solarvis" && <span className={classes.costSavingText}>{yillikKazanc} ₺</span>}
                                  {module === "german_solarvis" && <span className={classes.costSavingText}>{yillikKazancEUR} €</span>}
                                  <span className={classes.costSavingText2}>{t('ProgressBar.kazanc')}</span>
                                </div>
                              </div>
                              <div className={classes.totalCost}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 24 }}>
                                  <CgTimelapse />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  {module === "arcelikkurumsalcozumler" && <span className={classes.costSavingText}>{yaklasikAmortisman} {t('SummaryPage.yil')}</span>}
                                  {module !== "arcelikkurumsalcozumler" &&
                                  <span className={classes.costSavingText}>
                                    {amortismanYilAy[1] === 0
                                      ? `${amortismanYilAy[0]} ${t('SummaryPage.yil')}`
                                      : `${amortismanYilAy[0]} ${t('SummaryPage.yil')} ${amortismanYilAy[1]} ${t('SummaryPage.ay')}`}
                                  </span>}
                                  <span className={classes.costSavingText2}>{t('ProgressBar.amortisman')}</span>
                                </div>
                              </div>
                              <div className={classes.totalCost}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 24 }}>
                                  <ImPriceTag />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  {module !== "german_solarvis" && <span className={classes.costSavingText}>{yaklasikMaliyet} ₺</span>}
                                  {module === "german_solarvis" && <span className={classes.costSavingText}>{yaklasikMaliyetEUR} €</span>}
                                  <span className={classes.costSavingText2}>{t('ProgressBar.maliyet')}</span>
                                </div>
                              </div>
                            </div>}
                          </div>}
                          {/* {isTabletOrMobile && <Navbar/>} */}
                          {stepNumber === 0 && <AddressPage usedFor="endUser" />}
                          {stepNumber === 1 && <DrawingPage usedFor="endUser" />}
                          {stepNumber === 2 && <RoofSlopePage usedFor="endUser" />}
                          {stepNumber === 3 && <InvoiceInfoPage usedFor="endUser" />}
                          {stepNumber === 4 && firstScreenAtEnd === "contact" && <ContactInfoComp usedFor="endUser" />}
                          {stepNumber === 5 && firstScreenAtEnd === "contact" && <SummaryInfoComp usedFor="endUser" />}
                          {stepNumber === 4 && firstScreenAtEnd === "summary" && <SummaryInfoComp usedFor="endUser" />}
                          {stepNumber === 5 && firstScreenAtEnd === "summary" && <ContactInfoComp usedFor="endUser" />}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          {!(module === "solarVis" || module === "volthaus") &&
          <Snackbar open={cookieShowState} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={handleClose}>
            <Alert onClose={handleClose} severity="info">
              {t('KVKK.kvkk1')}
              <a href={`${cookiesLink}`} target='_blank' rel="noopener noreferrer" style={{ color: 'white' }}>
                {t('KVKK.kvkk2')}
              </a>
              {t('KVKK.kvkk3')} 
            </Alert>
          </Snackbar>}
          {(module === "solarvis" && isDesktopOrLaptop && solarvisBannerStateLocal) &&
          <div className={classes.solarVisPopper}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton aria-label="delete" className={classes.margin} onClick={() => setSolarvisBannerStateLocal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <img src={require('../../assets/resources/integration-solarvis.png')} alt="solarvis integration image" style={{ width: '30%' }} />
              <div style={{ marginLeft: 15 }}>
                <h4>{t('solarvisIntegration.title')}</h4>
                <p style={{ margin: '10px 0px' }}>{t('solarvisIntegration.description')}</p>
                <a href={(localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en') ? "https://solarvis.co/en/contact/" : "https://solarvis.co/iletisim/"} target='_blank' rel="noopener noreferrer">
                  <button
                    className={classes.nextButton}
                  >
                    <span className={classes.startText}>{t('solarvisIntegration.button')}</span>
                    <div className={classes.buttonIcon}>
                      <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                          <AiOutlineArrowRight />
                      </IconContext.Provider>
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>}
          {(module === "solarvis" && isDesktopOrLaptop && !solarvisBannerStateLocal) &&
          <div className={classes.solarVisPopperMinimize}>
            <div className={classes.solarVisPopperMinimizeTextDiv} onClick={() => setSolarvisBannerStateLocal(true)}>
              <p>{t('solarvisIntegration.title')}</p>
            </div>
            <img src={require('../../assets/resources/click_icon.png')} alt="click icon" className={classes.popperExpandIcon} />
          </div>}
          <Footer />
        </main>
        
    </>
    )
  }