import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adminUserId: localStorage.getItem('adminUserId') || '',
  isAdmin: JSON.parse(localStorage.getItem('isAdmin')) || false,
  adminPassword: localStorage.getItem('adminPassword') || '',
  adminMail: localStorage.getItem('adminMail') || '',
  adminLoggedIn: JSON.parse(localStorage.getItem('adminLoggedIn')) || false,
  adminName: localStorage.getItem('adminName') || '',
  adminSurname: localStorage.getItem('adminSurname') || '',
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    changeAdminUserId: (state, action) => {
      state.adminUserId = action.payload.adminUserId;
      localStorage.setItem('adminUserId', action.payload.adminUserId);
    },
    changeIsAdminState: (state, action) => {
        state.isAdmin = action.payload.isAdmin;
        localStorage.setItem('isAdmin', JSON.stringify(action.payload.isAdmin));
      },
    changeAdminPassword: (state, action) => {
      state.adminPassword = action.payload.adminPassword;
      localStorage.setItem('adminPassword', action.payload.adminPassword);
    },
    changeAdminMail: (state, action) => {
        state.adminMail = action.payload.adminMail;
        localStorage.setItem('adminMail', action.payload.adminMail);
      },
    changeAdminLoggedIn: (state, action) => {
      state.adminLoggedIn = action.payload.adminLoggedIn;
      localStorage.setItem('adminLoggedIn', action.payload.adminLoggedIn);
    },
    changeAdminName: (state, action) => {
      state.adminName = action.payload.adminName;
      localStorage.setItem('adminName', action.payload.adminName);
    },
    changeAdminSurname: (state, action) => {
      state.adminSurname = action.payload.adminSurname;
      localStorage.setItem('adminSurname', action.payload.adminSurname);
    },
    resetAdminInfo: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const {
    changeAdminUserId,
    changeIsAdminState,
    changeAdminPassword,
    changeAdminMail,
    changeAdminLoggedIn,
    changeAdminName,
    changeAdminSurname,
    resetAdminInfo
} = adminSlice.actions

export default adminSlice.reducer