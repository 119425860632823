import React from "react";

const KVKKMetni2 = () => {
    return (
        <div>
            <p><strong>Açık Rıza Beyanı</strong></p>
            <br />
            <p><strong>Yalnızca size özel hizmetler sunmamızı ve size ulaşmamızı ister misiniz?</strong> İş Bankası tarafından sizlere özel ürünler, hizmetler ve teklifler oluşturulması, tanıtım, ürün/hizmet teklifi, pazarlama ve kampanya faaliyetlerinde kullanılabilmesi ile tarafınıza uygun hizmetler ve ürünler geliştirilmesi, müşteri memnuniyeti çalışmaları yapılması, Bankamızın, iştirak ve bağlı ortaklıklarımızın mevcut veya yeni ürün çalışmaları ve pazar araştırması ve hedef müşteri grubu tespiti için kişisel verilerinizin işlenmesini ve bu çerçevede bizlerle paylaşmış olduğunuz iletişim bilgilerinizden size ulaşmamızı, bu amaçla verilerinizin yurt içindeki iş ortaklarına, İş Bankası iştirak ve bağlı ortaklıklarına aktarılmasını kabul ediyor musunuz?</p>
        </div>
    );
};

export default KVKKMetni2;