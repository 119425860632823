import React, { useEffect, useState } from "react";
import axiosInstance from "../components/axios/axiosApi";
import { useDispatch } from "react-redux";
import { changeLoggedIn, changeUserId } from "../services/users/userReducer";
import { changeAdminLoggedIn, changeIsAdminState } from "../services/users/adminReducer";
import Spinner from '../components/UI/SunSpinner/SunSpinner'


function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("access");
    if (token) {
      axiosInstance
        .get("/token/verify")
        .then((res) => {
          const data = res.data;
          if (data && data.status) {
            if (data.is_demo === false) {
              if (!data.is_admin) {
                dispatch(changeUserId({ userId: data.musteri_id }));
                dispatch(changeLoggedIn({ loggedIn: true }));
              }
              if (data.is_admin) {
                dispatch(changeAdminLoggedIn({ adminLoggedIn: true }));
                dispatch(changeIsAdminState({ isAdmin: true }));
              }
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Spinner />
  }

  return children;
}

export default AuthProvider;