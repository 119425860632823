import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import classes from './Navbar.module.css';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import {
    withStyles,
    Select,
    MenuItem,
    InputBase,
    Button
  } from '@material-ui/core';
import { FaUser } from 'react-icons/fa';
import { MdLogout, MdRefresh } from 'react-icons/md';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { HiSun } from 'react-icons/hi';
import LoginControl from '../../components/UI/Navbar/LoginControl';
import { resetSummary } from '../../services/products/product/summaryReducer';
import { resetState } from '../../services/products/product/reducer';
import { resetStep } from '../../services/products/product/stepReducer';

const StyledSelect = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
})(Select);

const StyledButtonMobile = withStyles({
    root: {
        backgroundColor: 'var(--secondary-color)',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#4f4860'
        },
        padding: '6px',
        width: '40px',
        minWidth: '40px',
        height: '40px'
    }
})(Button);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    marginRight: 10,
    backgroundColor: 'whitesmoke',
    borderRadius: 4,
    height: 40,
    alignSelf: 'center'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'whitesmoke',
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
    'var(--font-fam)',
    'sans-serif',
    ].join(','),
    '&:focus': {
    borderRadius: 4,
    // borderColor: '#80bdff',
    // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const BootstrapInputMobile = withStyles((theme) => ({
    root: {
        'label + &': {
        marginTop: theme.spacing(3),
        },
        marginRight: 0,
        backgroundColor: 'whitesmoke',
        borderRadius: 4,
        height: 40,
        alignSelf: 'center'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'whitesmoke',
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        'var(--font-fam)',
        'sans-serif',
        ].join(','),
        '&:focus': {
        borderRadius: 4,
        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


function Navbar(props) {

    const { t, } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

    const [language, setLanguage] = React.useState(localStorage.getItem('dil') || String(i18n.language).split('-')[0]);

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
        localStorage.setItem('dil', event.target.value);
        i18n.changeLanguage(event.target.value);
      };

    const dispatch = useDispatch();
    const { lastInfo } = useSelector(state => state.steps);
    const { logoPreviewURL, firmaKodu } = useSelector(state => state.companyInfo);
    const { module } = useSelector(state => state.module);
    const { isim } = useSelector(state => state.summary);
    const { loggedIn } = useSelector(state => state.users);
    const { adminName, adminLoggedIn } = useSelector(state => state.admin);

    const clearCustomerInfo = () => {
        dispatch(resetState());
        dispatch(resetSummary());
        dispatch(resetStep());
        // Admin ile alakalı bilgileri bir değişkende tut geri kalan her şeyi localStorage'dan sil
        const adminUserId = localStorage.getItem('adminUserId') || '';
        const adminMail = localStorage.getItem('adminMail');
        const adminLoggedIn = JSON.parse(localStorage.getItem('adminLoggedIn'));
        const adminName = localStorage.getItem('adminName');
        const adminSurname = localStorage.getItem('adminSurname');
        const adminAccessToken = localStorage.getItem('access');
        const adminRefreshToken = localStorage.getItem('refresh');
        const adminLanguage = localStorage.getItem('dil');
        const adminLanguagei18n = localStorage.getItem('i18nextLng');
        localStorage.clear();
        localStorage.setItem("cacheCleaned", "yes");
        localStorage.setItem('adminUserId',adminUserId);
        localStorage.setItem('adminMail',adminMail);
        localStorage.setItem('adminLoggedIn',adminLoggedIn);
        localStorage.setItem('adminName',adminName);
        localStorage.setItem('adminSurname',adminSurname);
        localStorage.setItem('access',adminAccessToken);
        localStorage.setItem('refresh',adminRefreshToken);
        localStorage.setItem('dil',adminLanguage);
        localStorage.setItem('i18nextLng',adminLanguagei18n);
        // TODO: Access ve refresh tokenları silme
        // Axios'taki mail password'ü admin'e göre ayarlamak gerekiyor
        window.location.reload();
      }

    const clearLocalStorageAndReload = () => {
        localStorage.clear();
        localStorage.setItem("cacheCleaned", "yes");
        window.location.reload();
    }

    /* const willMount = React.useRef(true);
      if (willMount.current) {
        console.log(i18n.language);
        willMount.current = false;
      } */

    return (
        <>
            {isTabletOrMobile && !lastInfo && <header>
                <div className={classes.headerWrapper} style={module === "ibtenerji" ? { height: '100%' } : {}}>
                    <div className={classes.headroom}>
                        <section className={classes.section}>
                            <div className={classes.innerWrapper}>
                                <div className={classes.navigationSection}>
                                    <div className={classes.seperateItems}>
                                        <div className={classes.hrefTag} style={module === "ibtenerji" ? { width: 100 } : {}}>
                                            {firmaKodu !== 'Istanbul GES' &&
                                            <Link to="/" style={{ alignItems: 'center', display: 'flex' }}>
                                                <img 
                                                    src={logoPreviewURL}
                                                    alt={module} 
                                                    className={classes.logo}
                                                />
                                                {module === "akbank" &&
                                                <img 
                                                    src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/logos/electraVis_logo.png"
                                                    alt="solarVis Logo"
                                                    className={classes.logo}
                                                    style={{ marginLeft: '25px' }}
                                                />}
                                            </Link>}
                                            {firmaKodu === 'Istanbul GES' &&
                                            <a href="https://istanbulges.com" style={{ alignItems: 'center', display: 'flex' }}>
                                                <img 
                                                    src={logoPreviewURL}
                                                    alt={module} 
                                                    className={classes.logo}
                                                />
                                            </a>}
                                        </div>
                                        {(adminLoggedIn) &&
                                            <StyledButtonMobile onClick={clearLocalStorageAndReload} aria-describedby={"refresh"} variant="contained">
                                                <IconContext.Provider value={{ color: '#ffffff', size: 13 }}>
                                                    <MdRefresh />
                                                </IconContext.Provider>
                                            </StyledButtonMobile>
                                            }
                                        {(adminLoggedIn) &&
                                            <StyledButtonMobile onClick={clearLocalStorageAndReload} aria-describedby={"logout"} variant="contained">
                                                    <IconContext.Provider value={{ color: '#ffffff', size: 13 }}>
                                                        <MdLogout />
                                                    </IconContext.Provider>
                                            </StyledButtonMobile>
                                        }
                                        {firmaKodu !== 'IBT Solar Home' &&
                                        <StyledSelect
                                            labelId="Change-language"
                                            id="change-language"
                                            value={language}
                                            onChange={handleChangeLanguage}
                                            label="Language"
                                            variant="outlined"
                                            input={<BootstrapInputMobile />}
                                        >
                                            {(firmaKodu !== 'electraVis Global' && firmaKodu !== 'GreenEffect') && <MenuItem value="tr">
                                                <img
                                                    style={{ width: 20 }}
                                                    alt="Turkey"
                                                    src="/images/turkey.svg"
                                                />
                                            </MenuItem>}
                                            <MenuItem value="en">
                                                <img
                                                    style={{ width: 20 }}
                                                    alt="United States"
                                                    src="/images/united-states.svg"
                                                />
                                            </MenuItem>
                                            {(firmaKodu === 'electraVis Global' || firmaKodu === 'GreenEffect') &&
                                            <MenuItem value="el">
                                                <img
                                                    style={{ width: 20 }}
                                                    alt="γιουνάνκα"
                                                    src="/images/greece.png"
                                                />
                                            </MenuItem>}
                                            {(firmaKodu === 'electraVis Global') &&
                                            <MenuItem value="ro">
                                                <img
                                                    style={{ width: 20 }}
                                                    alt="Română"
                                                    src="/images/romania.svg"
                                                />
                                            </MenuItem>}
                                        </StyledSelect>}
                                        {module !== "akbank" &&
                                        <StyledButtonMobile aria-describedby={"profile"} variant="contained">
                                            <Link to="login">
                                                <IconContext.Provider value={{ color: '#ffffff', size: 13 }}>
                                                    <FaUser />
                                                </IconContext.Provider>
                                            </Link>
                                            {/* <span style={{ marginLeft: '8px', color: 'white'  }}>Hoşgeldin {isim} {soyisim}</span> */}
                                        </StyledButtonMobile>}
                                    </div>
                                </div>
                            </div>
                            <div>
                                {isim && loggedIn &&
                                <div style={{marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                                        <IconContext.Provider value={{ color: '#ba8122', size: 25 }}>
                                            <HiSun />
                                        </IconContext.Provider>
                                    <span style={{fontWeight: 'bold', marginLeft: '5px', fontSize: '0.9rem'}}>{t('UserMainPage.hosgeldin')} {isim}!</span>
                                </div>}
                            </div>
                        </section>
                    </div>
                </div>
            </header>}
            {isDesktopOrLaptop && <header className={classes.header}>
                <div className={classes.headerWrapper}>
                    <div className={classes.headroom}>
                        <section className={classes.section}>
                            <div className={classes.innerWrapper}>
                                <div className={classes.navigationSection}>
                                    <header className= {classes.NavigationHeader}>
                                        <div className={classes.hrefTag}>
                                            {firmaKodu !== 'Istanbul GES' &&
                                            <Link to="/" style={{ alignItems: 'center', display: 'flex' }}>
                                                <img 
                                                    src={logoPreviewURL}
                                                    alt={module} 
                                                    className={classes.logo}
                                                />
                                                {module === "akbank" &&
                                                <img 
                                                    src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/logos/electraVis_logo.png"
                                                    alt="solarVis Logo"
                                                    className={classes.logo}
                                                    style={{ marginLeft: '25px' }}
                                                />}
                                            </Link>}
                                            {firmaKodu === 'Istanbul GES' &&
                                            <a href="https://istanbulges.com" style={{ alignItems: 'center', display: 'flex' }}>
                                                <img 
                                                    src={logoPreviewURL}
                                                    alt={module} 
                                                    className={classes.logo}
                                                />
                                            </a>}
                                        </div>
                                        <div className={classes.languageProfileWrapper}>
                                            {(loggedIn && isim) &&  <LoginControl name={isim} />}
                                            {(adminLoggedIn && adminName) &&  <LoginControl name={adminName} />}
                                            {!adminLoggedIn && !(module === "akbank" || module === "ibtenerji") &&
                                            <div className={classes.profile}>
                                                <a style={{ textDecoration: 'none' }} href={`/login`}>
                                                    <IconContext.Provider value={{ color: '#ffffff', size: 13 }}>
                                                        <FaUser />
                                                    </IconContext.Provider>
                                                    {!loggedIn && <span style={{ marginLeft: '8px', color: 'white'  }}>{t('LoginPage.giris')}</span>}
                                                    {loggedIn && <span style={{ marginLeft: '8px', color: 'white'  }}>{t('ProgressBar.hesabınız')}</span>}
                                                </a>
                                            </div>}
                                            {(adminLoggedIn) &&
                                            <div className={classes.profile} style={{ cursor: 'pointer' }} onClick={clearCustomerInfo}>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <IconContext.Provider value={{ color: '#ffffff', size: 18 }}>
                                                        <MdRefresh />
                                                    </IconContext.Provider>
                                                    <span style={{ marginLeft: '8px', color: 'white' }}>Bilgileri Sıfırla</span>
                                                </div>
                                            </div>
                                            }
                                            {(adminLoggedIn) &&
                                            <div className={classes.profile} style={{ cursor: 'pointer' }} onClick={clearLocalStorageAndReload}>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <IconContext.Provider value={{ color: '#ffffff', size: 18 }}>
                                                        <MdLogout />
                                                    </IconContext.Provider>
                                                    <span style={{ marginLeft: '8px', color: 'white' }}>Çıkış Yap</span>
                                                </div>
                                            </div>
                                            }
                                            {firmaKodu !== 'IBT Solar Home' &&
                                            <StyledSelect
                                                labelId="Change-language"
                                                id="change-language"
                                                value={language}
                                                onChange={handleChangeLanguage}
                                                label="Language"
                                                variant="outlined"
                                                input={<BootstrapInput />}
                                            >
                                                {(firmaKodu !== 'electraVis Global' && firmaKodu !== 'GreenEffect') && <MenuItem value="tr">
                                                    <img
                                                        style={{ width: 20, marginRight: 10 }}
                                                        alt="Turkey"
                                                        src="/images/turkey.svg"
                                                    />
                                                    Türkçe
                                                </MenuItem>}
                                                <MenuItem value="en">
                                                    <img
                                                        style={{ width: 20, marginRight: 10 }}
                                                        alt="United States"
                                                        src="/images/united-states.svg"
                                                    />
                                                    English
                                                </MenuItem>
                                                {(firmaKodu === 'electraVis Global' || firmaKodu === 'GreenEffect') &&
                                                <MenuItem value="el">
                                                    <img
                                                        style={{ width: 20, marginRight: 10 }}
                                                        alt="γιουνάνκα"
                                                        src="/images/greece.png"
                                                    />
                                                    γιουνάνκα
                                                </MenuItem>}
                                                {(firmaKodu === 'electraVis Global') &&
                                                <MenuItem value="ro">
                                                    <img
                                                        style={{ width: 20, marginRight: 10 }}
                                                        alt="Română"
                                                        src="/images/romania.svg"
                                                    />
                                                    Română
                                                </MenuItem>}
                                            </StyledSelect>
                                            }
                                        </div>
                                    </header>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </header>}
        </>
    );
}

export default Navbar;