

const demoCred = {
  "solarvis": {
    "username": process.env.REACT_APP_SOLARVISDEFAULT_UI,
    "password": process.env.REACT_APP_SOLARVISDEFAULT_PW
  },
  "electraVis Global": {
    "username": process.env.REACT_APP_SOLARVISGLOBAL_UI,
    "password": process.env.REACT_APP_SOALRVISGLOBAL_PW
  },
  "istanbulges": {
    "username": process.env.REACT_APP_ISTANBULGES_UI,
    "password": process.env.REACT_APP_ISTANBULGES_PW
  },
  "enisolar": {
    "username": process.env.REACT_APP_ENISOLAR_UI,
    "password": process.env.REACT_APP_ENISOLAR_PW
  },
  "cn": {
    "username": process.env.REACT_APP_CN_UI,
    "password": process.env.REACT_APP_CN_PW
  },

  "aksasolar": {
    "username": process.env.REACT_APP_AKSA_UI,
    "password": process.env.REACT_APP_AKSA_PW
  },
  "istrich": {
    "username": process.env.REACT_APP_ISTRICH_UI,
    "password": process.env.REACT_APP_ISTRICH_PW
  },

  "arcelikkurumsalcozumler": {
    "username": process.env.REACT_APP_ARCELIK_UI,
    "password": process.env.REACT_APP_ARCELIK_PW
  },

  "solarvil": {
    "username": process.env.REACT_APP_SOLARVIL_UI,
    "password": process.env.REACT_APP_SOLARVIL_PW
  },

  "solarmesken": {
    "username": process.env.REACT_APP_SOLARMESKEN_UI,
    "password": process.env.REACT_APP_SOLARMESKEN_PW
  },

  "akbank": {
    "username": process.env.REACT_APP_AKBANK_UI,
    "password": process.env.REACT_APP_AKBANK_PW
  },

  "isbank": {
    "username": process.env.REACT_APP_ISBANK_UI,
    "password": process.env.REACT_APP_ISBANK_PW
  },

  "volthaus": {
    "username": process.env.REACT_APP_VOLTHAUS_UI,
    "password": process.env.REACT_APP_VOLTHAUS_PW
  },

  "greeneffect": {
    "username": process.env.REACT_APP_GREENEFFECT_UI,
    "password": process.env.REACT_APP_GREENEFFECT_PW
  },

  "kiracsolar": {
    "username": process.env.REACT_APP_KIRACSOLAR_UI,
    "password": process.env.REACT_APP_KIRACSOLAR_PW
  },

  "ibtenerji": {
    "username": process.env.REACT_APP_IBTSOLAR_UI,
    "password": process.env.REACT_APP_IBTSOLAR_PW
  },

  "german_solarvis": {
    "username": process.env.REACT_APP_GERMANSOLARVIS_UI,
    "password": process.env.REACT_APP_GERMANSOLARVIS_PW
  },

  "aydemplus": {
    "username": process.env.REACT_APP_AYDEMPLUS_UI,
    "password": process.env.REACT_APP_AYDEMPLUS_PW
  },

  "garantibbva": {
    "username": process.env.REACT_APP_GARANTIBBVA_UI,
    "password": process.env.REACT_APP_GARANTIBBVA_PW

  }

};

export default demoCred;
