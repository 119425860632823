/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import axiosInstance from '../../../components/axios/axiosApi';
import { changeUserAPIStatus, changeUserId } from '../../../services/users/userReducer';
import { changeDiscoveryRequest } from '../../../services/products/product/moduleReducer';
import {
  changeLocation,
  changeTempLocation,
  changeCityName,
  changeIlce,
  changeMarker,
  changeStaticMapURL,
  changeDetailedAdress,
  changeDrawingPointsNew
} from '../../../services/products/product/reducer';
import { Container, Grid, Box, Button, withStyles } from '@material-ui/core';
import classes from './UserMainPage.module.css';
import {
  changeIsim,
  changeMail,
  changeSoyisim,
  changeTelefon,
  changeVKN,
  changeTCKN,
  changeTesis,
  changeFatura,
  changeSozlesmeGucu,
  changeSarj,
  changeBatarya,
  changeDailyConsumptionProfile,
  changeMonthlyConsumptionProfile,
  changeConsumptionProfile,
} from '../../../services/products/product/summaryReducer';
import { useMediaQuery } from 'react-responsive';
import Spinner from '../../../components/UI/Spinner/Spinner'; 
import download from 'downloadjs';
import { changeUserSessionCounter } from '../../../services/users/userReducer';

const StyledButton = withStyles({
    root: {
      textTransform: 'none',
      backgroundColor: 'var(--secondary-color)',
      color: 'white'
    }
  })(Button);

const MainPage = () => {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const { discoveryRequest } = useSelector(state => state.module);
  const { userId, loggedIn } = useSelector((state) => state.users);
  const { staticMapURL, detailedAdress } = useSelector(state => state.location);
  const { mail, tesis } = useSelector(state => state.summary);
  const [ loading, setLoading ] = useState(false);

  const [referenceCode, setReferenceCode] = useState('');

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

  const updateUserStatus = async () => {
    await axiosInstance.post('musteri/save?format=json', {
      status: 4,
      language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase(),
      mail: mail,
      subcon_reference_code: referenceCode,
    })
      .then((response) => {
        if (response.data.status) {
          dispatch(changeDiscoveryRequest(true));
          dispatch(changeUserAPIStatus({userStatus: 4}));
        }
      })
      // .catch((error) => console.log(error));
  }

  async function getReport(id) {
    // Define report type
    let reportName = 'GES Ön Fizibilite Raporu.pdf';
    let reportType = 'arcelik_tr';
    if (localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en' || localStorage.getItem('dil') === 'de' || String(i18n.language).split('-')[0] === 'de') {
      reportName = 'SPP Pre-Feasibility Report.pdf';
      reportType = 'arcelik_en';
    }
    setLoading(true);
    await axiosInstance.get(`report/generate?musteri_id=${id}&format=json&is_admin_page=0&report_type=${reportType}`/* , {
      responseType: 'blob'
    } */)
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' }
        );
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        setLoading(false);
        download(file, reportName, );
      })
      // .catch((error) => console.log(error));
  }

  const fetchUserFeasibilityInfo = async (url) => {
    let userSessionCounterTemp;
    let roofArea;

    await axiosInstance.get('/customer/fetch_profile_info/?format=json')
      .then(response => {

        const data = response.data.data;

        if (data.hasOwnProperty('isim') && data.isim) dispatch(changeIsim({ isim: data.isim }));
        if (data.hasOwnProperty('soy_isim') && data.soy_isim) dispatch(changeSoyisim({ soyisim: data.soy_isim }));
        if (data.hasOwnProperty('telefon') && data.telefon) dispatch(changeTelefon({ telefon: data.telefon }));
        if (data.hasOwnProperty('tckn') && data.tckn) dispatch(changeTCKN({ tckn: data.tckn }));
        if (data.hasOwnProperty('vkn') && data.vkn) dispatch(changeVKN({ vkn: data.vkn }));
        if (data.hasOwnProperty('address') && data.address) dispatch(changeDetailedAdress({ detailedAdress: data.address }));
        if (data.hasOwnProperty('username') && data.username) dispatch(changeMail({ mail: data.username }));

      })
      .catch(error => {
        console.log(error);
      })
    
    await axiosInstance.get(`get_user_feasibility_info/?format=json`)
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data;
          roofArea = data.alan;
          userSessionCounterTemp = data.session_counter;
          dispatch(changeUserId({ userId: data.id}));
          dispatch(changeLocation({ latitude: data.enlem, longitude: data.boylam }));
          dispatch(changeTempLocation({ tempLatitude: data.enlem, tempLongitude: data.boylam }));
          dispatch(changeCityName({ cityName: data.il}));
          dispatch(changeIlce({ ilce: data.ilce}));
          dispatch(changeDrawingPointsNew({drawingPointsNew: data.drawing_points}));
          dispatch(changeTesis({ tesis: data.tesis_tipi }));
          dispatch(changeFatura({ fatura: data.fatura_tutari }));
          dispatch(changeSozlesmeGucu({ sGucu: data.sgucu }));
          dispatch(changeBatarya({batarya: data.is_battery}));
          dispatch(changeSarj({sarj: data.is_charger}));
          dispatch(changeUserAPIStatus({userStatus: data.status}));
          if (data.status === 4) {
            dispatch(changeDiscoveryRequest(true));
          }
          if (!detailedAdress) {
            dispatch(changeDetailedAdress({detailedAdress: `${data.il}, ${data.ilce}`}))
          }
          dispatch(changeMarker({markerOn: true}));
          dispatch(changeUserSessionCounter({userSessionCounter: data.session_counter}));
          dispatch(changeStaticMapURL({staticMapURL: data.gmaps_link}));
          if (data.monthly_manual_consumptions) {
            dispatch(changeMonthlyConsumptionProfile({ monthlyConsumptionProfile: data.monthly_manual_consumptions }));
          }
          if (data.daily_consumption_percentages) {
            dispatch(changeDailyConsumptionProfile({ dailyConsumptionProfile: data.daily_consumption_percentages }));
          }
          dispatch(changeConsumptionProfile({ consumptionProfile: data.consumption_profile }));
        }
      })
      .catch(error => {
        // console.log(error);
    });

    if (url) {
      window.location.href = url.origin + url.pathname;
    }
  }

  useEffect(() => {
    fetchUserFeasibilityInfo("");
  }, [loggedIn]);

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  if (loading) {
    return (
      <div style={{minHeight: "90vh", height:"100%"}}>
        <Spinner text="Raporunuz oluşturuluyor.."/>
      </div>
    )
  }

  return (
      <Container 
          maxWidth={"xl"}
          style={{ height: '90vh', backgroundColor:'#F4F6F8' }}
      >
          <Grid 
              container
              spacing={3}
          >
              <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
              >
                  <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-around' }} >
                      <div>
                          <h2 className={classes.allh2}>{t('UserMainPage.reportReady')}</h2>
                          <p style={{ margin: '15px 0px' }}>{t('UserMainPage.reportText')}</p>
                          <Box my={2}>
                              <div style={{ textDecoration: 'none' }} onClick={() => getReport(userId)}>
                                  <StyledButton
                                      size="large"
                                      type="submit"
                                      variant="contained"
                                  >
                                      {t('UserMainPage.reportButton')}
                                  </StyledButton>
                              </div>
                          </Box>
                      </div>
                      {isDesktopOrLaptop &&
                      <div>
                          <img src="./images/report.png" alt="GES Fizibilite Raporu" className={classes.icon}/>
                      </div>}
                  </div>
              </Grid>
              {tesis !== "Mesken" &&
              <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
              >
                  <div 
                    className={classes.card}
                    style={{
                      marginTop: isDesktopOrLaptop ? '40px' : '20px',
                      marginBottom: isDesktopOrLaptop ? '40px' : '20px',
                      justifyContent: 'space-around'
                    }}
                  >
                      {!discoveryRequest &&
                      <div>
                          <h2 className={classes.allh2}>{t('UserMainPage.kesifHizmeti')}</h2>
                          <p style={{ margin: '15px 0px' }}>{t('UserMainPage.arcelikKesif')}</p>
                          {/* <p style={{ margin: '15px 0px', fontSize: '0.9rem' }}>Bu işlemi bir Arçelik bayiisinde yapıyorsanız aşağıdaki kutucuğa bayii kodunu girebilirsiniz.</p>
                          <TextField 
                            fullWidth
                            name="referenceCode"
                            type="number"
                            id="referenceCode"
                            label={(localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en') ? "Reference Code" : "Referans Kodu"}
                            onChange={(event) => setReferenceCode(event.target.value)}
                            value={referenceCode}
                            variant="outlined"
                            size="small"
                          /> */}
                          <Box my={2}>
                              <div style={{ textDecoration: 'none' }} onClick={() => updateUserStatus()}>
                                  <StyledButton
                                      size="large"
                                      type="submit"
                                      variant="contained"
                                  >
                                      {t('UserMainPage.kesifButon')}
                                  </StyledButton>
                              </div>
                          </Box>
                      </div>}
                      {discoveryRequest &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <h2 className={classes.allh2} style={{ fontSize: '1.5rem' }}>{t('UserMainPage.kesifHizmetiAlindi')} <span style={{ textDecoration: 'underline' }}>{t('UserMainPage.kesifHizmetiFree')}</span></h2>
                        </div>
                      }
                      {isDesktopOrLaptop &&
                      <div>
                          <img src="./images/solar-cati-kesif.png" alt="Keşif Hizmeti" className={classes.icon} style={{ width: '200px' }} />
                      </div>}
                  </div>
              </Grid>}
          </Grid>
      </Container>
  );
};

export default MainPage;
