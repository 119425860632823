import React, { useRef } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import classes from "./DrawingPage.module.css";
import { IconContext } from "react-icons/lib";
import { AiOutlineArrowRight } from "react-icons/ai";
import i18n from "../../i18n";
import GoogleMap from "../GoogleMapViewer/Map";
import { useSelector, useDispatch } from "react-redux";
import { changeStep } from "../../services/products/product/stepReducer";
import { changeBaseType } from "../../services/products/product/summaryReducer";
import { useMediaQuery } from "react-responsive";
import {
  changeArea,
  changeAlanTercihi,
  changeMarker,
} from "../../services/products/product/reducer";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import axiosInstance from "../../components/axios/axiosApi";
import axiosInstanceAdmin from "../../components/axios/axiosApiAdmin";
import StepProgress from "../../components/StepProgress";

const MarginDivider = withStyles({
  root: {
    marginTop: 20,
    marginBottom: 20,
  },
})(Divider);

function DrawingPage(props) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { usedFor } = props;
  const { baseType } = useSelector((state) => state.summary);
  const { firmaKodu } = useSelector((state) => state.companyInfo);
  const {
    roofArea,
    markerOn,
    alanTercihi,
    tempLatitude,
    tempLongitude,
    drawingPointsNew,
  } = useSelector((state) => state.location);
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [isDisabledButton, setIsDisabledButton] = React.useState(false);

  const willMount = useRef(true);
  if (willMount.current) {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/cati_alani" });
    willMount.current = false;
  }

  let usedAxiosInstance = axiosInstance;
  if (usedFor === "admin") {
    usedAxiosInstance = axiosInstanceAdmin;
  }

  const handleChangeChoice = (event) => {
    dispatch(changeAlanTercihi({ alanTercihi: event.target.value }));
    if (event.target.value === "manuel") {
      dispatch(changeMarker({ markerOn: true }));
    } else {
      dispatch(changeMarker({ markerOn: false }));
    }
  };

  const handleChange = (newValue) => {
    if (
      newValue === 2 && !["electraVis Global", "GreenEffect", "electraVis", "solarVis Germany"].includes(firmaKodu)) {
      usedAxiosInstance
        .get(
          `region/is_in_service_region_api?latitude=${String(
            tempLatitude.toFixed(4)
          )}&longitude=${String(
            tempLongitude.toFixed(4)
          )}&company_code=${firmaKodu}&service_depth=company&language=${(
            localStorage.getItem("dil") || String(i18n.language).split("-")[0]
          ).toUpperCase()}`
        )
        .then((response) => {
          if (response.data.service_status) {
            if (isMobile || alanTercihi === "manuel") {
              dispatch(changeStep({ stepNumber: 2 }));
            } else {
              dispatch(changeStep({ stepNumber: 3 }));
            }
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      if (newValue === 2) {
        if (isMobile || alanTercihi === "manuel") {
          dispatch(changeStep({ stepNumber: 2 }));
        } else {
          dispatch(changeStep({ stepNumber: 3 }));
        }
      } else if (newValue === 0) {
        dispatch(changeStep({ stepNumber: 0 }));
      }
    }
  };

  const handleAreaInput = (event) => {
    dispatch(changeArea({ roofArea: event.target.value }));
  };

  React.useEffect(() => {
    if (isTabletOrMobile) {
      if(!(roofArea > 15 && markerOn)) {
        setIsDisabledButton(true);
      }
      else {
        setIsDisabledButton(false);
      }
    } else {
      if (alanTercihi === "manuel") {
        if(!(roofArea > 15 && markerOn)) {
          setIsDisabledButton(true);
        }
        else {
          setIsDisabledButton(false);
        }
      } else {
        const invalidSlope = Array(drawingPointsNew[0]).some((point) => point && (Number(point?.slope) < 0 || Number(point?.slope) > 90 || String(point?.slope).length === 0))
        if(drawingPointsNew.length === 0 || invalidSlope) {
          setIsDisabledButton(true);
        }
        else {
          setIsDisabledButton(false);
        }
      }
    
    }
  },[isTabletOrMobile, alanTercihi,roofArea,markerOn,drawingPointsNew])
  // console.log(Array(drawingPointsNew).some((point) => Number(point.slope) < 0 || Number(point.slope) > 90))
  
  React.useEffect(() => {
    console.log(isDisabledButton)
  },[isDisabledButton])

  const decideButtonDisabled = () => {
   
  };

  return (
    <>
      <div className={classes.leftWrapper}>
        {isDesktopOrLaptop && alanTercihi === "cizim" && 
        <>
          <div className={classes.west} >
              {t('RoofSlopePage.bati')}
          </div>
          <div className={classes.east}>
              {t('RoofSlopePage.dogu')}
          </div>
          <div className={classes.south}>
              {t('RoofSlopePage.guney')}
          </div>
          <div className={classes.north}>
              {t('RoofSlopePage.kuzey')}
          </div>
        </>}
        <GoogleMap usedFor={usedFor} />
      </div>
      <div className={classes.rightWrapper}>
        <div className={classes.rightWrapperlv1}>
          <div className={classes.rightWrapperlv2}>
            <div className={classes.title}>
              <h2 className={classes.titleh2}>
                {baseType === "roof"
                  ? t("DrawingPage.catiOlc")
                  : t("DrawingPage.araziOlc")}
              </h2>
              {isDesktopOrLaptop && (
                <>
                  <div style={{ display: "flex" }}>
                    <Radio
                      checked={alanTercihi === "cizim"}
                      onChange={handleChangeChoice}
                      value="cizim"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "Cizim" }}
                    />
                    <span className={classes.spanText}>
                      {baseType === "roof"
                        ? t("DrawingPage.catiDrawingMetin")
                        : t("DrawingPage.araziDrawingMetin")}
                    </span>
                  </div>
                  <MarginDivider variant="middle" />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Radio
                      checked={alanTercihi === "manuel"}
                      onChange={handleChangeChoice}
                      value="manuel"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "Elle alan gir" }}
                    />
                    <span className={classes.spanText}>
                      {baseType === "roof"
                        ? t("DrawingPage.catiManuelMetin")
                        : t("DrawingPage.araziManuelMetin")}
                    </span>
                  </div>
                </>
              )}
              {isTabletOrMobile && (
                <span className={classes.spanText}>
                  {baseType === "roof"
                    ? t("DrawingPage.catiMobile")
                    : t("DrawingPage.araziMobile")}
                </span>
              )}
            </div>
            {(alanTercihi === "manuel" || isTabletOrMobile) && (
              <div className={classes.inputBox}>
                <input
                  type="number"
                  placeholder={
                    baseType === "roof"
                      ? t("DrawingPage.alanLabel")
                      : t("DrawingPage.alanAraziLabel")
                  }
                  name="çatı alanı"
                  onChange={handleAreaInput}
                  className={classes.roofAreaInput}
                  value={roofArea}
                />
                <span className={classes.prefix}>
                  m<sup>2</sup>
                </span>
              </div>
            )}
          </div>
          <div className={classes.formwizard}>
            {isTabletOrMobile && (
              <div
                style={{
                  padding: "0.5rem 50px",
                  display: "grid",
                  gridTemplateColumns: "auto 1fr auto",
                  alignItems: "center",
                  gridGap: "1rem",
                }}
              >
                <img src="/images/sun.png" alt="sun icon" style={{ width: "20px" }} />
                <StepProgress />
                <img
                  src="/images/solar-panel-home.png"
                  alt="solar panel icon"
                  style={{ width: "20px" }}
                />
              </div>
            )}
            <div className={classes.formwizardlv1}>
              <div className={classes.buttonContainer}>
                <button
                  onClick={() => handleChange(0)}
                  className={classes.backButton}
                >
                  <div className={classes.backButtonIcon}>
                    <IconContext.Provider
                      value={{ color: "#ffffff", size: 16 }}
                    >
                      <AiOutlineArrowRight />
                    </IconContext.Provider>
                  </div>
                  <span className={classes.startText}>{t("Buton.geri")}</span>
                </button>
                <button
                  disabled={isDisabledButton}
                  onClick={() => handleChange(2)}
                  className={classes.nextButton}
                >
                  <span className={classes.startText}>{t("Buton.ileri")}</span>
                  <div className={classes.buttonIcon}>
                    <IconContext.Provider
                      value={{ color: "#ffffff", size: 16 }}
                    >
                      <AiOutlineArrowRight />
                    </IconContext.Provider>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DrawingPage;
