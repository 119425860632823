import React, { useEffect } from 'react';

import SunSpinner from '../../components/UI/SunSpinner/SunSpinner';
import axiosInstance from '../../components/axios/axiosApi';

function PaytrRedirect() {

    const callbackPaytr = (merchantOid) => {
        axiosInstance.get('peca/paytr_callback?merchant_oid=' + merchantOid)
            .then((response) => {
                const { data } = response.data;
                const redirectURL = data.redirectURL;
                window.location.href = redirectURL;
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    useEffect(() => {
        let URLString = window.location.href;
        let url = new URL(URLString);
        let merchantOid = url.searchParams.get("merchant_oid");
        callbackPaytr(merchantOid);
    }, []);

    return (
        <>
            <SunSpinner text="" />
        </>
      );
}

export default PaytrRedirect;
