import React, { useState, useRef, useEffect } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../components/axios/axiosApi";
import classes from "./SummaryPage.module.css";
import { IconContext } from "react-icons/lib";
import { Navigate, useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { changeStep } from "../../services/products/product/stepReducer";
import QuoteSent from "../QuoteSentPage/QuoteSent";
import { changeStaticMapURL } from "../../services/products/product/reducer";
import {
  changeIsim,
  changeSoyisim,
  changeTelefon,
  changeMail,
} from "../../services/products/product/summaryReducer";
import {
  changeUserId,
  changeSubconProjectId,
  changeFirstSession,
  changeAuthDialogState,
  changeUserAPIStatus,
  changeLoggedIn,
} from "../../services/users/userReducer";
import i18n from "../../i18n";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import de from "react-phone-input-2/lang/de.json";
import tr from "react-phone-input-2/lang/tr.json";
import {
  Button,
  Input,
  Dialog,
  withStyles,
  Typography
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Checkbox, Modal, Row, Col } from "antd";
import { loginReducer } from "../../utils/func/AuthLogin";
import { changeIsAdminState } from "../../services/users/adminReducer";
import UpdatePhoneNumber from "../../components/UpdatePhoneNumber/UpdatePhoneNumber";
import { numberVerificationSend } from "../../utils/func/NumberVerificationSend";
import UniversalPhoneNumber from "../../components/UniversalPhoneNumber/UniversalPhoneNumber";
import KVKKMetni1 from "../../utils/IsbankKVKKMetni1";
import KVKKMetni2 from "../../utils/IsbankKVKKMetni2";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ContactInfoComp(props) {
  const { usedFor } = props;

  const [contactForm] = Form.useForm();
  const { t } = useTranslation();

  const commonRules = [
    { required: true, message: t("ErrorMessages.fieldRequired") },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNumberUpdateModal, setPhoneNumberUpdateModal] = useState(false);
  const [verificationModalShow, setVerificationModalShow] = useState(false);
  const { userId, firstSession } = useSelector((state) => state.users);

  useEffect(() => {
    return () => {
      // if leave page, close auth dialog
      dispatch(changeAuthDialogState({ authDialogOpen: false }));
    };
  }, []);

  const {
    roofArea,
    cityName,
    ilce,
    tempLatitude,
    tempLongitude,
    drawingPointsNew,
    alanTercihi,
  } = useSelector((state) => state.location);

  const {
    fatura,
    tesis,
    meskenTipi,
    roofTilt,
    sGucu,
    trafoGucu,
    batarya,
    sarj,
    cephe,
    consumptionProfile,
    selectedDCPower,
    dailyConsumptionProfile,
    monthlyConsumptionProfile,
    monthlyConsumptionInput,
  } = useSelector((state) => state.summary);

  const { module, availableModules } = useSelector((state) => state.module);

  const { kvkkLink, firmaKodu, firstScreenAtEnd } = useSelector(
    (state) => state.companyInfo
  );

  const [analysisData, setAnalysisData] = useState({
    panel: null,
    guc: null,
    tlMaliyet: null,
    dolarMaliyet: null,
    uretim: null,
    amortisman: null,
    amortismanGonder: null,
    agac: null,
    co2: null,
    yillikTasarruf: null,
    dolar: null,
    aylikTuketim: null,
    yillikTuketim: null,
    yillikTasarrufUSD: null,
  });

  const initialValues = {
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    // password: "", // deprecated

    kvkk2: false, // common
    kvkk3: false, // common

    kvkk5: false, // arcelik

    kvkk9: false, // isbank
    kvkk10: false, // isbank
    kvkk11: false, // isbank
  };

  const [isbankKVKKDialog1Open, setIsbankKVKKDialog1Open] = useState(false);
  const [isbankKVKKDialog2Open, setIsbankKVKKDialog2Open] = useState(false);

  const handleClickOpen = (dialogNumber) => {
    if (dialogNumber === 1) {
      setIsbankKVKKDialog1Open(true);
    } else if (dialogNumber === 2) {
      setIsbankKVKKDialog2Open(true);
    }
  };

  const handleClose = (dialogNumber) => {
    if (dialogNumber === 1) {
      setIsbankKVKKDialog1Open(false);
    } else if (dialogNumber === 2) {
      setIsbankKVKKDialog2Open(false);
    }
  };

  const handleChange = () => {
    if (firstScreenAtEnd === "contact") {
      dispatch(changeStep({ stepNumber: 3 }));
    } else {
      dispatch(changeStep({ stepNumber: 4 }));
    }
  };

  const { loggedIn, authDialogOpen } = useSelector((state) => state.users);
  let usedAxiosInstance = axiosInstance;
  useEffect(() => {
    if (analysisData.guc) {
      axiosGetUserId(1, { email: contactForm.getFieldValue("email") });
    }
  }, [analysisData]);

  // this function for send number verification to backend when click the button
  const submitHandler = async (values) => {
    localStorage.setItem("mail", values.email);
    const verificationSend = await numberVerificationSend({
      phoneNumber: values.phoneNumber,
      musteriId: localStorage.getItem("userId"),
      email: values.email,
    });
    if (!verificationSend) {
      return;
    }

    const { status, message, foreigner } = verificationSend.data;

    if (status && foreigner) {
      axiosInstance.post('auth_verification_code', {
        musteri_id: localStorage.getItem('userId'),
        verification_code: 111111,
        ...values
      })
        .then((response) => {
          const data = response.data.data;
          if (response.data.status) {
            // await props.musteriSave(); // if response.data.status is true, send to musterisave request
            // await props.login(); // if musteriSave request is done, send to login request
            localStorage.setItem("access", data.access);
            localStorage.setItem("refresh", data.refresh);
            window.location.href = "/login"
          }
        })
    }
    else if (status) setVerificationModalShow(true);
    else if (
      status === false &&
      message === "Lütfen geçerli bir telefon numarası giriniz."
    ) {
      setPhoneNumberUpdateModal(true);
    } else if (
      status === false &&
      message === "Bir sorun oluştu.Lütfen başka bir mail adresi deneyin."
    ) {
      // dispatch auth dialog open
      dispatch(changeAuthDialogState({ authDialogOpen: true }));
    }

    return verificationSend;
  };

  const sendMusteriSave = async () => {
    const values = contactForm.getFieldsValue();
    const authStatePromise = axiosGetUserId(2, contactForm.getFieldsValue());
    const resp = await Promise.resolve(authStatePromise);
    if (!resp.authState) {
      dispatch(changeAuthDialogState({ authDialogOpen: true }));
    } else if (!resp.canSaved) {
      alert(
        "Bu e-posta ile işlem yapılamaz, lütfen farklı bir e-posta ile işlem yapınız."
      );
    } else {
      dispatch(changeIsim({ isim: values.name }));
      dispatch(changeSoyisim({ soyisim: values.surname }));
      dispatch(changeTelefon({ telefon: values.phoneNumber }));
      dispatch(changeMail({ mail: values.email }));
    }
  };

  const findMinMaxLatLngOfDrawings = () => {
    let maxLat;
    let minLat;
    let maxLng;
    let minLng;
    drawingPointsNew.forEach((value) => {
      value.points.forEach((point) => {
        if (!maxLat || point.lat > maxLat) {
          maxLat = point.lat;
        }
        if (!minLat || point.lat < minLat) {
          minLat = point.lat;
        }
        if (!maxLng || point.lng > maxLng) {
          maxLng = point.lng;
        }
        if (!minLng || point.lng < minLng) {
          minLng = point.lng;
        }
      });
    });
    return {
      maxLat: maxLat,
      minLat: minLat,
      maxLng: maxLng,
      minLng: minLng,
    };
  };

  const getDistance = (p1, p2) => {
    var rad = function (x) {
      return (x * Math.PI) / 180;
    };

    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat() - p1.lat());
    var dLong = rad(p2.lng() - p1.lng());
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat())) *
      Math.cos(rad(p2.lat())) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  };

  const calculateMaxRealDistanceFromLatLng = () => {
    const { maxLat, minLat, maxLng, minLng } = findMinMaxLatLngOfDrawings();
    const maxLatlng = new window.google.maps.LatLng(maxLat, maxLng);
    const minLatlng = new window.google.maps.LatLng(minLat, minLng);
    const maxDistance = getDistance(maxLatlng, minLatlng);
    return maxDistance;
  };

  async function axiosGetUserId(saveStatusId, values) {
    let totalArea = 0;
    if (alanTercihi === "cizim" && drawingPointsNew.length > 0) {
      drawingPointsNew.forEach((value) => (totalArea += value.area));
    } else {
      totalArea = roofArea;
    }

    let staticMapURLString = `https://maps.googleapis.com/maps/api/staticmap?size=400x400&maptype=satellite`;

    if (drawingPointsNew.length > 0 && alanTercihi === "cizim") {
      const maxRealDistance = Math.round(calculateMaxRealDistanceFromLatLng());

      // Decide Static Map zoom level according to total roof area
      let tempZoom = 18;
      if (maxRealDistance <= 10) {
        tempZoom = 21;
      } else if (maxRealDistance > 10 && maxRealDistance <= 40) {
        tempZoom = 20;
      } else if (maxRealDistance > 40 && maxRealDistance <= 80) {
        tempZoom = 19;
      } else if (maxRealDistance > 80 && maxRealDistance <= 160) {
        tempZoom = 18;
      } else if (maxRealDistance > 160 && maxRealDistance <= 320) {
        tempZoom = 17;
      } else if (maxRealDistance > 320) {
        tempZoom = 16;
      } else {
        tempZoom = 18;
      }

      staticMapURLString += `&zoom=${tempZoom}`;

      const colorNameListofGoogle = [
        "0xAD1717",
        "0xFB8B24",
        "0xE36414",
        "0x0F4C5C",
      ];
      drawingPointsNew.forEach((tempRoofAreaObject, idxx) => {
        let drawPoints = `&path=color:${colorNameListofGoogle[idxx]}|weight:5|fillcolor:${colorNameListofGoogle[idxx]}|`;
        tempRoofAreaObject.points.forEach((point) => {
          drawPoints += point.lat + "," + point.lng + "|";
        });
        drawPoints = drawPoints.slice(0, -1);
        staticMapURLString += drawPoints;
      });
    } else {
      // Decide Static Map zoom level according to total roof area
      let tempZoom = 18;
      if (totalArea <= 75) {
        tempZoom = 21;
      } else if (totalArea > 75 && totalArea <= 400) {
        tempZoom = 20;
      } else if (totalArea > 400 && totalArea <= 1500) {
        tempZoom = 19;
      } else if (totalArea > 1500 && totalArea <= 4000) {
        tempZoom = 18;
      } else if (totalArea > 4000 && totalArea <= 10000) {
        tempZoom = 17;
      } else if (totalArea > 10000) {
        tempZoom = 16;
      } else {
        tempZoom = 18;
      }
      staticMapURLString = `https://maps.googleapis.com/maps/api/staticmap?center=${tempLatitude},${tempLongitude}&zoom=${tempZoom}&size=400x400&maptype=satellite&markers=color:red%7C${tempLatitude},${tempLongitude}`;
    }
    dispatch(changeStaticMapURL({ staticMapURL: staticMapURLString }));
    let sessionCounter;
    if (firstSession === 1) {
      sessionCounter = true;
      dispatch(changeFirstSession({ firstSession: 0 }));
    } else {
      sessionCounter = false;
    }
    let userIdSend;
    if (saveStatusId === 2 || userId) {
      userIdSend = userId;
    } else if (saveStatusId === 1) {
      userIdSend = "00";
    }

    let authState = true;
    let canSaved = true;
    let saveRequestURL =
      usedFor === "admin" ? "admin/leadgen/musteri_save" : "musteri/save";
    await usedAxiosInstance
      .post(saveRequestURL, {
        musteri_id: userIdSend,
        is_session: sessionCounter,
        png: staticMapURLString,
        il: cityName,
        ilce: ilce,
        enlem: tempLatitude.toFixed(4),
        boylam: tempLongitude.toFixed(4),
        fatura_tutari: fatura,
        tesis_tipi: tesis,
        mesken_tipi: meskenTipi,
        sgucu: sGucu,
        trafoGucu: module === "aksasolar" ? trafoGucu : 0,
        is_battery: Boolean(batarya),
        is_charger: Boolean(sarj),
        language: (
          localStorage.getItem("dil") || String(i18n.language).split("-")[0]
        ).toUpperCase(),
        status: saveStatusId,
        drawing_areas:
          alanTercihi === "cizim" && drawingPointsNew.length > 0
            ? drawingPointsNew
            : [
              {
                area: Number(roofArea),
                slope: Number(roofTilt),
                aspect: Number(cephe),
                points: [],
              },
            ],
        consumption_profile: consumptionProfile,
        monthly_manual_consumptions: monthlyConsumptionProfile,
        daily_consumption_percentages: dailyConsumptionProfile,
        input_dc_guc: selectedDCPower ? Number(selectedDCPower) : null,
      })
      .then((response) => {
        // console.log(response.data)
        const data = response.data.data;
        const message = response.data.message;

        dispatch(changeUserAPIStatus({ userStatus: saveStatusId }));

        if (data["musteri_id"]) {
          dispatch(changeUserId({ userId: data["musteri_id"] }));
        }

        if (data.hasOwnProperty("subcon_project_id")) {
          dispatch(
            changeSubconProjectId({
              subconProjectId: data["subcon_project_id"],
            })
          );
        }
        if (message == "Kullanıcıyla auth olun.") {
          authState = false;
        } else if (
          response.data.case_code &&
          response.data.case_code === "saved_different_company"
        ) {
          canSaved = false;
          alert(message);
        }

        if (data["sms_sent"] === true) {
          setVerificationModalShow(true);
        } else if (data["sms_sent"] === false) {
          alert(data["sms_message"]);
          setPhoneNumberUpdateModal(true);
        }

        if (data["is_user_exists"]) {
          dispatch(changeAuthDialogState({ authDialogOpen: true }));
        }

        return data["musteri_id"];
      })
      .catch();

    return {
      authState: authState,
      canSaved: canSaved,
    };
  }

  async function axiosGet() {
    let sozlesmeGucuTemp = tesis === "Mesken" ? "10" : sGucu;
    let requestURL = `fizibilite?tutar=${fatura}`;
    if (module === "german_solarvis") {
      requestURL = `deutsche/feasibility?&tuketim=${monthlyConsumptionInput}`;
    }
    if (Number(selectedDCPower) === 0) {
      requestURL += `&enlem=${String(tempLatitude.toFixed(4))}&boylam=${String(
        tempLongitude.toFixed(4)
      )}&tesis=${tesis}&sgucu=${sozlesmeGucuTemp}&consumption_profile=${consumptionProfile}&format=json`;
    } else {
      requestURL += `&enlem=${String(tempLatitude.toFixed(4))}&boylam=${String(
        tempLongitude.toFixed(4)
      )}&tesis=${tesis}&sgucu=${sozlesmeGucuTemp}&consumption_profile=${consumptionProfile}&input_dc_guc=${selectedDCPower}&format=json`;
    }

    if (
      !monthlyConsumptionProfile.every((value) => value.consumption === 0) &&
      monthlyConsumptionProfile.length > 0
    ) {
      // Make this list of objects to string with splitted comma that only includes consumption values
      let tempMonthlyConsumptionProfile = "";
      monthlyConsumptionProfile.map(
        (value) =>
          (tempMonthlyConsumptionProfile += `${String(value.consumption)},`)
      );
      tempMonthlyConsumptionProfile = tempMonthlyConsumptionProfile.slice(
        0,
        -1
      );
      requestURL += `&monthly_manual_consumptions=${tempMonthlyConsumptionProfile}`;
    }
    if (
      dailyConsumptionProfile.every((value) => value.consumption !== 0) &&
      dailyConsumptionProfile.length > 0
    ) {
      // Make this list of objects to string with splitted comma that only includes consumption values
      let tempDailyConsumptionProfile = "";
      dailyConsumptionProfile.map(
        (value) =>
          (tempDailyConsumptionProfile += `${String(value.consumption)},`)
      );
      tempDailyConsumptionProfile = tempDailyConsumptionProfile.slice(0, -1);
      requestURL += `&daily_consumption_percentages=${tempDailyConsumptionProfile}`;
    }

    if (drawingPointsNew.length > 0 && alanTercihi === "cizim") {
      let tempDrawingPoints = "";
      drawingPointsNew.map(
        (value) =>
          (tempDrawingPoints += `${value.area},${value.slope},${value.aspect}|`)
      );
      tempDrawingPoints = tempDrawingPoints.slice(0, -1);
      requestURL += `&drawing_areas=${tempDrawingPoints}`;
    } else {
      requestURL += `&drawing_areas=${roofArea},${roofTilt},${cephe}`;
    }
    await usedAxiosInstance.get(requestURL).then((response) => {
      const data = response.data.data;
      setAnalysisData({
        panel: data["panel_sayisi"],
        guc: data["guc"],
        tlMaliyet: data["kdvli_tl_maliyet"],
        dolarMaliyet: data["kdvli_usd_maliyet"],
        uretim: data["yillik_uretim"], // String(Number(response.data['yillik_uretim'])*1000),
        amortisman: data["roi"].slice(0, 4),
        amortismanGonder: data["roi"],
        agac: data["agac"],
        co2: data["co2"],
        yillikTasarruf: data["yillik_ort_tl_tasrrf"],
        dolar: data["dolar"],
        aylikTuketim: data["aylik_tuketim"], // String((Number(response.data['tuketim'])*0.012).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
        yillikTuketim: data["yillik_tuketim"],
        yillikTasarrufUSD: data["yillik_ort_usd_tasrrf"],
      });
    });
  }

  const willMount = useRef(true);
  if (willMount.current) {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/kontak_bilgileri" });
    axiosGet();
    willMount.current = false;
  }

  if (loggedIn && firstScreenAtEnd !== "contact") {
    return <Navigate to="/login" />;
  } else if (loggedIn && firstScreenAtEnd === "contact") {
    dispatch(changeStep({ stepNumber: 5 }));
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  const loginReducerHandle = () => {
    loginReducer({
      values: {
        email: contactForm.getFieldValue("email"),
        password: contactForm.getFieldValue("password"),
      },
    })
      .then((result) => {
        const resp = result.data;

        // alert("Burda");
        if (resp.access) {
          const parsedInfo = parseJwt(resp.access);
          axiosInstance.defaults.headers.Authorization = `Bearer ${result.data.access}`;
          localStorage.setItem("access", result.data.access);
          localStorage.setItem("refresh", result.data.refresh);
          dispatch(changeMail({ mail: contactForm.getFieldValue("email") }));
          if (parsedInfo.isim) {
            dispatch(changeIsim({ isim: parsedInfo.isim }));
          }
          if (parsedInfo.soy_isim) {
            dispatch(changeSoyisim({ soyisim: parsedInfo.soy_isim }));
          }

          const isAdmin = parsedInfo.is_admin;
          if (isAdmin) {
            dispatch(changeIsAdminState({ isAdmin: true }));
            window.location.href = "/admin";
            return;
          }
          // fetchUserFeasibilityInfo();
          // window.location.href = "/"
          dispatch(changeLoggedIn({ loggedIn: true }));
          if (firstScreenAtEnd !== "contact") {
            navigate("/login");
          } else {
            dispatch(changeStep({ stepNumber: 5 }));
          }
        }
        // console.log(res.data);
      })
      .catch((err) => {
        alert("Bir hata oluştu.Lütfen tekrar deneyiniz");
      });
  };

  return (
    <>
      <Modal
        footer={null}
        open={verificationModalShow}
        onCancel={() => setVerificationModalShow(false)}
      >
        <QuoteSent
          url="auth_verification_code"
          formValues={contactForm.getFieldsValue()}
          musteriSave={sendMusteriSave}
          login={loginReducerHandle}
          onCancel={() => setVerificationModalShow(false)}
        />
      </Modal>

      <Form
        autoComplete="off"
        onFinish={submitHandler}
        initialValues={initialValues}
        className={classes.rightWrapperBefore}
        layout="vertical"
        style={{ width: "100%" }}
        scrollToFirstError={{
          behavior: "smooth",
        }}
        form={contactForm}
      >
        <div
          className={
            module === "istanbulges"
              ? classes.rightWrapperlv1BeforeIstanbulGES
              : classes.rightWrapperlv1Before
          }
        >
          <div
            className={classes.rightWrapperlv2Before}
            style={module !== "arcelikurumsalcozumler" && { maxWidth: 600 }}
          >
            {!(availableModules.includes("home") && tesis === "Mesken") && (
              <div className={classes.title}>
                <h2 className={classes.titleh2}>
                  {availableModules.includes("bank")
                    ? t("TeklifAlPage.titleBank")
                    : t("TeklifAlPage.title")}
                </h2>
                <span className={classes.infoText}>
                  {availableModules.includes("bank")
                    ? t("TeklifAlPage.fizibiliteGorBank")
                    : t("TeklifAlPage.fizibiliteGor")}
                </span>
              </div>
            )}
            {availableModules.includes("home") && tesis === "Mesken" && (
              <div className={classes.title}>
                <h2 className={classes.titleh2}>
                  {t("TeklifAlPage.titleVoltHaus")}
                </h2>
                <span className={classes.infoText}>
                  {t("TeklifAlPage.fizibiliteGorVoltHaus")}
                </span>
              </div>
            )}

            <div className={classes.inputContainer}>
              <Row gutter={8}>
                <Col sm={12} xs={24}>
                  <Form.Item
                    rules={[...commonRules]}
                    name="name"
                    label={t("TeklifAlPage.isim")}
                    style={{ width: "100%" }}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder={t("TeklifAlPage.isim")}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item
                    rules={[...commonRules]}
                    name="surname"
                    label={t("TeklifAlPage.soyisim")}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder={t("TeklifAlPage.soyisim")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col sm={12} xs={24}>
                  <Form.Item
                    rules={[
                      ...commonRules,
                      {
                        type: "email",
                        message: t("ErrorMessages.emailError"),
                      },
                    ]}
                    name="email"
                    label={t("TeklifAlPage.email")}
                  >
                    <Input
                      autoComplete={"off"}
                      style={{ width: "100%" }}
                      type="email"
                      placeholder={t("TeklifAlPage.email")}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item
                    rules={[
                      ...commonRules,
                      module === "german_solarvis"
                        ? undefined
                        : {
                          len: 10,
                          message: t("ErrorMessages.phoneError"),
                        },
                    ]}
                    name="phoneNumber"
                    label={t("ProfilePage.phoneLabel")}
                  >
                    <UniversalPhoneNumber
                      setValue={(value) =>
                        contactForm.setFieldValue("phoneNumber", value)
                      }
                      value={String(contactForm.getFieldValue("phoneNumber"))}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {module !== "isbank" && <div>
                <div className={classes.checkboxWrapper}>
                  <div className={classes.checkbox}>
                    <div className={classes.checkboxLabel}>
                      <label>
                        <p>
                          <span>
                            {t("TeklifAlPage.kvkk2")}
                            <a
                              href={`${kvkkLink}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t("TeklifAlPage.kvkk3")}
                            </a>
                            {t("TeklifAlPage.kvkk4")}
                          </span>
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>}
              {module === "arcelikkurumsalcozumler" && (
                <Form.Item
                  valuePropName="checked"
                  name={"kvkk5"}
                  rules={[
                    ...commonRules,
                    {
                      validator: (_, value) => {
                        if (!value)
                          return Promise.reject(
                            t("ErrorMessages.fieldRequired")
                          );
                        else return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Checkbox name={"kvkk5"}>
                    <p>{t("TeklifAlPage.kvkk5")}</p>
                  </Checkbox>
                </Form.Item>
              )}
              {module !== "isbank" && <Form.Item
                name={"kvkk2"}
                valuePropName="checked"
                rules={[
                  ...commonRules,
                  {
                    validator: (_, value) => {
                      if (!value)
                        return Promise.reject(t("ErrorMessages.fieldRequired"));
                      else return Promise.resolve();
                    },
                  },
                ]}
              >
                <Checkbox name="kvkk2" className={classes.checkbox}>
                  <p>
                    {t("TeklifAlPage.kvkk6")} {firmaKodu}{" "}
                    {t("TeklifAlPage.kvkk7")} {firmaKodu}{" "}
                    {t("TeklifAlPage.kvkk8")}
                  </p>
                </Checkbox>
              </Form.Item>}
              {module === "isbank" && (
                <div>
                  <Form.Item
                    name={"kvkk10"}
                    valuePropName="checked"
                    rules={[
                      ...commonRules,
                      {
                        validator: (_, value) => {
                          if (!value)
                            return Promise.reject(
                              t("ErrorMessages.fieldRequired")
                            );
                          else return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Checkbox name={"kvkk10"} className={classes.checkbox}>
                      <p><span onClick={() => handleClickOpen(1)} style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t("TeklifAlPage.kvkkIsbankAydinlatma")}</span><span>{t("TeklifAlPage.kvkkIsbankOkudum")}</span></p>
                    </Checkbox>
                  </Form.Item>
                </div>
              )}
              {module === "isbank" && (
                <div>
                  <Form.Item
                    name={"kvkk11"}
                    valuePropName="checked"
                    rules={[
                      ...commonRules,
                      {
                        validator: (_, value) => {
                          if (!value)
                            return Promise.reject(
                              t("ErrorMessages.fieldRequired")
                            );
                          else return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Checkbox name={"kvkk11"} className={classes.checkbox}>
                      <p><span onClick={() => handleClickOpen(2)} style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t("TeklifAlPage.kvkkIsbankAcikRiza")}</span><span>{t("TeklifAlPage.kvkkIsbankOkudum")}</span></p>
                    </Checkbox>
                  </Form.Item>
                </div>
              )}
              <Form.Item
                valuePropName="checked"
                name={"kvkk3"}
                rules={[
                  ...commonRules,
                  {
                    validator: (_, value) => {
                      if (value === true) return Promise.resolve();
                      else
                        return Promise.reject(t("ErrorMessages.fieldRequired"));
                    },
                  },
                ]}
              >
                <Checkbox name="kvkk3" className={classes.checkbox}>
                  <p>{t("TeklifAlPage.kvkk9")}</p>
                </Checkbox>
              </Form.Item>
              {module === "isbank" && (
                <div>
                  <Form.Item
                    name={"kvkk9"}
                    valuePropName="checked"
                    rules={[
                      ...commonRules,
                      {
                        validator: (_, value) => {
                          if (!value)
                            return Promise.reject(
                              t("ErrorMessages.fieldRequired")
                            );
                          else return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Checkbox name={"kvkk9"} className={classes.checkbox}>
                      <p>{t("TeklifAlPage.kvkkIsbank")}</p>
                    </Checkbox>
                  </Form.Item>
                </div>
              )}
            </div>
            <div className={classes.formwizardUpdate}>
              <div className={classes.formwizardlv1}>
                <div className={classes.buttonContainer}>
                  <button
                    onClick={() => handleChange(3)}
                    className={classes.backButton}
                  >
                    <div className={classes.backButtonIcon}>
                      <IconContext.Provider
                        value={{ color: "#ffffff", size: 16 }}
                      >
                        <AiOutlineArrowRight />
                      </IconContext.Provider>
                    </div>
                    <span className={classes.startText}>{t("Buton.geri")}</span>
                  </button>
                  <button type="submit" className={classes.nextButton}>
                    {firstScreenAtEnd === "contact" && (
                      <span className={classes.finishText}>
                        {t("Buton.ozet")}
                      </span>
                    )}
                    {firstScreenAtEnd !== "contact" && (
                      <span className={classes.finishText}>
                        {t("Buton.ileri")}
                      </span>
                    )}
                    <div className={classes.buttonIcon}>
                      <IconContext.Provider
                        value={{ color: "#ffffff", size: 16 }}
                      >
                        <AiOutlineArrowRight />
                      </IconContext.Provider>
                    </div>
                  </button>
                  <UpdatePhoneNumber
                    submitHandler={submitHandler}
                    open={phoneNumberUpdateModal}
                    contactFormInstance={contactForm}
                    setModalState={setPhoneNumberUpdateModal}
                  />
                  {
                    // if user exists and not logged in show modal to redirect to login page
                    authDialogOpen && (
                      <Modal
                        open={authDialogOpen}
                        onOk={() =>
                          navigate("/login", {
                            replace: true,
                            state: {
                              email: contactForm.getFieldValue("email"),
                            },
                          })
                        }
                        // okText={t("AlreadyExistsModalMessages.btnOk")}
                        // cancelText={t("AlreadyExistsModalMessages.btnCancel")}
                        footer={null}
                        onCancel={() =>
                          dispatch(
                            changeAuthDialogState({ authDialogOpen: false })
                          )
                        }
                      >
                        <p style={{ marginBottom: "12px", marginTop: "4px" }}>
                          {t("AlreadyExistsModalMessages.alreadyExistsMessage")}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            justifyContent: "end",
                            alignItems: "center",
                            gap: "6px",
                          }}
                        >
                          {/* Giriş yap primary renkte material ui componenti ve vazgeç butonu olacak */}
                          <Button
                            onClick={() =>
                              navigate("/login", {
                                replace: true,
                                state: {
                                  email: contactForm.getFieldValue("email"),
                                },
                              })
                            }
                            style={{ marginRight: "10px" }}
                            variant="contained"
                            color="primary"
                          >
                            {t("AlreadyExistsModalMessages.btnOk")}
                          </Button>
                          <Button
                            onClick={() =>
                              dispatch(
                                changeAuthDialogState({
                                  authDialogOpen: false,
                                })
                              )
                            }
                            variant="contained"
                          >
                            {t("AlreadyExistsModalMessages.btnCancel")}
                          </Button>
                        </div>
                      </Modal>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
      {module === "isbank" &&
        <Dialog onClose={() => handleClose(1)} aria-labelledby="aydinlatma-metni" open={isbankKVKKDialog1Open}>
          <DialogTitle id="aydinlatma-metni-title" onClose={() => handleClose(1)}>

          </DialogTitle>
          <DialogContent dividers>
            <KVKKMetni1 />
          </DialogContent>
        </Dialog>
      }
      {module === "isbank" &&
        <Dialog onClose={() => handleClose(2)} aria-labelledby="acik-riza" open={isbankKVKKDialog2Open}>
          <DialogTitle id="acik-riza-title" onClose={() => handleClose(2)}>

          </DialogTitle>
          <DialogContent dividers>
            <KVKKMetni2 />
          </DialogContent>
        </Dialog>
      }
    </>
  );
}
export default ContactInfoComp;
