import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: localStorage.getItem("userId") || "",
  subconProjectId: localStorage.getItem("subconProjectId") || "",
  password: localStorage.getItem("password") || "",
  loggedIn: false,
  cookieShow: localStorage.getItem("cookieShow") || false,
  apiRequestSource: "demo.solarvis.co", // 'api.localhost',
  firstSession: 1,
  authDialogOpen: false,
  userStatus: Number(localStorage.getItem("userAPIStatus")) || 1,
  userSessionCounter: null,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    changeUserId: (state, action) => {
      state.userId = action.payload.userId;
      localStorage.setItem("userId", action.payload.userId);
    },
    changeSubconProjectId: (state, action) => {
      state.subconProjectId = action.payload.subconProjectId;
      localStorage.setItem("subconProjectId", action.payload.subconProjectId);
    },
    changeCookieShow: (state, action) => {
      state.cookieShow = action.payload.cookieShow;
      localStorage.setItem("cookieShow", action.payload.cookieShow);
    },
    changeRequestSource: (state, action) => {
      state.apiRequestSource = action.payload.apiRequestSource;
    },
    changeLoggedIn: (state, action) => {
      state.loggedIn = action.payload.loggedIn;
    },
    changeFirstSession: (state, action) => {
      state.firstSession = action.payload.firstSession;
    },
    changeAuthDialogState: (state, action) => {
      state.authDialogOpen = action.payload.authDialogOpen;
    },
    changeUserAPIStatus: (state, action) => {
      if (action.payload.userStatus > state.userStatus) {
        state.userStatus = action.payload.userStatus;
        localStorage.setItem("userAPIStatus", action.payload.userStatus);
      }
    },
    changeUserSessionCounter: (state, action) => {
      state.userSessionCounter = action.payload.userSessionCounter;
    },
    resetUsers: () => initialState,

    
  },
});

// Action creators are generated for each case reducer function
export const {
  changeUserId,
  changeSubconProjectId,
  changeCookieShow,
  changeRequestSource,
  changeLoggedIn,
  changeFirstSession,
  changeAuthDialogState,
  changeUserAPIStatus,
  changeUserSessionCounter,
  resetUsers,
  
} = userSlice.actions;

export default userSlice.reducer;
