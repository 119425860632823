import { useTranslation } from "react-i18next";
import React from 'react';
import { IconContext } from "react-icons";
import { HiSun } from "react-icons/hi";

export default function LoginControl({ name }) {
  const { t } = useTranslation();

  return (
    <div style={{ marginRight: "30px", display: "flex", alignItems: "center" }}>
      <IconContext.Provider value={{ color: "#ba8122", size: 25 }}>
        <HiSun />
      </IconContext.Provider>
      <span
        style={{ fontWeight: "bold", marginLeft: "5px", fontSize: "1.1rem" }}
      >
        {t("UserMainPage.hosgeldin")} {name}!
      </span>
    </div>
  );
}