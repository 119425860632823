/* eslint-disable prefer-template */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import axiosInstance from "../../../components/axios/axiosApi";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import classes from "../UserDocumentUploadPage.module.css";
import FileUploadComponent from "./FileUploadComponent";
import { useMediaQuery } from "react-responsive";

const FileUploadPage = (props) => {
  const { t } = useTranslation();
  const { documentCategory } = props;

  const [fileNames, setFileNames] = useState([]);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 991px)" });

  const fetchDocumentList = () => {
    axiosInstance
      .get(`subcon/document/project_APIs/end_user_get_all_related_documents`)
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data } = response.data;
          setFileNames(data);
        }
      });
    // .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    fetchDocumentList();
  }, []);

  return (
    <>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <div className={classes.card}>
          <div style={{width: '100%'}}>
            <h2 style={{ fontWeight: "normal", fontSize: "1.5rem" }}>
              {documentCategory.document_category_name}{" "}
              {t("ElectricityBillUpload.upload")}
            </h2>
            <p style={{ margin: "10px 0" }}>
              {documentCategory.document_category_description}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: isDesktopOrLaptop ? "row" : "column",
                gap:"12px",
                width: "100%",
              }}
            >
              <FileUploadComponent
                acceptedTypes=""
                documentCategory={documentCategory}
                maxFiles={1}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: '100%',
                  paddingLeft: "20px",
                }}
              >
                <p style={{ textAlign: "left", marginTop: "20px" }}>
                  {t("ElectricityBillUpload.uploadedBefore")}
                </p>
                {fileNames.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      margin: "10px",
                      justifyContent: "space-between",
                      border: "1px solid #cecece",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <span>{file.document_category_name}</span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <a href={file.document_download_url}>
                        <IconButton aria-label="download">
                          <GetAppIcon />
                        </IconButton>
                      </a>
                      {/* <IconButton
                                                aria-label="download"
                                                onClick={() => deleteDocument(file.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default FileUploadPage;