/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import axiosInstanceAdmin from "../../components/axios/axiosApiAdmin";
import {
  makeStyles,
  withStyles,
  Button,
  Dialog,
  IconButton,
  FormControl,
  Input,
  InputAdornment,
  Tabs,
  Tab,
  Typography,
  Divider,
  FormLabel,
} from "@material-ui/core";
import ConsumptionBarGraph from "../graphs/ConsumptionBarGraph";
import { useMediaQuery } from "react-responsive";
import {
  changeConsumptionProfile,
  changeDailyConsumptionProfile,
  changeMonthlyConsumptionProfile,
} from "../../services/products/product/summaryReducer";
import axiosInstance from "../axios/axiosApi";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const StyledButton = withStyles({
  root: {
    borderRadius: 20,
    marginRight: "1rem",
  },
})(Button);

const StyledDialog = withStyles({
  paper: {
    height: "90%",
  },
})(Dialog);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: "Poppins",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    fontSize: "1.2rem",
  },
}))(DialogTitle);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 400,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function ConsumptionProfileDialog(props) {
  const { t } = useTranslation();
  const { dialogOpenState, setDialogOpenState, usedFor, prevProfile } = props;

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    consumptionProfile,
    monthlyConsumptionProfile,
    dailyConsumptionProfile,
  } = useSelector((state) => state.summary);

  let usedAxiosInstance = axiosInstance;
  if (usedFor === "admin") {
    usedAxiosInstance = axiosInstanceAdmin;
  }

  useEffect(() => {
    if (consumptionProfile !== "custom") {
      setGunlukTuketim(dailyConsumptionProfile);
      setAylikTuketim(monthlyConsumptionProfile);
      // setyillikTotTuk(( / 1000).toFixed(2));
    }
  }, [dailyConsumptionProfile, monthlyConsumptionProfile]);

  const initialYearlyTotalConsumption = monthlyConsumptionProfile.reduce(
    (a, b) => a + Number(b.consumption),
    0
  );

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [gunlukTuketim, setGunlukTuketim] = useState(dailyConsumptionProfile);
  const [aylikTuketim, setAylikTuketim] = useState(monthlyConsumptionProfile);
  const [yillikTotTuk, setyillikTotTuk] = useState(
    (initialYearlyTotalConsumption / 1000).toFixed(2)
  );

  const handleClose = () => {
    setDialogOpenState(false);
    changeConsumptionProfile({ consumptionProfile: prevProfile });
    setGunlukTuketim(dailyConsumptionProfile);
    setAylikTuketim(monthlyConsumptionProfile);
  };

  const saveConsumptionDialog = () => {
    dispatch(changeConsumptionProfile({ consumptionProfile: "custom" }));

    dispatch(
      changeMonthlyConsumptionProfile({
        monthlyConsumptionProfile: aylikTuketim,
      })
    );
    dispatch(
      changeDailyConsumptionProfile({ dailyConsumptionProfile: gunlukTuketim })
    );
    setDialogOpenState(false);
  };

  const handleChangeGunlukTuketim = (event) => {
    // console.log(event.target.name, event.target.value, event.target.id);
    /* setGunlukTuketim({ ...gunlukTuketim, [event.target.name]: event.target.value }); */
    const newData = gunlukTuketim.map((obj) => {
      if (obj.hour === String(event.target.id)) {
        /* console.log(event.target.name, event.target.value);
        console.log(event.target.type); */
        return {
          ...obj,
          consumption: event.target.value && Number(event.target.value),
        };
      }
      return obj;
    });
    setGunlukTuketim(newData);
  };

  useEffect(() => {
    setButtonDisabled(!isValidForm());
  }, [gunlukTuketim, aylikTuketim]);

  useEffect(() => {
    let tempYillikTotTuk = 0;
    aylikTuketim.map((tuketimObj) => {
      tempYillikTotTuk += Number(tuketimObj.consumption);
    });
    setyillikTotTuk((tempYillikTotTuk / 1000).toFixed(2));
  }, [aylikTuketim]);

  const handleOnBlurGunlukTuketim = () => {
    let tempTuketim = 0;
    gunlukTuketim.map((tuketimObj) => {
      if (tuketimObj.hour === "18.00 - 00.00") {
        tempTuketim += 0;
      } else {
        tempTuketim += Number(tuketimObj.consumption);
      }
    });

    const newData = gunlukTuketim.map((obj) => {
      if (obj.hour === "18.00 - 00.00") {
        return {
          ...obj,
          consumption: 100 - tempTuketim,
        };
      }
      return obj;
    });
    setGunlukTuketim(newData);
  };

  const isValidForm = () => {
    let isValid = true;
    gunlukTuketim.map((tuketimObj) => {
      if (
        tuketimObj.consumption === "" ||
        tuketimObj.consumption < 0 ||
        tuketimObj.consumption > 96 ||
        String(tuketimObj.consumption).length > 6
      ) {
        isValid = false;
      }
    });
    aylikTuketim.map((tuketimObj) => {
      if (
        tuketimObj.consumption === "" ||
        tuketimObj.consumption < 0 ||
        String(tuketimObj.consumption).length > 6
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  const handleChangeAylikTuketim = (event) => {
    /* setAylikTuketim({ ...aylikTuketim, [event.target.name]: event.target.value }); */
    let tempYillikTotTuk = 0;
    const newData = aylikTuketim.map((obj) => {
      if (obj.month === String(event.target.id)) {
        /* console.log(event.target.name, event.target.value);
        console.log(event.target.type); */
        tempYillikTotTuk += event.target.value ? Number(event.target.value) : 0;
        return {
          ...obj,
          consumption: event.target.value && Number(event.target.value),
        };
      }
      tempYillikTotTuk += Number(obj.consumption);
      return obj;
    });
    setAylikTuketim(newData);
    setyillikTotTuk((tempYillikTotTuk / 1000).toFixed(2));
  };

  return (
    <div>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogOpenState}
        fullWidth
        maxWidth="lg"
      >
        <StyledDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ fontSize: "1.2rem" }}
        >
          <span style={{ fontSize: "1.2rem" }}>
            {t("ConsumptionProfileDialog.enterDetailedConsumption")}
          </span>
        </StyledDialogTitle>
        <DialogContent dividers>
          <div
            className={classes.root}
            style={
              isDesktopOrLaptop
                ? { flexDirection: "row" }
                : { flexDirection: "column", height: "unset" }
            }
          >
            <div
              style={{
                width: "100%",
                padding: isDesktopOrLaptop ? 20 : 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <div
                  style={{
                    width: isDesktopOrLaptop ? "50%" : "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h5 style={{ fontSize: "1.1rem", fontWeight: "normal" }}>
                    {t("ConsumptionProfileDialog.dailyConsumption")}
                  </h5>
                  <div
                    style={{
                      padding: 10,
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {gunlukTuketim.map((dailyConsumptionObj, idx) => {
                      return (
                        <>
                          <div
                            style={
                              isDesktopOrLaptop
                                ? { padding: "0px 15px", paddingBottom: "10px" }
                                : { padding: "5px" }
                            }
                          >
                            <FormControl>
                              <FormLabel
                                style={{ padding: 0, margin: 0, fontSize: 12 }}
                              >
                                {dailyConsumptionObj.hour}
                              </FormLabel>
                              <Input
                                name={dailyConsumptionObj.hour}
                                type="number"
                                required
                                disabled={
                                  dailyConsumptionObj.hour === "18.00 - 00.00"
                                }
                                value={dailyConsumptionObj.consumption}
                                style={{
                                  width: isDesktopOrLaptop ? 120 : 100,
                                  margin: 0,
                                }}
                                inputProps={{ min: 1, max: 96, step: 1 }}
                                renderSuffix={() => {
                                  return (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  );
                                }}
                                id={dailyConsumptionObj.hour}
                                onChange={handleChangeGunlukTuketim}
                                onBlur={handleOnBlurGunlukTuketim}
                                size="small"
                                error={
                                  dailyConsumptionObj.consumption === "" ||
                                  dailyConsumptionObj.consumption < 0 ||
                                  dailyConsumptionObj.consumption > 100
                                }
                              />
                            </FormControl>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                {isDesktopOrLaptop && <div style={{ width: "50%" }} />}
              </div>
              <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <h5 style={{ fontSize: "1.1rem", fontWeight: "normal" }}>
                    {t("ConsumptionProfileDialog.monthlyConsumption")}
                  </h5>
                </div>
                <div
                  style={{
                    padding: 10,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {aylikTuketim.map((monthlyConsumptionObj, idx) => {
                    return (
                      <>
                        <div
                          style={
                            isDesktopOrLaptop
                              ? {
                                padding: "0px 15px",
                                width: "15%",
                                paddingBottom: "10px",
                              }
                              : { padding: "5px", width: "40%" }
                          }
                        >
                          <FormControl>
                            <FormLabel
                              style={{ padding: 0, margin: 0, fontSize: 12 }}
                            >
                              {monthlyConsumptionObj.month}
                            </FormLabel>
                            <Input
                              name={monthlyConsumptionObj.month}
                              type="number"
                              value={monthlyConsumptionObj.consumption}
                              style={{ width: 100, margin: 0 }}
                              inputProps={{ min: 1, step: 1 }}
                              renderSuffix={() => {
                                return (
                                  <InputAdornment position="end">
                                    kWh
                                  </InputAdornment>
                                );
                              }}
                              id={monthlyConsumptionObj.month}
                              onChange={handleChangeAylikTuketim}
                              size="small"
                            />
                          </FormControl>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div>
                  <span style={{ marginLeft: "25px" }}>
                    {`${t("ConsumptionProfileDialog.yearlyConsumption")}: `}
                    <strong>{`${yillikTotTuk} MWh`}</strong>
                  </span>
                </div>
                <div>
                  <ConsumptionBarGraph barData={aylikTuketim} mB={0} mT={20} />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <StyledButton
                onClick={handleClose}
                variant="outlined"
                color="primary"
              >
                {t("ConsumptionProfileDialog.cancel")}
              </StyledButton>
              <StyledButton
                variant="contained"
                disabled={buttonDisabled}
                color="primary"
                onClick={saveConsumptionDialog}
              >
                {t("ConsumptionProfileDialog.save")}
              </StyledButton>
            </div>
          </div>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}

ConsumptionProfileDialog.propTypes = {
  projectId: PropTypes.number,
  tesisTipi: PropTypes.string,
  sgucu: PropTypes.number,
  yillikTuketim: PropTypes.array,
  saatlikTuketim: PropTypes.array,
  yillikTotalTuketim: PropTypes.number,
  tuketimInput: PropTypes.number,
  periyot: PropTypes.string,
};
