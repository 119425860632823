import axiosInstance from "../../components/axios/axiosApi";

export const recaptchaValidation = async (captchaRef) => {
  const value = await captchaRef.current.getValue();
  if (!value) return false;

  try {
    const { data } = await axiosInstance.post("/recaptcha_validation", {
      recaptchaValue: value,
    });
    if (!data.success) {
      throw new Error("Recaptcha validation failed");
    }
    return true;
  } catch (err) {
    return false;
  }
};
