import React, { useState, useRef, useEffect } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../components/axios/axiosApi";
import axiosInstanceAdmin from "../../../components/axios/axiosApiAdmin";
import classes from "./AdminContact.module.css";
import { IconContext } from "react-icons/lib";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { changeStep } from "../../../services/products/product/stepReducer";
import de from "react-phone-input-2/lang/de.json";
import tr from "react-phone-input-2/lang/tr.json";
import { changeStaticMapURL } from "../../../services/products/product/reducer";
import {
  changeUserId,
  changeSubconProjectId,
  changeFirstSession,
  changeUserAPIStatus,
} from "../../../services/users/userReducer";
import { Dialog, Button, TextField, withStyles } from "@material-ui/core";
import { Form } from "antd";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import i18n from "../../../i18n";
import { resetSummary } from "../../../services/products/product/summaryReducer";
import { resetState } from "../../../services/products/product/reducer";
import { resetStep } from "../../../services/products/product/stepReducer";
import PhoneInput from "react-phone-input-2";

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

const DialogContent = withStyles(() => ({
  root: {
    padding: 20,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 900,
  },
})(Dialog);

function ContactInfoComp(props) {
  const { usedFor } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userId, firstSession } = useSelector((state) => state.users);

  const {
    roofArea,
    cityName,
    ilce,
    tempLatitude,
    tempLongitude,
    drawingPointsNew,
    alanTercihi,
  } = useSelector((state) => state.location);

  const {
    fatura,
    tesis,
    meskenTipi,
    roofTilt,
    sGucu,
    trafoGucu,
    batarya,
    sarj,
    cephe,
    consumptionProfile,
    selectedDCPower,
    dailyConsumptionProfile,
    monthlyConsumptionProfile,
  } = useSelector((state) => state.summary);

  const { module } = useSelector((state) => state.module);

  const { firstScreenAtEnd } = useSelector((state) => state.companyInfo);

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const handleClose = () => {
    setSaveDialogOpen(false);
  };

  const [analysisData, setAnalysisData] = useState({
    panel: null,
    guc: null,
    tlMaliyet: null,
    dolarMaliyet: null,
    uretim: null,
    amortisman: null,
    amortismanGonder: null,
    agac: null,
    co2: null,
    yillikTasarruf: null,
    dolar: null,
    aylikTuketim: null,
    yillikTuketim: null,
    yillikTasarrufUSD: null,
  });

  const [contactInfo, setContactInfo] = useState({
    name: "",
    surname: "",
    phoneNumber: "0(   )    -    ",
    email: "",
  });

  const [validity, setValidity] = useState({
    nameValid: true,
    surnameValid: true,
    phoneNumberValid: true,
    emailValid: true,
  });

  const handleChange = () => {
    if (firstScreenAtEnd === "contact") {
      dispatch(changeStep({ stepNumber: 3 }));
    } else {
      dispatch(changeStep({ stepNumber: 4 }));
    }
  };

  const findMinMaxLatLngOfDrawings = () => {
    let maxLat;
    let minLat;
    let maxLng;
    let minLng;
    drawingPointsNew.forEach((value) => {
      value.points.forEach((point) => {
        if (!maxLat || point.lat > maxLat) {
          maxLat = point.lat;
        }
        if (!minLat || point.lat < minLat) {
          minLat = point.lat;
        }
        if (!maxLng || point.lng > maxLng) {
          maxLng = point.lng;
        }
        if (!minLng || point.lng < minLng) {
          minLng = point.lng;
        }
      });
    });
    return {
      maxLat: maxLat,
      minLat: minLat,
      maxLng: maxLng,
      minLng: minLng,
    };
  };

  const getDistance = (p1, p2) => {
    var rad = function (x) {
      return x * Math.PI / 180;
    };

    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat() - p1.lat());
    var dLong = rad(p2.lng() - p1.lng());
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  };

  const calculateMaxRealDistanceFromLatLng = () => {
    const { maxLat, minLat, maxLng, minLng } = findMinMaxLatLngOfDrawings();
    const maxLatlng = new window.google.maps.LatLng(maxLat, maxLng);
    const minLatlng = new window.google.maps.LatLng(minLat, minLng);
    const maxDistance = getDistance(maxLatlng, minLatlng);
    return maxDistance;
  };

  const checkValidity = (value, inputArea) => {
    let isValid = true;
    if (inputArea === "name") {
      isValid = value && isValid;
      setValidity({
        ...validity,
        nameValid: isValid,
      });
    }

    if (inputArea === "surname") {
      isValid = value && isValid;
      setValidity({
        ...validity,
        surnameValid: isValid,
      });
    }

    if (inputArea === "email") {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
      setValidity({
        ...validity,
        emailValid: isValid,
      });
    }

    if (inputArea === "phoneNumber") {
      // const pattern = /^\d+$/;
      const pattern = /^0[.\(\/]5[0-9][0-9][.\)\/][.\ \/][1-9]([0-9]){2}[.\-\/]([0-9]){4}$/;
      isValid = pattern.test(value) && isValid;
      setValidity({
        ...validity,
        phoneNumberValid: isValid,
      });
    }
  };

  const inputChangeHandler = (event) => {
    const value = event.target.value;
    checkValidity(value, event.target.name);
    setContactInfo({
      ...contactInfo,
      [event.target.name]: value,
    });
  };

  let usedAxiosInstance = axiosInstance;
  if (usedFor === "admin") {
    usedAxiosInstance = axiosInstanceAdmin;
  }

  useEffect(() => {
    if (analysisData.guc) {
      axiosGetUserId(1);
    }
  }, [analysisData]);

  const clearCustomerInfo = () => {
    dispatch(resetState());
    dispatch(resetSummary());
    dispatch(resetStep());
    // Admin ile alakalı bilgileri bir değişkende tut geri kalan her şeyi localStorage'dan sil
    const adminUserId = localStorage.getItem("adminUserId") || "";
    const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    const adminMail = localStorage.getItem("adminMail");
    const adminLoggedIn = JSON.parse(localStorage.getItem("adminLoggedIn"));
    const adminName = localStorage.getItem("adminName");
    const adminSurname = localStorage.getItem("adminSurname");
    const adminAccessToken = localStorage.getItem("access");
    const adminRefreshToken = localStorage.getItem("refresh");
    const adminLanguage = localStorage.getItem("dil");
    const adminLanguagei18n = localStorage.getItem('i18nextLng');
    localStorage.clear();
    localStorage.setItem("cacheCleaned", "yes");
    localStorage.setItem("adminUserId", adminUserId);
    localStorage.setItem("isAdmin", isAdmin);
    localStorage.setItem("adminMail", adminMail);
    localStorage.setItem("adminLoggedIn", adminLoggedIn);
    localStorage.setItem("adminName", adminName);
    localStorage.setItem("adminSurname", adminSurname);
    localStorage.setItem("access", adminAccessToken);
    localStorage.setItem("refresh", adminRefreshToken);
    localStorage.setItem("dil", adminLanguage);
    localStorage.setItem('i18nextLng', adminLanguagei18n);
    // TODO: Access ve refresh tokenları silme
    // Axios'taki mail password'ü admin'e göre ayarlamak gerekiyor
    window.location.reload();
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveDialogOpen(false);
    await usedAxiosInstance
      .post("admin/leadgen/end_user_save", {
        musteri_id: userId,
        name: adminSaveForm.getFieldValue('name'),
        surname: adminSaveForm.getFieldValue('surname'),
        phone: adminSaveForm.getFieldValue('phoneNumber'),
        mail: adminSaveForm.getFieldValue('email'),
      })
      .then((response) => {
        const data = response.data.data;

        if (response.data.status) {
          if (data["musteri_id"]) {
            dispatch(changeUserId({ userId: data["musteri_id"] }));
          }
          clearCustomerInfo();
        } else {
          alert(response.data.message);
        }

      })
      .catch();
  };

  async function axiosGetUserId(saveStatusId) {
    let totalArea = 0;
    if (alanTercihi === "cizim" && drawingPointsNew.length > 0) {
      drawingPointsNew.forEach((value) => (totalArea += value.area));
    } else {
      totalArea = roofArea;
    }

    let staticMapURLString = `https://maps.googleapis.com/maps/api/staticmap?size=400x400&maptype=satellite`;

    if (drawingPointsNew.length > 0 && alanTercihi === "cizim") {
      const maxRealDistance = Math.round(calculateMaxRealDistanceFromLatLng());

      // Decide Static Map zoom level according to total roof area
      let tempZoom = 18;
      if (maxRealDistance <= 10) {
        tempZoom = 21;
      } else if (maxRealDistance > 10 && maxRealDistance <= 40) {
        tempZoom = 20;
      } else if (maxRealDistance > 40 && maxRealDistance <= 80) {
        tempZoom = 19;
      } else if (maxRealDistance > 80 && maxRealDistance <= 160) {
        tempZoom = 18;
      } else if (maxRealDistance > 160 && maxRealDistance <= 320) {
        tempZoom = 17;
      } else if (maxRealDistance > 320) {
        tempZoom = 16;
      } else {
        tempZoom = 18;
      }

      staticMapURLString += `&zoom=${tempZoom}`;

      const colorNameListofGoogle = [
        "0xAD1717",
        "0xFB8B24",
        "0xE36414",
        "0x0F4C5C",
      ];
      drawingPointsNew.forEach((tempRoofAreaObject, idxx) => {
        let drawPoints = `&path=color:${colorNameListofGoogle[idxx]}|weight:5|fillcolor:${colorNameListofGoogle[idxx]}|`;
        tempRoofAreaObject.points.forEach((point) => {
          drawPoints += point.lat + "," + point.lng + "|";
        });
        drawPoints = drawPoints.slice(0, -1);
        staticMapURLString += drawPoints;
      });
    } else {
      // Decide Static Map zoom level according to total roof area
      let tempZoom = 18;
      if (totalArea <= 75) {
        tempZoom = 21;
      } else if (totalArea > 75 && totalArea <= 400) {
        tempZoom = 20;
      } else if (totalArea > 400 && totalArea <= 1500) {
        tempZoom = 19;
      } else if (totalArea > 1500 && totalArea <= 4000) {
        tempZoom = 18;
      } else if (totalArea > 4000 && totalArea <= 10000) {
        tempZoom = 17;
      } else if (totalArea > 10000) {
        tempZoom = 16;
      } else {
        tempZoom = 18;
      }
      staticMapURLString = `https://maps.googleapis.com/maps/api/staticmap?center=${tempLatitude},${tempLongitude}&zoom=${tempZoom}&size=400x400&maptype=satellite&markers=color:red%7C${tempLatitude},${tempLongitude}`;
    }

    dispatch(changeStaticMapURL({ staticMapURL: staticMapURLString }));

    let sessionCounter;
    if (firstSession === 1) {
      sessionCounter = true;
      dispatch(changeFirstSession({ firstSession: 0 }));
    } else {
      sessionCounter = false;
    }

    let userIdSend;
    if (saveStatusId === 2 || userId) {
      userIdSend = userId;
    } else if (saveStatusId === 1) {
      userIdSend = "00";
    }

    let authState = true;
    let canSaved = true;

    let saveRequestURL = "admin/leadgen/musteri_save";

    await usedAxiosInstance
      .post(saveRequestURL, {
        musteri_id: userIdSend,
        is_session: sessionCounter,
        png: staticMapURLString,
        il: cityName,
        ilce: ilce,
        enlem: tempLatitude.toFixed(4),
        boylam: tempLongitude.toFixed(4),
        fatura_tutari: fatura,
        tesis_tipi: tesis,
        mesken_tipi: meskenTipi,
        sgucu: sGucu,
        trafoGucu: module === "aksasolar" ? trafoGucu : 0,
        // isim: adminSaveForm.getFieldValue('name'),
        // soy_isim: adminSaveForm.getFieldValue('surname'),
        // telefon: adminSaveForm.getFieldValue('phoneNumber'),
        // mail: adminSaveForm.getFieldValue('email'),
        is_battery: Boolean(batarya),
        is_charger: Boolean(sarj),
        language: (
          localStorage.getItem("dil") || String(i18n.language).split("-")[0]
        ).toUpperCase(),
        status: saveStatusId,
        drawing_areas:
          alanTercihi === "cizim" && drawingPointsNew.length > 0
            ? drawingPointsNew
            : [
              {
                area: Number(roofArea),
                slope: Number(roofTilt),
                aspect: Number(cephe),
                points: [],
              },
            ],
        consumption_profile: consumptionProfile,
        monthly_manual_consumptions: monthlyConsumptionProfile,
        daily_consumption_percentages: dailyConsumptionProfile,
        input_dc_guc: selectedDCPower ? Number(selectedDCPower) : null,
      })
      .then((response) => {
        const data = response.data.data;

        dispatch(changeUserAPIStatus({ userStatus: saveStatusId }));

        if (data["musteri_id"]) {
          dispatch(changeUserId({ userId: data["musteri_id"] }));
        }

        if (data.hasOwnProperty("subcon_project_id")) {
          dispatch(
            changeSubconProjectId({
              subconProjectId: data["subcon_project_id"],
            })
          );
        }

        return data["musteri_id"];
      })
      .catch();

    return {
      authState: authState,
      canSaved: canSaved,
    };
  }

  async function axiosGet() {
    let sozlesmeGucuTemp = tesis === "Mesken" ? "10" : sGucu;

    let requestURL = `fizibilite?tutar=${fatura}`;

    if (Number(selectedDCPower) === 0) {
      requestURL += `&enlem=${String(tempLatitude.toFixed(4))}&boylam=${String(
        tempLongitude.toFixed(4)
      )}&tesis=${tesis}&sgucu=${sozlesmeGucuTemp}&consumption_profile=${consumptionProfile}&format=json`;
    } else {
      requestURL += `&enlem=${String(tempLatitude.toFixed(4))}&boylam=${String(
        tempLongitude.toFixed(4)
      )}&tesis=${tesis}&sgucu=${sozlesmeGucuTemp}&consumption_profile=${consumptionProfile}&input_dc_guc=${selectedDCPower}&format=json`;
    }

    if (
      !monthlyConsumptionProfile.every((value) => value.consumption === 0) &&
      monthlyConsumptionProfile.length > 0
    ) {
      // Make this list of objects to string with splitted comma that only includes consumption values
      let tempMonthlyConsumptionProfile = "";
      monthlyConsumptionProfile.map(
        (value) =>
          (tempMonthlyConsumptionProfile += `${String(value.consumption)},`)
      );
      tempMonthlyConsumptionProfile = tempMonthlyConsumptionProfile.slice(
        0,
        -1
      );
      requestURL += `&monthly_manual_consumptions=${tempMonthlyConsumptionProfile}`;
    }
    if (
      dailyConsumptionProfile.every((value) => value.consumption !== 0) &&
      dailyConsumptionProfile.length > 0
    ) {
      // Make this list of objects to string with splitted comma that only includes consumption values
      let tempDailyConsumptionProfile = "";
      dailyConsumptionProfile.map(
        (value) =>
          (tempDailyConsumptionProfile += `${String(value.consumption)},`)
      );
      tempDailyConsumptionProfile = tempDailyConsumptionProfile.slice(0, -1);
      requestURL += `&daily_consumption_percentages=${tempDailyConsumptionProfile}`;
    }

    if (drawingPointsNew.length > 0 && alanTercihi === "cizim") {
      let tempDrawingPoints = "";
      drawingPointsNew.map(
        (value) =>
          (tempDrawingPoints += `${value.area},${value.slope},${value.aspect}|`)
      );
      tempDrawingPoints = tempDrawingPoints.slice(0, -1);
      requestURL += `&drawing_areas=${tempDrawingPoints}`;
    } else {
      requestURL += `&drawing_areas=${roofArea},${roofTilt},${cephe}`;
    }

    await usedAxiosInstance
      .get(requestURL)
      .then((response) => {
        const data = response.data.data;
        setAnalysisData({
          panel: data["panel_sayisi"],
          guc: data["guc"],
          tlMaliyet: data["kdvli_tl_maliyet"],
          dolarMaliyet: data["kdvli_usd_maliyet"],
          uretim: data["yillik_uretim"], // String(Number(response.data['yillik_uretim'])*1000),
          amortisman: data["roi"].slice(0, 4),
          amortismanGonder: data["roi"],
          agac: data["agac"],
          co2: data["co2"],
          yillikTasarruf: data["yillik_ort_tl_tasrrf"],
          dolar: data["dolar"],
          aylikTuketim: data["aylik_tuketim"], // String((Number(response.data['tuketim'])*0.012).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
          yillikTuketim: data["yillik_tuketim"],
          yillikTasarrufUSD: data["yillik_ort_usd_tasrrf"],
        });
      })
      .catch();
  }

  const willMount = useRef(true);
  if (willMount.current) {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/kontak_bilgileri" });
    axiosGet();
    willMount.current = false;
  }

  const onSubmitAdminSaveUser = () => {
    setSaveDialogOpen(true);
  };

  const [adminSaveForm] = Form.useForm();

  return (
    <>
      <div className={classes.rightWrapperBefore}>
        <div className={classes.rightWrapperlv1Before}>
          <div className={classes.rightWrapperlv2Before}>
            <div className={classes.title}>
              <h2 className={classes.titleh2}>Müşteri İletişim Bilgileri</h2>
              <span className={classes.infoText}>
                Müşteri bilgilerini bilmiyorsanız yine de kayıt yapabilirsiniz.
              </span>
            </div>
            <Form
              initialValues={{
                name: "",
                surname: "",
                email: "",
                phoneNumber: "",
              }}
              onFinish={onSubmitAdminSaveUser}
              form={adminSaveForm}
              className={classes.inputContainer}
            >
              <div className={classes.inputs}>
                <Form.Item
                  name={"name"}

                  className={classes.input}
                >
                  <TextField
                    fullWidth
                    name="name"
                    id="given-name"
                    autoComplete="given-name"
                    type="text"
                    label={t("TeklifAlPage.isim")}
                    onChange={(event) => inputChangeHandler(event)}
                    value={contactInfo.name}
                    error={!validity.nameValid}
                  />
                </Form.Item>
                <Form.Item
                  name={"surname"}

                  className={classes.input}
                >
                  <TextField
                    fullWidth
                    name="surname"
                    id="family-name"
                    autoComplete="family-name"
                    type="text"
                    label={t("TeklifAlPage.soyisim")}
                    onChange={(event) => inputChangeHandler(event)}
                    value={contactInfo.surname}
                    error={!validity.surnameValid}
                  />
                </Form.Item>
                <Form.Item
                  name={"email"}
                  rules={[
                    { type: "email", message: t("ErrorMessages.emailError") },
                  ]}
                  className={classes.input}
                >
                  <TextField
                    fullWidth
                    id="email"
                    label={t("TeklifAlPage.email")}
                    name="email"
                    autoComplete="email"
                    onChange={(event) => inputChangeHandler(event)}
                    value={contactInfo.email}
                    error={!validity.emailValid}
                  />
                </Form.Item>
                <Form.Item
                  name={"phoneNumber"}
                  className={classes.input}
                >
                  <PhoneInput
                    country={module === "german_solarvis" ? "de" : "tr"}
                    onlyCountries={module !== "german_solarvis" ? ["tr"] : []}
                    placeholder={
                      module === "german_solarvis"
                        ? t("ProfilePage.phoneLabel")
                        : "(5xx) xxx xx xx"
                    }
                    masks={{ tr: "(...) ... .. ..", de: "... ........" }}
                    areaCodes={false}
                    prefix=""
                    autoFormat
                    disableCountryCode
                    // countryCodeEditable={false}
                    containerStyle={{ outline: "none", border: "none" }}
                    localization={module === "german_solarvis" ? de : tr} // for input lang
                    inputProps={{ autoComplete: "tel-national" }}
                    inputStyle={{
                      width: "100%",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      outline: "none",
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      borderRadius: "0px",
                      borderColor: "darkgray",
                    }}

                  />
                </Form.Item>
              </div>
              <div className={classes.formwizardUpdate}>
                <div className={classes.formwizardlv1}>
                  <div className={classes.buttonContainer}>
                    <button
                      onClick={() => handleChange(3)}
                      className={classes.backButton}
                    >
                      <div className={classes.backButtonIcon}>
                        <IconContext.Provider
                          value={{ color: "#ffffff", size: 16 }}
                        >
                          <AiOutlineArrowRight />
                        </IconContext.Provider>
                      </div>
                      <span className={classes.startText}>
                        {t("Buton.geri")}
                      </span>
                    </button>
                    <button type="submit" className={classes.nextButton}>
                      <span className={classes.finishText}>Kaydı Tamamla</span>
                      <div className={classes.buttonIcon}>
                        <IconContext.Provider
                          value={{ color: "#ffffff", size: 16 }}
                        >
                          <AiOutlineArrowRight />
                        </IconContext.Provider>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={saveDialogOpen}
      >
        <DialogContent dividers>
          {!contactInfo.email && (
            <span>
              Müşterinin iletişim adresi olmadan müşteri kaydı yapılacaktır.
              Onaylıyor musunuz?
            </span>
          )}
          {contactInfo.email && (
            <span>
              Müşteri kaydı iletişim bilgileri ile birlikte oluşturulacaktır.
              Onaylıyor musunuz?
            </span>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={submitHandler}
            color="primary"
            variant="contained"
          >
            Evet
          </StyledButton>
          <StyledButton onClick={handleClose} color="primary">
            Hayır
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
}

export default ContactInfoComp;
