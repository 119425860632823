import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import classes from "./NavbarMain.module.css";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import {
  withStyles,
  Select,
  MenuItem,
  InputBase,
  Button,
  Popover,
  Divider,
} from "@material-ui/core";
import { FaUser } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Link, useNavigate } from "react-router-dom";
import { resetUsers } from "../../services/users/userReducer";
import { resetStep } from "../../services/products/product/stepReducer";
import { resetModule } from "../../services/products/product/moduleReducer";
import { resetSummary } from "../../services/products/product/summaryReducer";
import { resetCompanyInfo } from "../../services/products/product/companyInfoReducer";
import { resetConstants } from "../../services/products/product/constants";
import { resetState } from "../../services/products/product/reducer";
import { HiSun } from "react-icons/hi";
import { grey } from "@material-ui/core/colors";

const StyledDivider = withStyles({
  root: {
    backgroundColor: grey[300],
    height: 0.5,
    width: "80%",
  },
})(Divider);

const StyledSelect = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
})(Select);

const StyledButtonDesktop = withStyles({
  root: {
    marginRight: "30px",
    backgroundColor: "var(--secondary-color)",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4f4860",
    },
  },
})(Button);

const StyledButtonMobile = withStyles({
  root: {
    backgroundColor: "var(--secondary-color)",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4f4860",
    },
    padding: "12px",
  },
})(Button);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    marginRight: 30,
    backgroundColor: "whitesmoke",
    borderRadius: 4,
    height: 40,
    alignSelf: "center",
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "whitesmoke",
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["var(--font-fam)", "sans-serif"].join(","),
    "&:focus": {
      borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function Navbar(props) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 991px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [language, setLanguage] = React.useState(
    localStorage.getItem("dil") || String(i18n.language).split("-")[0]
  );

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
    localStorage.setItem("dil", event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const dispatch = useDispatch();
  const { module } = useSelector((state) => state.module);
  const { logoPreviewURL, firmaKodu } = useSelector(
    (state) => state.companyInfo
  );
  const { isim } = useSelector((state) => state.summary);
  const { loggedIn } = useSelector((state) => state.users);

  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem("cacheCleaned", "yes");
    dispatch(resetUsers());
    dispatch(resetCompanyInfo());
    dispatch(resetConstants());
    dispatch(resetModule());
    dispatch(resetState());
    dispatch(resetStep());
    dispatch(resetSummary());
    navigate("/", { replace: true });
    window.location.reload();
  };

  return (
    <>
      {isTabletOrMobile && (
        <header>
          <div className={classes.headerWrapper}>
            <div className={classes.headroom}>
              <section
                className={classes.section}
              >
                <div className={classes.innerWrapper}>
                  <div className={classes.navigationSection}>
                    <div className={classes.seperateItems}>
                      <Link to="/login" className={classes.hrefTag}>
                        <img
                          src={logoPreviewURL}
                          alt={module}
                          className={classes.logo}
                        />
                      </Link>
                      <div className={classes.languageProfileWrapper}>
                        <StyledSelect
                          labelId="Change-language"
                          id="change-language"
                          value={language}
                          onChange={handleChangeLanguage}
                          label="Language"
                          variant="outlined"
                          input={<BootstrapInput />}
                        >
                          {firmaKodu !== "electraVis Global" &&
                            firmaKodu !== "GreenEffect" &&
                            firmaKodu !== "solarVis Germany" && (
                              <MenuItem value="tr">
                                <img
                                  style={{ width: 20 }}
                                  alt="Turkey"
                                  src="/images/turkey.svg"
                                />
                              </MenuItem>
                            )}
                          <MenuItem value="en">
                            <img
                              style={{ width: 20 }}
                              alt="United States"
                              src="/images/united-states.svg"
                            />
                          </MenuItem>
                          {(firmaKodu === "electraVis Global" ||
                            firmaKodu === "GreenEffect") && (
                            <MenuItem value="el">
                              <img
                                style={{ width: 20 }}
                                alt="γιουνάνκα"
                                src="/images/greece.png"
                              />
                            </MenuItem>
                          )}
                          {firmaKodu === "electraVis Global" && (
                            <MenuItem value="ro">
                              <img
                                style={{ width: 20 }}
                                alt="Română"
                                src="/images/romania.svg"
                              />
                            </MenuItem>
                          )}
                          {firmaKodu === "solarVis Germany" && (
                            <MenuItem value="de">
                              <img
                                style={{ width: 20 }}
                                alt="Deutsch"
                                src="/images/germany.svg"
                              />
                            </MenuItem>
                          )}
                        </StyledSelect>
                        <StyledButtonMobile
                          aria-describedby={id}
                          variant="contained"
                          onClick={handleClick}
                        >
                          <IconContext.Provider
                            value={{ color: "#ffffff", size: 13 }}
                          >
                            <FaUser />
                          </IconContext.Provider>
                          {/* <span style={{ marginLeft: '8px', color: 'white'  }}>Hoşgeldin {isim} {soyisim}</span> */}
                        </StyledButtonMobile>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <div className={classes.popover}>
                            <Link
                              className={classes.popoverItem}
                              to="/"
                            >
                              {t("UserMainPage.feasibilityAgain")}
                            </Link>
                            <Link
                              className={classes.popoverItem}
                              to="account"
                            >
                              {t("UserMainPage.profilim")}
                            </Link>
                            <Divider />
                            <span
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              onClick={handleLogout}
                            >
                              {t("UserMainPage.cikis")}
                            </span>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {isim && loggedIn && (
                    <div
                      style={{
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <IconContext.Provider
                        value={{ color: "#ba8122", size: 25 }}
                      >
                        <HiSun />
                      </IconContext.Provider>
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                          fontSize: "0.9rem",
                        }}
                      >
                        {t("UserMainPage.hosgeldin")} {isim}!
                      </span>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </header>
      )}
      {isDesktopOrLaptop && (
        <header className={classes.header}>
          <div className={classes.headerWrapper}>
            <div className={classes.headroom}>
              <section
                className={classes.section}
              >
                <div className={classes.innerWrapper}>
                  <div className={classes.navigationSection}>
                    <header className={classes.NavigationHeader}>
                      <Link to="/login" className={classes.hrefTag}>
                        <img
                          src={logoPreviewURL}
                          alt={module}
                          className={classes.logo}
                        />
                      </Link>
                      <div className={classes.languageProfileWrapper}>
                        {isim && (
                          <div
                            style={{
                              marginRight: "30px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IconContext.Provider
                              value={{ color: "#ba8122", size: 25 }}
                            >
                              <HiSun />
                            </IconContext.Provider>
                            <span
                              style={{
                                fontWeight: "bold",
                                marginLeft: "5px",
                                fontSize: "1.1rem",
                              }}
                            >
                              {t("UserMainPage.hosgeldin")} {isim}!
                            </span>
                          </div>
                        )}
                        <StyledButtonDesktop
                          aria-describedby={id}
                          variant="contained"
                          onClick={handleClick}
                        >
                          <IconContext.Provider
                            value={{ color: "#ffffff", size: 13 }}
                          >
                            <FaUser />
                          </IconContext.Provider>
                          <span style={{ marginLeft: "8px", color: "white" }}>
                            {t("ProgressBar.hesabınız")}
                          </span>
                        </StyledButtonDesktop>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <div className={classes.popover}>
                            <Link
                              className={classes.popoverItem}
                              to="/"
                            >
                              <span>{t("UserMainPage.feasibilityAgain")}</span>
                            </Link>
                            {/* <StyledDivider /> */}
                            <Link
                              className={classes.popoverItem}
                              to="account"
                            >
                              <span>{t("UserMainPage.profilim")}</span>
                            </Link>
                            <StyledDivider />
                            <span
                              className={classes.popoverItem}
                              onClick={handleLogout}
                            >
                              {t("UserMainPage.cikis")}
                            </span>
                          </div>
                        </Popover>
                        <StyledSelect
                          labelId="Change-language"
                          id="change-language"
                          value={language}
                          onChange={handleChangeLanguage}
                          label="Language"
                          variant="outlined"
                          input={<BootstrapInput />}
                        >
                          {firmaKodu !== "electraVis Global" &&
                            firmaKodu !== "GreenEffect" &&
                            firmaKodu !== "solarVis Germany" && (
                              <MenuItem value="tr">
                                <img
                                  style={{ width: 20, marginRight: 10 }}
                                  alt="Turkey"
                                  src="/images/turkey.svg"
                                />
                                Türkçe
                              </MenuItem>
                            )}
                          <MenuItem value="en">
                            <img
                              style={{ width: 20, marginRight: 10 }}
                              alt="United States"
                              src="/images/united-states.svg"
                            />
                            English
                          </MenuItem>
                          {(firmaKodu === "electraVis Global" ||
                            firmaKodu === "GreenEffect") && (
                            <MenuItem value="el">
                              <img
                                style={{ width: 20, marginRight: 10 }}
                                alt="γιουνάνκα"
                                src="/images/greece.png"
                              />
                              γιουνάνκα
                            </MenuItem>
                          )}
                          {firmaKodu === "electraVis Global" && (
                            <MenuItem value="ro">
                              <img
                                style={{ width: 20, marginRight: 10 }}
                                alt="Română"
                                src="/images/romania.svg"
                              />
                              Română
                            </MenuItem>
                          )}
                          {firmaKodu === "solarVis Germany" && (
                            <MenuItem value="de">
                              <img
                                style={{ width: 20, marginRight: 10 }}
                                alt="Deutsch"
                                src="/images/germany.svg"
                              />
                              Deutsch
                            </MenuItem>
                          )}
                        </StyledSelect>
                      </div>
                    </header>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default Navbar;
