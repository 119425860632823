/* eslint-disable prefer-template */
import { useNavigate } from "react-router-dom";
import React, { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
// import qs from 'qs';
import classes from "./Account.module.css";
import QuoteSend from "../../../pages/QuoteSentPage/QuoteSent";
import {
  Box,
  Button,
  Typography,
  withStyles,
  Snackbar,
  OutlinedInput,
} from "@material-ui/core";
// import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
// import { changeAccessToken, changeRefreshToken } from 'src/services/auth/authReducer';
import de from "react-phone-input-2/lang/de.json";
import tr from "react-phone-input-2/lang/tr.json";
import {
  changeIsAdminState,
  changeAdminMail,
  changeAdminLoggedIn,
  changeAdminName,
  changeAdminSurname,
} from "../../../services/users/adminReducer";
import MuiAlert from "@material-ui/lab/Alert";
import axiosInstance from "../../../components/axios/axiosApi";
import axiosInstanceAdmin from "../../../components/axios/axiosApiAdmin";
import Navbar from "../../../containers/LoginNavbar/Navbar";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import i18n from "../../../i18n";
import ReCaptcha from "../../../components/Captcha/ReCaptcha";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Form, Modal } from "antd";
import { loginReducer } from "../../../utils/func/AuthLogin";
import ReactCodeInput from "react-code-input";
import PhoneInput from "react-phone-input-2";
import { changeLoggedIn } from "../../../services/users/userReducer";
import { recaptchaValidation } from "../../../utils/func/recaptcha-validation";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

const LoginView = (props) => {
  const { usedFor } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { module } = useSelector((state) => state.module);
  const { firmaKodu } = useSelector((state) => state.companyInfo);
  const [forgotPassword, setForgotPassword] = useState(false);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState("warning");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 991px)" });

  const [showSmsVerification, setShowSmsVerification] = useState(false);
  const [adminLoginForm] = Form.useForm();

  const [updatePhoneNumberModalOpen, setUpdatePhoneNumberModalOpen] =
    useState(false);

  const reacaptchaRefForLogin = createRef();
  const reacaptchaRefForResetPasswordAdmin = createRef();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState(false);
  };

  const decideHelmet = () => {
    if (
      localStorage.getItem("dil") === "tr" ||
      String(i18n.language).split("-")[0] === "tr"
    ) {
      if (module === "solarvis") {
        return (
          <Helmet>
            <title>{`solarVis | Kullanıcı Girişi`}</title>
            <meta
              name="description"
              content="Fizibilite raporunu gör ve fotoğraflarını yükle."
            />
          </Helmet>
        );
      } else {
        return (
          <Helmet>
            <title>{`${firmaKodu} | Kullanıcı Girişi`}</title>
            <meta
              name="description"
              content="Fizibilite raporunu gör ve fotoğraflarını yükle."
            />
          </Helmet>
        );
      }
    } else {
      return (
        <Helmet>
          <title>{`${firmaKodu} | User Login`}</title>
          <meta
            name="description"
            content="View the feasibility report and upload your photos."
          />
        </Helmet>
      );
    }
  };

  const handleSubmit = () => {
    let domainSend;
    if (String(window.location.host) === "localhost:3000") {
      domainSend = "demotest.solarvis.co";
    } else if (String(window.location.host) === "localhost:3002") {
      domainSend = "isb-test.solarvis.co";
    } else {
      domainSend = window.location.host;
    }

    // axiosInstance
    //   .post(`/token/?domain=${domainSend}`, {
    //     username: values.email,
    //     password: values.password,
    //   })
    //   .then((result) => {
    //     const resp = result.data;

    //     if (
    //       "case_code" in resp &&
    //       resp.case_code === "saved_different_company"
    //     ) {
    //       alert(resp.message);
    //     }

    //     if ("access" in resp) {
    //       const parsedInfo = parseJwt(resp.access);
    //       const isAdmin = parsedInfo.is_admin;
    //       if (!isAdmin) {
    //         alert("Bu işlemi yapmaya yetkiniz yoktur!");
    //         return;
    //       }

    //       axiosInstance.defaults.headers.Authorization = `Bearer ${result.data.access}`;
    //       localStorage.setItem("access", result.data.access);
    //       localStorage.setItem("refresh", result.data.refresh);
    //       dispatch(changeAdminLoggedIn({ adminLoggedIn: true }));
    //       dispatch(changeAdminMail({ adminMail: values.email }));
    //       if (parsedInfo.isim) {
    //         dispatch(
    //           changeAdminName({ adminName: parsedInfo.isim })
    //         );
    //       }
    //       if (parsedInfo.soy_isim) {
    //         dispatch(
    //           changeAdminSurname({
    //             adminSurname: parsedInfo.soy_isim,
    //           })
    //         );
    //       }
    //       dispatch(
    //         changeAdminPassword({
    //           adminPassword: values.password,
    //         })
    //       );
    //       dispatch(changeIsAdminState({ isAdmin: true }));

    //       navigate("/admin", { replace: true });
    //     }
    //   })
    //   .catch((error) => {
    //     if (
    //       error.response.status === 401 &&
    //       error.response.data.detail ===
    //         "No active account found with the given credentials"
    //     ) {
    //       alert(t("LoginPage.hataliGiris"));
    //     } else {
    //       alert(t("LoginPage.hataliGiris2"));
    //     }
    //   })
    //   .finally(() => {
    //     window.grecaptcha.reset();
    //   });
  };

  const login = async (values) => {
    if (reacaptchaRefForLogin.current.getValue()?.length === 0) {
      alert(t("LoginPage.captchaError"));
      return;
    }
    const recaptcha_validation = await recaptchaValidation(
      reacaptchaRefForLogin
    );
    if (!recaptcha_validation) {
      alert(t("LoginPage.captchaError"));
      reacaptchaRefForLogin.current.reset();
      return;
    }
    loginReducer({
      values,
    })
      .then((result) => {
        const resp = result.data;
        // if resp.status is true, open the verification modal
        if (resp.status) {
          if(resp.foreigner) {
            axiosInstance.post("verify_single_sign_on", {
              email: adminLoginForm.getFieldValue('email'),
              verification_code: 111111
            })
            .then(async (response) => {
              const data = response.data.data;
              if (response.data.status) {
                // await props.musteriSave(); // if response.data.status is true, send to musterisave request
                // await props.login(); // if musteriSave request is done, send to login request
                localStorage.setItem("access", data.access);
                localStorage.setItem("refresh", data.refresh);
      
                setSnackbarMessage(response.data.message);
                setSnackbarType("success");
                setSnackbarState(true);
      
                setTimeout(() => {
                  window.location.href = "/admin"
                }, 250);
              } else {
                setSnackbarMessage(response.data.message);
                setSnackbarType("warning");
                setSnackbarState(true);
              }
            })
          }
          else {
            setShowSmsVerification(true);
          }
        } else if (
          resp.status === false &&
          String(resp.message).includes(
            "lütfen telefon numaranızı güncelleyin."
          )
        ) {
          setUpdatePhoneNumberModalOpen(true);
        } else {
          alert(resp.message);
        }
        if (
          typeof resp.case_code !== "undefined" &&
          resp.case_code === "saved_different_company"
        ) {
          reacaptchaRefForLogin.current.reset();
          alert(resp.message);
        }
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.data.detail ===
            "No active account found with the given credentials"
        ) {
          alert(t("LoginPage.hataliGiris"));
        } else {
          alert(t("LoginPage.hataliGiris"));
        }
        reacaptchaRefForLogin.current.reset();
      });
  };

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  const updatePhoneNumber = (values) => {
    axiosInstance
      .post("update_phone_number", {
        email: adminLoginForm.getFieldValue("email"),
        verification_code: values.verification_code,
        updated_phone: values.updated_phone_number,
      })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          alert(message);
          setUpdatePhoneNumberModalOpen(false);
          const { access, refresh } = res.data.data;
          const parsedJwt = parseJwt(access);
          localStorage.setItem("access", access);
          localStorage.setItem("refresh", refresh);
          if (parsedJwt.is_admin) {
            dispatch(changeIsAdminState({ isAdmin: true }));
            dispatch(changeAdminLoggedIn({ adminLoggedIn: true }));
            dispatch(changeAdminMail({ adminMail: values.email }));
            if (parsedJwt.isim) {
              dispatch(changeAdminName({ adminName: parsedJwt.isim }));
            }
            if (parsedJwt.soy_isim) {
              dispatch(
                changeAdminSurname({
                  adminSurname: parsedJwt.soy_isim,
                })
              );
            }
            navigate("/admin", { replace: true });
          } else {
            dispatch(changeLoggedIn({ loggedIn: true }));
            navigate("/login", { replace: true });
          }
        } else {
          alert(message);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Telefon numarası güncellenirken bir problem oluştu");
      });
  };

  return (
    <>
      {decideHelmet()}
      <Modal
        open={showSmsVerification}
        onCancel={() => setShowSmsVerification(false)}
        footer={null}
      >
        <QuoteSend
          usedFor="login|admin"
          url="verify_single_sign_on"
          formValues={adminLoginForm.getFieldsValue()}
          onCancel={() => setShowSmsVerification(false)}
        />
      </Modal>
      <Modal
        open={updatePhoneNumberModalOpen}
        onCancel={() => setUpdatePhoneNumberModalOpen(false)}
        title="Telefon Numarnızı Güncelleyin"
        footer={null}
      >
        <p style={{ marginBottom: "16px" }}>
          Doğrulama kodu hesabınıza kayıtlı e-posta adresinize gönderildi
        </p>
        <Form
          initialValues={{
            updated_phone_number: "",
            verification_code: "",
          }}
          layout="vertical"
          onFinish={updatePhoneNumber}
        >
          <Form.Item
            label="Doğrulama Kodu"
            required
            name={"verification_code"}
            rules={[
              { required: true, message: "Alan zorunludur" },
              {
                len: 6,
                message: "Doğrulama kodu 6 haneli olmalıdır.",
              },
            ]}
          >
            <ReactCodeInput fields={6} autoFocus type="number" />
          </Form.Item>
          <Form.Item
            name="updated_phone_number"
            required
            rules={[{ required: true, message: "Alan zorunludur" }]}
            label="Yeni Telefon Numaranız"
          >
            <PhoneInput
              country={module === "german_solarvis" ? "de" : "tr"}
              onlyCountries={module !== "german_solarvis" ? ["tr"] : []}
              placeholder={
                module === "german_solarvis"
                  ? t("ProfilePage.phoneLabel")
                  : "(5xx) xxx xx xx"
              }
              masks={{ tr: "(...) ... .. ..", de: "... ........" }}
              areaCodes={false}
              prefix=""
              autoFormat
              disableCountryCode
              // countryCodeEditable={false}
              containerStyle={{ outline: "none", border: "none" }}
              localization={module === "german_solarvis" ? de : tr} // for input lang
              inputProps={{ autoComplete: "tel-national" }}
              inputStyle={{
                width: "100%",
                paddingTop: "12px",
                paddingBottom: "12px",
                outline: "none",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                borderRadius: "0px",
                borderColor: "darkgray",
              }}
            />
          </Form.Item>
          <Form.Item
            style={{ margin: 12, display: "flex", justifyContent: "end" }}
          >
            <Button variant="contained" type="submit" color="primary">
              {t("LoginPage.send")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className={classes.pageWrapper}>
        <div className={classes.container}>
          <div className={classes.loginWrapper}>
            <Navbar />

            <Form
              form={adminLoginForm}
              initialValues={{
                email: "",
              }}
              onFinish={login}
              className={classes.form}
              style={{ marginTop: "12vh", width: "100%" }}
            >
              <Box mb={3}>
                <Typography color="textPrimary" variant="h4">
                  {t("AdminPages.adminLogin")}
                </Typography>
              </Box>
              {/* <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label={t("LoginPage.email")}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    /> */}
              <Form.Item
                required
                name="email"
                rules={[
                  { required: true, message: "Alan zorunludur" },
                  {
                    type: "email",
                    message: "Lütfen geçerli bir e-posta adresi girin",
                  },
                ]}
              >
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name="email"
                  type="email"
                  placeholder={t("LoginPage.email")}
                  variant="outlined"
                  style={{ marginBottom: 8 }}
                />
              </Form.Item>

              <ReCaptcha captchaRef={reacaptchaRefForLogin} />
              <Box my={2}>
                <StyledButton
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t("LoginPage.giris")}
                </StyledButton>
              </Box>
            </Form>
          </div>
          {isDesktopOrLaptop && (
            <div className={classes.iconWrapper}>
              <img
                src="https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/leadgen_website_images/isbank/isb-home-image.jpeg"
                alt="solar_panel"
                className={classes.solarImage}
              />
            </div>
          )}
        </div>
        <Snackbar
          open={snackbarState}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default LoginView;
