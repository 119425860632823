import React from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Navbar from '../../../containers/AlternativeNavbar/Navbar';
import MuiAlert from '@material-ui/lab/Alert';
import { IconContext } from 'react-icons/lib';
import { GiReceiveMoney } from 'react-icons/gi';
import { ImPriceTag } from 'react-icons/im';
import { CgTimelapse } from 'react-icons/cg';
import { withStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import classes from './PageWrapper.module.css';
import AddressPage from '../../AddressPage/AddressPage';
import DrawingPage from '../../DrawingPage/DrawingPage';
import RoofSlopePage from '../../RoofSlopePage/RoofSlopePage';
import { Helmet } from "react-helmet";
import InvoiceInfoPage from '../../InvoiceInfoPage/InvoiceInfoPageRedux';
import SummaryInfoComp from '../../SummaryPage/SummaryInfoComp';
import QuoteSent from '../../QuoteSentPage/QuoteSent';
import AdminContact from '../Contact/AdminContact';
import Footer from '../../../containers/UserPageFooter/UserPageFooter';
import i18n from '../../../i18n';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { resetSummary } from '../../../services/products/product/summaryReducer';
import { resetState } from '../../../services/products/product/reducer';
import { resetStep } from '../../../services/products/product/stepReducer';

import {
  Button,
  Dialog
} from '@material-ui/core';

const DialogContent = withStyles(() => ({
  root: {
    padding: 20,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 900
  }
})(Dialog);

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'var(--secondary-color)',
    color: 'white'
  }
})(Button);

const StyledAlert = withStyles({
  root: {
    backgroundColor: 'var(--primary-color)'
  }
})(MuiAlert);

function Alert(props) {
    return <StyledAlert elevation={6} variant="filled" {...props} />;
  }

  export default function PageWrapper(props) {
    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [clearCustomerDialogOpen, setClearCustomerDialogOpen] = React.useState(false);

    const handleClose = () => {
      setClearCustomerDialogOpen(false);
    };

    const { firmaKodu, firstScreenAtEnd } = useSelector(state => state.companyInfo);
    const { stepNumber, lastInfo } = useSelector(state => state.steps);
    const { userId } = useSelector(state => state.users);
    const { yillikKazanc, yillikKazancEUR, yaklasikMaliyet, yaklasikMaliyetEUR, yaklasikAmortisman, amortismanYilAy} = useSelector(state => state.summary);
    const { module } = useSelector(state => state.module);

    const stepSettings = {
      "firstContactThenSummary": {
        "first": 4,
        "second": 6
      },
      "firstSummaryThenContact": {
        "first": 6,
        "second": 6
      }
    }

    const stepSettingsCompany = () => {
      if (firstScreenAtEnd === "contact") {
        return stepSettings.firstContactThenSummary
      } else {
        return stepSettings.firstSummaryThenContact
      }
    }

    const stepperClasses = () => {
      if (firstScreenAtEnd === "contact") {
        return classes.stepWrapperLast
      } else {
        return classes.stepWrapperLastQuoteSent
      }
    }

    const heightController = () => {
      if (stepNumber === 3) {
        return classes.stepWrapperUnsetHeight
      } else {
        return classes.stepWrapper
      }
    }

    const decideHelmet = () => {
      if (localStorage.getItem('dil') === 'tr' || String(i18n.language).split('-')[0] === 'tr') {
        if (module === "solarvis") {
            return <Helmet>
            <title>{`solarVis Admin | Güneş Enerjisi Proje Teklifi`}</title>
            </Helmet>
        } else {
          return <Helmet>
          <title>{`${firmaKodu} Admin | Güneş Enerjisi Proje Teklifi`}</title>
          </Helmet>
        }
      } else {
        return <Helmet>
          <title>{`${firmaKodu} Admin | Calculate Your Solar Potential`}</title>
          </Helmet>
      }
    };

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });

    if (!JSON.parse(localStorage.getItem('adminLoggedIn'))) {
      return <Navigate to="/admin/login"/>
    }

    const clearCustomerInfo = () => {
      dispatch(resetState());
      dispatch(resetSummary());
      dispatch(resetStep());
      // Admin ile alakalı bilgileri bir değişkende tut geri kalan her şeyi localStorage'dan sil
      const adminUserId = localStorage.getItem('adminUserId') || '';
      const isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
      const adminPassword = localStorage.getItem('adminPassword');
      const adminMail = localStorage.getItem('adminMail');
      const adminLoggedIn = JSON.parse(localStorage.getItem('adminLoggedIn'));
      const adminName = localStorage.getItem('adminName');
      const adminSurname = localStorage.getItem('adminSurname');
      const adminAccessToken = localStorage.getItem('access');
      const adminRefreshToken = localStorage.getItem('refresh');
      const adminLanguage = localStorage.getItem('dil');
      const adminLanguagei18n = localStorage.getItem('i18nextLng');
      localStorage.clear();
      localStorage.setItem("cacheCleaned", "yes");
      localStorage.setItem('adminUserId',adminUserId);
      localStorage.setItem('isAdmin',isAdmin);
      localStorage.setItem('adminPassword',adminPassword);
      localStorage.setItem('adminMail',adminMail);
      localStorage.setItem('adminLoggedIn',adminLoggedIn);
      localStorage.setItem('adminName',adminName);
      localStorage.setItem('adminSurname',adminSurname);
      localStorage.setItem('access',adminAccessToken);
      localStorage.setItem('refresh',adminRefreshToken);
      localStorage.setItem('dil',adminLanguage);
      localStorage.setItem('i18nextLng',adminLanguagei18n);
      // TODO: Access ve refresh tokenları silme
      // Axios'taki mail password'ü admin'e göre ayarlamak gerekiyor
      window.location.reload();
    }
    
    return (
      <>
        {decideHelmet()}
        <main className={classes.mainWrapper}>
          <Navbar usedFor="admin"/>
          <h1 className={classes.headerOne}>{t('ProgressBar.header')}</h1>
          <div className={classes.outerWrapper}>
              <div className={classes.wrapper}>
                  <div className={classes.solarCalculator}>
                    <div className={classes.anotherWrapper}>
                      <div className={classes.motionDiv}>
                        <div
                          className={stepNumber !== stepSettingsCompany().first ? heightController() : stepperClasses() }
                          style={(stepNumber === 1 && isDesktopOrLaptop)
                            ? { gridTemplateColumns: '2fr 1fr' }
                            : {}
                          }
                        >
                          {stepNumber !== stepSettingsCompany().second &&
                          <div className={classes.stepper}>
                            <div style={{
                              margin: 0,
                              minWidth: 0,
                              height: '100%',
                              width: '100%',
                              backgroundColor: 'white'
                            }}>
                              <div className={classes.leftStepper}>
                                <h3>{t('AdminPages.adminActivity')}</h3>
                                {!userId &&
                                <span>{t('AdminPages.newCustomerRegister')}</span>}
                                {userId &&
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                  }}
                                >
                                  <span><strong>{userId}</strong>{t('AdminPages.projectNumberText')}</span>
                                  <StyledButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      setClearCustomerDialogOpen(true);
                                    }}
                                  >
                                    {t('AdminPages.newRegister')}
                                  </StyledButton>
                                  <a
                                    href={`${process.env.REACT_APP_CRM_ENDPOINT}projects/${userId}`}
                                    target="_blank"
                                    style={{ textDecoration: 'none', marginLeft: 5 }}
                                  >
                                    <StyledButton
                                      size="small"
                                      type="button"
                                      variant="contained"
                                      style={{ textDecoration: 'none' }}
                                    >
                                      {t('AdminPages.showInAdminPanel')}
                                    </StyledButton>
                                  </a>
                                </div>}
                              </div>
                            </div>
                            <div className={stepNumber === 3 ? classes.rightStepperAnimated : classes.rightStepper}>
                              <div className={classes.costSavingWrapper}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 30 }}>
                                  <GiReceiveMoney />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  {module !== "german_solarvis" && <span className={classes.costSavingText}>{yillikKazanc} ₺</span>}
                                  {module === "german_solarvis" && <span className={classes.costSavingText}>{yillikKazancEUR} €</span>}
                                  <span className={classes.costSavingText2}>{t('ProgressBar.kazanc')}</span>
                                </div>
                              </div>
                              <div className={classes.totalCost}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 24 }}>
                                  <CgTimelapse />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  {module === "arcelikkurumsalcozumler" && <span className={classes.costSavingText}>{yaklasikAmortisman} {t('SummaryPage.yil')}</span>}
                                  {module !== "arcelikkurumsalcozumler" &&
                                  <span className={classes.costSavingText}>
                                    {amortismanYilAy[1] === 0
                                      ? `${amortismanYilAy[0]} ${t('SummaryPage.yil')}`
                                      : `${amortismanYilAy[0]} ${t('SummaryPage.yil')} ${amortismanYilAy[1]} ${t('SummaryPage.ay')}`}
                                  </span>}
                                  <span className={classes.costSavingText2}>{t('ProgressBar.amortisman')}</span>
                                </div>
                              </div>
                              <div className={classes.totalCost}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 24 }}>
                                  <ImPriceTag />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  {module !== "german_solarvis" && <span className={classes.costSavingText}>{yaklasikMaliyet} ₺</span>}
                                  {module === "german_solarvis" && <span className={classes.costSavingText}>{yaklasikMaliyetEUR} €</span>}
                                  <span className={classes.costSavingText2}>{t('ProgressBar.maliyet')}</span>
                                </div>
                              </div>
                            </div>
                          </div>}
                          {/* {isTabletOrMobile && <Navbar/>} */}
                          {stepNumber === 0 && <AddressPage usedFor="admin" />}
                          {stepNumber === 1 && <DrawingPage usedFor="admin" />}
                          {stepNumber === 2 && <RoofSlopePage usedFor="admin" />}
                          {stepNumber === 3 && <InvoiceInfoPage usedFor="admin" />}
                          {stepNumber === 4 && <SummaryInfoComp usedFor="admin" />}
                          {stepNumber === 5 && <AdminContact usedFor="admin" />}
                          {stepNumber === 6 && <QuoteSent usedFor="admin" />}
                          {/* {stepNumber === 5 && <QuoteSent />} */}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={clearCustomerDialogOpen}>
            <DialogContent dividers>
              <span>{t('AdminPages.ask1')}</span>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => clearCustomerInfo()} color="primary" variant="contained">
                {t('AdminPages.yes')}
              </Button>
              <Button onClick={handleClose} color="primary">
                {t('LoginPage.kapat')}
              </Button>
            </DialogActions>
          </StyledDialog>
          <Footer />
        </main>
        
    </>
    )
  }