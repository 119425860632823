/* global google */
import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon,
  OverlayView,
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import classes from "./ReactGoogleMapExample.module.css";
import {
  FormControl,
  Button,
  TextField,
  Snackbar,
  InputBase,
  withStyles,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import {
  changeLocation,
  changeTempLocation,
  changeMarker,
  changeArea,
  changeInfoWindow,
  changeCurrentZoom,
  changeTempMapZoom,
  changeIlce,
  changeCityName,
  changePlaka,
  changeDrawingPointsNew,
} from "../../services/products/product/reducer";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconContext } from "react-icons/lib";
import { FaDraftingCompass, FaCompass } from "react-icons/fa";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import axios from "axios";

import geolib from "geolib";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledButton = withStyles({
  root: {
    borderRadius: 20,
    marginRight: "1rem",
  },
})(Button);

const BootstrapSelect = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: "0.85rem",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

class Map extends Component {
  constructor(props) {
    super(props);
    this.shapes = [];
    this.colorList = ["#ad1717", "#FB8B24", "#E36414", "#0F4C5C"];
    this.state = {
      currentMode: "draw",
      showError: false,
      enlem: null,
      boylam: null,
      start: true,
      startMobile: true,
      mobileDrawButton: false,
      markerLat: null,
      marketLng: null,
      open: true,
      imageSize: {
        width: 96,
        height: 96,
      },
    };
    this.handleOverlayComplete = this.handleOverlayComplete.bind(this);
    this.deleteShapes = this.deleteShapes.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  mapMounted = (ref) => {
    this.map = ref;
  };

  /* componentDidMount() {
    this.setState({
      markerLat: this.props.latitude,
      marketLng: this.props.longitude
    });
  } */

  componentDidMount() {
    setTimeout(() => {
      this.props.dispatch(
        changeLocation({
          latitude: this.map.getCenter().lat() + 0.0000000001,
          longitude: this.map.getCenter().lng() + 0.0000000001,
        })
      );
    }, 500);
  }

  handleOverlayComplete(e) {
    // this.deleteShapes();

    const shape = e.overlay;
    shape.type = e.type;
    let areaGoogle = google.maps.geometry.spherical.computeArea(
      shape.getPath()
    );
    //console.log(shape.getPath().getArray());

    if (areaGoogle < 15) {
      this.setState({
        showError: true,
      });
      this.shapes.push(shape);
      this.shapes.forEach((shape) => shape.setMap(null));
    } else {
      this.setState({
        enlem: shape.getPath().getAt(0).lat(),
        boylam: shape.getPath().getAt(0).lng(),
        currentMode: null,
      });

      shape.setEditable(false);
      this.shapes.push(shape);
      //localStorage.setItem('cizim', JSON.stringify(shape));
      localStorage.setItem("alan", Math.round(String(Math.round(areaGoogle))));
      //let drawPoints = [];
      let drawPointsString = "";
      let tempPolygonPoints = [];
      let areaCenterLatList = [];
      let areaCenterLngList = [];
      shape
        .getPath()
        .getArray()
        .map((point, index) => {
          //console.log(point.lat());
          //drawPoints.push([point.lat(), point.lng()]);
          if (shape.getPath().getArray().length - 1 === index) {
            drawPointsString += String(point.lat()) + "," + String(point.lng());
          } else {
            drawPointsString +=
              String(point.lat()) + "," + String(point.lng()) + "|";
          }

          //Alanın tam ortasına imleç koymak için listeye kaydet sonrasında ortalamasını al
          areaCenterLatList.push(Number(point.lat()));
          areaCenterLngList.push(Number(point.lng()));

          // Define the LatLng coordinates for the polygon's path. Geri geldiğinde tekrar çizili olması için.
          tempPolygonPoints.push({
            lat: Number(point.lat()),
            lng: Number(point.lng()),
          });
          return point;
        });
      //console.log(tempPolygonPoints);

      this.props.dispatch(
        changeDrawingPointsNew({
          drawingPointsNew: [
            ...this.props.drawingPointsNew,
            {
              points: tempPolygonPoints,
              area: Math.round(String(Math.round(areaGoogle))),
              slope: 5,
              aspect: 0,
              areaColor: this.colorList[this.props.drawingPointsNew.length % 4],
              knownSlope: false,
              selectedSlopeChoice: 0,
            },
          ],
        })
      );

      let average = (array) => array.reduce((a, b) => a + b) / array.length;
      this.props.dispatch(
        changeCurrentZoom({ currentZoom: this.map.getZoom() })
      );
      this.props.dispatch(
        changeTempLocation({
          tempLatitude: average(areaCenterLatList),
          tempLongitude: average(areaCenterLngList),
        })
      );
      // this.props.dispatch(changeLocation({latitude: average(areaCenterLatList), longitude: average(areaCenterLngList)}));
      this.props.dispatch(changeMarker({ markerOn: true }));
      this.getCityDistrict(
        average(areaCenterLatList),
        average(areaCenterLngList)
      );
    }
    //console.log(this.state.chosenCoord);
    //google.maps.drawing.DrawingManager.setDrawingMode(null);
  }

  // toggleSelection(shape) {
  //   if (shape.getEditable() === true) shape.setEditable(false);
  //   else shape.setEditable(true);
  // }

  deleteShapes() {
    this.shapes.forEach((shape) => shape.setMap(null));
    this.setState({
      currentMode: "draw",
    });
    this.props.dispatch(changeArea({ roofArea: 0 }));
    this.props.dispatch(changeDrawingPointsNew({ drawingPointsNew: [] }));
    this.props.dispatch(changeMarker({ markerOn: false }));
  }

  deleteShape(idxx) {
    /* this.shapes[index].setMap(null); */
    this.shapes.forEach((shape) => {
      if (Number(idxx) === Number(this.shapes.indexOf(shape))) {
        shape.setMap(null);
      } else {
        // console.log('x');
      }
    });
    this.shapes.splice(idxx, 1);
    this.props.dispatch(
      changeLocation({
        latitude: this.map.getCenter().lat() + 0.0000000001,
        longitude: this.map.getCenter().lng() + 0.0000000001,
      })
    );
    this.props.dispatch(
      changeDrawingPointsNew({
        drawingPointsNew: this.props.drawingPointsNew.filter(
          (bolge) => this.props.drawingPointsNew.indexOf(bolge) !== idxx
        ),
      })
    );
  }

  startDrawButton() {
    this.setState((prevState) => ({
      ...prevState,
      currentMode: "draw",
    }));
  }

  switchToDrawMode() {
    this.setState({
      currentMode: "draw",
      start: false,
    });
    this.props.dispatch(changeInfoWindow({ infoWindow: true }));
  }

  switchToDrawModeMobile() {
    this.setState({
      currentMode: "draw",
      mobileDrawButton: false,
    });
  }

  switchToStartState() {
    this.setState({
      //currentMode: "draw",
      startMobile: false,
      mobileDrawButton: true,
    });
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      showError: false,
    });
  };

  handleChange(event) {
    this.props.dispatch(changeArea({ roofArea: event.target.value }));
    localStorage.setItem(
      "alan",
      Math.round(String(Math.round(event.target.value)))
    );
  }

  handleChangeInput(event, idxx, inputType) {
    console.log(event.target.value, idxx, inputType);
    this.props.dispatch(
      changeDrawingPointsNew({
        drawingPointsNew: this.props.drawingPointsNew.map(
          (drawingSection, idx) => {
            if (idxx === idx) {
              return {
                ...drawingSection,
                slope: event.target.value,
              };
            }
            return drawingSection;
          }
        ),
      })
    );
  }

  handleChangeSlopeChoice(event, idxx, inputType) {
    this.props.dispatch(
      changeDrawingPointsNew({
        drawingPointsNew: this.props.drawingPointsNew.map(
          (drawingSection, idx) => {
            if (idxx === idx) {
              return {
                ...drawingSection,
                slope: event.target.value,
                selectedSlopeChoice: event.target.value,
              };
            }
            return drawingSection;
          }
        ),
      })
    );
  }

  handleChangeCepheSelect(event, idxx) {
    this.props.dispatch(
      changeDrawingPointsNew({
        drawingPointsNew: this.props.drawingPointsNew.map(
          (drawingSection, idx) => {
            if (idxx === idx) {
              return {
                ...drawingSection,
                aspect: event.target.value,
              };
            }
            return drawingSection;
          }
        ),
      })
    );
  }

  handleClick(event) {
    /* if (this.props.mobile || this.props.alanTercihi === 'manuel') {
      this.setState({
        markerLat: event.latLng.lat(),
        markerLng: event.latLng.lng()
      });
      //this.map.setCenter()
      //this.props.dispatch(changeLocation({latitude: event.latLng.lat(), longitude: event.latLng.lng()}));
      this.props.dispatch(changeTempLocation({tempLatitude: event.latLng.lat(), tempLongitude: event.latLng.lng()}));
      this.props.dispatch(changeMarker({markerOn: true}));
      //console.log(this.map.getCenter().lat());
    } */
    this.setState({
      markerLat: event.latLng.lat(),
      markerLng: event.latLng.lng(),
    });
    this.props.dispatch(
      changeTempLocation({
        tempLatitude: event.latLng.lat(),
        tempLongitude: event.latLng.lng(),
      })
    );
    this.props.dispatch(changeMarker({ markerOn: true }));
    this.props.dispatch(changeCurrentZoom({ currentZoom: this.map.getZoom() }));

    this.getCityDistrict(event.latLng.lat(), event.latLng.lng());
  }

  handleOnMapDrag(event) {
    this.props.dispatch(
      changeLocation({
        latitude: this.map.getCenter().lat(),
        longitude: this.map.getCenter().lng(),
      })
    );
    this.props.dispatch(
      changeTempLocation({
        tempLatitude: this.map.getCenter().lat(),
        tempLongitude: this.map.getCenter().lng(),
      })
    );
    /* this.setState({
      markerLat: this.map.getCenter().lat(),
      markerLng: this.map.getCenter().lng()
    }); */
    // this.props.dispatch(changeMarker({markerOn: true}));
  }

  handleZoomChange(event) {
    const currentZoom = this.map.getZoom();

    const baseSize = 32; // Baz genişlik (px)

    // Yakınlaştırma seviyesine göre boyut hesaplaması
    const scaleFactor = 2;
    const size = Math.round(baseSize * scaleFactor);

    this.state.imageSize = {
      height: size,
      width: size,
    };

    this.props.dispatch(changeTempMapZoom({ tempMapZoom: this.map.getZoom() }));
  }

  generateShapeCards2() {
    if (this.props.drawingPointsNew.length !== 0) {
      return this.props.drawingPointsNew.map((bolge, idxx) => {
        return (
          <div className={classes.shapeCard}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className={classes.insideWrapper}>
                <span
                  className={classes.sectionNumberRoundedText}
                  style={{ backgroundColor: bolge.areaColor }}
                >
                  {idxx + 1}
                </span>
                <div className={classes.drawingInfoSectionItem}>
                  {bolge.area && <span>{`${bolge.area} m2`}</span>}
                </div>
                <div className={classes.drawingInfoSectionItem}>
                  <div
                    className={classes.drawingInfoSectionItem}
                    style={{ marginRight: 3 }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.translationObject("RoofSlopePage.slope")}:
                    </span>
                  </div>
                  <FormControl variant="outlined">
                    <Select
                      labelId="slope-select-label"
                      id={`${String(idxx)}-slope`}
                      name="slope"
                      value={bolge.selectedSlopeChoice}
                      onChange={(e) =>
                        this.handleChangeSlopeChoice(e, idxx, "select")
                      }
                      label="Eğim Seçimi"
                      input={<BootstrapSelect />}
                    >
                      {[
                        {
                          name: `${this.props.translationObject(
                            "RoofSlopePage.duz"
                          )} (0-5°)`,
                          value: "0",
                        },
                        {
                          name: `${this.props.translationObject(
                            "RoofSlopePage.azegim"
                          )} (5-10°)`,
                          value: "5",
                        },
                        {
                          name: `${this.props.translationObject(
                            "RoofSlopePage.egimli"
                          )} (10-20°)`,
                          value: "15",
                        },
                        {
                          name: `${this.props.translationObject(
                            "RoofSlopePage.cokegim"
                          )} (+20°)`,
                          value: "25",
                        },
                        {
                          name: this.props.translationObject(
                            "RoofSlopePage.knownSlope"
                          ),
                          value: "10",
                        },
                      ].map((yon) => (
                        <MenuItem
                          style={{ fontSize: "0.85rem" }}
                          value={yon.value}
                        >
                          {yon.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {bolge.selectedSlopeChoice == "10" && (
                    <FormControl>
                      <TextField
                        style={{ width: "60px" }}
                        name="slope"
                        type="number"
                        value={bolge.slope}
                        /* style={{ width: '150px' }} */
                        id={idxx}
                        onChange={(e) =>
                          this.handleChangeInput(e, idxx, "input")
                        }
                        size="small"
                        variant="outlined"
                        error={
                          Number(bolge.slope) < 0 || Number(bolge.slope) > 90
                        }
                        helperText={
                          bolge.slope === "" ? "Boş bırakılamaz!" : ""
                        }
                        inputProps={{
                          min: 0,
                        }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                          endAdornment: (
                            <InputAdornment position="end">°</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  )}
                </div>
                <div className={classes.drawingInfoSectionItem}>
                  <div
                    className={classes.drawingInfoSectionItem}
                    style={{ marginRight: 3 }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.translationObject("RoofSlopePage.aspect")}:
                    </span>
                  </div>
                  <FormControl variant="outlined">
                    <Select
                      labelId="aspect-select-label"
                      id={String(idxx)}
                      name={idxx}
                      value={bolge.aspect}
                      onChange={(e) => this.handleChangeCepheSelect(e, idxx)}
                      label="Cephe Seçimi"
                      input={<BootstrapSelect />}
                    >
                      {[
                        {
                          name: this.props.translationObject(
                            "RoofSlopePage.kuzey"
                          ),
                          value: "-180",
                        },
                        {
                          name: this.props.translationObject(
                            "RoofSlopePage.guney"
                          ),
                          value: "0",
                        },
                        {
                          name: this.props.translationObject(
                            "RoofSlopePage.dogu"
                          ),
                          value: "-90",
                        },
                        {
                          name: this.props.translationObject(
                            "RoofSlopePage.bati"
                          ),
                          value: "90",
                        },
                        {
                          name: this.props.translationObject(
                            "RoofSlopePage.kuzeydogu"
                          ),
                          value: "135",
                        },
                        {
                          name: this.props.translationObject(
                            "RoofSlopePage.kuzeybati"
                          ),
                          value: "-45",
                        },
                        {
                          name: this.props.translationObject(
                            "RoofSlopePage.guneydogu"
                          ),
                          value: "-135",
                        },
                        {
                          name: this.props.translationObject(
                            "RoofSlopePage.guneybati"
                          ),
                          value: "45",
                        },
                      ].map((yon) => (
                        <MenuItem value={yon.value}>{yon.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => this.deleteShape(idxx)}
                  size="small"
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  getCityDistrict(lat, lng) {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyC8haRE39XSEpuxc8tR7s90FVhD3NxKUzg`
      )
      .then((response) => {
        let countryCode = "TR";
        response.data.results[0].address_components.forEach((addressObject) => {
          // Decide component names accorfing to the country
          // Türkiye olduğu durum
          if (
            addressObject.types[0] === "country" &&
            addressObject.short_name !== "TR"
          ) {
            countryCode = addressObject.short_name;
          }
        });
        response.data.results[0].address_components.forEach((addressObject) => {
          // Decide component names accorfing to the country
          // Türkiye olduğu durum
          if (countryCode === "TR") {
            if (addressObject.types[0] === "postal_code") {
              this.props.dispatch(
                changePlaka({ plaka: addressObject.short_name.slice(0, 2) })
              );
            } else if (
              addressObject.types[0] === "administrative_area_level_2"
            ) {
              if (
                String(addressObject.short_name) === "null" ||
                String(addressObject.short_name) === "undefined"
              ) {
                this.props.dispatch(changeIlce({ ilce: "Merkez" }));
              } else {
                this.props.dispatch(
                  changeIlce({ ilce: String(addressObject.short_name) })
                );
              }
            } else if (
              addressObject.types[0] === "administrative_area_level_1"
            ) {
              this.props.dispatch(
                changeCityName({ cityName: String(addressObject.short_name) })
              );
            }
          } else {
            // Türkiye harici ülke olduğu durum
            // Şehir ismi
            if (addressObject.types[0] === "locality") {
              this.props.dispatch(
                changeCityName({ cityName: String(addressObject.short_name) })
              );
            } else {
              if (addressObject.types[0] === "administrative_area_level_2") {
                this.props.dispatch(
                  changeIlce({ ilce: String(addressObject.short_name) })
                );
              } else {
                this.props.dispatch(changeIlce({ ilce: "-" }));
              }
            }
          }
        });
      });
    // .catch((error) => console.log(error));
  }
  getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  render() {
    return (
      <>
        <GoogleMap
          onClick={(e) => this.handleClick(e)}
          onDragEnd={(e) => this.handleOnMapDrag(e)}
          onZoomChanged={(e) => this.handleZoomChange(e)}
          /* defaultZoom={7} */
          zoom={this.props.tempMapZoom}
          //defaultCenter={{lat: Number(localStorage.getItem('enlem')), lng: Number(localStorage.getItem('boylam'))} || this.props.center}
          defaultMapTypeId="hybrid"
          defaultOptions={{
            /* scrollwheel: false,  mouse scroll'unu kapatıyor zoom için*/
            /* disableDefaultUI: true, */
            clickableIcons: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            rotateControl: false,
            tilt: 0,
          }}
          //center={{lat: Number(localStorage.getItem('enlem')), lng: Number(localStorage.getItem('boylam'))}}
          //center={{lat: this.props.markerOn ? this.props.tempLatitude : this.props.latitude, lng: this.props.markerOn ? this.props.tempLongitude : this.props.longitude}}
          center={{ lat: this.props.latitude, lng: this.props.longitude }}
          ref={this.mapMounted}
        >
          {(!this.props.drawingOnMedia || this.props.alanTercihi === "manuel") &&
            this.props.markerOn && (
              <Marker
                key={Math.round(this.state.markerLat)}
                //position={{ lat: this.state.markerLat, lng: this.state.markerLng }}
                position={{
                  lat: this.props.tempLatitude,
                  lng: this.props.tempLongitude,
                }}
              />
            )}

          {this.props.drawingPointsNew.length > 0 &&
            this.props.drawingOnMedia &&
            this.props.alanTercihi === "cizim" &&
            this.props.drawingPointsNew.map((bolge, idxx) => (
              <Polygon
                key={idxx}
                paths={bolge.points}
                options={{
                  strokeWeight: 5,
                  fillOpacity: 0.45,
                  fillColor: bolge.areaColor,
                  strokeColor: bolge.areaColor,
                  editable: false,
                  draggable: false,
                }}
              />
            ))}

          {this.props.drawingOnMedia && this.props.alanTercihi === "cizim" && (
            <DrawingManager
              //defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
              key={this.state.currentMode}
              defaultOptions={{
                polygonOptions: {
                  strokeWeight: 5,
                  fillOpacity: 0.45,
                  fillColor:
                    this.colorList[this.props.drawingPointsNew.length % 4],
                  strokeColor:
                    this.colorList[this.props.drawingPointsNew.length % 4],
                  editable: false,
                  draggable: false,
                },
                drawingControl: true,
                drawingControlOptions: {
                  position: google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: ["polygon"],
                },
              }}
              drawingMode={
                this.state.currentMode === "draw"
                  ? google.maps.drawing.OverlayType.POLYGON
                  : null
              }
              onOverlayComplete={this.handleOverlayComplete}
            />
          )}

          {/* Desktop Info Box */}
          {this.props.drawingOnMedia && this.props.alanTercihi === "cizim" && (
            <div
              className={classes.shapeCardWrapper}
              style={
                this.props.drawingPointsNew.length === 0
                  ? { display: "flex", justifyContent: "center" }
                  : {}
              }
            >
              {this.generateShapeCards2()}
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "var(--secondary-color)",
                }}
                variant="contained"
                color="primary"
                /* className={classes.button} */
                onClick={() => this.startDrawButton()}
                startIcon={<BorderColorIcon />}
              >
                {this.props.drawingPointsNew.length !== 0
                  ? this.props.translationObject("DrawingPage.addNewRoofArea")
                  : this.props.translationObject("DrawingPage.startDrawing")}
              </Button>
            </div>
          )}
          {/* Mobile Info Box */}
        </GoogleMap>

        <Snackbar
          open={this.state.showError}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="info">
            {this.props.smallAreaAlertText}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  latitude: state.location.latitude,
  longitude: state.location.longitude,
  zoomLevel: state.location.zoomLevel,
  roofArea: state.location.roofArea,
  markerOn: state.location.markerOn,
  tempLatitude: state.location.tempLatitude,
  tempLongitude: state.location.tempLongitude,
  panelAmount: state.location.panelAmount,
  alanTercihi: state.location.alanTercihi,
  currentZoom: state.location.currentZoom,
  tempMapZoom: state.location.tempMapZoom,
  stepNumber: state.steps.stepNumber,
  drawingPointsNew: state.location.drawingPointsNew,
});

export default connect(mapStateToProps)(withGoogleMap(Map));
