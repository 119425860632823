import axiosInstance from "../../components/axios/axiosApi";

export const loginReducer = ({ values }) => {
  // const {values} = payload
  const mail = localStorage.getItem("mail", values.email);
  if (!mail) {
    localStorage.setItem("mail", values.email);
  }
  
  return axiosInstance.post(`/single_sign_on`, {
    email: values.email || mail,
  })
};
