import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";

import de from "react-phone-input-2/lang/de.json";
import tr from "react-phone-input-2/lang/tr.json";
import { Button } from "@material-ui/core";

function UpdatePhoneNumber({
  contactFormInstance,
  open,
  submitHandler,
  setModalState,
}) {
  const [phoneNumberUpdateModal, setPhoneNumberUpdateModal] = useState(open);
  const [updatePhoneForm] = Form.useForm();
  const { t } = useTranslation();
  const { module } = useSelector((state) => state.module);

  React.useEffect(() => {
    setPhoneNumberUpdateModal(open);
  }, [open]);

  const sendSetPhoneNumberRequest = async (values) => {
    const updatePhoneNumberRequest = await submitHandler({
      phoneNumber: values.phone,
    });

    const { status, message } = updatePhoneNumberRequest.data;

    if (status) {
      alert(message);
      if (contactFormInstance) {
        contactFormInstance.setFieldValue("phoneNumber", values.phone);
      }
      setPhoneNumberUpdateModal(false);
    } else {
      alert(message || "Bir problem oluştu");
      updatePhoneForm.resetFields();
    }
  };

  // if open changes, props.setModalState will be call
  useEffect(() => {
    if (phoneNumberUpdateModal === false && setModalState) {

      setModalState(false);
    }
  }, [phoneNumberUpdateModal]);

  return (
    <Modal
      footer={null}
      open={phoneNumberUpdateModal}
      onCancel={() => setPhoneNumberUpdateModal(false)}
      title={t("UpdateNumberModalMessages.title")}

    >
      <p>
        {t("UpdateNumberModalMessages.updatePhoneNumberMessage")}
      </p>
      <Form
        style={{ marginTop: "5px" }}
        initialValues={{
          phone: "",
        }}
        onFinish={sendSetPhoneNumberRequest}
        form={updatePhoneForm}
      >
        <Form.Item
          name="phone"
          required
          rules={[{ required: true, message: "Alan zorunludur" }]}
        >
          <PhoneInput
            country={module === "german_solarvis" ? "de" : "tr"}
            onlyCountries={module !== "german_solarvis" ? ["tr"] : []}
            placeholder={
              module === "german_solarvis"
                ? t("ProfilePage.phoneLabel")
                : "(5xx) xxx xx xx"
            }
            masks={{ tr: "(...) ... .. ..", de: "... ........" }}
            areaCodes={false}
            prefix=""
            autoFormat
            disableCountryCode
            // countryCodeEditable={false}
            containerStyle={{ outline: "none", border: "none" }}
            localization={module === "german_solarvis" ? de : tr} // for input lang
            inputProps={{ autoComplete: "tel-national" }}
            inputStyle={{
              width: "100%",
              paddingTop: "12px",
              paddingBottom: "12px",
              outline: "none",
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderRadius: "0px",
              borderColor: "darkgray",
            }}
            onChange={(phone) => {
              if (phone.length > 10) {
                console.log(phone);
              }
            }}
          />
        </Form.Item>
        <Form.Item
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            color="primary"
          >
            {t("LoginPage.send")}
          </Button>


        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdatePhoneNumber;
